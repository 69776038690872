import { css } from '@emotion/css';
import { MagnifyingGlass, X } from 'phosphor-react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Div, Input, Text } from '../../shared/components';
import { container, flex } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { replaceText } from '../editor-components/text/text-utils';
import { EditorDetailsContext } from '../provider/editor-detail-provider';

export const Tags = ({ onClose, textUtils }) => {
  const ref = useRef();
  const { state, setState, focusEditor } = textUtils;
  const { editorDetails } = useContext(EditorDetailsContext);
  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState('');

  const addTag = (data) => {
    try {
      const selection = state?.getSelection();
      const { nextState } = replaceText(state, selection, `#[${data?.value}]#`);
      setState(nextState, true);
      onClose();
      focusEditor();
    } catch (err) {
      console.log(err);
    }
  };

  const list = useMemo(() => {
    if (!search || !showSearch) {
      return editorDetails?.tags;
    }
    return editorDetails?.tags?.filter((item) => {
      return item?.name?.toLowerCase()?.includes(search.toLowerCase());
    });
  }, [search, editorDetails?.tags?.length]);

  useEffect(() => {
    if (ref?.current && showSearch) {
      ref?.current?.focus();
    }
  }, [ref?.current, showSearch]);

  return (
    <Div
      css={css`
        width: 100%;
      `}
    >
      <Div
        css={css`
          ${flex('space-between')} padding: 32px;
          border-bottom: 1px solid ${colors.gray[300]};
        `}
      >
        {showSearch ? (
          <Input
            ref={ref}
            css={`
              width: 100%;
              border-radius: 30px;
              background-color: ${colors.gray[100]};
              border: none;
              :hover {
                border: none;
              }
              input {
                border: none;
                background-color: ${colors.gray[100]};
              }
            `}
            onMouseDown={(e) => e.stopPropagation()}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            startIcon={
              <MagnifyingGlass
                color={colors.gray[400]}
                className={css`
                  margin: 0 16px;
                `}
              />
            }
            endIcon={
              <Button
                styles="icon sm"
                css={`
                  margin-right: 8px;
                `}
                onClick={() => {
                  setSearch('');
                  setShowSearch(false);
                }}
              >
                <X />
              </Button>
            }
          />
        ) : (
          <>
            <Text h2>Smart Text</Text>
            <Button styles="icon" onClick={() => setShowSearch(true)}>
              <MagnifyingGlass />
            </Button>
          </>
        )}
      </Div>
      <Div
        css={css`
          padding: 16px 0;
        `}
      >
        {list?.map((tag) => (
          <Div
            key={tag?.value}
            css={css`
              ${flex('space-between')} padding: 16px 32px;
              ${container.hover}
            `}
            onClick={() => addTag(tag)}
          >
            <Text label>{tag?.name}</Text>
          </Div>
        ))}
      </Div>
    </Div>
  );
};
