import { Switch } from '@material-ui/core';
import { css } from '@emotion/css';
import { colors } from '../styles';
import { Div } from './div';

export const Toggle = (props) => {
  const { bgColorInactive = colors.gray[200], bgColorActive = colors.black, css: cssString = '', ...rest } = props;
  return (
    <Div
      className={css`
        .MuiSwitch-root {
          height: 48px;
          width: 72px;
          border-radius: 16px;
        }
        .MuiSwitch-thumb {
          background-color: white;
          margin: 7px 8px;
          height: 16px;
          width: 16px;
          :hover {
            background-color: white;
          }
        }
        .MuiIconButton-root + .MuiSwitch-track,
        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
          background: ${bgColorActive};
          opacity: 100%;
          border-radius: 30px;
        }

        .MuiIconButton-root + .MuiSwitch-track {
          background: ${bgColorInactive};
        }
        .MuiSwitch-switchBase.Mui-checked {
          transform: translateX(22px);
        }
        ${cssString}
      `}
    >
      <Switch {...rest} />
    </Div>
  );
};
