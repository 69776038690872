import { css } from '@emotion/css';
import { colors } from '../styles';

const dotStyle = (color) => css`
  position: absolute;
  background-color: ${color};
  border-radius: 30px;
  padding: 0 8px;
  min-width: 32px;
`;

export const Badge = ({ children, display = true, color = colors.purple, spacing = '12' }) => {
  return (
    <>
      {display && (
        <div
          className={css`
            top: -${spacing}px;
            right: -${spacing}px;
            ${dotStyle(color)}
          `}
        >
          {children}
        </div>
      )}
    </>
  );
};
