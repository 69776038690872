import { css } from '@emotion/css';
import { Asterisk, CaretDown, CaretUp } from 'phosphor-react';
import { useState } from 'react';
import { Div, Text } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { sectionContainer } from '../product-section';
import { Disclaimers } from './disclaimers';
import { Collapse } from '../../common/components/Collapse';

export const OptionalDetails = ({ state }) => {
  const [open, setOpen] = useState(false);

  return (
    <Div css={sectionContainer(open)} onClick={() => !open && setOpen(!open)}>
      <Div
        css={css`
          ${flex('jcsb aic')} cursor: pointer;
          ${open && `padding-bottom: 16px;`}
        `}
        onClick={() => setOpen(!open)}
      >
        <Div
          css={css`
            ${flex('aic')}
          `}
        >
          <Div
            css={css`
              ${flex('aic')} min-width: 32px;
              margin-right: 8px;
            `}
          >
            {<Asterisk />}
          </Div>
          <Text styles="h3">Optional Details</Text>
        </Div>
        {open ? <CaretUp /> : <CaretDown />}
      </Div>

      <Collapse isOpen={open}>{<Disclaimers state={state} />}</Collapse>
    </Div>
  );
};
