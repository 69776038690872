import { isToday } from 'date-fns';
import { css } from '@emotion/css';
import { CaretDown, CaretUp, Funnel, List, MagnifyingGlass, SquaresFour, X } from 'phosphor-react';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useQueryAPI } from '../react-query';
import { CustomAxios } from '../redux/axios/axios';
import { Button, Div, Input, Modal, Text } from '../shared/components';
import { BriteLoader } from '../shared/components/brite-loader';
import { flex } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { useDebounceValue } from '../shared/use-debounce-value';
import { useInput } from '../shared/use-input';
import { BenefitsPackageItems } from './BenefitPackageItems';
import { ACLWrapper } from '../shared/components/acl-wrapper';
import { WRITE_BENEFITS_PACKAGE } from '../shared/acl-constants';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { DocumentToBP } from './document-to-bp/document-to-bp';
import { Box } from '../shared/components/box';
import { Dropdown } from '../shared/components/dropdown';
import { Option } from '../shared/components/Option';
import { useForm } from '../shared/use-form';
import Dates from '../shared/components/Dates';
import { v4 as uuid } from 'uuid';
import { Checkbox } from '../shared/components/checkbox';

const DEFAULT_PACKAGE_NAME = 'Package Name';

const sortMap = {
  recentlyUpdated: {
    label: 'Recently Updated',
    sortFn: (list) => {
      if (!list) return [];
      return list.sort((a, b) => {
        const dateA = new Date(a.ModifiedAt);
        const dateB = new Date(b.ModifiedAt);
        if (dateA < dateB) return 1;
        if (dateA > dateB) return -1;
        if (a.Name < b.Name) return -1;
        if (a.Name > b.Name) return 1;
        return 0;
      });
    },
  },
  createdAt: {
    label: 'Created Date',
    sortFn: (list) => {
      if (!list) return [];
      return list.sort((a, b) => {
        const dateA = new Date(a.CreatedAt);
        const dateB = new Date(b.CreatedAt);
        if (dateA < dateB) return 1;
        if (dateA > dateB) return -1;
        if (a.Name < b.Name) return -1;
        if (a.Name > b.Name) return 1;
        return 0;
      });
    },
  },
  alphabetical: {
    label: 'Alphabetical',
    sortFn: (list) => {
      if (!list) return [];
      return list.sort((a, b) => {
        return a?.Name?.localeCompare(b?.Name);
      });
    },
  },
  planDate: {
    label: 'Plan Date',
    sortFn: (list) => {
      if (!list) return [];
      const unSetPlanDates = list.filter((item) => !item?.PlanStartDate || !item?.PlanEndDate);
      const setPlanDates = list.filter((item) => item?.PlanStartDate && item?.PlanEndDate);
      const setList = setPlanDates.sort((a, b) => {
        const dateA = new Date(a.PlanEndDate);
        const dateB = new Date(b.PlanEndDate);
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        if (a.Name < b.Name) return -1;
        if (a.Name > b.Name) return 1;
        return 0;
      });
      return [...setList, ...unSetPlanDates];
    },
  },
};

export const benefitProductsSelector = (data, key = 'CreatedAt') => {
  const list = data.sort((a, b) => a?.Name?.localeCompare(b?.Name));
  const { today, notToday } = list.reduce(
    (prev, item) => {
      if (isToday(new Date(item[key]))) {
        return { ...prev, today: [...prev.today, item] };
      }
      return { ...prev, notToday: [...prev.notToday, item] };
    },
    { today: [], notToday: [] }
  );
  return [...today, ...notToday];
};

export const BenefitPackagePage = () => {
  const { businessId } = useParams();

  const aiAutofill = useFeatureFlagPayload('ai-autofill');
  const [search, setSearch] = useState('');
  const [debounced_search] = useDebounceValue(search);

  const query = useQueryAPI({
    url: `v1/benefitspackage`,
    select: benefitProductsSelector,
  });

  const [pkgNameProps, setPkgName] = useInput(DEFAULT_PACKAGE_NAME);
  const [modal, setModal] = useState('');

  const organizer = useForm({
    view: 'blocks',
    sort: 'recentlyUpdated',
    filters: [],
  });

  // Get available Plan Date filters
  const availableFilters = useMemo(() => {
    const planYears = query?.data
      ?.reduce((prev, item) => {
        return [...prev, Dates.getDateFromISO(item?.PlanStartDate).getFullYear()];
      }, [])
      .sort((a, b) => b - a);
    const uniqueYears = [...new Set(planYears)];
    const planStartFilters = uniqueYears.map((year) => ({
      id: uuid(),
      label: `Plan Year ${year}`,
      validator: (data) => {
        return data?.PlanStartDate && Dates.getDateFromISO(data?.PlanStartDate).getFullYear() === year;
      },
    }));
    return [
      {
        id: uuid(),
        label: 'No Plan Year',
        validator: (data) => !data?.PlanStartDate,
      },
      ...planStartFilters,
    ];
  }, [query?.dataUpdatedAt]);

  const closeModal = () => {
    setModal('');
    setPkgName(DEFAULT_PACKAGE_NAME);
  };

  const createPackage = async (e) => {
    e?.preventDefault();
    try {
      const body = { Name: pkgNameProps.value };
      query?.cache?.setData([{ ...body, CreatedAt: new Date().toISOString(), ProductCount: 0 }, ...query.data]);
      closeModal();
      await CustomAxios.post(`/v1/benefitspackage`, body);
      window?.ChurnZero?.push(['trackEvent', 'Benefit Package Created', `Name: ${pkgNameProps.value}`, 1]);
    } catch (err) {
      toast.error(`Error creating new package.`);
      console.warn(err);
    } finally {
      query.refetch();
    }
  };

  const list = useMemo(() => {
    let list = query?.data || [];
    // Search input filter:
    if (!!debounced_search) {
      const term = debounced_search?.toLowerCase();
      list = query?.data?.filter((item) => {
        return item?.Name?.toLowerCase().includes(term);
      });
    }
    // Plan year filters:
    if (organizer.values.filters?.length) {
      list = list?.filter((pkg) => {
        return organizer.values.filters?.some?.((item) => item?.validator?.(pkg));
      });
    }
    // Sort list:
    return sortMap?.[organizer.values.sort]?.sortFn(list);
  }, [organizer.updateAt, debounced_search, query?.dataUpdatedAt]);

  const updateFilters = (item) => {
    console.log(item);
    const hasFilter = organizer.values.filters?.some?.(({ id }) => id === item?.id);
    let filters = organizer.values.filters;
    if (hasFilter) {
      filters = organizer.values.filters?.filter?.(({ id }) => id !== item?.id);
    } else {
      filters = [...organizer.values.filters, item];
    }
    organizer.setValues({ ...organizer.values, filters });
  };

  return (
    <>
      <Box
        page
        css={css`
          height: 100%;
        `}
      >
        <Div
          css={css`
            ${flex('space-between ai-center')}
          `}
        >
          <Text h1>Benefit Packages</Text>
          <Div
            css={css`
              ${flex('right')}
            `}
          >
            <Input
              styles="search"
              css={`
                margin-right: 16px;
                min-width: 300px;
              `}
              placeholder="Search"
              startIcon={<MagnifyingGlass color={colors.gray[500]} />}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Dropdown
              stopPropagation
              button={() => (
                <Button
                  secondary
                  css={`
                    position: relative;
                  `}
                >
                  <Funnel
                    size={24}
                    color={organizer.values.filters.length ? colors.purple : colors.black}
                    weight={organizer.values.filters.length ? 'bold' : 'bold'}
                  />
                  <strong>Filters</strong>
                </Button>
              )}
              popper={{ placement: 'bottom-end' }}
            >
              <Box
                css={`
                  background-color: white;
                  border-radius: 8px;
                `}
              >
                {availableFilters?.map((item) => {
                  const isChecked = organizer.values.filters?.some?.(({ id }) => id === item.id);
                  return (
                    <Box
                      option
                      onClick={() => updateFilters(item)}
                      flex="space-between"
                      css={`
                        width: 250px;
                      `}
                    >
                      <Text label bold>
                        {item?.label}
                      </Text>
                      <Checkbox checked={isChecked} />
                    </Box>
                  );
                })}
              </Box>
            </Dropdown>
            <Dropdown
              button={({ open }) => (
                <Button
                  secondary
                  css={`
                    text-transform: none;
                    padding: 10px 16px;
                    margin-right: 8px;
                  `}
                >
                  <Text label>
                    <strong>Sort:</strong> {sortMap[organizer.values.sort].label}
                  </Text>
                  {open ? <CaretUp size={24} /> : <CaretDown size={24} />}
                </Button>
              )}
              popper={{ placement: 'bottom-end' }}
            >
              <Box
                css={`
                  background-color: white;
                  width: 250px;
                  padding-top: 8px;
                `}
              >
                {Object.entries(sortMap).map(([key, { label }]) => (
                  <Option value={key} key={key} onClick={() => organizer.merge({ sort: key })}>
                    {label}
                  </Option>
                ))}
              </Box>
            </Dropdown>
            <Box
              flex="right"
              css={`
                margin-right: 16px;
                border: 1px solid ${colors.gray[300]};
                border-radius: 8px;
                padding: 4px;
                svg {
                  padding: 4px;
                  cursor: pointer;
                }
                svg[data-selected='true'] {
                  background-color: ${colors.gray[100]};
                  border-radius: 4px;
                }
              `}
            >
              <SquaresFour
                size={32}
                data-selected={organizer.values.view === 'blocks'}
                onClick={() => organizer.merge({ view: 'blocks' })}
              />
              <List
                size={32}
                data-selected={organizer.values.view === 'table'}
                onClick={() => organizer.merge({ view: 'table' })}
              />
            </Box>
            <ACLWrapper acls={[WRITE_BENEFITS_PACKAGE]}>
              <Button onClick={() => setModal('create-package')}>New Package</Button>
            </ACLWrapper>
          </Div>
        </Div>
        <Box
          flex="left"
          css={`
            height: 32px;
            gap: 16px;
          `}
        >
          {organizer.values.filters?.map((item) => (
            <Box pill onClick={(e) => updateFilters(item)}>
              <Text
                label
                css={`
                  margin-right: 8px;
                `}
              >
                {item.label}
              </Text>
              <X size={16} />
            </Box>
          ))}
        </Box>
        <Div
          css={css`
            position: relative;
            ${flex('center ai-center')} width: 100%;
          `}
        >
          <BriteLoader
            isLoading={query.isLoading}
            css={`
              height: calc(100vh - 180px);
              padding-bottom: 35vh;
            `}
          >
            <Text
              h2
              css={`
                margin: 32px 0;
              `}
            >
              Fetching Benefit Packages...
            </Text>
          </BriteLoader>
          <BenefitsPackageItems businessId={businessId} query={query} list={list} organizer={organizer} />
        </Div>
      </Box>

      <DocumentToBP
        display={aiAutofill?.value && modal === 'create-package'}
        startFromScratch={() => setModal('create-package-scratch')}
        onClose={closeModal}
      />

      <Modal
        display={(!aiAutofill?.value && modal === 'create-package') || modal === 'create-package-scratch'}
        onClose={closeModal}
      >
        <Modal.Paper>
          <Modal.Header title="Name this Benefit Package" onClose={closeModal} />
          <form onSubmit={createPackage}>
            <Modal.Body>
              <Text
                label
                bold
                css={`
                  margin-bottom: 8px;e
                `}
              >
                Name
              </Text>
              <Input
                {...pkgNameProps}
                onFocus={(event) => {
                  // Select the whole default value since we want them to overwrite it all.
                  event.target.select();
                }}
              />
            </Modal.Body>
            <Modal.Actions>
              <Button styles="secondary" onClick={closeModal}>
                Cancel
              </Button>
              <Button type="submit" disabled={!pkgNameProps.value}>
                Confirm
              </Button>
            </Modal.Actions>
          </form>
        </Modal.Paper>
      </Modal>
    </>
  );
};
