import { MenuItem } from '@material-ui/core';
import { css } from '@emotion/css';
import { Button, Div, Select } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { modifyContent } from '../provider/utils';

export const BenefitsToolbar = ({ editor, element, location, toolbar }) => {
  const { data } = element;
  return (
    <Div
      css={css`
        ${flex('left')}
      `}
    >
      <Button secondary onClick={() => toolbar.setModal('benefits-options')}>
        Change Plan
      </Button>
      {element?.component === 'pricing' ? (
        <Div
          css={css`
            margin-left: 16px;
          `}
        >
          <Select
            value={data?.variantStyle || 'clean'}
            onChange={(e) =>
              modifyContent.merge(editor, location, {
                data: { variantStyle: e.target.value },
              })
            }
            css={`
              width: 200px;
            `}
          >
            <MenuItem value="clean">Clean</MenuItem>
            <MenuItem value="bold">Bold</MenuItem>
          </Select>
        </Div>
      ) : null}
    </Div>
  );
};
