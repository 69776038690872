import { MenuItem } from '@material-ui/core';
import { RichUtils } from 'draft-js';
import { css } from '@emotion/css';
import {
  TextBolder,
  TextItalic,
  CaretDown,
  TextUnderline,
  ListBullets,
  LinkSimple,
  TextAlignLeft,
  TextAlignCenter,
  TextAlignRight,
  LinkSimpleBreak,
  TextStrikethrough,
  TextH,
  DotsThreeVertical,
  X,
  Trash,
  TextT,
} from 'phosphor-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Div, DropMenu, Input, Modal, Select, Text } from '../../shared/components';
import { flex, px } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { useForm } from '../../shared/use-form';
import { modifyContent } from '../provider/utils';
import { useEditorResource } from '../use-editor-resource';
import { ColorPicker } from './menu-components';
import { useEntity } from './use-entity';

export const formatUrl = (text) => {
  let url = text;
  if (!/^https?:\/\//i.test(url) && !/^http?:\/\//i.test(url)) {
    url = 'https://' + url;
  }
  try {
    const data = new URL(url);
    return [data.href, true];
  } catch (err) {
    return ['', false];
  }
};

const LinkSettings = ({ linkData, setLinkData, updateLink, removeLink, ...modalProps }) => {
  const { values, setValues, getProperty, hasChanges } = useForm(
    {
      ...linkData,
      target: linkData?.target || '_blank',
    },
    [linkData, modalProps?.display]
  );

  const validUrl = useMemo(() => {
    const [href, isValid] = formatUrl(values?.linkText);
    setValues({ ...values, href });
    return isValid;
  }, [values?.linkText]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (hasChanges && validUrl && !!values?.linkText) {
      updateLink(values);
    }
  };

  const closeLinkSettings = (e) => {
    const { name } = e.target || {};
    if (name === 'trash-link' || !values?.linkText) {
      removeLink();
    }
    modalProps.onClose();
  };

  return (
    <Modal {...modalProps}>
      <Div
        css={css`
          width: 500px;
        `}
      >
        <Div
          css={css`
            ${flex('space-between')} padding: 32px;
          `}
        >
          <Text h2>Link</Text>
          <Button styles="icon" onClick={modalProps.onClose}>
            <X />
          </Button>
        </Div>
        <form type="submit" onSubmit={handleSubmit}>
          <Div
            css={css`
              padding: 0 32px;
            `}
          >
            <Input {...getProperty('linkText')} placeholder="Link" error={!validUrl} />
            <Text
              label
              bold
              css={`
                margin-top: 16px;
              `}
            >
              Open link to
            </Text>
            <Select {...getProperty('target')}>
              <MenuItem value="_blank">New Tab</MenuItem>
              <MenuItem value="_self">Same Tab</MenuItem>
            </Select>
            <Div
              css={css`
                ${flex('space-between')} padding: 32px 0;
              `}
            >
              <Button styles="icon" onClick={closeLinkSettings} name="trash-link" disabled={!linkData?.href}>
                <Trash
                  className={css`
                    pointer-events: none;
                  `}
                />
              </Button>
              <Div
                css={css`
                  ${flex('right grow')}
                `}
              >
                <Button
                  css={`
                    margin-right: 16px;
                  `}
                  onClick={closeLinkSettings}
                  secondary
                >
                  Close
                </Button>
                <Button onClick={handleSubmit} disabled={!hasChanges || !validUrl}>
                  Update
                </Button>
              </Div>
            </Div>
          </Div>
        </form>
      </Div>
    </Modal>
  );
};

export const typographyMap = {
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  body: 'Body',
  subtitle: 'Subtitle',
  all: 'All',
};

export const TypographyDropdown = ({ type, changeType, styles, showAll = false, width = '130px' }) => {
  const currentTypography =
    type === 'all'
      ? {
          ...styles?.text?.body,
          fontSize: '16px',
          lineHeight: '18px',
          fontWeight: 'bold',
        }
      : {
          ...styles?.text?.[type],
          fontSize: '16px',
          lineHeight: '18px',
        };

  return (
    <DropMenu
      button={
        <Div
          css={css`
            ${flex('jcsb aic')} cursor: pointer;
            background-color: ${styles?.general?.backgroundColor};
            text-transform: capitalize;
            width: ${width};
            outline: 1px solid ${colors.gray[300]};
            padding: 8px;
            border-radius: 8px;
          `}
        >
          <Text style={currentTypography}>{typographyMap[type]}</Text>
          <CaretDown size={16} />
        </Div>
      }
      title=""
    >
      <Div
        className={css`
          width: 280px;
          background-color: ${styles?.general?.backgroundColor};
        `}
      >
        {showAll && (
          <MenuItem
            onClick={() => changeType('all')}
            style={{
              ...styles?.text?.body,
              fontSize: '16px',
              lineHeight: '18px',
              fontWeight: 'bold',
            }}
          >
            Any
          </MenuItem>
        )}
        <MenuItem onClick={() => changeType('h1')} style={styles?.text?.h1}>
          Heading 1
        </MenuItem>
        <MenuItem onClick={() => changeType('h2')} style={styles?.text?.h2}>
          Heading 2
        </MenuItem>
        <MenuItem onClick={() => changeType('h3')} style={styles?.text?.h3}>
          Heading 3
        </MenuItem>
        <MenuItem onClick={() => changeType('h4')} style={styles?.text?.h4}>
          Heading 4
        </MenuItem>
        <MenuItem onClick={() => changeType('body')} style={styles?.text?.body}>
          Body
        </MenuItem>
        <MenuItem onClick={() => changeType('subtitle')} style={styles?.text?.subtitle}>
          Subtitle
        </MenuItem>
      </Div>
    </DropMenu>
  );
};

export const TextToolbar = ({ editor, element, location, textUtils }) => {
  const onUpdate = (updates) => modifyContent.merge(editor, location, updates);
  const { data: styles } = useEditorResource('styles');

  const [displayLink, setDisplayLink] = useState(false);
  const [linkData, setLinkData] = useState({
    link: '',
    linkText: '',
    text: '',
    target: '_blank',
  });

  const { selectActiveEntity, activeEntity, updateEntity } = useEntity(textUtils);
  const [entitySelection, setEntitySelection] = useState(null);
  const hasSelection = !textUtils.state.getSelection().isCollapsed();

  const changeType = (type) => onUpdate({ type });

  const toggleRich = (event, type, style) => {
    event.preventDefault();
    if (type === 'block') {
      textUtils.toggleBlock(style);
    } else if (type === 'inline') {
      textUtils.toggleInline(style);
    }
  };

  const isActive = (...types) => {
    const currentStyles = textUtils.cursorStyles;
    const hasStyle = types.some((item) => currentStyles?.includes(item));
    return hasStyle ? `icon-active sm` : `icon sm`;
  };

  const selectEntity = () => {
    const selection = selectActiveEntity();
    setEntitySelection(selection);
  };

  const addLink = () => {
    selectEntity();
    const data = { href: '', linkText: '', target: '_blank' };
    setLinkData(data);
    setDisplayLink(true);
  };

  const removeLink = (e = null) => {
    e?.preventDefault();
    const nextState = RichUtils.toggleLink(textUtils.state, entitySelection, null);
    textUtils.setState(nextState);
  };

  const updateLink = (data) => {
    updateEntity(activeEntity.key, data);
    setLinkData(data);
    selectActiveEntity();
    setDisplayLink(false);
  };

  useEffect(() => {
    if (activeEntity?.type === 'LINK') {
      setLinkData(activeEntity?.data);
    } else {
      setLinkData({});
    }
  }, [activeEntity?.type, activeEntity?.key]);

  const updateColor = useCallback(
    (key, value) => {
      textUtils.toggleInline(`${key}-${value}`);
    },
    [textUtils.toggleInline]
  );

  const extractColorFromCursorStyles = (key, fallback) => {
    const colorReference = textUtils?.cursorStyles?.findLast?.((item) => item?.startsWith?.(key));
    if (colorReference) {
      const color = colorReference.replace(key, '');
      return color;
    }
    return fallback;
  };

  const fontColor = extractColorFromCursorStyles('color-', `var(--text-${element.type})`);

  const handleLink = () => {
    activeEntity?.key ? selectEntity() : addLink();
    setDisplayLink(true);
  };

  const toggleList = (e) => {
    const isUnordered = textUtils.cursorStyles?.includes('unordered-list-item');
    const isOrdered = textUtils.cursorStyles?.includes('ordered-list-item');
    if (!isUnordered && !isOrdered) {
      toggleRich(e, 'block', 'unordered-list-item');
    } else if (!isOrdered) {
      toggleRich(e, 'block', 'unordered-list-item');
      toggleRich(e, 'block', 'ordered-list-item');
    } else {
      toggleRich(e, 'block', 'ordered-list-item');
    }
  };

  const isCursorOnLink = activeEntity?.type === 'LINK';

  return (
    <Div>
      <Div
        css={css`
          ${flex('aic')}
        `}
        onMouseDown={(e) => e.preventDefault()}
        onClick={(e) => e.stopPropagation()}
      >
        <Div
          css={css`
            ${flex('aic')} padding-right: ${px.sm};
          `}
        >
          <TypographyDropdown type={element?.type} changeType={changeType} styles={styles} />
        </Div>

        <Div
          css={css`
            ${flex('aic')} padding: 0 ${px.sm};
            border-right: 1px solid ${colors.gray[200]};
          `}
        >
          <Button
            styles={`${isCursorOnLink ? 'icon-active' : 'icon'} sm`}
            disabled={!hasSelection && !isCursorOnLink}
            onClick={handleLink}
            hoverLabel={isCursorOnLink ? 'Edit Link' : 'Add Link'}
          >
            {isCursorOnLink ? <LinkSimple /> : <LinkSimpleBreak />}
          </Button>

          <Button styles={isActive('BOLD')} onClick={(e) => toggleRich(e, 'inline', 'BOLD')} hoverLabel="Bold">
            <TextBolder />
          </Button>
          <Button styles={isActive('ITALIC')} onClick={(e) => toggleRich(e, 'inline', 'ITALIC')} hoverLabel="Italic">
            <TextItalic />
          </Button>
          <DropMenu
            button={
              <Button
                hoverLabel="More Options"
                styles={isActive('UNDERLINE', 'STRIKETHROUGH', 'HIGHLIGHT')}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
              >
                <DotsThreeVertical />
              </Button>
            }
          >
            <Div
              css={css`
                ${flex('aic')} padding: 0 ${px.sm};
              `}
            >
              <Button
                styles={isActive('UNDERLINE')}
                onClick={(e) => toggleRich(e, 'inline', 'UNDERLINE')}
                hoverLabel="Underline"
              >
                <TextUnderline />
              </Button>
              <Button
                styles={isActive('STRIKETHROUGH')}
                onClick={(e) => toggleRich(e, 'inline', 'STRIKETHROUGH')}
                hoverLabel="Strikethrough"
              >
                <TextStrikethrough />
              </Button>
              <Button
                styles={isActive('HIGHLIGHT')}
                onClick={(e) => toggleRich(e, 'inline', 'HIGHLIGHT')}
                hoverLabel="Highlight"
              >
                <TextH />
              </Button>
            </Div>
            <Div
              css={css`
                ${flex('aic')} padding: 0 ${px.sm};
              `}
            >
              <Div
                css={css`
                  position: relative;
                `}
              >
                <Button
                  styles={isActive('SUPERSCRIPT')}
                  onClick={(e) => toggleRich(e, 'inline', 'SUPERSCRIPT')}
                  hoverLabel="Superscript"
                >
                  <TextT />
                </Button>
                <Text
                  label
                  css={`
                    position: absolute;
                    font-size: 0.83em;
                    top: -3px;
                    right: 0;
                  `}
                >
                  1
                </Text>
              </Div>
              <Div
                css={css`
                  position: relative;
                `}
              >
                <Button
                  styles={isActive('SUBSCRIPT')}
                  onClick={(e) => toggleRich(e, 'inline', 'SUBSCRIPT')}
                  hoverLabel="Subscript"
                >
                  <TextT />
                </Button>
                <Text
                  label
                  css={`
                    position: absolute;
                    font-size: 0.83em;
                    bottom: -3px;
                    right: 0;
                  `}
                >
                  1
                </Text>
              </Div>
            </Div>
          </DropMenu>
        </Div>

        <Div
          css={css`
            ${flex('aic')} padding: 0 ${px.sm};
            border-right: 1px solid ${colors.gray[200]};
          `}
        >
          <Button
            styles={isActive('LEFT-ALIGN', 'unstyled')}
            onClick={(e) => toggleRich(e, 'block', 'LEFT-ALIGN')}
            hoverLabel="Left Align"
          >
            <TextAlignLeft />
          </Button>
          <Button
            styles={isActive('CENTER-ALIGN')}
            onClick={(e) => toggleRich(e, 'block', 'CENTER-ALIGN')}
            hoverLabel="Center Align"
          >
            <TextAlignCenter />
          </Button>
          <Button
            styles={isActive('RIGHT-ALIGN')}
            onClick={(e) => toggleRich(e, 'block', 'RIGHT-ALIGN')}
            hoverLabel="Right Align"
          >
            <TextAlignRight />
          </Button>
          <Button styles={isActive('unordered-list-item')} onClick={toggleList} hoverLabel="List">
            <ListBullets />
          </Button>
        </Div>
        <Div
          css={css`
            margin-left: 8px;
          `}
        />
        <ColorPicker
          item={element}
          title="Font Color"
          updateColor={(value) => updateColor('color', value)}
          currentColor={fontColor}
        />
        <Div
          css={css`
            margin-left: 8px;
          `}
        />
        <ColorPicker
          item={element}
          title="Background Color"
          updateColor={(backgroundColor) =>
            onUpdate({
              container: { attributes: { style: { backgroundColor } } },
            })
          }
          currentColor={element?.container?.attributes?.style?.backgroundColor || ''}
        />
      </Div>

      {displayLink && (
        <LinkSettings
          display={displayLink}
          onClose={() => setDisplayLink(false)}
          {...{
            linkData,
            setLinkData,
            updateLink,
            removeLink,
          }}
        />
      )}
    </Div>
  );
};
