import { useResource, useRequests } from '../react-query';
import { guides } from '../react-query/resources/guides';
import { toast } from 'react-toastify';
import { ConfirmationModal } from '../shared/components';

export const DeleteGuideModal = ({ display, hideDeleteGuide, guideId }) => {
  const guidesResource = useResource(guides, { staleTime: 5 * 60 * 1000 });
  const guidesRequests = useRequests(guidesResource);

  const deleteCourse = async (id) => {
    try {
      const deleteGuide = guides.utils.getDelete(id);
      await guidesRequests.delete(deleteGuide);
    } catch (err) {
      console.error('Error uploading media.', err);
      toast.error(`Error uploading media.`);
    }
  };

  return (
    <ConfirmationModal
      display={display}
      title="Are you sure you want to delete this guide?"
      onConfirm={() => deleteCourse(guideId)}
      onClose={() => hideDeleteGuide()}
      isDeleteAlert
      confirmText="Delete Guide"
    />
  );
};
