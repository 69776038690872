import { MenuItem } from '@material-ui/core';
import { css } from '@emotion/css';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { sortMap } from '../BriteEditor/editor-components/benefits/add-benefit';
import { useQueryAPI } from '../react-query';
import { CustomAxios } from '../redux/axios/axios';
import { Button, Div, Modal, Select, Text } from '../shared/components';
import { flex } from '../shared/shared-styles';
import { CarrierLogo } from './carrier-logo';
import { CostSummary } from './field-components/cost-summary';
import { SummaryField } from './field-components/summary-field';
import { getNetworkMap } from './multi-network';
import { cloneDeep } from 'lodash';
import { PRODUCT_HEADERS } from '../benefit-package/constants';
import { summaryFieldConfigs, summarySectionConfigs } from './configs/product-summary.config';
import { getFieldConfigs, getFieldsObject, getSectionConfig } from './configs/config-utils';

export const ProductComparison = ({ productId, productType, packageId, businessId, ...modalProps }) => {
  const { data: products } = useQueryAPI({
    url: `v2/benefitspackage/${packageId}/product`,
    select: (data) => {
      const productsByType = data.filter(({ Type }) => productType === Type);
      const sortedProducts = productsByType.sort(
        (a, b) =>
          a?.MultiNetworkID?.localeCompare(b?.MultiNetworkID) ||
          sortMap[a?.MultiNetworkCategory]?.localeCompare(sortMap[b?.MultiNetworkCategory])
      );
      return sortedProducts;
    },
    defaultValue: [],
  });

  const [selectedComps, setSelectedComps] = useState([]);
  const [compProducts, setCompProducts] = useState([]);

  const getComps = async () => {
    try {
      const promises = await Promise.allSettled(
        selectedComps.map(({ ID }) => CustomAxios.get(`v1/product-layout/product/${ID}`))
      );
      const comps = selectedComps.reduce((prev, item, idx) => {
        return [
          ...prev,
          {
            ...item,
            layout: promises?.[idx]?.value?.data?.Layout,
          },
        ];
      }, []);

      let updatedComps = [];

      for (let comp of comps) {
        let clonedData = cloneDeep(comp);

        if (clonedData?.layout?.Sections) {
          for (let section of clonedData.layout.Sections) {
            if (section.DisplayValue === 'Vision') {
              let combinedFrequencyFields = [];
              for (let field of section.Fields) {
                if (field.Type === 'visionFrequency') {
                  combinedFrequencyFields[combinedFrequencyFields.length - 1].frequency = field;
                  combinedFrequencyFields[combinedFrequencyFields.length - 1].Type = 'visionFrequency';
                } else {
                  combinedFrequencyFields.push(field);
                }
              }

              section.Fields = combinedFrequencyFields;
            }
          }
        }

        updatedComps.push(clonedData);
      }

      console.log('updatedComps', updatedComps);
      setCompProducts(updatedComps);
    } catch (err) {
      console.warn(err);
      toast.error(`Error loading products...`);
    }
  };

  useEffect(() => {
    getComps();
  }, [selectedComps.map(({ ID }) => ID).toString()]);

  useEffect(() => {
    const product1 = products?.find(({ ID }) => ID === productId);
    const product2 = products?.find(({ ID }) => ID !== productId);
    if (product1 && product2) {
      setSelectedComps([product1, product2]);
    }
  }, [products]);

  const costSectionState = useMemo(() => {
    return compProducts.reduce((prev, item) => {
      const idx = item?.layout?.Sections?.findIndex(({ DisplayValue }) => DisplayValue === 'Premiums & Contributions');
      if (idx > -1) {
        const cost = item?.layout?.Sections?.[idx];
        const field = cost?.Fields?.find(({ PropertyChain }) => PropertyChain === 'Cost');
        return [...prev, field?.State !== 'hide'];
      }
      return prev;
    }, []);
  });

  const updateSelectedComps = (event, idx) => {
    let comps = [...selectedComps];
    const product = products?.find(({ ID }) => ID === event.target.value);
    comps.splice(idx, 1, product);
    setSelectedComps(comps);
  };

  const getFieldState = (sectionIdx, fieldIdx) => {
    const hasShowFieldState = compProducts.some((product) => {
      const field = product?.layout?.Sections?.[sectionIdx]?.Fields?.[fieldIdx];
      return field?.State !== 'hide';
    });
    return hasShowFieldState;
  };

  return (
    <Modal {...modalProps} full>
      <Div
        css={css`
          width: calc(100vw - 64px);
          height: calc(100vh - 64px);
          overflow-y: auto;
          margin: 32px;
        `}
      >
        <Div
          css={css`
            ${flex('space-between')} box-sizing: border-box;
          `}
        >
          <Text h1>Compare Plans</Text>
          <Button styles="secondary" onClick={modalProps.onClose}>
            Close
          </Button>
        </Div>

        <Div
          css={css`
            ${flex('left start')}
          `}
        >
          {compProducts.map((item, idx) => {
            const fieldsObject = getFieldsObject(item?.layout?.Sections);
            return (
              <Div
                css={css`
                  width: 400px;
                `}
              >
                <Div
                  css={css`
                    padding: 0 32px;
                  `}
                >
                  {item?.Details?.IsMultiCarrier ? (
                    <div
                      className={css`
                        display: flex;
                        align-items: center;
                        gap: 16px;
                        justify-content: space-between;
                      `}
                    >
                      <CarrierLogo
                        carrierID={item?.ProviderID || '00000000-0000-0000-0000-000000000000'}
                        planCarrierName={item?.ProviderName || ''}
                        maxWidth="150px"
                        maxHeight="100px"
                        position="center"
                      />
                      <div
                        className={css`
                          border-left: 1px solid #26282d;
                          height: 50px;
                        `}
                      ></div>
                      <CarrierLogo
                        carrierID={item?.Details?.MultiCarrierID || '00000000-0000-0000-0000-000000000000'}
                        planCarrierName={item?.Details?.MultiCarrierName || ''}
                        maxWidth="150px"
                        maxHeight="100px"
                        position="center"
                      />
                    </div>
                  ) : (
                    <CarrierLogo
                      maxWidth="200px"
                      maxHeight="100px"
                      position="bottom center"
                      carrierID={item.ProviderID}
                      planCarrierName={item.ProviderName}
                      className={css`
                        margin-bottom: 16px;
                      `}
                    />
                  )}

                  <Select
                    value={item?.ID}
                    onChange={(event) => updateSelectedComps(event, idx)}
                    className={css`
                      margin-bottom: 32px;
                      ${flex('space-between')}
                    `}
                  >
                    {products?.map((item) => (
                      <MenuItem key={item?.ID} value={item?.ID}>
                        <Div
                          css={css`
                            ${flex('space-between')} width: 100%;
                          `}
                        >
                          <Text label>{item.ProductName || PRODUCT_HEADERS[item?.Type]}</Text>
                          <Text
                            className={css`
                              flex-grow: 1;
                              text-align: right;
                            `}
                          >
                            {getNetworkMap(item?.Type)[item?.MultiNetworkCategory]}
                          </Text>
                        </Div>
                      </MenuItem>
                    ))}
                  </Select>
                </Div>

                {costSectionState.some((item) => item) && (
                  <CostSummary
                    state={{ product: item, businessId }}
                    comparisonView={true}
                    markHidden={!costSectionState[idx]}
                  />
                )}

                {item.layout?.Sections?.map((section, sectionIdx) => {
                  const fieldConfig = getFieldConfigs(summaryFieldConfigs, {
                    product: item,
                    fieldsObject,
                  });
                  return !getSectionConfig(summarySectionConfigs, section)?.hideSection &&
                    section?.DisplayValue !== 'Premiums & Contributions' ? (
                    <>
                      <Text
                        h4
                        className={css`
                          margin: 32px;
                          margin-bottom: 0;
                        `}
                      >
                        {section?.DisplayValue}
                      </Text>
                      {section?.Fields?.map((field, fieldIdx) =>
                        getFieldState(sectionIdx, fieldIdx) ? (
                          <SummaryField
                            product={item}
                            config={fieldConfig[field?.PropertyChain]}
                            field={field}
                            markHidden={true}
                            comparisonView={true}
                          />
                        ) : null
                      )}
                    </>
                  ) : null;
                })}

                {item?.Type === 'insurance_plan' && item?.TitleDescriptions && item?.TitleDescriptions.length > 0 && (
                  <Text
                    h4
                    className={css`
                      width: 100%;
                      padding: 16px 32px;
                      padding-top: 32px;
                    `}
                  >
                    Other Details
                  </Text>
                )}
                {item?.TitleDescriptions?.filter(({ Autogenerated = false }) => !Autogenerated)?.map(
                  ({ Title }, idx) => (
                    <SummaryField
                      product={item}
                      markHidden={true}
                      comparisonView={true}
                      field={{
                        DisplayValue: Title,
                        State: 'show',
                        Type: 'text-input',
                        PropertyChain: `TitleDescriptions[${idx}].Description`,
                      }}
                    />
                  )
                )}
              </Div>
            );
          })}
        </Div>
      </Div>
    </Modal>
  );
};
