import { useState } from 'react';

// This hook is used to manage the state of a modal with data.
export const useModalWithData = (initialState = false, initialData = null) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const [data, setData] = useState(initialData);

  // Show the modal with the given data.
  const show = (data) => {
    setData(data);
    setIsOpen(true);
  };

  // Hide the modal and reset the data.
  const hide = () => {
    setIsOpen(false);
    setData(null);
  };

  return { isOpen, data, show, hide };
};
