import React, { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { css } from '@emotion/css';
import { Typography } from '@material-ui/core';
import { UploadSimple, FileX } from 'phosphor-react';
import { useEvent } from '../shared/use-event';

const rejectStyle = css`
  border: 3px dashed #f62222;
`;

const activeDragStyle = css`
  @keyframes toWhite {
    from {
      background: none;
    }
    to {
      background: white;
    }
  }
  background: white;
  z-index: 100;
  animation: toWhite 0.15s;
  display: flex;
  justify-content: center;
  border: 3px dashed #2196f3;
`;

const container = ({ isDragActive, isDragAccept, isDragReject }) => css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 20%;
  text-align: center;
  background: none;
  outline: none;
  ${isDragActive && activeDragStyle}
  ${!isDragAccept && isDragReject && rejectStyle}
`;

export const fileTypesMap = {
  csv: 'text/csv',
  pdf: 'application/pdf',
  txt: 'text/plain',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  images: 'image/png, image/gif, image/jpeg, image/svg+xml',
};

export const FullScreenUploader = ({ onDrop, noClick = true, accept }) => {
  const lastElement = useRef();
  const [display, setDisplay] = useState(false);

  const triggerOn = (e) => {
    lastElement.current = e.target;
    setDisplay(true);
  };

  const triggerOff = (e) => {
    if (e.target === lastElement.current || e.target === document) {
      setDisplay(false);
    }
  };

  useEvent('dragenter', triggerOn);
  useEvent('dragleave', triggerOff);
  useEvent('dragend', triggerOff);
  useEvent('drop', triggerOff);

  const mappedFiles = Object.entries(fileTypesMap || {})
    .reduce((prev, [key, value]) => {
      if (accept.includes(key)) {
        return [...prev, value];
      }
      return prev;
    }, [])
    .join(', ');

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    noClick,
    accept: mappedFiles,
    noDragEventsBubbling: true,
  });

  if (!display) {
    return null;
  }

  return (
    <div
      {...getRootProps({
        className: container({ isDragActive, isDragReject, isDragAccept }),
      })}
    >
      {isDragActive && (
        <div>
          {!isDragReject && (
            <>
              <UploadSimple size={72} color="#2196f3" />
              <Typography variant="h4">Drop file anywhere to upload</Typography>
            </>
          )}
          {isDragReject && (
            <>
              <FileX size={72} color="#F62222" />
              <Typography variant="h4" color="error">
                Sorry, but we only support
              </Typography>
              <Typography color="error" variant="h6">
                {accept} files
              </Typography>
            </>
          )}
        </div>
      )}
      <input {...getInputProps()} />
    </div>
  );
};
