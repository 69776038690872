import { isToday } from 'date-fns';
import { extractSearchQuery, getGenericQueryProps, getUrl } from '.';

const url = '/v2/media';

const utils = {
  sortDocuments: (data = []) => {
    const docs = data.reduce(
      (prev, item) => {
        const createdAtIsToday = isToday(new Date(item.CreatedAt));
        return createdAtIsToday
          ? { ...prev, recent: [...prev.recent, item] }
          : { ...prev, former: [...prev.former, item] };
      },
      { recent: [], former: [] }
    );

    const recentDocs = docs.recent.sort((a, b) => a.Name.localeCompare(b.Name));
    const formerDocs = docs.former.sort((a, b) => a.Name.localeCompare(b.Name));

    return [...recentDocs, ...formerDocs];
  },
  extractFileObject: (file) => {
    return {
      data: file,
      name: file?.name,
      type: file?.type,
    };
  },
  getPost: (file, searchQuery = {}) => {
    const name = file.name ? encodeURIComponent(file.name) : 'no-name';
    const type = file.type ? encodeURIComponent(file.type.replace(/\//g, '.')) : 'image.png';
    const searchParams = extractSearchQuery(searchQuery);
    const url = `/v2/media/${name}/${type}?${searchParams}`;
    const body = new FormData();
    body.append('file', file?.data);
    const axiosConfig = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return { url, body, config: axiosConfig };
  },
  getPut: (id, body) => ({
    url: `/v2/media/${id}`,
    body,
    optimistic: (old) => {
      const { Filename, ...rest } = body;
      return old?.map((item) => (item?.ID === id ? { ...item, ...rest, Name: Filename } : item));
    },
  }),
  getDelete: (id) => {
    return {
      url: `/v2/media/${id}`,
      optimistic: (old) =>
        old?.map((item) => {
          const { ID, ...rest } = item;
          if (ID === id) {
            return rest;
          }
          return item;
        }),
    };
  },
};

export const media = {
  url,
  key: 'media',
  getResourceOptions: (resourceProps) => {
    const resourceUrl = getUrl(url, resourceProps);
    return {
      url: resourceUrl,
      queryProps: {
        initialData: [],
        select: utils.sortDocuments,
        ...getGenericQueryProps(resourceUrl, resourceProps),
      },
    };
  },
  utils,
};
