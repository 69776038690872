import React from 'react';
import { Text } from '../shared/components/text';
import { Div } from '../shared/components/div';
import { Button } from '../shared/components/button';
import { PhosphorLogo } from 'phosphor-react';
import { colors } from '../shared/styles';
import { flex, px } from '../shared/shared-styles';
import { css } from '@emotion/css';

export const Components = () => {
  return (
    <Div
      css={css`
        display: flex;
      `}
    >
      <Div
        css={css`
          ${flex('grow')} padding: ${px.md};
        `}
      >
        <Text styles="h2 underline">Text</Text>
        <Text styles="h1">H1</Text>
        <Text styles="h2">H2</Text>
        <Text styles="h3">H3</Text>
        <Text styles="h4">H4</Text>
        <Text>Body</Text>
        <Text styles="label">Label</Text>
        <Text styles="caption">Caption</Text>
      </Div>
      <Div
        css={css`
          ${flex('grow')} padding: ${px.md};
        `}
      >
        <Text styles="h2 underline">Button</Text>
        <Button styles="mt">Primary</Button>
        <Button styles="secondary mt">Secondary</Button>
        <Button styles="text mt">Text</Button>
        <Button styles="mt" disabled>
          Disabled
        </Button>
        <Div
          css={css`
            ${flex('aic')} margin-top: ${px.md};
          `}
        >
          <Button styles="icon">
            <PhosphorLogo />
          </Button>
          <Text styles="ml label">Icon</Text>
        </Div>
      </Div>
      <Div
        css={css`
          ${flex('grow')} padding: ${px.md};
        `}
      >
        <Text styles="h2 underline">Colors</Text>

        <Text styles="label" color={colors.gray}>
          Grays
        </Text>
        <Div
          css={css`
            ${flex('wrap')}
          `}
        >
          <Text styles="pad-sm mr right radius" color={colors.gray[100]} bgColor={colors.gray[600]}>
            gray[600]
          </Text>
          <Text styles="pad-sm mr right radius" color={colors.gray[200]} bgColor={colors.gray[500]}>
            gray[500]
          </Text>
          <Text styles="pad-sm mr right radius" color={colors.gray[300]} bgColor={colors.gray[400]}>
            gray[400]
          </Text>
          <Text styles="pad-sm mr right radius" color={colors.gray[400]} bgColor={colors.gray[300]}>
            gray[300]
          </Text>
          <Text styles="pad-sm mr right radius" color={colors.gray[500]} bgColor={colors.gray[200]}>
            gray[200]
          </Text>
          <Text styles="pad-sm mr right radius" color={colors.gray[600]} bgColor={colors.gray[100]}>
            gray[100]
          </Text>
        </Div>

        <Div
          css={css`
            ${flex('jcsb aic')}
          `}
        >
          <Text styles="label">Black</Text>
          <Text styles="pad-sm radius" color="white" bgColor={colors.black}>
            black
          </Text>
        </Div>

        <Div
          css={css`
            ${flex('jcsb aic')}
          `}
        >
          <Text styles="label">Red</Text>
          <Text styles="pad-sm radius" color="white" bgColor={colors.red[100]}>
            red[100]
          </Text>
        </Div>

        <Div
          css={css`
            ${flex('jcsb aic')}
          `}
        >
          <Text styles="label">Orange</Text>
          <Text styles="pad-sm radius" color="white" bgColor={colors.orange[100]}>
            orange[100]
          </Text>
        </Div>

        <Div
          css={css`
            ${flex('jcsb aic')}
          `}
        >
          <Text styles="label">Yellow</Text>
          <Text styles="pad-sm radius" color="white" bgColor={colors.yellow[100]}>
            yellow[100]
          </Text>
        </Div>

        <Div
          css={css`
            ${flex('jcsb aic')}
          `}
        >
          <Text styles="label">Green</Text>
          <Text styles="pad-sm radius" color="white" bgColor={colors.green[100]}>
            green[100]
          </Text>
        </Div>
      </Div>
    </Div>
  );
};
