import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { useSearchParams } from '../shared/use-search-params';
import { useStore } from '../store-provider/use-store';
import { genericRequest, getResourcePacket } from './resources';
import { CustomAxios } from '../redux/axios/axios';
import { useMemo } from 'react';
import { cloneDeep } from 'lodash';

// resourceProps: {
//
//    ...useQueryProps,
//
//    ------------------------------------------------------------------------
//    params: object
//      | params: { param: 'value' }       =    '/path/:param'       =   '/path/value'
//    search: object
//      | search: { query: 'value' }  =    '/path?query=value'  =   '/path?query=value'
//
//      --> We'll try to extract params from the location.pathname & location.search query by default.
//      --- Optionally, where that is not possible params can be passed in manually
//    ------------------------------------------------------------------------
//    transform: function
//      --> Use this instead of react-query's `select` function so that we can
//      --- transform the data without breaking the response structure.
//
// }

export const useResource = (resourceObject, resourceProps = {}) => {
  const { transform = null } = resourceProps;

  const queryClient = useQueryClient();

  const routerParams = useParams();
  const { params: searchParams } = useSearchParams();

  const {
    data: { user, selectedBusiness },
  } = useStore();

  const params = {
    ...routerParams,
    ...searchParams,
    productId: routerParams?.planId || '',
    userId: user?.ID,
    ...resourceProps?.params,
    ...resourceProps?.search,
  };

  const businessId = selectedBusiness ? selectedBusiness.ID || '' : '';
  const resourcePacket = getResourcePacket(resourceObject, {
    businessId,
    ...resourceProps,
    params,
  });

  const query = useQuery(
    resourcePacket.queryProps?.queryKey,
    resourcePacket.queryProps?.queryFn,
    resourcePacket.queryProps
  );

  const setData = (value) => {
    queryClient.setQueryData(resourcePacket.queryProps?.queryKey, value);
  };

  const data = useMemo(() => {
    const next = transform ? transform(query?.data) : query?.data;
    return next;
  }, [transform, query?.dataUpdatedAt]);

  return {
    // For convenience, data is added here (so we can *transform the data)
    data,
    // Modified URL
    url: resourcePacket?.url,
    // Resource Key
    key: resourceObject?.key,
    // useQuery return
    query,
    // Update cached data (optimistic UI)
    setData,
    // Params for use in methods
    params,
  };
};

export const useRequests = (resource = {}) => {
  const request = (axios, options) => {
    const { optimistic, ...rest } = options;
    return genericRequest(axios, optimistic, resource, rest);
  };

  return {
    put: async (options = {}) => {
      const { url, body, config, ...rest } = options;
      return await request(() => CustomAxios.put(url, body, config), rest);
    },
    post: async (options = {}) => {
      const { url, body, config, ...rest } = options;
      return await request(() => CustomAxios.post(url, body, config), rest);
    },
    delete: async (options = {}) => {
      const { url, body, config, ...rest } = options;
      return await request(() => CustomAxios.delete(url, config), rest);
    },
    patch: async (options = {}) => {
      const { url, body, config, ...rest } = options;
      return await request(() => CustomAxios.patch(url, body, config), rest);
    },
  };
};

// Optimistically update items in a resource (use resource utils if possible)
export const bulkOptimistic = (resource, next) => {
  const old = cloneDeep(resource?.query?.data);
  resource?.setData(next);
  // Return a function to revert the changes
  return () => resource?.setData(old);
};
