import { css } from '@emotion/css';
import { CaretDown, CaretUp } from 'phosphor-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { compareTwoStrings } from '../../benefit-package/auto-paster-v2/paste-utils';
import { useQueryAPI } from '../../react-query';
import { Div, Input, Text } from '../../shared/components';
import { animation, container, flex } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { useBounds } from '../../shared/use-bounds';
import { useOutsideClick } from '../../shared/use-outside-click';
import { useStateSync } from '../../shared/use-state-sync';
import { productModifier } from '../configs/product-utils';

export const emptyValue = {
  ID: '00000000-0000-0000-0000-000000000000',
  Name: 'Custom Carrier',
};

export const handleCarriers = (data) => {
  const filteredData = data.filter(({ Name }) => Name);
  return filteredData;
};

export const Carriers = ({ state, field, config }) => {
  const isMultiCarrier = field?.PropertyChain === 'Details.MultiCarrierID';

  const property = isMultiCarrier ? 'MultiCarrier' : 'Provider';

  const idProperty = property + 'ID';
  const nameProperty = property + 'Name';
  const logoProperty = property + 'LogoUrl';

  const searchTermValue =
    state?.searchTerm?.propertyChain === idProperty || state?.searchTerm?.propertyChain === nameProperty
      ? state?.searchTerm?.value
      : '';

  const propertyPath = useMemo(() => {
    if (isMultiCarrier) {
      return state?.product?.Details || {};
    } else {
      return state?.product || {};
    }
  });

  const isCustom = !propertyPath[idProperty] || propertyPath[idProperty] === emptyValue.ID;

  const resetSearchTerm = () => {
    state?.dispatch({
      type: 'set-search-term',
      payload: {},
    });
  };

  const inputRef = useRef();

  const { data: carriers = [], dataUpdatedAt } = useQueryAPI({
    url: `/v1/carriers`,
    defaultValue: [emptyValue],
    select: handleCarriers,
    enabled: false,
  });

  const [isFiltered, setIsFiltered] = useState(false);

  const [search, setSearch] = useStateSync(() => {
    if (!!searchTermValue) {
      return searchTermValue;
    }

    if (isCustom) {
      return propertyPath[nameProperty];
    }

    return carriers.find(({ ID }) => ID === propertyPath[idProperty])?.Name || emptyValue.Name;
  }, [dataUpdatedAt, propertyPath[idProperty], propertyPath[nameProperty], searchTermValue, isCustom]);

  const [open, setOpen] = useState(false);

  const listRef = useOutsideClick(() => setOpen(false));

  const list = useMemo(() => {
    if (!isFiltered || !search) {
      return carriers;
    }
    const searchValue = search?.toLowerCase();
    return carriers.filter(({ Name }) => {
      const option = Name?.toLowerCase();

      if (!searchValue) {
        return true;
      }
      const isValid =
        option?.includes(searchValue) || searchValue?.includes(option) || compareTwoStrings(option, searchValue) >= 0.6;
      return isValid;
    });
  }, [isFiltered, search, carriers.length]);

  const selectCarrier = (item) => {
    setOpen(false);
    const name = item?.ID === emptyValue?.ID ? search : item?.Name;

    if (isMultiCarrier) {
      productModifier.setProduct(
        state,
        {
          ...state?.product,
          Details: {
            ...state?.product.Details,
            [idProperty]: item?.ID,
            [logoProperty]: item?.LogoURL,
            [nameProperty]: name,
          },
        },
        {
          category: state?.network?.category,
        }
      );
    } else {
      productModifier.setProduct(
        state,
        {
          ...state?.product,
          [idProperty]: item?.ID,
          [logoProperty]: item?.LogoURL,
          [nameProperty]: name,
        },
        {
          category: state?.network?.category,
        }
      );
    }
    resetSearchTerm();
  };

  const setCustomName = () => {
    if (isCustom) {
      if (isMultiCarrier) {
        productModifier.setProduct(
          state,
          {
            ...state?.product,

            Details: {
              ...state?.product.Details,

              [nameProperty]: search,
            },
          },

          {
            category: state?.network?.category,
          }
        );
      } else {
        productModifier.setProduct(
          state,
          {
            ...state?.product,
            [nameProperty]: search,
          },
          {
            category: state?.network?.category,
          }
        );
      }
      resetSearchTerm();
    }
  };

  useEffect(() => {
    if (!open && !isCustom && propertyPath[nameProperty]) {
      setSearch(propertyPath[nameProperty]);
    }
  }, [open]);

  useEffect(() => {
    if (!!searchTermValue) {
      setOpen(true);
      setIsFiltered(true);
    }
  }, [searchTermValue]);

  const buttonBounds = useBounds(inputRef, [open]);
  const boundsHeight = buttonBounds.top < 100 + window.innerHeight * 0.4;
  const position = boundsHeight ? 'bottom' : 'top';

  return (
    <Div
      css={css`
        position: relative;
        width: 100%;
      `}
    >
      <Input
        className="suggested-field"
        placeholder={isCustom ? 'Custom Carrier Name' : 'Select a Carrier'}
        disabled={config?.disabled}
        endIcon={
          <Div
            css={css`
              ${flex('left')}
              ${isCustom ? `border-left: 1px solid ${colors.gray[300]};` : ``}
              overflow: visible;
              cursor: pointer;
              p {
                padding: 0 8px;
              }
            `}
            onClick={() => setOpen(true)}
          >
            {isCustom ? <Text label>Custom</Text> : null}
            {open ? <CaretUp /> : <CaretDown />}
          </Div>
        }
        ref={inputRef}
        onFocus={() => !isCustom && setOpen(true)}
        onClick={() => {
          setOpen(true);
          setIsFiltered(true);
        }}
        onBlur={setCustomName}
        value={search}
        data-field={true}
        onChange={(e) => {
          if (!open) {
            setOpen(true);
          }
          setIsFiltered(true);
          setSearch(e.target.value);
        }}
      />
      {(open && !isCustom) || (open && list.length && isCustom) ? (
        <Div
          css={css`
            transition: opacity 0.2s ease;
            ${animation('fadeIn', '.2s ease')}
            position: absolute;
            ${position === 'bottom' ? `top: calc(100% + 8px);` : position === 'top' ? `bottom: calc(100% + 8px);` : ``}
            left: 0;
            width: 100%;
            z-index: 10000000000000000000000000000000000000;
            ${container.box}
            padding: 0;
            max-height: 40vh;
            overflow: auto;
            min-height: 56px;
            ${searchTermValue ? `outline: 2px solid ${colors.purple};` : ''}
            div {
              padding: 8px 16px;
            }
          `}
          ref={listRef}
        >
          {isCustom ? null : (
            <Div
              css={css`
                ${container.hover}
              `}
              onClick={() => selectCarrier(emptyValue)}
            >
              <Text label bold={true}>
                Custom Carrier
              </Text>
            </Div>
          )}
          {list.length ? (
            list.map((item) => (
              <Div
                css={css`
                  ${container.hover}
                `}
                onClick={() => selectCarrier(item)}
              >
                <Text label>{item?.Name}</Text>
              </Div>
            ))
          ) : (
            <Text
              label
              css={`
                color: ${colors.red[100]};
                padding: 8px 16px;
              `}
            >
              Nothing found.
            </Text>
          )}
        </Div>
      ) : null}
    </Div>
  );
};
