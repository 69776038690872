import { Checkbox, MenuItem } from '@material-ui/core';
import { css } from '@emotion/css';
import { ArrowLeft, MapPin, Trash, X } from 'phosphor-react';
import { useEffect, useMemo, useState } from 'react';
import { extendedStates } from '../constants';
import { CustomAxios } from '../redux/axios/axios';
import { Button, Div, Input, Loader, Modal, Select, Text } from '../shared/components';
import { container, flex } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { useStateSync } from '../shared/use-state-sync';

export const ZipCodes = ({ product, onUpdate, ...modalProps }) => {
  const { ApplicableStates = [], ApplicableZipCodes = [] } = product;

  const [tab, setTab] = useState('zips');
  const [mode, setMode] = useState('manual');
  const [manualZips, setManualZips] = useState('');

  const [state, setState] = useState(ApplicableStates?.includes('ALL') ? 'ALL' : ApplicableStates[0]);
  const [counties, setCounties] = useState([]);
  const [zipCodeList, setZipCodeList] = useState([]);
  const [isLoadingCounties, setIsLoadingCounties] = useState(false);
  const [isLoadingZips, setIsLoadingZips] = useState(false);

  const [search, setSearch] = useState('');
  const [county, setCounty] = useState('');
  const [zipCodes, setZipCodes] = useStateSync(ApplicableZipCodes, [ApplicableZipCodes]);

  const fetchZywave = async (type, url, list = []) => {
    if (type === 'counties') {
      setIsLoadingCounties(true);
    } else if (type === 'zip-codes') {
      setIsLoadingZips(true);
    }

    try {
      const { data } = await CustomAxios.get(url);
      if (type === 'counties') {
        const nextList = data.Data.map((county) => county.County).filter(
          (value, index, array) => array.indexOf(value) === index
        );
        list = [...list, ...nextList];
      } else if (type === 'zip-codes') {
        const nextList = data.Data.map((county) => county.PostalCode);
        list = [...list, ...nextList];
      }
      if (data.Next) {
        await fetchZywave(type, data.Next, list);
      } else {
        if (type === 'counties') {
          const nextList = list.filter((value, index, array) => array.indexOf(value) === index);
          setCounties(nextList);
          setIsLoadingCounties(false);
        } else if (type === 'zip-codes') {
          const nextList = list.filter((value, index, array) => array.indexOf(value) === index);
          setZipCodes((old) => {
            const dedupedList = nextList.filter((item) => !old.includes(item));
            return [...old, ...dedupedList];
          });
          setZipCodeList(nextList);
          setIsLoadingZips(false);
        }
      }
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    if (state && state !== 'ALL') {
      fetchZywave('counties', `/v1/zywave/areas?state=${state}`);
    }
  }, [state]);

  useEffect(() => {
    if (state && state !== 'ALL' && !!county) {
      fetchZywave('zip-codes', `/v1/zywave/areas?state=${state}&county=${county}`);
    }
  }, [county]);

  const stateList = ApplicableStates.includes('ALL')
    ? extendedStates
    : extendedStates.filter(({ id }) => ApplicableStates.includes(id));

  const zipList = useMemo(() => {
    if (!search) {
      return ApplicableZipCodes;
    }
    return ApplicableZipCodes.filter((key) => key.includes(search));
  }, [search, ApplicableZipCodes]);

  const handleZipCode = (zip) => {
    if (zipCodes.includes(zip)) {
      setZipCodes((zips) => zips.filter((item) => item !== zip));
    } else {
      setZipCodes((zips) => [...zips, zip]);
    }
  };

  const removeZipCode = (zip) => {
    console.log(zip);
    setZipCodes((zips) => zips.filter((item) => item !== zip));
  };

  const manualZipList = useMemo(() => {
    const zipCodeRegExp = new RegExp(/[^0-9-]+/g);
    const list = manualZips.split(zipCodeRegExp)?.filter((item) => !!item);
    return [...new Set(list)];
  }, [manualZips]);

  const handleSave = () => {
    let value = [];
    if (mode === 'auto') {
      value = [...zipCodes];
    } else {
      value = [...zipCodes, ...manualZipList];
    }
    value = [...new Set(value)];
    onUpdate('ApplicableZipCodes', value);
    if (tab === 'add') {
      setTab('zips');
      setManualZips('');
    } else {
      modalProps.onClose();
    }
  };

  const totalZips = tab === 'zips' || mode === 'auto' ? zipCodes?.length : manualZipList?.length;

  return (
    <Modal {...modalProps}>
      <Div
        css={css`
          width: 500px;
        `}
      >
        <Div
          css={css`
            ${flex('space-between')} padding: 16px;
            border-bottom: 1px solid ${colors.gray[300]};
          `}
        >
          <Text h2>Manage Zip Codes</Text>
          <Button styles="icon" onClick={modalProps.onClose}>
            <X />
          </Button>
        </Div>
        <Div
          css={css`
            margin: 32px;
          `}
        >
          {tab === 'zips' ? (
            <>
              <Div
                css={css`
                  ${flex('space-between')} margin-bottom: 16px;
                `}
              >
                <Text h4>{totalZips} Applied Zip Codes</Text>
                <Button onClick={() => setTab('add')} styles="secondary">
                  Add Zip Codes
                </Button>
              </Div>
              <Input
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                className={css`
                  width: 100%;
                `}
              />
              <Div
                css={css`
                  height: 20vh;
                  overflow-y: auto;
                `}
              >
                {zipList.map((key) =>
                  zipCodes?.includes(key) ? (
                    <Div
                      css={css`
                        ${flex('space-between')} margin: 4px 0;
                        ${container.hover}
                        padding: 4px 8px;
                        border-radius: 8px;
                        height: 32px;
                        :not(:hover) {
                          .button {
                            display: none;
                          }
                        }
                      `}
                      key={key}
                    >
                      <Text label>{key}</Text>
                      <Button styles="icon sm" className="button" onClick={() => removeZipCode(key)}>
                        <Trash />
                      </Button>
                    </Div>
                  ) : null
                )}
                {!totalZips && <Text label>Add zip codes by clicking 'Add Zip Codes' in the top right.</Text>}
              </Div>
            </>
          ) : tab === 'add' ? (
            <Div
              css={css`
                width: 100%;
              `}
            >
              <Div
                css={css`
                  ${flex('left')}
                `}
              >
                <Button styles="icon" onClick={() => setTab('zips')}>
                  <ArrowLeft />
                </Button>
                <Text
                  h4
                  className={css`
                    margin-left: 16px;
                  `}
                >
                  Zip Codes ({mode === 'auto' ? totalZips - ApplicableZipCodes?.length : totalZips})
                </Text>
              </Div>
              <Div
                css={css`
                  ${flex('space-between')}
                  width: 100%;
                `}
              >
                <Button
                  tab-active={mode === 'manual'}
                  tab-inactive={mode !== 'manual'}
                  onClick={() => setMode('manual')}
                  css={`
                    flex-grow: 1;
                  `}
                >
                  Manually Add
                </Button>
                <Button
                  tab-active={mode === 'auto'}
                  tab-inactive={mode !== 'auto'}
                  css={`
                    flex-grow: 1;
                  `}
                  onClick={() => setMode('auto')}
                >
                  Auto Add
                </Button>
              </Div>
              {mode === 'auto' ? (
                <>
                  <Div
                    css={css`
                      ${flex('space-between')} margin-top: 16px;
                    `}
                  >
                    <Text label>Applicable States</Text>
                    <Select
                      className={css`
                        width: 50%;
                      `}
                      value={state}
                      onChange={(event) => setState(event.target.value)}
                    >
                      {stateList.map(({ id, label }) => (
                        <MenuItem key={id} value={id}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Div>

                  <Div
                    css={css`
                      ${flex('space-between')} margin: 16px 0;
                      ${state === 'ALL' && `opacity: .4;`}
                    `}
                  >
                    <Text label>Applicable County</Text>
                    <Div
                      css={css`
                        position: relative;
                        width: 50%;
                      `}
                    >
                      <Loader type="three-dots" isLoading={isLoadingCounties} />
                      <Select
                        className={css`
                          width: 100%;
                        `}
                        value={county || ''}
                        onChange={(event) => setCounty(event.target.value)}
                        disabled={isLoadingCounties || !counties?.length || !state || state === 'ALL'}
                      >
                        {counties?.map((county, idx) => (
                          <MenuItem key={county + idx} value={county}>
                            {county}
                          </MenuItem>
                        ))}
                      </Select>
                    </Div>
                  </Div>
                  <Div
                    css={css`
                      position: relative;
                      height: 20vh;
                      overflow-y: auto;
                    `}
                  >
                    <Loader
                      type="icon"
                      isLoading={isLoadingZips}
                      className={css`
                        height: 20vh;
                      `}
                    >
                      <MapPin className="bounce" size={64} />
                    </Loader>

                    {!isLoadingZips &&
                      zipCodeList.map((key) => (
                        <Div
                          css={css`
                            ${flex('space-between')} margin: 4px 8px;
                          `}
                          key={key}
                        >
                          <Text label>{key}</Text>
                          <Checkbox checked={zipCodes?.includes(key)} onChange={() => handleZipCode(key)} />
                        </Div>
                      ))}
                  </Div>
                </>
              ) : mode === 'manual' ? (
                <>
                  <Div
                    css={css`
                      margin: 16px 0;
                    `}
                  >
                    <Text label bold>
                      Add your zip codes here
                    </Text>
                    <Input rows={2} value={manualZips} onChange={(e) => setManualZips(e.target.value)} />
                    <Text>Paste or type in as many zip codes here as you'd like.</Text>
                    <Div
                      css={css`
                        ${flex('left start')}
                        height: 20vh;
                        overflow-y: auto;
                      `}
                    >
                      {manualZipList?.map((item) => (
                        <Text
                          css={`
                            margin: 8px;
                            padding: 4px 8px;
                            border-radius: 30px;
                            background-color: ${colors.gray[100]};
                          `}
                        >
                          {item}
                        </Text>
                      ))}
                    </Div>
                  </Div>
                </>
              ) : null}
            </Div>
          ) : null}
        </Div>

        <Div
          css={css`
            ${flex('right')} width: 100%;
            padding: 32px;
            border-top: 1px solid ${colors.gray[300]};
          `}
        >
          <Button
            styles="secondary"
            className={css`
              margin-right: 8px;
            `}
            onClick={modalProps.onClose}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            disabled={
              tab === 'add' && mode === 'manual' ? !manualZipList?.length : ApplicableZipCodes?.length === totalZips
            }
          >
            {tab === 'add' ? 'Add' : 'Update'}
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
