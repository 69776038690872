import { useState } from 'react';
import { useStore } from '../store-provider/use-store';

export const useLocalStorage = (key, defaultValue) => {
  const {
    data: { parentBusiness },
  } = useStore();
  const localStorageKey = `BRITE-${parentBusiness?.ID}/viewed/${key}`;

  const [value, setValue] = useState(localStorage.getItem(localStorageKey) ?? defaultValue);

  const set = (value) => {
    localStorage.setItem(localStorageKey, value);
    setValue(value);
  };

  return { value, set };
};
