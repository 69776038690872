import React, { useMemo, useState } from 'react';
import { css } from '@emotion/css';
import { useLocation, matchPath } from 'react-router-dom';
import { useStore } from '../store-provider/use-store';
import { useBusinessAuth } from '../Dashboard/use-business-auth';
import { CompanySelect } from './company-select';
import { history } from '../history';
import { Typography } from '@material-ui/core';
import { useQueryAPI } from '../react-query/query-api';
import { Button } from '../shared/components/button';
import { SuperSettings } from './super-settings';
import { flex } from '../shared/shared-styles';
import { ReactComponent as SuperSettingsIcon } from '../images/svg/super-settings.svg';

export const defaultBusiness = {
  CRMID: '',
  CreatedAt: '',
  Domain: '',
  EmployeeCount: 0,
  ID: '',
  Industry: '',
  IsActive: true,
  LogoURL: '',
  ModifiedAt: '',
  Name: '',
  ParentID: '',
  PaymentSource: 'multi',
  State: 'UT',
  Type: 'single',
};

export const MultiChooseCompanyContainer = (props) => {
  const [modal, setModal] = useState('');
  const location = useLocation();
  const onDashboard = location.pathname.includes('dashboard');
  const {
    data: { companies, selectedBusiness, isBriteUser },
  } = useStore();
  const { setBusiness } = useBusinessAuth();

  const { data: children } = useQueryAPI({
    enabled: isBriteUser !== null && !!isBriteUser && !!selectedBusiness.ParentID,
    url: `v2/business/${selectedBusiness.ParentID}/children`,
    defaultValue: [],
  });

  const setBusinessAndRouteBack = (business) => {
    setBusiness(business);
    const match = matchPath(location.pathname, { path: '/:businessId/:path' });
    if (match) {
      history.push(`/${business.ID}/${match.params.path}`);
    } else {
      history.push(`/${business.ID}/courses`);
    }
  };

  const options = useMemo(() => {
    if (!onDashboard && isBriteUser) {
      const sorted = children.sort(({ Name: a }, { Name: b }) => a.localeCompare(b));
      return sorted;
    } else if (companies.length) {
      return companies;
    }
  }, [companies.length, children.length, isBriteUser, selectedBusiness.ParentID, onDashboard]);

  const handleChange = (business) => {
    setBusinessAndRouteBack(business);
  };

  if (location.pathname.includes('/companies')) {
    return null;
  }

  return (
    <div
      className={css`
        margin: 4px;
        display: flex;
        align-items: center;
        font-size: 12px;
        border-radius: 4px;
        z-index: 500;
      `}
    >
      {isBriteUser ? (
        <div
          className={css`
            ${flex('left')}
          `}
        >
          <CompanySelect selectBusiness={({ option }) => handleChange(option)} defaultBusinessList={options} />
          <div
            className={css`
              margin-left: 16px;
            `}
          >
            <Button
              onClick={() => setModal('super-settings')}
              styles="naked"
              css={`
                svg {
                  width: 64px;
                  cursor: pointer;
                }
              `}
            >
              <SuperSettingsIcon />
            </Button>
          </div>
          {modal === 'super-settings' && (
            <SuperSettings display={modal === 'super-settings'} onClose={() => setModal('')} />
          )}
        </div>
      ) : companies.length > 1 ? (
        <CompanySelect selectBusiness={({ option }) => handleChange(option)} defaultBusinessList={options} />
      ) : (
        <Typography style={{ fontWeight: 'bold', fontSize: '20px' }}>{selectedBusiness.Name}</Typography>
      )}
    </div>
  );
};
