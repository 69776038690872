import { MenuItem } from '@material-ui/core';
import { css } from '@emotion/css';
import { FolderSimple, UploadSimple } from 'phosphor-react';
import React from 'react';
import { Button, Div, DropMenu, Text } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { modifyContent } from '../provider/utils';
import { DocumentsLibrary } from './menu-components/documents-library';
import { UploadDocuments } from './menu-components/upload-document';

export const DocumentToolbar = ({ editor, element, location, toolbar }) => {
  const onUpdate = (updates) => modifyContent.merge(editor, location, updates);

  const updateDocuments = (updates) => {
    onUpdate(updates);
  };

  return (
    <Div
      css={css`
        ${flex('jcl aic')}
      `}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Div>
        <DropMenu button={<Button styles="secondary">{element?.document?.ID ? 'Replace' : 'Add'} Document</Button>}>
          <MenuItem onClick={() => toolbar.setModal('document-upload')}>
            <UploadSimple size={24} />
            <Text styles="label padl-sm">Upload Document</Text>
          </MenuItem>
          <MenuItem onClick={() => toolbar.setModal('document-library')}>
            <FolderSimple size={24} />
            <Text styles="label padl-sm">Select from Documents</Text>
          </MenuItem>
        </DropMenu>
      </Div>

      <a target="_blank" href={element?.document?.PublicURL}>
        <Text
          styles="link padh-lg ellipsis uppercase"
          className={css`
            max-width: 300px;
          `}
        >
          {element?.document?.Name}
        </Text>
      </a>

      <UploadDocuments
        display={toolbar?.modal === 'document-upload'}
        onClose={() => toolbar.setModal('')}
        onUpdate={updateDocuments}
      />

      <DocumentsLibrary
        element={element}
        display={toolbar?.modal === 'document-library'}
        onClose={() => toolbar.setModal('')}
        onUpdate={updateDocuments}
      />
    </Div>
  );
};
