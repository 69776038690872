import { css } from '@emotion/css';
import { ArrowLeft, Plus } from 'phosphor-react';
import { useState } from 'react';
import { Button, ConfirmationModal, Div, Modal } from '../shared/components';
import { textTheme } from '../shared/components/text';
import { flex, px, container } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { DesignStylesList } from './design-styles-list';
import { DesignStylesViewer } from './design-styles-viewer';
import { EditStyle } from './edit-style/edit-style';
import { NewDesignStylesModal } from './new-design-styles-modal';
import { useDesignStyles } from './use-design-styles';
import { Text } from '../shared/components';
import { Box } from '../shared/components/box';
export const courseNameStyle = css`
  border-radius: 8px;
  border: 1px solid ${colors.gray[200]};
  outline: none;
  padding: 8px;
  ${textTheme.h3}
  max-width: 450px;
  font-weight: normal;
  cursor: text;
  padding-right: 24px;
  :focus,
  :hover {
    border: 1px solid ${colors.gray[400]};
  }
  :disabled {
    border: 1px solid transparent;
  }
`;

export const DesignStylesPage = () => {
  const designStyleProps = useDesignStyles();

  const [isSaving, setIsSaving] = useState(false);
  const [displayNewModal, setDisplayNewModal] = useState(false);
  const [cancelChanges, setCancelChanges] = useState(false);

  const { current, setCurrent, hasChanges, resetCurrent, createNewDesignStyle, designStylesQuery } = designStyleProps;

  const updateThemeId = (id) => {
    const item = designStylesQuery?.data?.find(({ ID }) => ID === id);
    setCurrent(item);
  };

  const handleSaveEdits = async () => {
    try {
      setIsSaving(true);
      await designStyleProps.saveCurrent();
      setCurrent({});
    } catch {
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancelEdits = () => {
    if (hasChanges) {
      setCancelChanges(true);
    } else {
      resetCurrent();
      setCurrent({});
    }
  };

  return (
    <Box page>
      <Div
        css={css`
          ${container.header}
        `}
      >
        <Text h1>Design Styles</Text>
        <Button onClick={() => setDisplayNewModal(true)}>
          <Plus size={16} color="white" /> Add Design Style
        </Button>
      </Div>

      <Div>
        <Div
          css={css`
            ${flex('space-between start')}
            width: 100%;
          `}
        >
          <DesignStylesList
            designStyleProps={designStyleProps}
            themeId={designStyleProps?.current?.ID}
            setThemeId={updateThemeId}
            isPage={true}
          />
          <EditStyle designStyleProps={designStyleProps} isPage={true} />
        </Div>
      </Div>

      <Modal display={current?.ID} full>
        <Div
          css={css`
            ${flex('jcsb aic')} padding: ${px.md};
            padding-left: ${px.xl};
            padding-right: ${px.xl};
            width: 100%;
            height: 100px;
            border-bottom: 1px solid ${colors.gray[300]};
          `}
        >
          <Div
            css={css`
              ${flex('aic')}
            `}
          >
            <Button styles="icon mr" onClick={handleCancelEdits}>
              <ArrowLeft />
            </Button>
            <input
              className={courseNameStyle}
              value={current?.Name || ''}
              onChange={(e) => {
                const Name = e.target.value;
                setCurrent({ ...current, Name });
              }}
            />
          </Div>
          <Div
            css={css`
              ${flex('aic')}
            `}
          >
            <Button styles="secondary mr" onClick={handleCancelEdits}>
              Cancel
            </Button>
            <Button disabled={isSaving || !hasChanges} onClick={handleSaveEdits}>
              {isSaving ? 'Saving...' : 'Save'}
            </Button>
          </Div>
        </Div>
        <Div
          css={css`
            ${flex('jcc')} width: 100%;
            max-width: 1200px;
            margin: 0 auto;
          `}
        >
          <DesignStylesViewer designStyleProps={designStyleProps} isPage={true} />
          <EditStyle designStyleProps={designStyleProps} />
        </Div>
      </Modal>

      <NewDesignStylesModal
        createNewDesignStyle={createNewDesignStyle}
        display={displayNewModal}
        onClose={() => setDisplayNewModal(false)}
      />

      {/* Cancel with changes confirmation */}
      <ConfirmationModal
        display={cancelChanges}
        onClose={() => {
          setCancelChanges(false);
          resetCurrent();
          setCurrent({});
        }}
        onExit={() => setCancelChanges(false)}
        title="You have unsaved changes, would you like to save them before leaving?"
        closeText="Leave Anyway"
        onConfirm={() => {
          setCancelChanges(false);
          handleSaveEdits();
        }}
      />
    </Box>
  );
};
