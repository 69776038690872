import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import { CustomAxios } from '../redux/axios/axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { sortBy } from 'lodash';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { history } from '../history';
import { MoreMenu } from './MoreMenu';
import IconButton from '@material-ui/core/IconButton';
import { cloneDeep } from 'lodash';
import Chip from '@material-ui/core/Chip';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useStore } from '../store-provider/use-store';
import { Input, Box, Text, Modal } from '../shared/components';
import { Tooltip } from '../common/components/Tooltip';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export const CarrierMarketingCourses = (props) => {
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [publishModalCourse, setPublishModalCourse] = useState(null);
  const [courseLinkErrorMsg, setCourseLinkErrorMsg] = useState('');
  const [analytics, setAnalytics] = useState(null);

  const {
    data: { selectedBusiness },
  } = useStore();
  const { LogoURL, ID: businessId } = selectedBusiness;

  const classes = useStyles();

  const getMarketingCourses = async () => {
    try {
      setLoading(true);
      const response = await CustomAxios.get(`/v1/course`);

      let courses = response.data;

      courses = courses.filter((course) => course.Type === 'vendor');

      courses = sortBy(courses, (o) => o.Name.toLowerCase());

      for (let course of courses) {
        const res = await CustomAxios.get(`/v1/course/${course.ID}/analytics`);
        let analytics = res.data;
        let totalCount = 0;

        for (let analytic of analytics) {
          totalCount += analytic.ImportedCount;
        }

        course.analytics = { analytics, totalCount };
      }

      setCourses(courses);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error('Could not retrieve vendor library guides');
    }
  };

  useEffect(() => {
    getMarketingCourses();
  }, []);

  const updatePublished = async (course) => {
    try {
      setLoading(true);

      const response = await CustomAxios.get(`/v1/course/${course.ID}`);

      let courseResponse = response.data;

      let clonedCourse = cloneDeep(courseResponse);
      clonedCourse.IsPublished = !course.IsPublished;
      let vanityURL = clonedCourse.VanityURL;

      try {
        await CustomAxios.put(`/v1/course/${course.ID}/vanityurl`, {
          URL: course.VanityURL,
        });
        vanityURL = course.VanityURL;
      } catch (e) {
        // eslint-disable-next-line
        throw 'vanity';
      }

      clonedCourse.VanityURL = vanityURL;

      await CustomAxios.put(`/v1/course/${course.ID}`, clonedCourse);

      setPublishModalCourse(null);

      getMarketingCourses();
      let successMsg = 'Guide successfully published';
      if (!clonedCourse.IsPublished) {
        successMsg = 'Guide successfully marked as draft';
      }

      toast.success(successMsg);
    } catch (e) {
      setLoading(false);
      if (e === 'vanity') {
        toast.error('Url is already taken');
      } else {
        toast.error('Could not update published status on guide');
      }
    }
  };

  const duplicateMarketingCourse = (courseId) => {
    props.duplicate(courseId);
    props.goBackTab();
  };

  const deleteMarketingCourse = (courseId) => {
    props.delete(courseId);
    props.goBackTab();
  };

  const routeToBuilder = (id) => {
    history.push(`/${businessId}/courses/${id}?vendor=true`);
  };

  if (loading) {
    return (
      <div
        className={css`
          display: flex;
          justify-content: center;
          margin: 48px;
        `}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <div
          className={css`
            font-weight: 400;
            color: #797979;
            margin-right: 10px;
            margin-left: 12px;
          `}
        >
          Create and manage guides that can be imported into any Brite guide.
        </div>

        <div
          id="addnewcourse"
          className={css`
            display: flex;
            align-items: center;
            margin-top: 5px;
          `}
        >
          <div
            className={css`
              margin-right: 14px;
            `}
          >
            <Button
              onClick={async () => {
                let newCourseDefault = {
                  Name: 'Untitled Guide',
                  Type: 'vendor',
                  Views: 0,
                  LogoURL: LogoURL || '',
                  IsPublished: false,
                  Pages: [
                    {
                      Type: 'brite-editor',
                      Content: null,
                      Name: 'Introduction',
                      Order: 1,
                      FrontendID: '_' + Math.random().toString(36).substr(2, 9),
                      InsurancePlans: [],
                      Question: null,
                      IsLocked: false,
                      TemplateID: '00000000-0000-0000-0000-000000000000',
                    },
                  ],
                };

                const response = await CustomAxios.post(`/v1/course`, newCourseDefault);
                routeToBuilder(response.data.ID);
              }}
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
            >
              Add
            </Button>
          </div>
        </div>
      </div>

      {courses.length === 0 && (
        <div
          className={css`
            text-align: center;
            font-size: 16px;
            margin: 24px;
          `}
        >
          You haven't created any vendor library guides yet.
        </div>
      )}

      <div
        className={css`
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
        `}
      >
        {courses.map((course) => {
          return (
            <Box
              css={`
                width: 230px;
                margin: 16px;
              `}
              key={course.ID}
            >
              {!course.IsPublished && (
                <div
                  className={css`
                    margin: 8px;
                  `}
                >
                  <Tooltip label={'You must publish this guide for other Brite users to use it'} placement="top">
                    <Chip label="Draft" />
                  </Tooltip>
                </div>
              )}
              <Box
                flex="center"
                css={`
                  margin: 0px auto;
                `}
              >
                {course.LogoURL && (
                  <img
                    className={css`
                      max-height: 40px;
                      max-width: 175px;
                    `}
                    src={course.LogoURL}
                    alt="Guide Logo"
                  />
                )}
              </Box>

              <div
                className={css`
                  padding: 10px;
                  overflow: auto;
                  height: 64px;
                `}
                title={course.Name}
              >
                <Text
                  css={`
                    font-weight: 500;
                  `}
                >
                  {course.Name}
                </Text>
              </div>

              <div
                className={css`
                  display: flex;
                  border-top: 1px solid #eee;
                  justify-content: space-between;
                  padding-left: 8px;
                  padding-top: 2px;
                  padding-bottom: 2px;
                `}
              >
                <Tooltip
                  title={
                    course.IsPublished
                      ? 'Mark this guide as a draft. Brite users will no longer be able to import this guide into their guide.'
                      : 'Publish this guide so all Brite users can import the pages into their guides.'
                  }
                  placement="top"
                >
                  <Button
                    color="primary"
                    onClick={() => {
                      setPublishModalCourse(course);
                    }}
                  >
                    {course.IsPublished ? 'Set as Draft' : 'Publish'}
                  </Button>
                </Tooltip>
                <div>
                  <Tooltip title="Edit" placement="top">
                    <IconButton aria-label="Edit" color="default" onClick={() => routeToBuilder(course.ID)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>

                  <MoreMenu
                    courseId={course.ID}
                    duplicateCourse={duplicateMarketingCourse}
                    deleteCourse={deleteMarketingCourse}
                    viewAnalytics={true}
                    showAnalytics={() => {
                      setAnalytics(course.analytics);
                    }}
                  />
                </div>
              </div>
            </Box>
          );
        })}
      </div>

      {!!publishModalCourse && (
        <Modal
          display
          onClose={() => {
            setPublishModalCourse(null);
          }}
        >
          <div>
            <div
              className={css`
                font-weight: 400;
                font-size: 22px;
                line-height: 28px;
                color: #797979;
                margin: 20px;
                margin-bottom: 5px;
                border-bottom: 1px solid #cbc9c9;
                padding-bottom: 8px;
              `}
            >
              Access control for {publishModalCourse.Name}
            </div>
            <div
              className={css`
                margin: 24px;
              `}
            >
              {publishModalCourse.IsPublished ? (
                <div>
                  This guide is currently published to the Vendor Library. Users of Brite can import this guide into
                  their own guides.
                </div>
              ) : (
                <div>
                  This guide is currently NOT published to the Vendor Library. Only your company can see this guide.
                </div>
              )}

              <div
                className={css`
                  padding-top: 24px;
                  font-weight: bold;
                  padding-bottom: 12px;
                `}
              >
                Custom url this guide can be viewed at:
              </div>
              <div
                className={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <div
                  className={css`
                    width: 375px;
                  `}
                >
                  https://britehr.app/
                </div>
                <Input
                  onChange={(e) => {
                    const val = e.target.value;
                    if (!val.match(/^[a-zA-Z0-9-_]*$/)) {
                      const errMsg = 'URL can only contain letters, numbers, dashes, and underscores.';
                      setCourseLinkErrorMsg(errMsg);
                      toast.error(errMsg);
                    } else {
                      setCourseLinkErrorMsg('');
                    }

                    setPublishModalCourse({
                      ...publishModalCourse,
                      VanityURL: e.target.value,
                    });
                  }}
                  value={publishModalCourse.VanityURL}
                  placeholder="Enter custom extension"
                  css={`
                    height: 40px;
                    font-weight: 400;
                  `}
                />
              </div>

              <div
                className={css`
                  display: flex;
                  justify-content: center;
                  margin-top: 44px;
                `}
              >
                <div
                  className={css`
                    margin-right: 24px;
                  `}
                >
                  <Button
                    onClick={() => {
                      setPublishModalCourse(null);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button
                    variant="contained"
                    disabled={!publishModalCourse.VanityURL || !!courseLinkErrorMsg}
                    color="primary"
                    onClick={() => {
                      updatePublished(publishModalCourse);
                    }}
                  >
                    {publishModalCourse.IsPublished ? 'Set as Draft' : 'Publish'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {!!analytics && (
        <Modal
          display
          onClose={() => {
            setAnalytics(null);
          }}
        >
          <div>
            <div
              className={css`
                font-weight: 400;
                font-size: 22px;
                line-height: 28px;
                color: #797979;
                margin: 20px;
                margin-bottom: 5px;
                border-bottom: 1px solid #cbc9c9;
                padding-bottom: 8px;
              `}
            >
              Analytics
            </div>
            <div
              className={css`
                margin: 24px;
              `}
            >
              <div
                className={css`
                  margin-bottom: 32px;
                  font-size: 18px;
                `}
              >
                This guide has been imported{' '}
                <span
                  className={css`
                    font-weight: bold;
                    font-size: 20px;
                  `}
                >
                  {analytics.totalCount}
                </span>{' '}
                time{analytics.totalCount === 1 ? '' : 's'}.
              </div>

              {analytics.totalCount > 0 && (
                <>
                  <div
                    className={css`
                      margin-bottom: 12px;
                    `}
                  >
                    Below you can see a breakdown of each guide that has imported this guide and how many times it was
                    imported.
                  </div>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="import table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Guide Name</TableCell>
                          <TableCell align="right">Imported Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {analytics.analytics.map((row) => (
                          <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                              {row.CourseName}
                            </TableCell>
                            <TableCell align="right">{row.ImportedCount}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
              <div
                className={css`
                  display: flex;
                  justify-content: center;
                  margin-top: 44px;
                `}
              >
                <div
                  className={css`
                    margin-right: 24px;
                  `}
                >
                  <Button
                    onClick={() => {
                      setAnalytics(null);
                    }}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
