import React, { useCallback, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/css';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { states, industries } from '../constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useDropzone } from 'react-dropzone';
import { CustomAxios } from '../redux/axios/axios';
import { toast } from 'react-toastify';
import { fileTypesMap } from '../Documents/FullscreenUploader';
import { Button as BriteButton } from '../shared/components';
import { UploadSimple } from 'phosphor-react';
import { flex } from '../shared/shared-styles';
import { BriteLoader } from '../shared/components/brite-loader';
import { useStore } from '../store-provider/use-store';
import { createChildBusiness } from '../redux/replace-redux-actions';
import { useForm } from '../shared/use-form';
import { useFormErrors } from '../shared/useFormErrors';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const validate = (values) => {
  const errors = {};
  if (!values.Name) {
    errors.Name = 'Required';
  }

  if (!values.EmployeeCount) {
    errors.EmployeeCount = 'Required';
  }

  if (!values.websiteURL) {
    errors.websiteURL = 'Required';
  }

  if (!values.companyState) {
    errors.companyState = 'Required';
  }

  if (!values.firstName) {
    errors.firstName = 'Required';
  }

  if (!values.lastName) {
    errors.lastName = 'Required';
  }

  if (!values.email) {
    errors.email = 'Required';
  }
  if (values.email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(values.email)) {
      errors.email = 'Invalid email address';
    }
  }

  return errors;
};

export const AddCompany = (props) => {
  const {
    data: {
      selectedBusiness: { ParentID: businessId },
      user: { ID: userId },
    },
  } = useStore();

  const prevLoading = usePrevious(props.loading);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (prevLoading && !props.loading) {
      props.closeAddCompanyModal();
    }
  }, [props.loading]);

  const uploadSpreadsheet = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setLoading(true);
      const file = acceptedFiles[0];
      const filename = encodeURIComponent(file.name);
      const filetype = encodeURIComponent(file.type.replace(/\//g, '.'));
      const url = `v2/media/${filename}/${filetype}?is_private=true`;
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      const config = { headers: { 'content-type': 'multipart/form-data' } };
      try {
        const { data } = await CustomAxios.post(url, formData, config);

        try {
          const mediaId = data.ID;
          await CustomAxios.post(`/v2/business/imports/${mediaId}`);
        } catch (e) {
          console.log('error occurred when attempting to automatically import new spreadsheet', e);
        }
        props.closeAddCompanyModal();
      } catch (err) {
        console.warn(err);
        toast.error('Error uploading document. Try again.');
      } finally {
        setLoading(false);
      }
    } else {
      toast.error('Invalid file or too many files');
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: uploadSpreadsheet,
    accept: [fileTypesMap.xlsx, fileTypesMap.xlsm],
    noDragEventsBubbling: true,
  });

  const form = useForm(
    {
      Name: '',
      EmployeeCount: '',
      websiteURL: '',
      companyState: '',
      industry: '',
      firstName: '',
      lastName: '',
      email: '',
      inviteUser: true,
    },
    []
  );

  const { values } = form;
  const handleChange = (e) => form?.merge({ [e.target.name]: e.target.value });

  const { touchedErrors, onBlur, hasErrors } = useFormErrors(form, validate);

  const handleSubmit = async (event) => {
    if (!hasErrors) {
      event.preventDefault();
      setLoading(true);
      await createChildBusiness(values, {
        businessId,
        userId,
      });
      setLoading(false);
      props.closeAddCompanyModal();
    }
  };

  if (props.loading) {
    return (
      <div
        className={css`
          display: flex;
          justify-content: center;
          margin-top: 80px;
        `}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className={css`
          padding: 24px;
          min-width: 400px;
          overflow: auto;
        `}
      >
        <div
          className={css`
            margin: 12px;
            font-weight: bold;
            margin-left: 0px;
            margin-top: -6px;
          `}
        >
          Company Information
        </div>
        <div
          className={css`
            margin-bottom: 18px;
          `}
        >
          <TextField
            fullWidth
            onBlur={onBlur}
            error={touchedErrors.Name}
            label="Company name"
            variant="outlined"
            onChange={handleChange}
            value={values.Name}
            name="Name"
          />
        </div>

        <div
          className={css`
            margin-bottom: 18px;
          `}
        >
          <TextField
            fullWidth
            onBlur={onBlur}
            error={touchedErrors.EmployeeCount}
            label="# of employees"
            variant="outlined"
            onChange={handleChange}
            value={values.EmployeeCount}
            type="number"
            name="EmployeeCount"
          />
        </div>

        <div
          className={css`
            margin-bottom: 18px;
          `}
        >
          <TextField
            fullWidth
            onBlur={onBlur}
            error={touchedErrors.websiteURL}
            label="Website URL"
            variant="outlined"
            onChange={handleChange}
            value={values.websiteURL}
            name="websiteURL"
          />
        </div>

        <div
          className={css`
            margin-bottom: 18px;
          `}
        >
          <FormControl variant="outlined" onBlur={onBlur} error={touchedErrors.companyState} fullWidth>
            <InputLabel id="state">State</InputLabel>
            <Select
              labelId="state"
              id="select-state"
              value={values.companyState}
              onChange={handleChange}
              name="companyState"
              input={<OutlinedInput label="State" />}
            >
              {states.map((state) => (
                <MenuItem key={state.id} value={state.id}>
                  {state.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div
          className={css`
            margin-bottom: 18px;
          `}
        >
          <FormControl variant="outlined" onBlur={onBlur} error={touchedErrors.industry} fullWidth>
            <InputLabel id="industry">Industry</InputLabel>
            <Select
              labelId="industry"
              id="select-state"
              value={values.industry}
              name="industry"
              onChange={handleChange}
              input={<OutlinedInput label="Industry" />}
            >
              {industries.map((industry) => (
                <MenuItem key={industry.name} value={industry.name}>
                  {industry.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div
          className={css`
            margin: 12px;
            font-weight: bold;
            margin-left: 0px;
          `}
        >
          Company Contact Information
        </div>

        <div
          className={css`
            margin: 12px;
            margin-left: 0px;

            max-width: 375px;
            font-size: 10px;
            line-height: 14px;
          `}
        >
          This information is used to create an admin user for the company you are adding. They will only get an email
          inviting them to join Brite if you select the checkbox below.
        </div>

        <div
          className={css`
            margin-bottom: 18px;
          `}
        >
          <TextField
            fullWidth
            onBlur={onBlur}
            error={touchedErrors.firstName}
            label="First Name"
            variant="outlined"
            onChange={handleChange}
            value={values.firstName}
            name="firstName"
          />
        </div>

        <div
          className={css`
            margin-bottom: 18px;
          `}
        >
          <TextField
            fullWidth
            error={touchedErrors.lastName}
            label="Last Name"
            variant="outlined"
            onChange={handleChange}
            value={values.lastName}
            name="lastName"
          />
        </div>

        <div
          className={css`
            margin-bottom: 18px;
          `}
        >
          <TextField
            fullWidth
            error={touchedErrors.email}
            label="Email"
            variant="outlined"
            onChange={handleChange}
            value={values.email}
            name="email"
            helperText={touchedErrors.email}
          />
        </div>

        <div
          className={css`
            margin-bottom: 18px;
          `}
        >
          <FormControlLabel
            control={<Checkbox checked={values.inviteUser} onChange={handleChange} name="inviteUser" />}
            label="Send email to invite this user to Brite"
          />
        </div>

        <div
          className={css`
            ${flex('space-between')}
            margin-top: 20px;
          `}
        >
          <input {...getInputProps()} />
          {loading ? (
            <BriteLoader size={32} weight={12} overlay={false} />
          ) : (
            <BriteButton styles="icon" hoverLabel="Upload Company Sheet" {...getRootProps()}>
              <UploadSimple />
            </BriteButton>
          )}
          <div
            className={css`
              ${flex('right')}
            `}
          >
            <BriteButton
              secondary
              disabled={loading}
              css={`
                margin-right: 16px;
              `}
              onClick={() => {
                props.closeAddCompanyModal();
              }}
            >
              Cancel
            </BriteButton>
            <BriteButton disabled={loading || hasErrors} type="submit">
              Add Company
            </BriteButton>
          </div>
        </div>
      </form>
    </>
  );
};
