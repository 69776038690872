import React from 'react';
import { isToday } from 'date-fns';
import { css, cx } from '@emotion/css';

export const NewTag = ({ className, createdAt, css: cssString = '' }) =>
  !createdAt || isToday(new Date(createdAt)) ? (
    <span
      className={cx(
        css`
          background-color: #25282d;
          color: white;
          font-weight: bold;
          border-radius: 16px;
          padding: 2px 8px;
          font-size: 0.7em;
          margin-right: 8px;
          ${cssString}
        `,
        className
      )}
    >
      New
    </span>
  ) : null;
