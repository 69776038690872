import { css } from '@emotion/css';
import { Check } from 'phosphor-react';
import { container, flex } from '../shared-styles';
import { colors } from '../styles';
import { Div } from './div';

export const Checkbox = (props) => {
  return (
    <Div
      className={css`
        border-radius: 4px;
        width: 24px;
        height: 24px;
        padding: 0;
        cursor: pointer;
        ${props?.checked
          ? `
          border: 2px solid ${colors.black};
          background-color: ${colors.black};
          ${flex('center')}
          transition: background-color .2s ease;
        `
          : `
          border: 2px solid ${colors.gray[400]};
          ${container.hover}
        `}
        ${props?.css}
      `}
      onClick={props?.onChange}
    >
      {props?.checked ? <Check size={24} color="white" /> : null}
    </Div>
  );
};
