import { useState } from 'react';

// This hook is used to manage the state of a modal.
export const useModal = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const hide = () => setIsOpen(false);

  const toggle = () => setIsOpen(!isOpen);

  return { isOpen, hide, toggle };
};
