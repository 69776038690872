import { formatDuration } from 'date-fns';

export const durationsMap = {
  days: 'd',
  hours: 'h',
  minutes: 'm',
  months: 'mo',
  seconds: 's',
  weeks: 'wk',
  years: 'yr',
  day: 'd',
  hour: 'h',
  minute: 'm',
  month: 'mo',
  second: 's',
  week: 'wk',
  year: 'yr',
};

export const getDurationFromSeconds = (seconds) => {
  if (!seconds) {
    return 'No Data';
  }
  const days = Math.floor(seconds / (24 * 3600));
  seconds %= 24 * 3600;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  const separatedDurations = {
    days: Math.round(days),
    hours: Math.round(hours),
    minutes: Math.round(minutes),
    seconds: Math.round(seconds),
  };

  const durationString = formatDuration(separatedDurations);
  return Object.entries(durationsMap)?.reduce((prev, [key, value]) => {
    return prev.replace(` ${key}`, value);
  }, durationString);
};

export function createData(page, pageName, visits, unique, average, total) {
  return { page, pageName, visits, unique, average, total };
}

export function createDataOld(page, name, views, average, median, percentile) {
  return { page, name, views, average, median, percentile };
}

export function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'string') {
    if (b[orderBy].toUpperCase() < a[orderBy].toUpperCase()) {
      return -1;
    }
    if (b[orderBy].toUpperCase() > a[orderBy].toUpperCase()) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const headCells = [
  {
    id: 'page',
    numeric: true,
    disablePadding: false,
    label: 'Page',
  },
  { id: 'pageName', numeric: false, disablePadding: false, label: 'Page Name' },
  { id: 'visits', numeric: true, disablePadding: false, label: 'Visits' },
  {
    id: 'unique',
    numeric: true,
    disablePadding: false,
    label: 'Unique Visitors',
  },
  {
    id: 'average',
    numeric: false,
    disablePadding: false,
    label: 'Average Time on Page',
  },
  {
    id: 'total',
    numeric: false,
    disablePadding: false,
    label: 'Total Time on Page',
  },
];

export const headCellsOld = [
  {
    id: 'page',
    numeric: true,
    disablePadding: false,
    label: 'Page',
  },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'views', numeric: true, disablePadding: false, label: 'Views' },
  {
    id: 'average',
    numeric: false,
    disablePadding: false,
    label: 'Avg Time on Page',
  },
  { id: 'median', numeric: false, disablePadding: false, label: 'Median Time' },
  {
    id: 'percentile',
    numeric: false,
    disablePadding: false,
    label: '90th Percentile',
  },
];
