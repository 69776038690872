import React, { useState } from 'react';
import { css } from '@emotion/css';
import { IconButton, Button } from '@material-ui/core';
import { CustomAxios } from '../redux/axios/axios';
import { toast } from 'react-toastify';
import { X } from 'phosphor-react';
import { Modal } from '../shared/components';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import Dates from '../shared/components/Dates';

const ExportModal = (props) => {
  const [startDate, setStartDate] = useState(startOfDay(subDays(new Date(), 7)));
  const [endDate, setEndDate] = useState(endOfDay(new Date()));
  const [isExporting, setIsExporting] = useState(false);

  const exportData = async () => {
    setIsExporting(true);
    try {
      await CustomAxios.post(
        `/v1/analytics/v2/export?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
      );
    } catch (e) {
      console.log('Error doing export', e);
      toast.error('Error starting export. Please try again.');
    } finally {
      setIsExporting(false);
    }
    props.onClose();
  };

  return (
    <Modal display={true} onClose={props.onClose}>
      <div
        className={css`
          width: 700px;
          padding: 32px;
          padding-top: 24px;
        `}
      >
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
          `}
        >
          <div
            className={css`
              color: var(--700, #25282d);
              font-feature-settings: 'clig' off, 'liga' off;

              /* H2 */
              font-family: Roboto;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: 32px; /* 133.333% */
            `}
          >
            Export Analytics Report
          </div>
          <IconButton onClick={() => props.onClose()}>
            <X />
          </IconButton>
        </div>
        <div
          className={css`
            color: var(--Gray-600, #36414c);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            letter-spacing: 0.15px;
            margin-bottom: 16px;
          `}
        >
          Please select a date range for your report
        </div>
        <div>
          <Dates.Range
            start={startDate}
            end={endDate}
            maxDate={endOfDay(new Date())}
            setRange={({ start, end }) => {
              setStartDate(startOfDay(start));
              setEndDate(endOfDay(end));
            }}
          />

          <div
            className={css`
              color: var(--Gray-500, #66737f);
              font-feature-settings: 'clig' off, 'liga' off;

              /* Caption */
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 171.429% */
              letter-spacing: 0.15px;
              margin-top: 24px;
              margin-bottom: 24px;
            `}
          >
            Multiple reports will be exported and added to your documents tab for this company. This could take several
            minutes.
          </div>

          <div
            className={css`
              display: flex;
              justify-content: flex-end;
              gap: 16px;
              margin: 8px;
            `}
          >
            <Button variant="outlined" disabled={isExporting} onClick={props.onClose}>
              Cancel
            </Button>
            <Button color="primary" variant="contained" disabled={isExporting} onClick={exportData}>
              Export
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ExportModal;
