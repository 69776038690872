import { useMemo, useState } from 'react';
import { stringToMutedColorHex } from '../../Notifications/components/ActorIcons';
import { useStore } from '../../store-provider/use-store';
import { Box } from './box';
import { getMentionName } from './slate-text/utils';
import { Text } from './text';
import { animation } from '../shared-styles';

export const UserAvatar = ({ user = null, size = 40 }) => {
  const [loaded, setLoaded] = useState(false);
  const {
    data: { user: currentUser },
  } = useStore();
  const data = user || currentUser;
  const name = getMentionName(data);
  const color = useMemo(() => {
    return data?.Preferences?.Color || stringToMutedColorHex(name);
  }, [name, data?.Preferences?.Color]);
  return (
    <Box
      flex="center"
      css={`
        width: ${size}px;
        height: ${size}px;
        min-width: ${size}px;
        min-height: ${size}px;
        border-radius: 50%;
        overflow: hidden;
        background-color: ${color};
        padding: 2px;
        p {
          color: white;
        }
        img {
          ${loaded ? animation('fadeIn', '.2s ease') : ''}
          border: 2px solid white;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      `}
    >
      {data?.Preferences?.ImageURL ? (
        <img src={data?.Preferences?.ImageURL} alt={name} onLoad={() => setLoaded(true)} />
      ) : (
        <Text bold>{name?.slice(0, 1)?.toUpperCase()}</Text>
      )}
    </Box>
  );
};
