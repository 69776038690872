import { css } from '@emotion/css';
import { ChromePicker } from 'react-color';
import { Button, Div, DropMenu, Input, Text } from '../../shared/components';
import { flex, px } from '../../shared/shared-styles';
import { buttonContainer } from '../design-styles';
import { colorStyle } from './edit-style';

const buttonTextColorKey = `Body.ButtonProperties.FontColor`;

export const EditButton = ({ current, updateCurrent }) => {
  const colorKey = 'Body.ButtonColor';
  const color = current?.Theme?.Body?.ButtonColor || '#000000';
  const buttonTextColor =
    current?.Theme?.Body?.ButtonProperties?.FontColor || current?.Theme?.Body?.BackgroundColor || '#FFFFFF';

  return (
    <Div
      className={css`
        margin-top: 32px;
      `}
    >
      <Div
        className={css`
          height: 40px;
        `}
      >
        <button className={buttonContainer(current?.Theme)}>Button</button>
      </Div>

      <Div
        css={css`
          margin: ${px.xl} 0;
        `}
      >
        <Text styles="mb-sm" label bold>
          Button Color
        </Text>
        <Div
          css={css`
            ${flex('jcsb aic')} position: relative;
          `}
        >
          <Input
            className={css`
              width: 100%;
            `}
            value={color}
            onChange={(e) => updateCurrent(colorKey, e.target.value)}
          />
          <DropMenu
            button={<Button styles="naked" className={colorStyle(color)} />}
            transformOrigin={{ horizontal: 'right' }}
          >
            <Div onClick={(e) => e.stopPropagation()}>
              <ChromePicker color={color} onChange={(color) => updateCurrent(colorKey, color.hex)} disableAlpha />
            </Div>
          </DropMenu>
        </Div>
      </Div>

      <Div
        css={css`
          margin: ${px.xl} 0;
        `}
      >
        <Text styles="mb-sm" label bold>
          Button Text Color
        </Text>
        <Div
          css={css`
            ${flex('jcsb aic')} position: relative;
          `}
        >
          <Input
            className={css`
              width: 100%;
            `}
            value={buttonTextColor}
            onChange={(e) => updateCurrent(buttonTextColorKey, e.target.value)}
          />
          <DropMenu
            button={<Button styles="naked" className={colorStyle(buttonTextColor)} />}
            transformOrigin={{ horizontal: 'right' }}
          >
            <Div onClick={(e) => e.stopPropagation()}>
              <ChromePicker
                color={buttonTextColor}
                onChange={(color) => updateCurrent(buttonTextColorKey, color.hex)}
                disableAlpha
              />
            </Div>
          </DropMenu>
        </Div>
      </Div>
    </Div>
  );
};
