import { differenceInMinutes, max, parseJSON } from 'date-fns';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryAPI } from '../../react-query';
import { CustomAxios } from '../../redux/axios/axios';
import { expandCostDetails, getLatestTrackedCell } from './media-utils';

const getSuggestionsUrl = (productId, media) => {
  const fileId = media?.state?.mediaId;
  const sheetName = media?.state?.activeSheet;

  switch (media?.state?.fileType) {
    case 'xlsx':
      // Sheet names can contain special characters which will break this request if not encoded.
      let urlEncodedSheetName = encodeURIComponent(sheetName);
      return `/v1/bp/autofill_suggestion?product_id=eq.${productId}&original_file_id=eq.${fileId}&tab_name=eq.${urlEncodedSheetName}`;
    case 'pdf':
      return `/v1/bp/autofill_suggestion?product_id=eq.${productId}&original_file_id=eq.${fileId}`;
    default:
      return '';
  }
};

export const useAISuggestions = (
  { media, state },
  { currentPropertyChain, displaySettings, fieldsConfig, fieldHistory }
) => {
  const { packageId } = state;

  const aiAutofillFlag = useFeatureFlagPayload('ai-autofill');
  const costTierFF = useFeatureFlagPayload('tier-structure');

  const [pollInterval, setPollInterval] = useState(false);

  const unmappedProperties = useMemo(() => {
    let unmapped = [];
    if (!state?.layout?.Layout?.Sections?.length) {
      return unmapped;
    }
    for (let section of state?.layout?.Layout?.Sections) {
      section.Fields.forEach((field) => {
        if (field.PropertyChain === 'Cost') {
          const costFields = expandCostDetails({
            cost: state?.product?.Cost,
            displaySettings,
            field,
            config: fieldsConfig[field.PropertyChain],
            index: -1,
            featureFlag: costTierFF?.value,
          });
          unmapped.push(...costFields?.map((f) => f.field));
        } else {
          unmapped.push(field);
        }
      });
    }
    return unmapped.filter((field) => {
      const fieldHistoryCheck = getLatestTrackedCell({
        data: {
          fieldHistory,
          productId: state.productId,
          property: field.PropertyChain,
        },
      });
      return !fieldHistoryCheck;
    });
  }, [state?.update?.modifiedAt]);

  const kickOffSuggestions = async () => {
    let body = {
      ProductID: state.productId,
      ProductName: state?.product?.ProductName,
      UnmappedProperties: unmappedProperties,
    };

    try {
      let url;
      switch (media?.state?.fileType) {
        case 'xlsx':
          const latestFieldHistory = getLatestTrackedCell({
            data: {
              fieldHistory,
              productId: state.productId,
              property: 'ProductName',
            },
          });
          if (
            !latestFieldHistory?.source_metadata?.ref_cell_row ||
            !latestFieldHistory?.source_metadata?.ref_cell_col ||
            latestFieldHistory?.source_metadata?.ref_sheet !== media?.state?.activeSheet
          ) {
            console.debug(
              'product name field history is either missing or not on the active sheet, so we will not kickoff suggestions'
            );
            return;
          }

          if (!media?.state?.activeSheet) {
            return;
          }

          let urlEncodedSheetName = encodeURIComponent(media?.state?.activeSheet);
          url = `/v1/benefitspackage/${packageId}/imports/${media?.state?.mediaId}/tabs/${urlEncodedSheetName}`;
          body = {
            ProductNameColumn: latestFieldHistory.source_metadata.ref_cell_col,
            ProductNameRow: latestFieldHistory.source_metadata.ref_cell_row,
            AssistantID: 'asst_cmEyDyyZUFzeMhJhzIFX3Akg',
            ...body,
          };
          break;
        case 'pdf':
          url = `/v1/benefitspackage/${packageId}/imports/${media?.state?.mediaId}/suggestions`;
          break;
        default:
          break;
      }

      console.debug(`kicking off suggestion ${url}`, body);
      await CustomAxios.post(url, body);

      setPollInterval(3000);
    } catch (e) {
      console.log('Error kicking off suggestions', e);
      setPollInterval(false);
    }
  };

  const transformSuggestionResponse = useCallback((data) => {
    let hasPending = false;
    const completedList = data
      ?.filter(({ completed_at }) => !!completed_at)
      ?.map(({ completed_at }) => parseJSON(completed_at));
    const mostRecent = !completedList?.length ? null : max(completedList);
    const suggestions = data?.reduce((prev, item) => {
      hasPending = hasPending || item?.status === 'pending';
      const propertyChain = item?.property_chain === 'ProviderName' ? 'ProviderID' : item?.property_chain;
      return { ...prev, [propertyChain]: item };
    }, {});

    return {
      hasPending,
      suggestions,
      mostRecent,
    };
  }, []);

  const isMediaReady =
    media?.state?.fileType === 'xlsx' ? !!media?.state?.activeSheet : media?.state?.fileType === 'pdf';

  const isQueryingReady = !!state?.productId && !!media?.state?.mediaId && aiAutofillFlag?.value && isMediaReady;

  const getTimeSinceLastComplete = (query) => {
    const latest = query?.data?.mostRecent;
    return latest === null ? 0 : differenceInMinutes(new Date(query?.dataUpdatedAt), latest);
  };

  const query = useQueryAPI({
    url: getSuggestionsUrl(state?.productId, media),
    select: transformSuggestionResponse,
    enabled: isQueryingReady,
    refetchInterval: pollInterval,
    defaultValue: {
      suggestions: {},
      mostRecent: null,
      hasPending: false,
    },
    cacheTime: 0,
    params: {
      axios: {
        headers: {
          'Accept-Profile': 'brite',
        },
      },
    },
  });

  const kickoffIsReady =
    !!packageId &&
    isQueryingReady &&
    !!state.product?.ProductName &&
    !state?.hasChanges &&
    !!media?.state?.fileType &&
    !query?.isLoading &&
    !Object.keys(query?.data?.suggestions || {})?.length &&
    !!unmappedProperties?.length;

  useEffect(() => {
    if (kickoffIsReady) {
      kickOffSuggestions();
    }
  }, [kickoffIsReady, query?.dataUpdatedAt]);

  useEffect(() => {
    const timeSinceLastComplete = getTimeSinceLastComplete(query);
    const shouldPollSuggestions = query?.data?.hasPending && timeSinceLastComplete < 16;
    setPollInterval(shouldPollSuggestions ? 3000 : false);
  }, [query?.dataUpdatedAt]);

  const lookupSuggestion = (propertyChain, allowExisting = false) => {
    // for now, we never return a suggestion if they already have a value
    const latestFieldHistory = getLatestTrackedCell({
      data: {
        fieldHistory,
        productId: state.productId,
        property: propertyChain,
      },
    });

    if (!allowExisting && !!latestFieldHistory) {
      return null;
    }

    const fileId = media?.state?.mediaId;
    const sheetName = media?.state?.activeSheet;

    const suggestion = query?.data?.suggestions?.[propertyChain];
    if (!suggestion || suggestion.original_file_id !== fileId) {
      return null;
    }

    // any additional checks based on the file type
    switch (media?.state?.fileType) {
      case 'xlsx':
        if (suggestion.tab_name !== sheetName) {
          return null;
        }
        break;
      case 'pdf':
        break;
      default:
        break;
    }

    return suggestion;
  };

  const current = useMemo(() => {
    const suggestion = lookupSuggestion(currentPropertyChain);
    return suggestion;
  }, [currentPropertyChain, media?.state?.fieldIdx, fieldHistory, query?.dataUpdatedAt]);

  return {
    current,
    data: query?.data?.suggestions,
    lookup: lookupSuggestion,
    isPolling: !!pollInterval || query?.isLoading,
  };
};
