import React, { useState, useEffect, useRef, useMemo } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { css } from '@emotion/css';
import QRCode from 'react-qr-code';
import HandoutImage from './handout-icon.png';
import { useStore } from '../store-provider/use-store';
import { EnvelopeSimple, Phone, Warning, X } from 'phosphor-react';
import { CustomAxios } from '../redux/axios/axios';
import { toast } from 'react-toastify';
import { Loader, Toggle, Modal, Div, Text, Select, Box, Button } from '../shared/components';
import { colors } from '../shared/styles';
import { flex, px } from '../shared/shared-styles';
import StyleWave from './wave.png';
import { Editor, EditorState, ContentState } from 'draft-js';
import Alert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';

import TextField from '@material-ui/core/TextField';
import { addDays, format } from 'date-fns';
import Dates from '../shared/components/Dates';
import { benefitsPackage, useRequests, useResource } from '../react-query';

const EditorBlock = (props) => {
  const { style, defaultText, setValue } = props;
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createWithContent(ContentState.createFromText(defaultText))
  );
  const editor = React.useRef(null);

  return (
    <div
      style={style}
      className={css`
        :hover,
        :focus {
          border-radius: 16px;
          outline: 1px solid var(--gray-700, #25282d);
        }
      `}
    >
      <Editor
        ref={editor}
        editorState={editorState}
        onChange={(editorState) => {
          setEditorState(editorState);
          setValue(editorState.getCurrentContent().getPlainText('\u0001'));
        }}
        spellCheck={true}
      />
    </div>
  );
};

function shadeColor(color, percent) {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  var RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
}

const AnotherFunSVG = ({ bgColor }) => {
  return (
    <svg width="342" height="183" viewBox="0 0 342 183" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M342 -1V183L0 -1H342Z" fill={bgColor} />
    </svg>
  );
};

const FunSVG = ({ bgColor, position }) => {
  let darkerColor = shadeColor(bgColor, -30);
  if (position === 'top') {
    return (
      <svg width="468" height="362" viewBox="0 0 468 362" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient
            id="paint0_linear_8555_23825"
            x1="378.087"
            y1="-363.749"
            x2="-78.1959"
            y2="105.409"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={'#1EE4C1'} />
            <stop offset="0.5" stopColor={darkerColor} />
            <stop offset="1" stopColor={bgColor} />
          </linearGradient>
        </defs>
        <path
          d="M12.8786 172.487C21.4851 198.046 15.1219 215.292 8.87997 232.21C2.85653 248.536 -3.05392 264.555 4.71012 287.442C6.94229 294.027 10.3064 301.181 15.1255 309.074C66.878 393.826 157.113 359.135 272.009 314.964C277.091 313.01 282.222 311.038 287.4 309.056L287.427 309.047C364.436 279.594 412.529 240.298 439.285 199.755C440.502 197.912 441.674 196.067 442.803 194.22C462.995 161.178 469.314 127.568 465.839 98.0056C461.886 64.3484 445.233 35.9369 421.911 19.5958C418.759 17.3861 415.485 15.3965 412.104 13.6439C354.609 -16.1607 327.323 12.1761 298.186 42.4354C267.581 74.2198 234.933 108.125 163.087 78.9979C126.946 64.3565 95.4265 62.5171 70.3199 68.828C20.8527 81.2624 -3.71967 125.337 10.3075 165.471C10.5996 166.307 10.9085 167.142 11.2343 167.974C11.5419 168.76 11.8357 169.538 12.1159 170.309C12.3822 171.042 12.6363 171.768 12.8786 172.487Z"
          fill="url(#paint0_linear_8555_23825)"
        />
        <path
          d="M287.426 309.045C511.454 223.363 490.744 54.4085 412.104 13.6439C294.216 -47.4675 303.333 135.855 163.087 78.9979C54.2596 34.9095 -12.6659 106.902 11.2317 167.967C35.1293 229.031 -24.801 243.673 15.1185 309.053C69.1569 397.544 165.162 355.802 287.39 309.027L287.426 309.045Z"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M287.427 309.047C399.441 266.206 450.276 202.54 463.259 144.502C476.269 86.4548 451.418 34.008 412.106 13.6494C353.147 -16.9065 325.98 13.6327 295.994 44.7226C266.008 75.8126 233.231 107.444 163.088 79.0018C54.2585 34.9058 -12.6655 106.903 11.2343 167.974C35.1341 229.045 -24.7964 243.687 15.1255 309.074C69.1673 397.575 165.172 355.832 287.4 309.056L287.427 309.047Z"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M398.128 34.1722C346.03 6.28122 321.331 34.0316 293.54 62.5133C290.222 65.9396 286.85 69.384 283.379 72.7126C258.549 96.6957 228.788 116.067 173.652 94.7244C120.094 74.0186 77.3136 83.609 52.4901 104.97C32.1765 122.478 24.4137 149.088 33.7098 173.745C54.6076 229.257 2.31262 241.329 38.6653 298.814C87.6826 376.59 173.34 339.929 281.821 298.591C381.344 260.681 426.724 204.224 439.651 152.761C452.577 101.297 432.819 52.6709 398.119 34.1454L398.128 34.1722Z"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M384.131 54.7005C338.882 29.4428 316.599 54.3352 291.057 80.2818C288.069 83.3306 285.053 86.3884 281.948 89.3572C259.64 110.714 232.915 128.182 184.188 110.424C136.802 93.2319 98.352 101.62 75.111 119.625C56.186 134.289 48.3514 157.768 56.1482 179.558C73.4653 227.955 29.3866 239.02 62.1813 288.638C106.196 355.663 181.514 324.11 276.221 288.219C363.236 255.275 403.25 205.933 416.073 161.084C428.896 116.235 414.246 71.387 384.131 54.7005Z"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M370.127 75.2258C331.682 52.736 311.914 74.7072 288.604 98.0652C285.973 100.727 283.286 103.408 280.546 106.017C260.797 124.766 237.09 140.277 194.726 126.148C153.512 112.469 119.402 119.681 97.7069 134.314C80.1706 146.134 72.2824 166.444 78.5798 185.369C92.3074 226.624 56.5814 236.636 85.6816 278.432C124.83 334.66 189.672 308.26 270.605 277.816C345.139 249.829 379.833 207.647 392.453 169.386C406.063 128.086 395.585 90.1274 370.136 75.2526L370.127 75.2258Z"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M356.124 95.7491C324.512 75.9285 307.222 95.0503 286.116 115.829C283.814 118.114 281.484 120.407 279.109 122.656C261.883 138.78 241.211 152.387 205.256 141.842C170.215 131.678 140.462 137.678 120.323 148.965C104.211 157.958 96.2058 175.092 101.013 191.178C111.196 225.246 83.6774 234.313 109.183 268.224C143.357 313.692 197.841 292.436 264.991 267.412C327.034 244.355 356.527 209.323 368.834 177.686C382.764 141.907 376.943 108.83 356.133 95.776L356.124 95.7491Z"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M342.124 116.28C317.343 99.1289 302.541 115.428 283.64 133.627C281.694 135.525 279.721 137.432 277.675 139.304C262.998 152.792 245.336 164.506 215.788 157.545C186.911 150.867 161.57 155.727 142.904 163.605C128.254 169.79 120.15 183.712 123.43 196.941C130.097 223.812 110.785 231.936 132.67 257.971C161.867 292.678 205.959 276.548 259.362 256.962C308.923 238.862 333.332 210.97 345.199 185.941C359.56 155.646 358.314 127.477 342.088 116.263L342.124 116.28Z"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M328.124 136.818C310.175 122.336 297.887 135.804 281.19 151.423C279.573 152.944 277.957 154.464 276.276 155.976C264.131 166.866 249.505 176.586 226.357 173.272C203.208 169.958 182.732 173.854 165.558 178.288C152.35 181.701 144.148 192.32 145.893 202.756C149.162 222.332 137.874 229.601 156.201 247.769C180.359 271.707 214.148 260.702 253.777 246.563C290.848 233.394 310.408 212.712 321.609 194.247C336.654 169.471 339.692 146.159 328.124 136.818Z"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M314.122 157.34C303.041 145.456 293.249 156.217 278.702 169.186C277.442 170.32 276.155 171.463 274.84 172.615C265.217 180.878 253.627 188.695 236.887 188.965C220.147 189.236 203.846 192.01 188.174 192.938C176.454 193.622 168.237 200.823 168.353 208.555C168.516 220.708 164.916 227.295 179.73 237.55C198.813 250.792 222.335 244.841 248.19 236.148C272.752 227.945 287.837 214.589 298.045 202.528C314.172 183.465 321.151 164.797 314.176 157.322L314.122 157.34Z"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M300.142 177.857C295.743 167.828 287.563 177.964 273.426 189.248C266.353 194.877 257.78 200.826 247.413 204.663C237.054 208.527 224.928 210.269 210.749 207.573C200.453 205.61 192.909 209.46 190.737 214.322C188.564 219.183 191.843 225.119 203.191 227.327C217.134 230.042 230.463 229.001 242.535 225.728C254.607 222.456 265.457 216.97 274.385 210.813C292.261 198.463 302.56 183.394 300.106 177.84L300.142 177.857Z"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  if (position === 'left') {
    return (
      <svg width="234" height="208" viewBox="0 0 234 208" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M-247.152 197.034L232.808 348.432C217.911 195.898 166.716 140.394 85.241 158.064C3.76616 175.735 11.0905 18.3323 -79.9082 27.2195C-155.908 34.6419 -138.212 105.321 -197.831 114.382C-241.528 121.03 -252.059 146.787 -247.152 197.034Z"
          fill="url(#paint0_linear_8809_7342)"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M232.836 348.43C217.939 195.896 166.744 140.392 85.2693 158.062C3.79448 175.733 11.1188 18.3303 -79.8799 27.2175C-155.88 34.64 -138.184 105.319 -197.803 114.38C-241.5 121.028 -252.031 146.785 -247.124 197.033"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M202.114 338.734C190.175 206.735 141.594 161.102 69.4336 175.006C43.169 180.079 27.183 165.26 13.6062 144.556C-10.3679 108.074 -27.6 57.0943 -77.1111 61.7393C-104.005 64.2706 -117.804 75.7445 -128.234 88.8892C-143.349 108.015 -151.358 130.669 -182.098 135.099C-219.973 140.576 -230.283 161.772 -227.992 203.101"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M171.362 329.044C162.384 217.611 116.449 181.846 53.5995 191.952C31.1295 195.575 17.131 183.229 5.24102 165.82C-16.5746 133.889 -32.804 92.5248 -74.3407 96.264C-97.1591 98.2703 -109.404 107.783 -118.803 118.478C-132.391 134.058 -140.227 152.314 -166.362 155.787C-198.384 160.089 -208.473 176.724 -208.834 209.107"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M140.638 319.336C134.586 228.441 91.3664 202.601 37.796 208.912C19.1238 211.117 7.07811 201.214 -3.09361 187.098C-22.7789 159.752 -37.9429 127.998 -71.5396 130.803C-90.3109 132.319 -100.979 139.773 -109.373 148.084C-121.469 160.09 -129.128 173.98 -150.624 176.524C-176.822 179.685 -186.692 191.728 -189.642 215.157"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M109.914 309.646C106.82 239.287 66.2941 223.468 21.9909 225.859C7.15085 226.673 -3.00486 219.221 -11.3985 208.359C-28.982 185.633 -43.0459 163.516 -68.7403 165.327C-83.4645 166.353 -92.5529 171.78 -99.9133 177.673C-110.455 186.099 -117.965 195.657 -134.888 197.246C-155.232 199.233 -164.913 206.718 -170.483 221.197"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M79.1588 299.953C79.0257 250.16 41.2361 244.486 6.15336 242.802C-4.88286 242.261 -13.1801 237.262 -19.7642 229.651C-35.3685 211.589 -48.2159 199.004 -65.9703 199.881C-76.6475 200.416 -84.1217 203.844 -90.48 207.322C-99.4917 212.233 -106.835 217.331 -119.147 218.026C-133.641 218.806 -143.098 221.762 -151.319 227.294"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_8809_7342"
            x1="-402.944"
            y1="390.554"
            x2="295.026"
            y2="2.86744"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={bgColor} />
            <stop offset="0.5" stopColor={darkerColor} />
            <stop offset="1" stopColor={bgColor} />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  if (position === 'right') {
    return (
      <svg width="262" height="200" viewBox="0 0 262 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.2692 220.906L790.27 318.359C695.139 240.155 729.373 26.7028 549.426 30.6342C409.248 33.6946 428.815 168.981 363.385 161.172C297.955 153.364 319.765 109.192 257.339 112.812C194.913 116.432 196.517 171.156 138.173 166.076C97.1678 162.521 56.6089 169.046 17.3035 220.872L17.2692 220.906Z"
          fill="url(#paint0_linear_8809_7345)"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M790.271 318.359C695.14 240.155 729.374 26.7028 549.427 30.6342C409.249 33.6947 428.816 168.981 363.386 161.172C297.956 153.364 319.766 109.192 257.34 112.812C194.914 116.432 196.518 171.156 138.174 166.076C97.1687 162.521 56.6098 169.046 17.3043 220.872"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M757.166 314.177C712.905 281.008 699.402 218.076 674.402 164.81C648.093 108.498 616.323 63.0402 531.486 64.8839C486.227 65.8846 458.709 81.7046 439.368 101.26C406.113 134.943 398.358 179.982 360.925 175.293C302.902 168.096 319.85 129.705 264.775 132.196C235.485 133.434 221.598 146.946 207.441 158.602C194.786 168.96 181.752 178.062 157.301 176.058C119.233 172.949 81.3615 180.366 47.7035 224.778"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M724.051 310.024C681.755 281.699 670.418 225.799 645.031 180.665C618.37 133.005 590.761 97.4666 513.604 99.0944C474.305 99.9551 449.152 112.835 430.972 129.014C400.181 156.39 391.858 193.756 358.522 189.374C307.898 182.731 319.971 150.3 272.302 151.506C246.702 152.148 234.14 162.871 221.315 172.059C209.876 180.229 197.965 187.592 176.521 185.967C141.351 183.31 106.24 191.577 78.1569 228.617"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M690.949 305.843C650.657 282.358 641.487 233.489 615.676 196.522C588.659 157.486 565.25 131.86 495.739 133.306C462.4 134.026 439.723 143.921 422.55 156.746C394.343 177.827 385.336 207.538 356.094 203.434C312.912 197.367 320.092 170.78 279.769 170.828C257.933 170.834 246.623 178.809 235.13 185.529C224.906 191.509 214.115 197.106 195.68 195.887C163.414 193.711 131.025 202.835 108.589 232.463"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M657.878 301.687C619.551 283.047 612.436 241.255 586.314 212.409C558.834 182.07 539.697 166.317 477.867 167.547C450.449 168.134 430.407 175.05 414.163 184.532C388.818 199.336 378.85 221.373 353.7 217.546C317.926 212.091 320.21 191.32 287.306 190.17C269.194 189.545 259.136 194.772 249.052 199.012C240.005 202.809 230.342 206.697 214.944 205.793C185.58 204.097 155.951 214.073 139.082 236.271"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M624.769 297.502C588.412 283.734 583.501 248.968 556.953 228.261C529.16 206.569 514.141 200.711 459.957 201.76C438.499 202.206 421.255 206.178 405.695 212.265C383.546 220.918 372.249 235.189 351.227 231.607C322.826 226.797 320.282 211.773 294.728 209.493C280.337 208.209 271.496 210.722 262.821 212.483C254.913 214.102 246.455 216.269 234.097 215.709C207.602 214.528 180.725 225.298 169.507 240.111"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M591.656 293.316C557.265 284.394 554.559 256.653 527.549 244.118C499.439 231.044 488.585 235.134 442.043 235.972C426.546 236.278 411.927 236.135 397.267 240.022C379.082 244.831 365.725 249.023 348.792 245.69C327.769 241.553 320.341 232.169 302.224 228.805C291.549 226.833 283.922 226.604 276.668 225.971C269.932 225.378 262.606 225.863 253.289 225.647C229.709 225.033 205.58 236.568 199.971 243.975"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M558.558 289.167C526.171 285.084 525.661 264.312 498.195 259.978C462.793 254.381 461.022 277.585 388.844 267.757C345.97 261.909 314.382 251.703 290.478 239.415C266.578 227.156 230.356 247.773 230.356 247.773"
          stroke="#240C64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_8809_7345"
            x1="47.2707"
            y1="426.684"
            x2="790.27"
            y2="318.359"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={bgColor} />
            <stop offset="0.5" stopColor={darkerColor} />
            <stop offset="1" stopColor={bgColor} />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return null;
};

export const Handout = ({ setShowBriteGuideHandout, guideURL, guideID, selectedCourse }) => {
  const {
    data: { parentBusiness, selectedBusiness },
  } = useStore();

  const [loading, setLoading] = useState(true);
  const [showQuality, setShowQuality] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [showDateSelection, setShowDateSelection] = useState(false);
  const [showContactOneModal, setShowContactOneModal] = useState(false);
  const [showContactTwoModal, setShowContactTwoModal] = useState(false);
  const [designStyles, setDesignStyles] = useState({});
  const [companyLogo, setCompanyLogo] = useState('');
  const [preparedByLogo, setPreparedByLogo] = useState('');
  const [contact, setContact] = useState({
    name: '',
    email: '',
    phone: '',
    title: '',
  });

  const [summaryTooLong, setSummaryTooLong] = useState(false);
  const [fileScale, setFileScale] = useState(5);
  const [contactTwo, setContactTwo] = useState({
    name: '',
    email: '',
    phone: '',
    title: '',
  });

  const [printData, setPrintData] = useState({
    showCompanyLogo: true,
    showHeadlineOne: true,
    showOpenEnrollment: true,
    showHeadlineTwo: true,
    showSummary: true,
    showContact: true,
    showContactTwo: true,
    showHandoutQRCode: true,
    showPreparedBy: true,
    headlineOne: 'Welcome to your',
    headlineTwo: 'Employee Benefits',
    summary:
      'Welcome to your benefits experience. It is Open Enrollment time, which means that you have the opportunity to make changes and updates to your benefits for the upcoming year. Once you make these elections you cannot change them unless you have a QLE (Qualifying Life Event). Scan the QR Code below to learn about each of these benefits and how they can benefit you and your loved ones.',

    style: 0,
    startDate: null,
    endDate: null,
  });

  const pkgResource = useResource(benefitsPackage);
  const pkgRequests = useRequests(pkgResource);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const response = await CustomAxios.get(`/v1/bp/handout_config?id=eq.${guideID}`, {
          headers: {
            'Accept-Profile': 'brite',
          },
        });

        if (response.data && response.data.length > 0) {
          setPrintData(response.data[0].config);
        }

        try {
          const designResponse = await CustomAxios.get(`/v1/themes/${selectedCourse.ThemeID}`);

          setDesignStyles(designResponse?.data?.Theme);
        } catch (e) {}

        setLoading(false);
      } catch (err) {
        console.warn(err);

        setLoading(false);
      }
    };

    fetch();
  }, []);

  // *** *** *** *** *** *** *** *** *** *** *** *** ***
  //  Override start and end enrollment date with
  //  the selected BP enrollment dates if the course has a BP
  //
  const hasBenefitsPackage =
    !!selectedCourse?.BenefitsPackageID && selectedCourse?.BenefitsPackageID !== '00000000-0000-0000-0000-000000000000';

  const enrollmentDates = useMemo(() => {
    if (hasBenefitsPackage) {
      return {
        start: Dates.getDateFromISO(pkgResource?.data?.EnrollmentStartDate),
        end: Dates.getDateFromISO(pkgResource?.data?.EnrollmentEndDate),
      };
    }
    return {
      start: Dates.getDateFromISO(printData?.startDate),
      end: Dates.getDateFromISO(printData?.endDate),
    };
  }, [pkgResource.data.EnrollmentStartDate, pkgResource.data.EnrollmentEndDate, hasBenefitsPackage]);

  const handleEnrollementDates = ({ start, end }) => {
    if (hasBenefitsPackage) {
      const putRequest = benefitsPackage?.utils?.getPut(selectedCourse?.BenefitsPackageID, {
        ...pkgResource.data,
        EnrollmentStartDate: start.toISOString(),
        EnrollmentEndDate: end.toISOString(),
      });
      pkgRequests.put(putRequest);
    } else {
      setPrintData((prev) => ({
        ...prev,
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      }));
    }
  };

  // *** *** *** *** *** *** *** *** *** *** *** *** ***

  const saveHandout = async () => {
    try {
      await CustomAxios.put(
        `/v1/bp/handout_config?id=eq.${guideID}`,
        {
          id: guideID,
          business_id: selectedBusiness.ID,
          config: printData,
        },
        {
          headers: {
            'Content-Profile': 'brite',
          },
        }
      );
    } catch (e) {}
  };

  const getBase64Image = (src, callback, outputFormat) => {
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      var canvas = document.createElement('CANVAS');
      var ctx = canvas.getContext('2d');
      var dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
    };
    img.src = src;
    if (img.complete || img.complete === undefined) {
      img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
      img.src = src;
    }
  };

  useEffect(() => {
    if (selectedBusiness.LogoURL) {
      getBase64Image(selectedBusiness.LogoURL, (url) => {
        setCompanyLogo(url);
      });
    }
    if (parentBusiness.LogoURL) {
      getBase64Image(parentBusiness.LogoURL, (url) => {
        setPreparedByLogo(url);
      });
    }

    if (selectedBusiness.HRContactName) {
      setContact({
        name: selectedBusiness.HRContactName,
        email: selectedBusiness.HRContactEmail,
        phone: selectedBusiness.HRContactPhone,
        title: selectedBusiness.HRContactTitle,
      });
    } else if (parentBusiness.HRContactName) {
      setContact({
        name: parentBusiness.HRContactName,
        email: parentBusiness.HRContactEmail,
        phone: parentBusiness.HRContactPhone,
        title: parentBusiness.HRContactTitle,
      });
    }

    if (parentBusiness.BrokerContactName) {
      setContactTwo({
        name: parentBusiness.BrokerContactName,
        email: parentBusiness.BrokerContactEmail,
        phone: parentBusiness.BrokerContactPhone,
        title: parentBusiness.BrokerContactTitle,
      });
    } else if (selectedBusiness.BrokerContactName) {
      setContactTwo({
        name: selectedBusiness.BrokerContactName,
        email: selectedBusiness.BrokerContactEmail,
        phone: selectedBusiness.BrokerContactPhone,
        title: selectedBusiness.BrokerContactTitle,
      });
    }
  }, [selectedBusiness.LogoURL, parentBusiness.LogoURL]);

  const printHandoutRef = useRef();

  const handleDownloadPdf = () => {
    setShowQuality(true);
    window?.ChurnZero?.push([
      'trackEvent',
      'Brite Guide Handout',
      'Clicked on Brite Guide Handout AND clicked download',
      1,
    ]);
  };

  const downloadWithScale = async (scale) => {
    setDownloading(true);
    saveHandout();
    const pdf = new jsPDF('p', 'pt', [595.28, 841.89]);
    const element = printHandoutRef.current;

    const canvas = await html2canvas(element, {
      quality: 4,
      scale: scale,
    });
    const data = canvas.toDataURL('image/png');
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);

    await pdf.save('handout.pdf');
    setDownloading(false);
    toast.success(`Succesfully downloaded as a PDF`);
  };

  return (
    <Modal
      onClose={() => {
        setShowBriteGuideHandout(false);
      }}
      display
      full
      css={`
        overflow-y: auto;
      `}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px;
          border-bottom: 1px solid #d1dae3;
        `}
      >
        <div
          className={css`
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 34px;
            line-height: 123.5%;
            /* identical to box height, or 42px */

            letter-spacing: 0.25px;

            color: #25282d;
            display: flex;
            align-items: center;
            gap: 16px;
          `}
        >
          <img
            src={HandoutImage}
            className={css`
              width: 54px;
              height: 54px;
            `}
            alt="Handout"
          />
          <div>Brite Guide Handout</div>
        </div>
        <div
          className={css`
            display: flex;
            align-items: center;
            gap: 16px;
          `}
        >
          <div>
            <Button
              secondary
              onClick={() => {
                saveHandout(false);
                setShowBriteGuideHandout(false);
              }}
            >
              Close
            </Button>
          </div>

          <div>
            <Button
              onClick={() => {
                handleDownloadPdf();
              }}
              disabled={loading || downloading || summaryTooLong}
            >
              {loading ? 'Loading...' : downloading ? 'Downloading...' : 'Download'}
            </Button>
          </div>
        </div>
      </div>
      {loading && (
        <div
          className={css`
            margin-top: 48px;
          `}
        >
          <Loader
            isLoading={true}
            className={css`
              ${flex('center start')}
              background-color: ${colors.gray[300]};
            `}
          />
        </div>
      )}

      {!loading && (
        <div
          className={css`
            overflow: auto;
          `}
        >
          {downloading && (
            <Alert variant="filled" severity="info" style={{ backgroundColor: '#6D44FB' }}>
              Downloading in process - Please wait.
            </Alert>
          )}
          <div
            className={css`
              margin-bottom: 12px;
            `}
          >
            <div
              className={css`
                padding-bottom: 8px;

                display: flex;
                justify-content: flex-start;
                gap: 20px;
              `}
            >
              <div
                className={css`
                  margin: 32px;
                `}
              >
                <div
                  className={css`
                    color: #25282d;
                    font-feature-settings: 'clig' off, 'liga' off;
                    /* H4 */
                    font-family: Roboto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px; /* 133.333% */
                    letter-spacing: 0.15px;
                    margin-bottom: 16px;
                  `}
                >
                  Select a Style
                </div>
                <div
                  className={css`
                    display: flex;
                    gap: 16px;
                  `}
                >
                  <div
                    className={css`
                      border-radius: 8px;
                      border: ${printData.style === 0 ? '2px solid #25282d' : '1px solid #9aa7b5'};
                      background: #fff;
                      width: 48px;
                      height: 48px;

                      &:hover {
                        outline: 2px solid var(--Gray-400, #25282d);
                      }
                    `}
                    onClick={() => {
                      setPrintData((old) => {
                        return {
                          ...old,
                          style: 0,
                        };
                      });
                    }}
                  >
                    <img
                      src={StyleWave}
                      className={css`
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                      `}
                      alt="Wave"
                    />
                  </div>
                  <div
                    className={css`
                      border-radius: 8px;
                      border: ${printData.style === 1 ? '2px solid #25282d' : '1px solid #9aa7b5'};
                      background: #fff;
                      width: 48px;
                      height: 48px;
                      &:hover {
                        outline: 2px solid var(--Gray-400, #25282d);
                      }
                    `}
                    onClick={() => {
                      setPrintData((old) => {
                        return {
                          ...old,
                          style: 1,
                        };
                      });
                    }}
                  >
                    {' '}
                  </div>

                  <div
                    className={css`
                      border-radius: 8px;
                      border: ${printData.style === 2 ? '2px solid #25282d' : '1px solid #9aa7b5'};

                      background: linear-gradient(219deg, #0a9edc 36.76%, rgba(255, 255, 255, 0) 36.77%);
                      width: 48px;
                      height: 48px;
                      &:hover {
                        outline: 2px solid var(--Gray-400, #25282d);
                      }
                    `}
                    onClick={() => {
                      setPrintData((old) => {
                        return {
                          ...old,
                          style: 2,
                        };
                      });
                    }}
                  >
                    {' '}
                  </div>
                </div>

                <div
                  className={css`
                    color: #25282d;
                    font-feature-settings: 'clig' off, 'liga' off;
                    /* H4 */
                    font-family: Roboto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px; /* 133.333% */
                    letter-spacing: 0.15px;
                    margin-top: 32px;
                  `}
                >
                  Handout Settings
                </div>
                <div
                  className={css`
                    border-radius: 8px;
                    border: 1px solid #d1dae3;
                    display: flex;
                    width: 300px;
                    padding: 4px 16px;
                    align-items: center;
                    gap: 16px;
                    margin-top: 16px;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <div>Company Logo</div>
                  <div>
                    <Toggle
                      checked={printData.showCompanyLogo}
                      onChange={(e) => {
                        setPrintData((old) => {
                          return {
                            ...old,
                            showCompanyLogo: e.target.checked,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={css`
                    border-radius: 8px;
                    border: 1px solid #d1dae3;
                    display: flex;
                    width: 300px;
                    padding: 4px 16px;
                    align-items: center;
                    gap: 16px;
                    margin-top: 16px;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <div>Open Enrollment Dates</div>
                  <div>
                    <Toggle
                      checked={printData.showOpenEnrollment}
                      onChange={(e) => {
                        setPrintData((old) => {
                          return {
                            ...old,
                            showOpenEnrollment: e.target.checked,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={css`
                    border-radius: 8px;
                    border: 1px solid #d1dae3;
                    display: flex;
                    width: 300px;
                    padding: 4px 16px;
                    align-items: center;
                    gap: 16px;
                    margin-top: 16px;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <div>Headline 1</div>
                  <div>
                    <Toggle
                      checked={printData.showHeadlineOne}
                      onChange={(e) => {
                        setPrintData((old) => {
                          return {
                            ...old,
                            showHeadlineOne: e.target.checked,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={css`
                    border-radius: 8px;
                    border: 1px solid #d1dae3;
                    display: flex;
                    width: 300px;
                    padding: 4px 16px;
                    align-items: center;
                    gap: 16px;
                    margin-top: 16px;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <div>Headline 2</div>
                  <div>
                    <Toggle
                      checked={printData.showHeadlineTwo}
                      onChange={(e) => {
                        setPrintData((old) => {
                          return {
                            ...old,
                            showHeadlineTwo: e.target.checked,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={css`
                    border-radius: 8px;
                    border: 1px solid #d1dae3;
                    display: flex;
                    width: 300px;
                    padding: 4px 16px;
                    align-items: center;
                    gap: 16px;
                    margin-top: 16px;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <div>Summary</div>
                  <div>
                    <Toggle
                      checked={printData.showSummary}
                      onChange={(e) => {
                        setPrintData((old) => {
                          return {
                            ...old,
                            showSummary: e.target.checked,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={css`
                    border-radius: 8px;
                    border: 1px solid #d1dae3;
                    display: flex;
                    width: 300px;
                    padding: 4px 16px;
                    align-items: center;
                    gap: 16px;
                    margin-top: 16px;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <div>Contact</div>
                  <div>
                    <Toggle
                      checked={printData.showContact}
                      onChange={(e) => {
                        setPrintData((old) => {
                          return {
                            ...old,
                            showContact: e.target.checked,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={css`
                    border-radius: 8px;
                    border: 1px solid #d1dae3;
                    display: flex;
                    width: 300px;
                    padding: 4px 16px;
                    align-items: center;
                    gap: 16px;
                    margin-top: 16px;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <div>Contact 2</div>
                  <div>
                    <Toggle
                      checked={printData.showContactTwo}
                      onChange={(e) => {
                        setPrintData((old) => {
                          return {
                            ...old,
                            showContactTwo: e.target.checked,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={css`
                    border-radius: 8px;
                    border: 1px solid #d1dae3;
                    display: flex;
                    width: 300px;
                    padding: 4px 16px;
                    align-items: center;
                    gap: 16px;
                    margin-top: 16px;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <div>QR Code</div>
                  <div>
                    <Toggle
                      checked={printData.showHandoutQRCode}
                      onChange={(e) => {
                        setPrintData((old) => {
                          return {
                            ...old,
                            showHandoutQRCode: e.target.checked,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={css`
                    border-radius: 8px;
                    border: 1px solid #d1dae3;
                    display: flex;
                    width: 300px;
                    padding: 4px 16px;
                    align-items: center;
                    gap: 16px;
                    margin-top: 16px;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <div>Prepared By</div>
                  <div>
                    <Toggle
                      checked={printData.showPreparedBy}
                      onChange={(e) => {
                        setPrintData((old) => {
                          return {
                            ...old,
                            showPreparedBy: e.target.checked,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                ref={printHandoutRef}
                className={css`
                  box-shadow: 0px 4px 10px 0px rgba(37, 40, 45, 0.3);

                  margin: 24px auto;

                  width: 595.28pt;
                  height: 841.89pt;
                  position: relative;
                  overflow: hidden;
                `}
              >
                {printData.style === 2 && (
                  <>
                    <div
                      className={css`
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: 2;
                      `}
                    >
                      <AnotherFunSVG bgColor={designStyles?.Body?.AccentColor || '#1EE4C1'} />
                    </div>
                  </>
                )}
                {printData.style === 0 && (
                  <>
                    {' '}
                    <div
                      className={css`
                        position: absolute;
                        top: -220px;
                        right: -120px;
                        z-index: 2;
                      `}
                    >
                      <FunSVG bgColor={designStyles?.Body?.AccentColor || '#1EE4C1'} position="top" />
                    </div>
                    <div
                      className={css`
                        position: absolute;
                        bottom: -5px;
                        left: 0px;
                        z-index: 2;
                      `}
                    >
                      <FunSVG bgColor={designStyles?.Body?.AccentColor || '#1EE4C1'} position="left" />
                    </div>
                    <div
                      className={css`
                        position: absolute;
                        bottom: -5px;
                        right: 0px;
                        z-index: 2;
                      `}
                    >
                      <FunSVG bgColor={designStyles?.Body?.AccentColor || '#1EE4C1'} position="right" />
                    </div>
                  </>
                )}
                <div
                  className={css`
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                  `}
                >
                  <div
                    className={css`
                      flex: 1;
                    `}
                  >
                    <div
                      className={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 48px;
                      `}
                    >
                      {printData.showCompanyLogo ? (
                        <img
                          crossOrigin="anonymous"
                          src={companyLogo}
                          className={css`
                            max-width: 167px;
                            max-height: 47px;
                          `}
                          alt="Company Logo"
                        />
                      ) : (
                        <div> </div>
                      )}
                      {printData.showOpenEnrollment && (
                        <div
                          className={css`
                            border-radius: 8px;
                            padding: 13px;
                            background: white;

                            border: 1px solid rgba(37, 40, 45, 0.25);
                            position: relative;
                            z-index: 200;
                            cursor: pointer;
                            &:hover {
                              border: 1px solid transparent;
                              outline: 2px solid #25282d;
                            }
                          `}
                          onClick={() => {
                            setShowDateSelection(true);
                          }}
                        >
                          <div
                            className={css`
                              color: #9aa7b5;
                              font-family: Roboto;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 700;
                              line-height: 25.5px;
                            `}
                          >
                            Open Enrollment
                          </div>
                          <div
                            className={css`
                              color: #25282d;
                              font-family: Roboto;
                              font-size: 16px;
                              font-style: normal;
                              font-weight: 700;
                              line-height: 25.5px;
                            `}
                          >
                            {enrollmentDates.start ? format(enrollmentDates.start, 'MMM do') : 'No start date set'} -{' '}
                            {enrollmentDates.end ? format(enrollmentDates.end, 'MMM do yyyy') : 'No end date set'}
                          </div>
                        </div>
                      )}

                      {!printData.showOpenEnrollment && (
                        <div
                          className={css`
                            border-radius: 8px;
                            padding: 13px;
                            background: transparent;

                            position: relative;
                            z-index: 200;
                            cursor: pointer;
                            height: 79px;
                          `}
                        ></div>
                      )}
                    </div>
                    {printData.showHeadlineOne && (
                      <EditorBlock
                        style={{
                          color: '#25282d',
                          fontFamily: 'Roboto',
                          fontSize: '40px',
                          fontStyle: 'italic',
                          fontWeight: 300,
                          lineHeight: '100%',
                          marginLeft: '56px',
                          marginRight: '56px',
                          marginBottom: '8px',
                        }}
                        defaultText={printData.headlineOne}
                        setValue={(val) =>
                          setPrintData((old) => {
                            return { ...old, headlineOne: val };
                          })
                        }
                      />
                    )}
                    {printData.showHeadlineTwo && printData.style === 0 && (
                      <EditorBlock
                        style={{
                          color: designStyles?.Heading?.FontColorOverride || designStyles?.Body?.FontColor || '#000000',
                          fontFamily: 'Roboto',
                          fontSize: '60px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: '100%',
                          marginLeft: '56px',
                          marginRight: '56px',
                        }}
                        defaultText={printData.headlineTwo}
                        setValue={(val) =>
                          setPrintData((old) => {
                            return { ...old, headlineTwo: val };
                          })
                        }
                      />
                    )}

                    {printData.showHeadlineTwo && printData.style === 2 && (
                      <EditorBlock
                        style={{
                          color: designStyles?.Body?.AccentColor || '#1EE4C1',
                          fontFamily: 'Roboto',
                          fontSize: '60px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: '100%',
                          marginLeft: '56px',
                          marginRight: '56px',
                        }}
                        defaultText={printData.headlineTwo}
                        setValue={(val) =>
                          setPrintData((old) => {
                            return { ...old, headlineTwo: val };
                          })
                        }
                      />
                    )}

                    {printData.showHeadlineTwo && printData.style === 1 && (
                      <EditorBlock
                        style={{
                          color: '#000000',
                          fontFamily: 'Roboto',
                          fontSize: '60px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: '100%',
                          marginLeft: '56px',
                          marginRight: '56px',
                        }}
                        defaultText={printData.headlineTwo}
                        setValue={(val) =>
                          setPrintData((old) => {
                            return { ...old, headlineTwo: val };
                          })
                        }
                      />
                    )}
                    {summaryTooLong && (
                      <div
                        className={css`
                          font-size: 10px;
                          color: red;
                          margin-bottom: -32px;
                          margin-left: 56px;
                        `}
                      >
                        Summary is too long
                      </div>
                    )}
                    {printData.showSummary && (
                      <EditorBlock
                        style={{
                          color: '#66737f',
                          fontFamily: 'Roboto',
                          fontSize: '18px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: '30px',
                          marginLeft: '56px',
                          marginRight: '56px',
                          marginTop: '32px',
                          marginBottom: '40px',
                          outline: summaryTooLong ? '1px solid red' : '',
                          borderRadius: summaryTooLong ? '16px' : '',
                        }}
                        defaultText={printData.summary}
                        setValue={(val) => {
                          if (val?.length < 590) {
                            setPrintData((old) => {
                              return { ...old, summary: val };
                            });
                            setSummaryTooLong(false);
                          } else {
                            setSummaryTooLong(true);
                          }
                        }}
                      />
                    )}

                    <div
                      className={css`
                        margin-left: 56px;
                        margin-right: 56px;
                        margin-top: ${printData.showSummary ? '0px' : '40px'};
                      `}
                    >
                      {(printData.showContact || printData.showContactTwo) && (
                        <div
                          className={css`
                            color: var(--Gray-700, #25282d);
                            font-family: Roboto;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 32px;
                          `}
                        >
                          Contact Info
                        </div>
                      )}
                      {printData.showContact && (
                        <div
                          className={css`
                            border-radius: 8px;
                            background: #f5f7fa;
                            margin-top: 8px;
                            padding: 16px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            cursor: pointer;
                            &:hover {
                              outline: 2px solid #25282d;
                            }
                          `}
                          onClick={() => setShowContactOneModal(true)}
                        >
                          <div
                            className={css`
                              padding-left: 16px;
                              width: 50%;
                            `}
                          >
                            <div
                              className={css`
                                color: #25282d;
                                font-family: Roboto;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: ${contactTwo?.name ? 700 : 400};

                                line-height: 32px;
                              `}
                            >
                              {contact?.name || 'Click to Edit Contact'}
                            </div>
                            <div
                              className={css`
                                color: var(--Gray-500, #66737f);
                                font-family: Roboto;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 32px;
                              `}
                            >
                              {contact?.title || ''}
                            </div>
                          </div>

                          <div
                            className={css`
                              padding-right: 100px;
                              width: 50%;
                            `}
                          >
                            {contact?.email && (
                              <div
                                className={css`
                                  display: flex;
                                  gap: 16px;
                                  align-items: center;
                                  svg {
                                    min-width: 24px;
                                  }
                                `}
                              >
                                <EnvelopeSimple size="24" />{' '}
                                <div
                                  className={css`
                                    color: var(--Gray-500, #66737f);
                                    font-family: Roboto;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 32px;
                                  `}
                                >
                                  {contact?.email || ''}
                                </div>
                              </div>
                            )}
                            {contact?.phone && (
                              <div
                                className={css`
                                  display: flex;
                                  gap: 16px;
                                  align-items: center;
                                  svg {
                                    min-width: 24px;
                                  }
                                `}
                              >
                                <Phone size="24" />{' '}
                                <div
                                  className={css`
                                    color: var(--Gray-500, #66737f);
                                    font-family: Roboto;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 32px;
                                  `}
                                >
                                  {contact?.phone || ''}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {printData.showContactTwo && (
                        <div
                          className={css`
                            border-radius: 8px;
                            background: #f5f7fa;
                            margin-top: 16px;
                            padding: 16px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            cursor: pointer;
                            &:hover {
                              outline: 2px solid #25282d;
                            }
                          `}
                          onClick={() => setShowContactTwoModal(true)}
                        >
                          <div
                            className={css`
                              padding-left: 16px;
                              width: 50%;
                            `}
                          >
                            <div
                              className={css`
                                color: #25282d;
                                font-family: Roboto;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: ${contactTwo?.name ? 700 : 400};
                                line-height: 32px;
                              `}
                            >
                              {contactTwo?.name || 'Click to Edit Contact'}
                            </div>
                            <div
                              className={css`
                                color: var(--Gray-500, #66737f);
                                font-family: Roboto;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 32px;
                              `}
                            >
                              {contactTwo?.title || ''}
                            </div>
                          </div>

                          <div
                            className={css`
                              padding-right: 100px;
                              width: 50%;
                            `}
                          >
                            {contactTwo?.email && (
                              <div
                                className={css`
                                  display: flex;
                                  gap: 16px;
                                  align-items: center;
                                  svg {
                                    min-width: 24px;
                                  }
                                `}
                              >
                                <EnvelopeSimple size="24" />{' '}
                                <div
                                  className={css`
                                    color: var(--Gray-500, #66737f);
                                    font-family: Roboto;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 32px;
                                  `}
                                >
                                  {contactTwo?.email || ''}
                                </div>
                              </div>
                            )}
                            {contactTwo?.phone && (
                              <div
                                className={css`
                                  display: flex;
                                  gap: 16px;
                                  align-items: center;
                                  svg {
                                    min-width: 24px;
                                  }
                                `}
                              >
                                <Phone size="24" />{' '}
                                <div
                                  className={css`
                                    color: var(--Gray-500, #66737f);
                                    font-family: Roboto;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 32px;
                                  `}
                                >
                                  {contactTwo?.phone || ''}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={css`
                      margin-left: 56px;
                      margin-right: 56px;
                      margin-bottom: 44px;
                    `}
                  >
                    <div
                      className={css`
                        border-radius: 16px;
                        border: 2px dashed var(--Gray-300, #d1dae3);
                        padding: 16px;
                        padding-left: 32px;
                        margin-bottom: ${printData.showPreparedBy ? '32px' : '132px'};
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      `}
                    >
                      <div>
                        <div
                          className={css`
                            color: var(--700, #25282d);
                            font-family: Roboto;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 32px; /* 177.778% */
                          `}
                        >
                          {printData.showHandoutQRCode ? 'Scan this QR code to view your company benefits' : 'Visit'}
                        </div>
                        <div
                          className={css`
                            color: var(--Gray-500, #66737f);
                            font-family: Roboto;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                            padding-top: 8px;
                          `}
                        >
                          {printData.showHandoutQRCode && (
                            <span
                              className={css`
                                color: var(--Gray-700, #25282d);
                                font-family: Roboto;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 24px;
                              `}
                            >
                              Or visit
                            </span>
                          )}{' '}
                          {guideURL}
                        </div>
                      </div>
                      <div>
                        {printData.showHandoutQRCode && (
                          <QRCode id="QRCode" value={`${guideURL}?lt=pdf-qr-code`} size={106} />
                        )}
                      </div>
                    </div>

                    {printData.showPreparedBy && (
                      <>
                        <div
                          className={css`
                            color: var(--Gray-400, #9aa7b5);
                            text-align: center;
                            font-family: Roboto;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 57px;
                          `}
                        >
                          Prepared by
                        </div>
                        <div
                          className={css`
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          `}
                        >
                          <img
                            crossOrigin="anonymous"
                            src={preparedByLogo}
                            className={css`
                              max-width: 167px;
                              max-height: 47px;
                            `}
                            alt="Prepared By Logo"
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {printData.style === 2 && (
                    <>
                      <div
                        className={css`
                          background-color: ${designStyles?.Body?.AccentColor || '#1EE4C1'};
                          z-index: 2;
                          height: 13px;
                          width: 100%;
                        `}
                      >
                        {' '}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal display={showDateSelection} onClose={() => setShowDateSelection(false)}>
        <Modal.Paper width="700px">
          <Modal.Header title="Open Enrollment" onClose={() => setShowDateSelection(false)} />
          <Modal.Body>
            {hasBenefitsPackage ? (
              <Box
                flex="left start"
                css={`
                  border-radius: 8px;
                  padding: 16px;
                  background-color: ${colors.gray[100]};
                  margin-bottom: 16px;
                  svg {
                    margin-right: 16px;
                  }
                `}
              >
                <Warning size={32} color={colors.yellow[100]} />
                <Text label>Changing these dates will change the enrollment dates in your benefit package.</Text>
              </Box>
            ) : null}
            <Dates.Range
              disabled={true}
              start={enrollmentDates.start}
              end={enrollmentDates.end}
              setRange={handleEnrollementDates}
              autoSetEnd={(start) => addDays(start, 365)}
            />
          </Modal.Body>
        </Modal.Paper>
      </Modal>

      <Modal display={showContactOneModal} onClose={() => setShowContactOneModal(false)}>
        <Div
          css={css`
            width: 500px;
            padding: ${px.xl};
          `}
        >
          <Div
            css={css`
              ${flex('space-between')}
            `}
          >
            <Text styles="h2">Contact Info</Text>
            <Button styles="icon" onClick={() => setShowContactOneModal(false)}>
              <X />
            </Button>
          </Div>

          <div
            className={css`
              margin-top: 16px;
              margin-bottom: 16px;
            `}
          >
            <div
              className={css`
                color: #25282d;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 171.429% */
                letter-spacing: 0.15px;
                margin-bottom: 8px;
              `}
            >
              Name
            </div>
            <TextField
              className={css`
                width: 100%;
              `}
              label="Name"
              variant="outlined"
              value={contact.name}
              onChange={(e) => {
                /*   const [contact, setContact] = useState({
                  name: "",
                  email: "",
                  phone: "",
                  title: "",
                }); */

                let val = e.target.value;

                setContact((old) => {
                  return { ...old, name: val };
                });
              }}
            />
          </div>

          <div
            className={css`
              margin-top: 16px;
              margin-bottom: 16px;
            `}
          >
            <div
              className={css`
                color: #25282d;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 171.429% */
                letter-spacing: 0.15px;
                margin-bottom: 8px;
              `}
            >
              Email
            </div>
            <TextField
              className={css`
                width: 100%;
              `}
              label="Email"
              variant="outlined"
              value={contact.email}
              onChange={(e) => {
                /*   const [contact, setContact] = useState({
                  name: "",
                  email: "",
                  phone: "",
                  title: "",
                }); */

                let val = e.target.value;

                setContact((old) => {
                  return { ...old, email: val };
                });
              }}
            />
          </div>

          <div
            className={css`
              margin-top: 16px;
              margin-bottom: 16px;
            `}
          >
            <div
              className={css`
                color: #25282d;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 171.429% */
                letter-spacing: 0.15px;
                margin-bottom: 8px;
              `}
            >
              Phone
            </div>
            <TextField
              className={css`
                width: 100%;
              `}
              label="Phone"
              variant="outlined"
              value={contact.phone}
              onChange={(e) => {
                let val = e.target.value;
                setContact((old) => {
                  return { ...old, phone: val };
                });
              }}
            />
          </div>

          <div
            className={css`
              margin-top: 16px;
              margin-bottom: 16px;
            `}
          >
            <div
              className={css`
                color: #25282d;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 171.429% */
                letter-spacing: 0.15px;
                margin-bottom: 8px;
              `}
            >
              Title
            </div>
            <TextField
              className={css`
                width: 100%;
              `}
              label="Title"
              variant="outlined"
              value={contact.title}
              onChange={(e) => {
                let val = e.target.value;
                setContact((old) => {
                  return { ...old, title: val };
                });
              }}
            />
          </div>

          <Div
            css={css`
              ${flex('right')}
            `}
          >
            <Button styles="secondary mr" variant="outlined" onClick={() => setShowContactOneModal(false)}>
              Cancel
            </Button>
            <div
              className={css`
                margin-left: 16px;
              `}
            >
              <Button
                variant={'contained'}
                color="primary"
                onClick={() => {
                  setShowContactOneModal(false);
                }}
              >
                Save
              </Button>
            </div>
          </Div>
        </Div>
      </Modal>

      <Modal display={showQuality} onClose={() => setShowQuality(false)}>
        <Div
          css={css`
            width: 500px;
            padding: ${px.xl};
          `}
        >
          <Div
            css={css`
              ${flex('space-between')}
            `}
          >
            <Text styles="h2">Download PDF</Text>
            <Button styles="icon" onClick={() => setShowQuality(false)}>
              <X />
            </Button>
          </Div>

          <div
            className={css`
              color: var(--700, #25282d);
              font-feature-settings: 'clig' off, 'liga' off;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px; /* 171.429% */
              letter-spacing: 0.15px;
              margin-top: 24px;
              margin-bottom: 4px;
            `}
          >
            PDF Quality
          </div>
          <div>
            {' '}
            <Select
              css={`
                width: 100%;
              `}
              placeholder="Select a File Size"
              value={fileScale}
              onChange={(e) => setFileScale(e.target.value)}
            >
              <MenuItem value={5}>High Quality - Printing</MenuItem>
              <MenuItem value={3}>Medium Quality - Web & Printing</MenuItem>
              <MenuItem value={2}>Low Quality - Web & Email</MenuItem>
            </Select>
          </div>

          <Div
            css={css`
              ${flex('right')}
              margin-top: 24px;
            `}
          >
            <Button
              styles="secondary mr"
              variant="outlined"
              onClick={() => {
                setShowQuality(false);
              }}
            >
              Cancel
            </Button>
            <div
              className={css`
                margin-left: 16px;
              `}
            >
              <Button
                variant={'contained'}
                color="primary"
                onClick={() => {
                  setShowQuality(false);
                  downloadWithScale(fileScale);
                }}
              >
                Download
              </Button>
            </div>
          </Div>
        </Div>
      </Modal>

      <Modal display={showContactTwoModal} onClose={() => setShowContactTwoModal(false)}>
        <Div
          css={css`
            width: 500px;
            padding: ${px.xl};
          `}
        >
          <Div
            css={css`
              ${flex('space-between')}
            `}
          >
            <Text styles="h2">Contact Info</Text>
            <Button styles="icon" onClick={() => setShowContactTwoModal(false)}>
              <X />
            </Button>
          </Div>

          <div
            className={css`
              margin-top: 16px;
              margin-bottom: 16px;
            `}
          >
            <div
              className={css`
                color: #25282d;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 171.429% */
                letter-spacing: 0.15px;
                margin-bottom: 8px;
              `}
            >
              Name
            </div>
            <TextField
              className={css`
                width: 100%;
              `}
              label="Name"
              variant="outlined"
              value={contactTwo.name}
              onChange={(e) => {
                /*   const [contact, setContact] = useState({
                  name: "",
                  email: "",
                  phone: "",
                  title: "",
                }); */

                let val = e.target.value;

                setContactTwo((old) => {
                  return { ...old, name: val };
                });
              }}
            />
          </div>

          <div
            className={css`
              margin-top: 16px;
              margin-bottom: 16px;
            `}
          >
            <div
              className={css`
                color: #25282d;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 171.429% */
                letter-spacing: 0.15px;
                margin-bottom: 8px;
              `}
            >
              Email
            </div>
            <TextField
              className={css`
                width: 100%;
              `}
              label="Email"
              variant="outlined"
              value={contactTwo.email}
              onChange={(e) => {
                /*   const [contact, setContact] = useState({
                  name: "",
                  email: "",
                  phone: "",
                  title: "",
                }); */

                let val = e.target.value;

                setContactTwo((old) => {
                  return { ...old, email: val };
                });
              }}
            />
          </div>

          <div
            className={css`
              margin-top: 16px;
              margin-bottom: 16px;
            `}
          >
            <div
              className={css`
                color: #25282d;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 171.429% */
                letter-spacing: 0.15px;
                margin-bottom: 8px;
              `}
            >
              Phone
            </div>
            <TextField
              className={css`
                width: 100%;
              `}
              label="Phone"
              value={contactTwo.phone}
              variant="outlined"
              onChange={(e) => {
                let val = e.target.value;
                setContactTwo((old) => {
                  return { ...old, phone: val };
                });
              }}
            />
          </div>

          <div
            className={css`
              margin-top: 16px;
              margin-bottom: 16px;
            `}
          >
            <div
              className={css`
                color: #25282d;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 171.429% */
                letter-spacing: 0.15px;
                margin-bottom: 8px;
              `}
            >
              Title
            </div>
            <TextField
              className={css`
                width: 100%;
              `}
              label="Title"
              variant="outlined"
              value={contactTwo.title}
              onChange={(e) => {
                let val = e.target.value;
                setContactTwo((old) => {
                  return { ...old, title: val };
                });
              }}
            />
          </div>

          <Div
            css={css`
              ${flex('right')}
            `}
          >
            <Button styles="secondary mr" variant="outlined" onClick={() => setShowContactTwoModal(false)}>
              Cancel
            </Button>
            <div
              className={css`
                margin-left: 16px;
              `}
            >
              <Button
                variant={'contained'}
                color="primary"
                onClick={() => {
                  setShowContactTwoModal(false);
                }}
              >
                Save
              </Button>
            </div>
          </Div>
        </Div>
      </Modal>
    </Modal>
  );
};
