import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import errorHandler from '../../lib/stackdriver/errorHandler';
import { CustomAxios } from '../../redux/axios/axios';
import { WRITE_BRITE, WRITE_COURSE } from '../../shared/acl-constants';
import { useAcls } from '../../shared/use-acls';
import { useDebounceValue } from '../../shared/use-debounce-value';
import { useInterval } from '../../shared/use-interval';

export const useLease = (values, deps = []) => {
  const path = `/v1/lease/${values.type}/${values.id}`;

  const requiredAcl = values.type === 'course' || values.type === 'page' ? WRITE_COURSE : WRITE_BRITE;
  const resourceId = values.resourceId || values.id;

  const canWriteCourse = useAcls([requiredAcl], resourceId);

  const [loading, setLoading] = useState(true);
  const [lease, setLease] = useState({});
  const [leasedToEmail, setLeasedToEmail] = useState('another user');
  const [hasExpired, setHasExpired] = useState(false);

  const [loading_delayed] = useDebounceValue(loading, 1000, [loading]);

  const getEmail = async (userID) => {
    try {
      const { data } = await CustomAxios.get(`/v1/users/${userID}`);
      return data.Email;
    } catch (e) {
      return 'another user';
    }
  };

  const handleError = async (e) => {
    if (e?.response?.data?.UserID) {
      setLease({});
      const email = await getEmail(e.response.data.UserID);
      setLeasedToEmail(email);
    } else {
      console.log('Error getting lease:', e);
      errorHandler.report(`Unexpected error occurred when attempting to create lease: ${e}`);
      // if lease is expired, let's just show a warning. This could lead to conflicting changes from users but I think it's a better approach while we're figuring out what's going on
      if (true || (lease.ExpiresAt && new Date(lease.ExpiresAt) < new Date())) {
        toast.error(
          'Unable to get lease, another user may be editing this guide or you may not be able to save changes.'
        );
        setHasExpired(true);
      }
    }
  };

  const updateLease = async (isCreate = false) => {
    if (values.id && canWriteCourse) {
      try {
        if (isCreate) {
          setLoading(true);
        }
        const { data } = await CustomAxios.put(path);
        setLease(data);
        setHasExpired(false);
        setLeasedToEmail('another user');
      } catch (err) {
        await handleError(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteLease = async () => {
    if (lease.TypeID) {
      try {
        await CustomAxios.delete(path);
      } catch (err) {
        console.log('Delete lease err', err);
        console.log('ERROR DELETING LEASE', values.type);
      }
    }
  };

  useEffect(() => {
    if (lease?.ID) {
      deleteLease();
    }
    updateLease(true);
    return deleteLease;
  }, [...deps]);

  useEffect(() => {
    if (!canWriteCourse) {
      setLoading(false);
    }
  }, [canWriteCourse]);

  useInterval(() => {
    updateLease();
  }, 20000);

  if (!canWriteCourse) {
    return {
      lease: {},
      reason: 'You do not have permission to edit this course',
      hasLease: false,
      hasExpired: false,
      loading: loading_delayed,
      deleteLease: () => {},
    };
  }

  const reason =
    values.type === 'course'
      ? `${leasedToEmail} is currently editing this guide.`
      : `${leasedToEmail} is currently editing this page.`;

  return {
    lease,
    reason: reason,
    hasLease: !!lease?.ID,
    hasExpired,
    loading: loading || loading_delayed,
    deleteLease,
  };
};
