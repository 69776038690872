import { css } from '@emotion/css';
import { VideoCamera } from 'phosphor-react';
import { useMemo } from 'react';
import { Div, Text } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { getVideoUrl } from '../toolbar-menus/video-toolbar';
import { useEditorResource } from '../use-editor-resource';

export const Video = ({ element }) => {
  const { attributes = {}, videoUrl } = element;
  const { style, ...iframeProps } = attributes;

  const { data: liveSmartFields, dataUpdatedAt } = useEditorResource('liveSmartFields');

  const src = useMemo(() => {
    if (
      iframeProps.src.startsWith('{{') &&
      iframeProps.src.endsWith('}}') &&
      liveSmartFields[iframeProps.src]?.Success
    ) {
      return getVideoUrl(liveSmartFields[iframeProps.src]?.Value);
    }
    return iframeProps?.src;
  }, [iframeProps.src, dataUpdatedAt]);

  const iframeStyles = style || {};

  const validUrl = useMemo(() => {
    try {
      new URL(src);
      return true;
    } catch (err) {
      return false;
    }
  }, [src]);

  if (!src || !validUrl) {
    return (
      <Div
        className={css`
          ${flex('jcc aic column')} min-height: 400px;
          width: 100%;
          background-color: ${colors.gray[100]};
          border-radius: 8px;
        `}
      >
        <VideoCamera size={48} />
        {!validUrl && !!videoUrl ? (
          <Text color={colors.red[100]}>Invalid Video URL</Text>
        ) : (
          <Text styles="bold label">Video</Text>
        )}
      </Div>
    );
  }

  const allow = iframeProps.allow + " vertical-scroll 'none'";

  return <iframe {...iframeProps} title="Video" src={src} allow={allow} allowFullScreen={true} style={iframeStyles} />;
};
