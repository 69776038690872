import { useState } from 'react';
import { toast } from 'react-toastify';
import { CustomAxios } from '../redux/axios/axios';
import { Button, Input, Modal, Text } from '../shared/components';

export const RenameFolderModal = ({ display, hideRenameFolder, folder, fetchFolders }) => {
  const [renameFolderName, setRenameFolderName] = useState(folder.Name);

  const renameFolder = async () => {
    try {
      hideRenameFolder();
      await CustomAxios.patch(`/v1/course/folder/${folder.ID}`, {
        Name: renameFolderName,
      });
      fetchFolders();
    } catch (err) {
      hideRenameFolder();
      toast.error(`Error renaming guide.`);
    }
  };

  const modalProps = {
    display,
    onClose: () => hideRenameFolder(),
  };

  return (
    <Modal {...modalProps}>
      <Modal.Paper>
        <Modal.Header title="Rename Folder" onClose={modalProps.onClose} />
        <Modal.Body>
          <Text
            label
            css={`
              font-size: 14px;
              font-weight: 700;
              margin-bottom: 4px;
            `}
          >
            Folder Name
          </Text>
          <Input
            onChange={(event) => setRenameFolderName(event.target.value)}
            onFocus={(event) => {
              // Select the whole default value since we want them to overwrite it all.
              event.target.select();
            }}
            value={renameFolderName}
          />
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={() => hideRenameFolder()}>
            Cancel
          </Button>
          <Button primary disabled={!renameFolderName} onClick={() => renameFolder()}>
            Save
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
