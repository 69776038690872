import { css } from '@emotion/css';
import { X } from 'phosphor-react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { CustomAxios } from '../../redux/axios/axios';
import { Button, Div, Input, Modal, Text } from '../../shared/components';
import { flex, px } from '../../shared/shared-styles';
import { useStore } from '../../store-provider/use-store';
import { editorUtils } from '../provider/utils';
import { useEditorResource } from '../use-editor-resource';

export const SaveContentBlock = ({ editor, ...modalProps }) => {
  const {
    state: { multiSelect },
  } = editor;

  const [blockName, setBlockName] = useState('');
  const {
    data: { selectedBusiness },
  } = useStore();

  const [saving, setSaving] = useState(false);

  const { refetch } = useEditorResource('blocks');

  const saveBlock = async (event) => {
    event.preventDefault();
    setSaving(true);
    const rows =
      multiSelect?.rows?.map((item) => {
        const { rowId, ...rest } = item;
        return rest;
      }) || [];
    try {
      const body = {
        business_id: selectedBusiness.ID,
        name: blockName,
        layout: { rows },
      };
      await CustomAxios.post('/v1/bp/course_editor_block', body, {
        headers: { 'Content-Profile': 'brite_public' },
      });
      refetch();
      toast.success(`Successfully saved your content block!`);
      editorUtils?.setProperty(editor, 'multiSelect', null);
      modalProps.onClose();
    } catch (err) {
      console.error(err);
      toast.error(`Couldn't save this content block!`);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal {...modalProps} onClick={(e) => e.stopPropagation()}>
      <Div
        css={css`
          padding: ${px.xl};
          width: 500px;
        `}
      >
        <Div
          css={css`
            ${flex('jcsb aic')} margin-bottom: ${px.md};
          `}
        >
          <Text styles="h2">Save Content Block</Text>
          <Button styles="icon" onClick={modalProps.onClose}>
            <X />
          </Button>
        </Div>
        <form>
          <Div
            css={css`
              margin-top: ${px.xs};
            `}
          >
            <Text styles="mb-sm h4">Name</Text>
            <Input
              className={css`
                width: 100%;
              `}
              value={blockName}
              onChange={(e) => setBlockName(e.target.value)}
            />
          </Div>

          <Div
            css={css`
              ${flex('jcr aic')} margin-top: ${px.xl};
            `}
          >
            <Button styles="secondary mr" onClick={modalProps.onClose}>
              Cancel
            </Button>
            <Button type="submit" onClick={saveBlock} disabled={saving || !blockName}>
              {saving ? 'Saving...' : 'Save'}
            </Button>
          </Div>
        </form>
      </Div>
    </Modal>
  );
};
