import { useLocation, matchPath } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

export const AppBusinessRefresh = () => {
  const location = useLocation();
  const match = matchPath(location.pathname, { path: '/:businessId/:path' });
  const queryClient = useQueryClient();
  const [lastId, setLastId] = useState('');
  useEffect(() => {
    if (lastId && lastId !== match?.params?.businessId) {
      queryClient?.invalidateQueries([match?.params?.businessId]);
    }
    setLastId(match?.params?.businessId);
  }, [match?.params?.businessId]);
  return null;
};
