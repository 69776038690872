import { BriteEditor } from './brite-editor';
import { EditorStoreProvider } from './provider/provider';

export const EditorProvider = (props) => {
  return (
    <EditorStoreProvider>
      <BriteEditor {...props} />
    </EditorStoreProvider>
  );
};
