import { Row } from './row';
import { useEditorResource } from '../use-editor-resource';
import { useDeepCompareMemo } from 'use-deep-compare';
import { useStore } from '../../store-provider/use-store';

export const MemoizedRow = (props) => {
  const { versionId } = props;

  const {
    data: { devMode },
  } = useStore();

  const { recentUpdate, layout, drag, selection, multiSelect, viewMode, saveStatus } = props?.editor?.state || {};

  const { data: styles } = useEditorResource('styles');
  const isSelectionOnRow =
    props.index === selection?.[0] ||
    (recentUpdate?.location?.length === 1 && recentUpdate?.location?.[0] === props?.index) ||
    recentUpdate?.type === 'initialize-content';
  const currentRowLastUpdate = isSelectionOnRow ? recentUpdate?.updatedAt : '';
  const savedUpate = saveStatus?.action === 'save-changes' ? saveStatus?.updatedAt : '';
  const multiSelectUpdate = props?.row?.columns?.map(({ width = null }) => width).toString();

  // return <Row {...props} />;
  const Component = useDeepCompareMemo(
    () => <Row {...props} />,
    [
      devMode,
      viewMode,
      versionId,
      props.pageId,
      drag?.isDragging,
      currentRowLastUpdate,
      savedUpate,
      layout?.rows?.length,
      props?.editor?.shiftKeyIsPressed,
      props?.row?.fullScreenWidth,
      props?.row?.hideOnDesktop,
      props?.row?.hideOnMobile,
      styles,
      multiSelectUpdate,
      multiSelect,
    ]
  );

  return Component;
};
