import { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { Button, Text } from '../../../shared/components';

export const MultipleChoicePreview = ({ question }) => {
  const [selectedAnswer, setSelectedAnswer] = useState('');

  const resetAnswer = () => {
    setSelectedAnswer('');
  };

  useEffect(() => {
    resetAnswer();
  }, [question]);

  const checkAnswer = (answer) => {
    setSelectedAnswer(answer.Text);
  };

  const anyCorrect = () => {
    for (let answer of question.Answers) {
      if (answer.IsCorrect) {
        return true;
      }
    }
    return false;
  };

  return (
    <div
      className={css`
        width: 500px;
        margin-top: 20px;
        height: 100%;
      `}
    >
      <div
        className={css`
          width: 100%;
          min-height: 200px;
          overflow: auto;
          border-radius: 5px;
          margin-top: 10px;
          padding: 10px;
          button {
            max-width: 260px;
          }
        `}
      >
        {!question?.Text ? (
          <Text
            label
            css={`
              text-align: center;
              margin: auto;
            `}
          >
            Click Edit Form to preview the multiple choice question.
          </Text>
        ) : (
          <div
            className={css`
              text-align: center;
              padding: 30px;
              margin-bottom: 50px;
            `}
          >
            <h1
              className={css`
                color: black;
                font-size: 23px;
                line-height: 32px;
              `}
            >
              {' '}
              {question?.Text}{' '}
            </h1>

            {question?.Answers.map((answer) => {
              if (!answer.Text) {
                return null;
              }
              return (
                <div>
                  <Button
                    primary
                    className={css`
                      margin-top: 25px;
                      box-shadow: 0px 3px 5px -1px rgba(148, 148, 148, 0.75);
                      ${answer.Text === selectedAnswer
                        ? answer.IsCorrect || !anyCorrect()
                          ? 'color: green; border-color: green;'
                          : 'color: red; border-color: red;'
                        : ''}
                    `}
                    onClick={() => {
                      checkAnswer(answer);
                    }}
                  >
                    {answer.Text}
                  </Button>
                </div>
              );
            })}

            {selectedAnswer && question?.Explanation && (
              <div
                className={css`
                  border: 1px solid #dedede;
                  border-radius: 5px;
                  padding: 10px;
                  margin-top: 32px;
                `}
              >
                {question?.Explanation}{' '}
              </div>
            )}
          </div>
        )}
      </div>
      <div
        className={css`
          display: flex;
          justify-content: right;
        `}
      >
        <Button styles="text" onClick={resetAnswer}>
          Reset Answer
        </Button>
      </div>
    </div>
  );
};
