import { useFeatureFlagPayload } from 'posthog-js/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { MEDIA, useQueryAPI } from '../react-query';
import { CustomAxios } from '../redux/axios/axios';
import { useSearchParams } from '../shared/use-search-params';
import { EditorDetailsContext } from './provider/editor-detail-provider';

// !!! - There are two hooks in here - !!!

// useCacheEditorResource:
// Used once when the editor loads. It fetches and
// caches all editor resources so they can be easily and
// quickly accessed in the `useEditorResource` hook.

// useEditorResource:
//  A `useQueryAPI` wrapper-hook that returns
//  the *cached* resource from the react-query provider and
//  adds additional context for editor resources.

const resourceSelect = (data) => {
  if ('Data' in data) {
    return data?.Data;
  }
  return data;
};

export const cacheKey = ['pre-fetch-editor-resource'];

const defaultOptions = {
  cacheKey,
  retry: 1,
  defaultValue: [],
  select: resourceSelect,
};

const combineOptions = (resourceOptions, options) => {
  const cacheKey = [...defaultOptions?.cacheKey, ...(resourceOptions?.cacheKey || []), ...(options?.cacheKey || [])];
  return {
    ...defaultOptions,
    ...resourceOptions,
    ...options,
    cacheKey,
  };
};

export const getResourceOptions = ({ businessId, packageId, pageId, courseId, themeId }) => ({
  media: {
    url: MEDIA + '?with_share_scope=business&with_share_scope=inherited',
  },
  carriers: {
    url: `v1/carriers`,
  },
  blocks: {
    urlKey: `v1/bp/course_editor_block`,
    queryFn: async () => {
      const { data: businessBlocks } = await CustomAxios.get(`v1/bp/course_editor_block?with_share_scope=business`, {
        headers: { 'Accept-Profile': 'brite_public' },
      });
      const { data: inheritedBlocks } = await CustomAxios.get(`v1/bp/course_editor_block?with_share_scope=inherited`, {
        headers: { 'Accept-Profile': 'brite_public' },
      });
      const data = [...businessBlocks, ...inheritedBlocks];
      return data;
    },
  },
  benefitsPackage: {
    url: `/v1/benefitspackage/${packageId}`,
    enabled: !!packageId && packageId !== '00000000-0000-0000-0000-000000000000',
  },
  products: {
    url: `v2/benefitspackage/${packageId}/product`,
    enabled: !!packageId && packageId !== '00000000-0000-0000-0000-000000000000',
  },
  liveSmartFields: {
    url: `/v1/pages/${pageId}/tags/evaluation`,
  },
  smartFields: {
    url: `/v1/tags?guide_id=${courseId}`,
  },
  styles: {
    url: themeId ? `v1/themes/design-object?themeID=${themeId}` : `v1/themes/design-object`,
  },
  comments: {
    url: `/v1/bp/needs_attention?business_id=eq.${businessId}&resource_id=eq.${courseId}`,
    cacheKey: ['comments'],
    params: {
      axios: {
        headers: { 'Accept-Profile': 'brite' },
      },
    },
  },
  pageVersions: {
    url: `v2/course/${courseId}/pages/${pageId}/versions?limit=50`,
    cacheKey: ['editor', 'versions'],
    defaultValue: [],
    retry: 1,
    cacheTime: 0,
  },
});

// ___________________________________________________________________________
//  For available properties in the `options` object see the `useQuery` docs.
export const useEditorResource = (resourceKey, options = {}) => {
  //  This hook automatically disables fetching because <<<-------------** !!!!
  //  that is done on load in the `useCacheEditorResource` and only
  //  uses the cached value. You can override this by passing
  //  { enabled: true } into the options object.
  // ___________________________________________________________________________
  const { businessId, courseId } = useParams();

  const {
    params: { pageId = '', packageId = '', courseModal = '' },
  } = useSearchParams();

  const {
    editorDetails: { themeId },
  } = useContext(EditorDetailsContext);

  const [enabled, setEnabled] = useState(false);

  const resourceOptions = getResourceOptions({
    businessId,
    packageId,
    pageId,
    courseId,
    themeId,
  });

  const query = useQueryAPI(
    {
      enabled,
      ...combineOptions(resourceOptions[resourceKey], options),
    },
    [courseModal]
  );

  const refetch = () => {
    setEnabled(true);
    query.refetch();
  };

  return { ...query, refetch };
};

// ------------------------------------------------------------
// WARNING: Only use this hook if it's absolutely necessary.
// Example: Accessing resources outside of the editor
//          react-context: (editor-details + editor-store)
// ------------------------------------------------------------
// This hook has the same function as the `useEditorResource`
// except that it can be used outside of the `Editor Details context` that provides
// the resources parameters. You have to pass those in manually
// via the `resourceParams` object.
export const useResource = (resourceKey, resourceParams = {}, options = {}) => {
  const [enabled, setEnabled] = useState(false);

  const resourceOptions = getResourceOptions(resourceParams);

  const query = useQueryAPI({
    enabled,
    ...combineOptions(resourceOptions[resourceKey], options),
  });

  const refetch = () => {
    setEnabled(true);
    query.refetch();
  };

  return { ...query, refetch };
};

export const useCacheEditorResources = ({ course }) => {
  const isInitialLoadRef = useRef(true);

  const { businessId, courseId } = useParams();
  const {
    params: { pageId = '', packageId = '', courseModal = '' },
  } = useSearchParams();

  const {
    editorDetails: { themeId },
    dispatch,
  } = useContext(EditorDetailsContext);

  const collaborationFF = useFeatureFlagPayload('editor-collaboration')?.value;

  useEffect(() => {
    dispatch({
      type: 'SET',
      key: 'themeId',
      payload: course?.ThemeID,
    });
  }, [course?.ThemeID]);

  const resourceOptions = getResourceOptions(
    {
      businessId,
      packageId,
      pageId,
      courseId,
      themeId,
    },
    [courseModal]
  );

  const { isLoading: isLoadingDocuments } = useQueryAPI(combineOptions(resourceOptions.media), [courseModal]);

  const { isLoading: isLoadingLogos } = useQueryAPI(combineOptions(resourceOptions.carriers), [courseModal]);

  const { isLoading: isLoadingBlocks } = useQueryAPI(combineOptions(resourceOptions.blocks), [courseModal]);

  useQueryAPI(combineOptions(resourceOptions.benefitsPackage), [courseModal]);

  const { isLoading: isLoadingProducts } = useQueryAPI(combineOptions(resourceOptions.products), [courseModal]);

  const { isLoading: isLoadingLiveSmartFields } = useQueryAPI(combineOptions(resourceOptions.liveSmartFields), [
    courseModal,
  ]);

  const { isLoading: isLoadingAvailableSmartFields } = useQueryAPI(combineOptions(resourceOptions.smartFields), [
    courseModal,
  ]);

  const { isLoading: isLoadingDesignObject } = useQueryAPI(combineOptions(resourceOptions.styles), [courseModal]);

  const { isLoading: isLoadingComments } = useQueryAPI(
    {
      ...combineOptions(resourceOptions.comments),
      enabled: collaborationFF,
      refetchInterval: 20000,
    },
    [courseModal]
  );

  const { isLoading: isLoadingPageVersions } = useQueryAPI(
    {
      ...combineOptions(resourceOptions.pageVersions),
    },
    [courseModal]
  );

  const isLoadingResources =
    isLoadingDocuments ||
    isLoadingLogos ||
    isLoadingBlocks ||
    isLoadingProducts ||
    isLoadingLiveSmartFields ||
    isLoadingAvailableSmartFields ||
    isLoadingDesignObject ||
    isLoadingComments ||
    isLoadingPageVersions;

  useEffect(() => {
    if (!isLoadingResources) {
      isInitialLoadRef.current = false;
    }
  }, [isLoadingResources]);
  return isLoadingResources && isInitialLoadRef?.current;
};
