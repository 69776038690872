import React, { forwardRef, useMemo, useState } from 'react';
import { css, cx } from '@emotion/css';
import { buildStyles, colors } from '../styles';
import { flex } from '../shared-styles';
import { Div } from './div';
import { TextField } from '@material-ui/core';
import { textTheme } from './text';
import { useStateSync } from '../use-state-sync';

export const inputStyles = {
  primary: `
    ${flex('left')}
    input, textarea {
      margin: 0;
      padding: 10px 8px;
      outline: none;
      font-size: 1.2em;
      width: 100%;
      border: none;
      resize: none;
      background-color: transparent;
      font-family: roboto;
      ${textTheme?.label}
      :disabled { opacity: .5; }
    }
    svg {
      margin: 0 8px;
      height: 20px;
      width: 20px;
      min-height: 20px;
      min-width: 20px;
      color: ${colors.black};
    }
    border-radius: 8px;
    border: 1px solid ${colors.gray[300]};
    background-color: white;
    margin: 0;
    resize: none;
    :disabled { border: none; }
    .MuiOutlinedInput-root {
      border: none;
      border-radius: 8px;
    }
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
      border: none;
      width: 100%;
      :hover {
        border: none;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

  `,
  search: `
    border: none;
    background-color: ${colors.gray[100]};
    border-radius: 30px;
    :hover {
      border: none !important;
    }
  `,
};

const formatPhoneNumber = (phoneNumberString) => {
  let phone = phoneNumberString.replace(/\D/g, '');
  const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    phone = `(${match[1]})${match[2] ? ' ' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
  }
  return phone;
};

const fallbackFromList = ['primary'];
const checkForFallback = (styles) => {
  const list = fallbackFromList.filter((attr) => styles.includes(attr));
  if (!list.length) {
    return `primary ${styles}`;
  }
  return styles;
};

export const Input = forwardRef(
  (
    {
      styles = '',
      className = '',
      css: cssStyles = '',
      color,
      bgColor,
      rows = 1,
      state = null,
      error = false,
      startIcon = null,
      endIcon = null,
      format = '',
      ...rest
    },
    ref
  ) => {
    const [errorState, setErrorState] = useState(false);

    const styling = useMemo(() => {
      const taggedStyle = checkForFallback(styles);
      return cx(
        css`
          ${buildStyles(inputStyles, taggedStyle)}
          ${errorState
            ? `border: 1px solid ${colors.red[100]};`
            : !rest?.disabled && `:hover, :focus { border: 1px solid ${colors.black}; }`}
          ${rest?.disabled &&
          `
            border: 1px solid ${colors.gray[200]};
          `}
          ${color ? `color: ${color};` : ''}
          ${bgColor ? `background-color: ${bgColor};` : ''}
        `,
        css`
          ${cssStyles}
        `,
        className,
        'shared-input'
      );
    }, [styles, className, cssStyles, color, bgColor, errorState, rest?.disabled]);

    const stateHandler = useMemo(() => {
      if (state) {
        const [value, setValue] = state;
        return { value, onChange: (e) => setValue(e.target.value) };
      }
      return {};
    }, [state]);

    const onBlur = (e) => {
      setErrorState(error);
      if (rest.onBlur) {
        rest.onBlur(e);
      }
    };

    const onFocus = (e) => {
      setErrorState(false);
      if (rest.onFocus) {
        rest.onFocus(e);
      }
    };

    const formatValue = (value) => {
      if (format === 'phone') {
        const matches = value?.match(/\d/g);
        const next = matches?.join('') || '';
        return formatPhoneNumber(next);
      } else {
        return value;
      }
    };

    const [value, setValue] = useStateSync(formatValue(rest.value), [rest.value]);

    const returnFormatChange = (event) => {
      if (format === 'phone') {
        const matches = event?.target?.value?.match(/\d/g);
        const value = matches?.join('');
        rest?.onChange(event, value);
      } else if (format === 'date') {
        rest?.onChange(event);
      } else {
        rest?.onBlur?.(event);
      }
    };

    const formatterOptions = !!format
      ? {
          value,
          onChange: (e) => setValue(e.target.value),
          onBlur: returnFormatChange,
        }
      : {};

    const props = {
      ...stateHandler,
      ...rest,
      ref,
      onBlur,
      onFocus,
      ...formatterOptions,
    };
    if (rest.type === 'date') {
      return (
        <Div css={styling}>
          <TextField
            {...props}
            className="shared-input"
            variant="outlined"
            type="date"
            inputProps={{
              style: {
                padding: '12px 10px',
              },
              max: '9999-12-31', // Don't allow more than 4 digits for the year (browsers won't limit by default)
            }}
            style={{
              width: '100%',
            }}
          />
        </Div>
      );
    } else if (rest.checkbox) {
      return <Div css={styling}></Div>;
    } else if (rows === 1) {
      return (
        <Div css={styling}>
          {startIcon}
          <input {...props} />
          {endIcon}
        </Div>
      );
    } else {
      return (
        <Div css={styling}>
          {startIcon}
          <textarea {...props} rows={rows} />
          {endIcon}
        </Div>
      );
    }
  }
);
