import { colors } from '../styles';
import { useThemedProps } from '../useThemedProps';
import { textTheme } from './text';

export const optionTheme = {
  default: `
    background: none;
    margin: 0;
    border: none;
    outline: none;
    ${textTheme.label}
    padding: 8px 16px;
    border-radius: 8px;
    :hover {
      background-color: ${colors.gray[100]};
      cursor: pointer;
    }
  `,
};

export const Option = ({ value, children, ...rest }) => {
  const props = useThemedProps(rest, optionTheme);

  return (
    <option value={value} {...props}>
      {children}
    </option>
  );
};
