import posthog from 'posthog-js';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useQueryAPI } from '../../../react-query';
import { CustomAxios } from '../../../redux/axios/axios';
import { useSearchParams } from '../../../shared/use-search-params';

export const usePages = ({ refetch: refetchPage }, setPageId) => {
  const { courseId } = useParams();
  const { params } = useSearchParams();

  const query = useQueryAPI({
    url: `v2/course/${courseId}/pages`,
    cacheKey: ['editor'],
    retry: 1,
  });

  useEffect(() => {
    if (!params?.pageId && query?.data?.[0]?.ID) {
      setPageId(query?.data?.[0]?.ID);
    }
  }, [query?.dataUpdatedAt]);

  const { data: pages, cache, refetch, isLoading } = query;

  const handlePageName = async (renameId, Name) => {
    const old = [...pages];
    try {
      const updateCache = old.map((page) => (page.ID === renameId ? { ...page, Name } : page));
      cache.setData(updateCache);
      const { data } = await CustomAxios.get(`v2/course/${courseId}/pages/${renameId}`);
      const { Order, ...page } = data;
      const updates = { ...page, Name };
      await CustomAxios.put(`v2/course/${courseId}/pages/${renameId}`, updates);
      posthog.capture('CourseEdit', {
        actionType: 'brite:rename_page:' + page.Type,
      });
    } catch (err) {
      cache.setData(old);
      console.log(err);
      toast.error(`Error saving page...`);
    } finally {
      refetchPage();
      refetch();
    }
  };

  const handleDuplicate = async (duplicateId, Name) => {
    const old = [...pages];
    try {
      const index = pages.findIndex(({ ID }) => ID === duplicateId);
      const InsertBeforePageID = pages?.[index + 1]?.ID || null;
      const { data } = await CustomAxios.get(`v2/course/${courseId}/pages/${duplicateId}`);

      const FrontendID = Math.random().toString(36);
      const idIndex = Math.min(11, FrontendID.length - 1);

      const { ID, Order, ...page } = data;
      const updates = {
        ...page,
        Name,
        InsertBeforePageID,
        FrontendID: '_' + FrontendID.substring(2, idIndex),
      };
      pages.splice(index + 1, 0, updates);
      cache.setData(pages);

      await CustomAxios.post(`v2/course/${courseId}/pages`, updates);
      posthog.capture('CourseEdit', {
        actionType: 'brite:duplicate_page:' + page.Type,
      });
    } catch (err) {
      cache.setData(old);
      console.log(err);
      toast.error(`Error saving page...`);
    } finally {
      refetch();
    }
  };

  const handleConvert = async (originalPageId) => {
    const old = [...pages];
    try {
      const index = pages.findIndex(({ ID }) => ID === originalPageId);
      const InsertBeforePageID = pages?.[index + 1]?.ID || null;
      const { data } = await CustomAxios.post(`/v2/course/${courseId}/pages/${originalPageId}/conversions`);

      const FrontendID = Math.random().toString(36);
      const idIndex = Math.min(11, FrontendID.length - 1);

      const { Order, ...page } = data;
      await CustomAxios.put(`/v2/course/${courseId}/pages/${page.ID}/order`, {
        InsertBeforePageID,
      });
      const updates = {
        ...page,
        Name: pages[index].Name + ' (Copy)',
        FrontendID: '_' + FrontendID.substring(2, idIndex),
      };
      await CustomAxios.put(`/v2/course/${courseId}/pages/${updates.ID}`, updates);

      pages.splice(index + 1, 0, updates);
      cache.setData(pages);
      posthog.capture('CourseEdit', {
        actionType: 'brite:page_converted:' + page.Type,
      });
    } catch (err) {
      cache.setData(old);
      console.log(err);
      toast.error(`Error saving page...`);
    } finally {
      refetch();
    }
  };

  const handleDelete = async (deleteIds) => {
    const old = [...pages];
    try {
      const firstDeleteIndex = pages.findIndex(({ ID }) => deleteIds.includes(ID));
      const pagesToDelete = pages.filter(({ ID }) => deleteIds.includes(ID));
      const updateCache = old.filter(({ ID }) => !deleteIds.includes(ID));
      cache.setData(updateCache);

      const nextIndex = Math.min(firstDeleteIndex, updateCache.length - 1);
      setPageId(updateCache[nextIndex]?.ID || '');

      await Promise.allSettled(
        pagesToDelete.map((item) => {
          posthog.capture('CourseEdit', {
            actionType: 'brite:delete_page:' + item.Type,
          });
          return CustomAxios.delete(`v2/course/${courseId}/pages/${item?.ID}`);
        })
      );
    } catch (err) {
      cache.setData(old);
      console.log(err);
      toast.error(`Error deleting page...`);
    } finally {
      refetch();
    }
  };

  const handleReOrder = async (sourcePages, destinationId) => {
    const old = [...pages];

    try {
      const InsertBeforePageID = destinationId;
      const sourcePageIds = sourcePages?.map(({ ID }) => ID);
      const filteredPages = old?.filter(({ ID }) => !sourcePageIds.includes(ID));

      if (destinationId === null) {
        cache.setData([...filteredPages, ...sourcePages]);
      } else {
        const nextPages = filteredPages?.reduce((prev, item) => {
          if (item?.ID === destinationId) {
            return [...prev, ...sourcePages, item];
          }
          return [...prev, item];
        }, []);
        cache.setData(nextPages);
      }

      await Promise.allSettled(
        sourcePages.map(({ ID }) =>
          CustomAxios.put(`v2/course/${courseId}/pages/${ID}/order`, {
            InsertBeforePageID,
          })
        )
      );
    } catch (err) {
      console.log(err);
      toast.error(`Error reordering the pages...`);
      cache.setData(old);
    } finally {
      refetch();
    }
  };

  const handleLockPage = async (pageId) => {
    const old = [...pages];
    try {
      const updatedList = old.map((item) => (item.ID === pageId ? { ...item, IsLocked: !item.IsLocked } : item));
      cache.setData(updatedList);
      const { data } = await CustomAxios.get(`v2/course/${courseId}/pages/${pageId}`);
      const { Order, ...page } = data;
      const updates = { ...page, IsLocked: !page.IsLocked };
      await CustomAxios.put(`v2/course/${courseId}/pages/${pageId}`, updates);
      posthog.capture('CourseEdit', {
        actionType: 'brite:lock_page:' + page.Type,
      });
    } catch (err) {
      console.log(err);
      toast.error(`Error locking this page...`);
    } finally {
      refetchPage();
      refetch();
    }
  };

  const handleOptions = async (pageId, options = {}) => {
    const old = [...pages];
    try {
      const updatedList = old.map((item) =>
        item.ID === pageId
          ? {
              ...item,
              ID: null,
            }
          : item
      );
      cache.setData(updatedList);
      const { data } = await CustomAxios.get(`v2/course/${courseId}/pages/${pageId}`);
      const { Order, ...page } = data;
      const updates = {
        ...page,
        Options: { ...(page?.Options || {}), ...options },
      };
      await CustomAxios.put(`v2/course/${courseId}/pages/${pageId}`, updates);
      posthog.capture('CourseEdit', {
        actionType: 'brite:edit_options:' + page.Type,
      });
    } catch (err) {
      console.log(err);
      toast.error(`Error updating options...`);
    } finally {
      refetchPage();
      refetch();
    }
  };

  return {
    query,
    refetch,
    handlePageName,
    handleOptions,
    handleDuplicate,
    handleConvert,
    handleDelete,
    handleReOrder,
    handleLockPage,
    isLoading,
  };
};
