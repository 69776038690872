import { css } from '@emotion/css';
import { X } from 'phosphor-react';
import { useState } from 'react';
import { Button, Div, Modal, Text } from '../../../../shared/components';
import { container, px, flex } from '../../../../shared/shared-styles';
import { ColorCreatorModal } from './color-creator-modal';

export const ColorCreator = ({ display, onClose, addCourseColor, designStyleColors }) => {
  const [colorPicked, setColorPicked] = useState('#000');

  const updateCurrentColor = (color, alphaValue = null) => {
    const { r, g, b, a } = color.rgb;
    const alpha = alphaValue || a;
    const rgba = `rgba(${r}, ${g}, ${b}, ${alpha})`;
    setColorPicked(rgba);
  };

  const addColor = () => {
    onClose();
    addCourseColor(colorPicked);
  };

  return (
    <Div onClick={(e) => e.stopPropagation()}>
      <Modal display={display} onClose={onClose}>
        <Div
          css={css`
            ${container.box} width: 450px;
            padding: 32px;
          `}
        >
          <Div
            css={css`
              ${flex('jcsb ais')} padding-bottom: ${px.md};
            `}
          >
            <Text styles="h2">Add A Color</Text>
            <Button styles="icon" onClick={onClose}>
              <X />
            </Button>
          </Div>
          <Div
            css={css`
              ${flex('jcsb aic')} padding: ${px.md} 0;
            `}
          >
            <ColorCreatorModal
              color={colorPicked}
              onChange={updateCurrentColor}
              designStyleColors={designStyleColors}
            />
          </Div>
          <Div
            css={css`
              ${flex('jcr')} padding-top: ${px.md};
            `}
          >
            <Button styles="secondary" onClick={onClose}>
              Close
            </Button>
            <Button styles="ml" onClick={addColor}>
              Add
            </Button>
          </Div>
        </Div>
      </Modal>
    </Div>
  );
};
