import { ADDITIONAL_TYPES, CORE_TYPES } from './constants';

const emptyState = {
  medical: [],
  'pre-tax': [],
  core: [],
  additional: [],
  custom: [],
  multiNetwork: {},
};

export const mutliNetworkCategoryMap = {
  core: 'Core',
  buyup: 'Buy Up',
  narrow: 'Narrow',
};

export const categorizeProducts = (products) => {
  if (products && products.length) {
    const list = products.reduce((prev, item) => {
      const { Type, MultiNetworkCategory, MultiNetworkID, ProductName = '', Details = {} } = item;

      let category = '';
      const isMultiNetwork = !!MultiNetworkID;

      if (CORE_TYPES.includes(Type)) {
        category = 'core';
      } else if (ADDITIONAL_TYPES.includes(Type)) {
        category = 'additional';
      } else if (Type === 'custom') {
        category = 'custom';
      } else if (Type === 'insurance_plan') {
        category = 'medical';
      } else {
        category = 'pre-tax';
      }
      let multiNetwork = { ...prev?.multiNetwork };
      if (isMultiNetwork) {
        const networkName =
          (category === 'medical' ? Details?.NetworkName : ProductName) ||
          mutliNetworkCategoryMap[MultiNetworkCategory];
        multiNetwork = {
          ...multiNetwork,
          [MultiNetworkCategory]: {
            ...prev.multiNetwork[MultiNetworkCategory],
            [MultiNetworkID]: [...(prev?.multiNetwork?.[MultiNetworkCategory]?.[MultiNetworkID] || []), networkName],
          },
        };
      }
      if (!isMultiNetwork || MultiNetworkCategory === 'core') {
        return { ...prev, [category]: [...prev[category], item], multiNetwork };
      }
      return { ...prev, multiNetwork };
    }, emptyState);

    return Object.entries(list).reduce((prev, [key, value]) => {
      if (key === 'multiNetwork') {
        return { ...prev, multiNetwork: value };
      }
      return {
        ...prev,
        [key]: value?.sort((a, b) => b.CreatedAt?.localeCompare(a.CreatedAt)),
      };
    }, {});
  }
  return emptyState;
};
