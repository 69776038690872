import { css } from '@emotion/css';
import { Plus, Trash } from 'phosphor-react';
import { Button, Div, Input, Text } from '../../shared/components';
import { Checkbox } from '../../shared/components/checkbox';
import { flex } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { productModifier } from '../configs/product-utils';

export const CustomQuestion = ({ state, needsAttention }) => {
  const { customQuestion } = state;

  const updateResponse = (updates) => {
    productModifier.setCustomQuestion(state, {
      ...customQuestion,
      Responses: [...updates],
    });
  };

  const addDetail = () => {
    let Responses = [...(customQuestion?.Responses || [])];
    Responses.push({ Text: '', ShouldRecommendProduct: false });
    updateResponse(Responses);
  };

  const updateDetail = (idx, property, value) => {
    let Responses = [...customQuestion?.Responses];
    Responses.splice(idx, 1, {
      ...Responses[idx],
      [property]: value,
    });
    updateResponse(Responses);
  };

  const removeDetail = (idx) => {
    let Responses = [...customQuestion?.Responses].filter((_, index) => index !== idx);
    updateResponse(Responses);
  };

  return (
    <Div>
      <Text label>To help employees determine the value of this benefit, you can ask a custom question.</Text>

      <Div
        css={css`
          ${flex('space-between start')}
          margin: 32px 0;
        `}
      >
        <Text label>Question</Text>
        <Input
          css={`
            width: 50%;
            margin-right: 48px;
          `}
          rows={2}
          value={customQuestion?.Text || ''}
          onChange={(event) =>
            productModifier.setCustomQuestion(state, {
              ...customQuestion,
              Text: event.target.value,
            })
          }
        />
      </Div>
      <Text label>Select any option that you want to recommend this benefit for an employee.</Text>

      <Div
        css={css`
          margin: 32px 0;
        `}
      >
        {!!customQuestion?.Responses?.length
          ? customQuestion?.Responses.map((data, idx) => (
              <Div
                css={css`
                  ${flex('left')}
                  margin-bottom: 16px;
                `}
              >
                <Checkbox
                  checked={data?.ShouldRecommendProduct}
                  onChange={() => updateDetail(idx, 'ShouldRecommendProduct', !data?.ShouldRecommendProduct)}
                />
                <Text
                  label
                  css={`
                    margin-left: 16px;
                  `}
                >
                  Option {idx + 1}
                </Text>
                <Input
                  css={`
                    margin-left: auto;
                    width: 50%;
                  `}
                  value={data.Text}
                  onChange={(e) => updateDetail(idx, 'Text', e.target.value)}
                />
                <Button
                  styles="icon sm"
                  css={`
                    margin: 0 8px;
                  `}
                  onClick={() => removeDetail(idx)}
                >
                  <Trash color={colors.gray[400]} />
                </Button>
              </Div>
            ))
          : null}
      </Div>

      <Div
        css={css`
          ${flex('left')}
        `}
      >
        <Button secondary onClick={addDetail}>
          <Plus size={24} />
          Add Option
        </Button>
      </Div>
    </Div>
  );
};
