import { css } from '@emotion/css';
import { Cards, FolderSimple } from 'phosphor-react';
import { useState } from 'react';
import { useResource, useRequests } from '../react-query';
import { guides } from '../react-query/resources/guides';
import { toast } from 'react-toastify';
import { Button, Modal, Text } from '../shared/components';

export const MoveGuideModal = ({ display, hideMoveGuide, folders, currentFolder, guide, fetchFolders }) => {
  const [loading, setLoading] = useState(false);

  const guidesResource = useResource(guides, { staleTime: 5 * 60 * 1000 });
  const guidesRequests = useRequests(guidesResource);

  const moveFolder = async (folderID) => {
    try {
      setLoading(true);
      const guidesPut = guides.utils.getPut(guide.ID, {
        ...guide,
        CourseFolderID: folderID,
      });
      await guidesRequests.put(guidesPut);
      fetchFolders();
      setLoading(false);
      hideMoveGuide();
    } catch (err) {
      setLoading(false);
      hideMoveGuide();
      toast.error(`Error moving guide.`);
    }
  };

  const modalProps = {
    display,
    onClose: () => hideMoveGuide(),
  };

  const folderStyle = css`
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px;
    cursor: pointer;
    &:hover {
      background: #d1dae3;
    }
  `;

  return (
    <Modal {...modalProps}>
      <Modal.Paper>
        <Modal.Header title={`Move ${guide.Name}`} onClose={modalProps.onClose} />
        <Modal.Body>
          <Text caption>You have the ability to move this guide to any folder that was created for this company.</Text>
          <div
            className={css`
              border-top: 1px solid #d1dae3;
              border-bottom: 1px solid #d1dae3;
              height: 150px;
              margin: 8px 0;
              overflow: auto;
            `}
          >
            {loading && (
              <div className={folderStyle}>
                <Text label>Moving to the new folder...</Text>{' '}
              </div>
            )}
            {!loading && (
              <>
                {currentFolder && (
                  <div
                    className={folderStyle}
                    onClick={() => {
                      moveFolder(null);
                    }}
                  >
                    <Cards />
                    <Text label>Move back to all guides</Text>
                  </div>
                )}
                {folders.map((folder) => {
                  if (currentFolder && currentFolder.ID === folder.ID) {
                    return null;
                  }
                  return (
                    <div
                      key={folder.ID}
                      className={folderStyle}
                      onClick={() => {
                        moveFolder(folder.ID);
                      }}
                    >
                      <FolderSimple />
                      <Text label>{folder.Name}</Text>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={() => hideMoveGuide()}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
