// EMPLOYEE-VIEW
import { css } from '@emotion/css';
import { Div, Text } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { FieldComponent } from './field-component';

export const SummaryField = ({ product, field, config, comparisonView = false }) => {
  if ((!comparisonView && field.State === 'hide') || config?.hideField) {
    return null;
  }

  const isCustomHideSectionTitle = product?.Type === 'custom' && !product?.TitleDescriptions?.length;

  const hasSectionTitle = 'sectionTitle' in field && !isCustomHideSectionTitle;

  const Wrapper = ({ children, hideAlternating }) => {
    return (
      <Div
        onClick={() => console.log(field)}
        className={hideAlternating ? '' : 'alternating-item'}
        css={css`
          overflow: hidden;
          ${comparisonView && field?.State === 'hide' && `opacity: .1;`}
          padding: 16px 32px;
          ${field?.State === 'highlight'
            ? `
            border-left: 4px solid var(--button-background-color, ${colors.purple});
            p {
              font-size: 1.3em;
              font-weight: bold;
            }
          `
            : ''}
        `}
      >
        {children}
      </Div>
    );
  };

  if (config?.hideField) {
    return null;
  } else if (config?.components?.length) {
    return (
      <>
        {config?.components?.map((Component) => (
          <Wrapper>
            <Component field={field} Wrapper={Wrapper} />
          </Wrapper>
        ))}
      </>
    );
  } else if (hasSectionTitle) {
    return (
      <Text
        h4
        className={css`
          width: 100%;
          padding: 8px 32px;
          padding-top: 16px;
        `}
      >
        {field.sectionTitle}
      </Text>
    );
  } else if (isCustomHideSectionTitle) {
    return null;
  }

  return (
    <Wrapper hideAlternating={field?.Type === 'label'}>
      {!hasSectionTitle ? (
        <Div
          css={css`
            ${!(field.RecDisplayValue || field?.DisplayValue)
              ? ''
              : comparisonView
                ? ''
                : flex('space-between start')} flex-grow: 1;
          `}
        >
          {field.Type === 'label' ? (
            <Text
              label
              bold={product?.Type === 'insurance_plan'}
              className={css`
                margin-top: 8px;
                width: 100%;
              `}
            >
              {field.RecDisplayValue || field.DisplayValue}
            </Text>
          ) : (
            <Text label>{field.RecDisplayValue || field.DisplayValue}</Text>
          )}
          {field.Type !== 'label' && <FieldComponent field={field} product={product} comparisonView={comparisonView} />}
        </Div>
      ) : null}
    </Wrapper>
  );
};
