import { useMemo, useState } from 'react';

export const useFormErrors = (form, validatorFn) => {
  const [touched, setTouched] = useState({});

  const { touchedErrors, errors } = useMemo(() => {
    const errors = validatorFn(form?.values);
    const touchedErrors = Object.entries(errors).reduce((prev, [name, value]) => {
      if (touched?.[name] && value) {
        return { ...prev, [name]: value };
      }
      return prev;
    }, {});

    return {
      touchedErrors,
      errors,
    };
  }, [form?.updateAt, touched]);

  const onBlur = (event) => {
    const { name } = event.target;
    setTouched((prev) => ({ ...prev, [name]: true }));
  };

  const hasErrors = !!Object.keys(errors || {}).length;
  const hasTouchedErrors = !!Object.keys(touchedErrors || {}).length;

  return {
    errors,
    hasErrors,
    touchedErrors,
    hasTouchedErrors,
    onBlur,
  };
};
