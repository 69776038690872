import React, { useContext, useEffect } from 'react';
import { Auth0Context } from './Auth/react-auth0-wrapper';
import { history } from './history';
import { useStore } from './store-provider/use-store';
import { Box, Button, Text } from './shared/components';
import BriteLogo from './images/brite-logo.png';
import { colors } from './shared/styles';
import { textTheme } from './shared/components/text';

const Home = () => {
  const { isAuthenticated, loading, ...context } = useContext(Auth0Context);

  const {
    data: { selectedBusiness },
  } = useStore();
  const businessId = selectedBusiness.ID || '';

  useEffect(() => {
    if (!loading && isAuthenticated && businessId) {
      history.push(`/${businessId}/courses`);
    }
  }, [isAuthenticated, businessId, loading]);

  return (
    <Box
      flex="column center"
      css={`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      `}
    >
      <Box
        flex="left"
        css={`
          border-radius: 16px;
          background-color: ${colors.gray[100]};
          padding: 32px;
        `}
      >
        <img src={BriteLogo} alt="brite builder" width="200px" />
        <Box
          css={`
            margin-left: 32px;
          `}
        >
          <Text
            h3
            css={`
              max-width: 400px;
            `}
          >
            You are about to enter the <strong>benefits experience platform</strong> for brokers, employers, and
            vendors.
          </Text>
          <Box
            flex="left"
            css={`
              margin-top: 16px;
            `}
          >
            <Button
              css={`
                margin-right: 16px;
              `}
              secondary
              onClick={() => {
                context.loginWithRedirect({
                  redirect_uri: window.location.origin,
                  appState: { targetUrl: `/${businessId}/courses` },
                  authorizationParams: {
                    screen_hint: 'signup',
                    prompt: 'login',
                  },
                });
              }}
            >
              Sign Up
            </Button>
            <Button
              onClick={() => {
                context.loginWithRedirect({
                  redirect_uri: window.location.origin,
                  appState: { targetUrl: `/${businessId}/courses` },
                  authorizationParams: {
                    prompt: 'login',
                  },
                });
              }}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        css={`
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 16px 32px;
        `}
        flex="space-between"
      >
        <Box>
          <Text label bold>
            Need assistance?
          </Text>
          <Text>sales@britebenefits.com</Text>
        </Box>
        <Box>
          <Text label bold right>
            Brite Benefits, Inc.
          </Text>
          <Box
            flex="right"
            css={`
              a {
                ${textTheme.label}
                text-decoration: none;
                margin-left: 16px;
                color: ${colors.gray[500]};
                :hover {
                  text-decoration: underline;
                }
            `}
          >
            <a href="https://share.hsforms.com/1UQbu6cvVR5-AJ09KpbxSjA3ihp9" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            <a href="https://share.hsforms.com/18ZIPbaK4QQGU3xaJ_0N2MA3ihp9" target="_blank" rel="noopener noreferrer">
              Terms of Use
            </a>
            <a href="https://share.hsforms.com/1vbQfUlKjSeSaZumLLoW67A3ihp9" target="_blank" rel="noopener noreferrer">
              Sub Processors
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
