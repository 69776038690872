import { css } from '@emotion/css';
import { X } from 'phosphor-react';
import { Button, Div, Modal, Text } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { useLocalStorage } from '../../shared/use-local-storage';
import AutoFillExample from './auto-fill-example.gif';

export const WelcomeModal = () => {
  const hasViewedAutoPaste = useLocalStorage('auto-paste', false);
  const closeHasViewedModal = () => hasViewedAutoPaste.set(true);
  return (
    <Modal
      display={!hasViewedAutoPaste?.value}
      onClose={closeHasViewedModal}
      className={css`
        z-index: 1000000;
      `}
    >
      <Div
        css={css`
          width: 700px;
        `}
      >
        <Div
          css={css`
            ${flex('right')}
            padding: 16px;
            padding-bottom: 0;
          `}
        >
          <Button styles="icon" onClick={closeHasViewedModal}>
            <X />
          </Button>
        </Div>
        <Div
          css={css`
            ${flex('center column')}
            padding: 0 32px;
          `}
        >
          <img src={AutoFillExample} alt="Auto Fill Example" />
          <Text
            h2
            css={`
              margin-top: 32px;
              margin-bottom: 16px;
              span {
                background: linear-gradient(270deg, #62a7e5 0%, #a949f5 50%, #c05194 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            `}
          >
            Brite <span>Auto-Fill</span> Quick Tips
          </Text>
          <Text center>
            Brite is here to make your life easier! Simply click on the cell with the details we're looking for and
            Brite will magically Auto-Fill the information for you. Easy-peasy!
          </Text>
        </Div>
        <Div
          css={css`
            ${flex('center')}
            padding: 32px;
          `}
        >
          <Button large onClick={closeHasViewedModal}>
            Got it
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
