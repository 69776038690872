import { css } from '@emotion/css';
import { Cards } from 'phosphor-react';
import { Div, Text, Toggle } from '../../shared/components';
import { container, flex, px } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { useState } from 'react';
import { useKeyboard } from '../../Router/use-keyboard';

export const BaseCourses = ({ data, current, setCurrent, removeSmartFields, setRemoveSmartFields }) => {
  const [showRemoveTagOption, setShowRemoveTagOption] = useState(false);

  const keydown = (captured, event) => {
    if (captured === 'ctrl+shift+KeyD') {
      event.stopPropagation();
      event.preventDefault();
      setShowRemoveTagOption(!showRemoveTagOption);
    }
  };

  useKeyboard({ keydown: keydown, options: { useCapture: true } });

  return (
    <Div
      css={css`
        ${flex('aic column')}
        max-width: 700px;
        margin: 0 auto;
        min-height: 50vh;
      `}
    >
      <Text styles="h2">Select a Base Guide to generate from</Text>

      {data?.map((item, idx) => (
        <Div
          key={item.ID}
          css={css`
            ${flex('aic')}
            ${container.box}
            ${container.hover}
            margin: ${px.md} 0;
            padding: 16px;
            width: 700px;
            ${item.ID === current ? `outline: 2px solid ${colors.black};` : ''}
          `}
          onClick={() => {
            setCurrent(item.ID);
            // default behavior for the "Default" base course is to remove smart text, everything else should keep them
            if (item.ID === '8149ff2f-56bf-421f-b802-62555703f6f9') {
              setRemoveSmartFields(true);
            } else {
              setRemoveSmartFields(false);
            }
          }}
        >
          <Div
            css={css`
              width: 56px;
              height: 56px;
              border-radius: 8px;
              margin-right: 16px;
              ${item?.ID === current
                ? `
                  background-color: ${colors.black};
                  color: white;
                `
                : `
                  color: ${colors.gray[500]};
                  border: 1px solid ${colors.gray[300]};
                `}

              ${flex('center')}
            `}
          >
            <Cards />
          </Div>
          <Text styles="h4 ml">{item.Name}</Text>
        </Div>
      ))}

      {showRemoveTagOption && (
        <Div
          css={css`
            ${flex('jcsb aic')} margin: ${px.xl} 0;
            width: 700px;
          `}
        >
          <Text styles="h4">Remove SmartFields</Text>
          <Toggle
            checked={removeSmartFields}
            onChange={(e) => {
              setRemoveSmartFields(e.target.checked);
            }}
          />
        </Div>
      )}
    </Div>
  );
};
