import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { CustomAxios } from '../../redux/axios/axios';
import { useDebounce } from '../../shared/use-debounce';
import posthog from 'posthog-js';

export const useAutoSave = (pageQuery, version, hasPageLease, usesDesignStyles) => {
  const { courseId } = useParams();

  const { data: page, cache } = pageQuery;

  const [saveStatus, setSaveStatus] = useState('');
  const [errorSaving, setErrorSaving] = useState(false);
  const [refreshTheme, setRefreshTheme] = useState(new Date().toISOString());

  useEffect(() => {
    if (errorSaving && hasPageLease) {
      setTimeout(() => setErrorSaving(false), 5000);
      toast.error(`Error saving page content...`);
    }
  }, [errorSaving]);

  useEffect(() => {
    if (!version.canRedo) {
      setSaveStatus('saved');
    } else {
      setSaveStatus('');
    }
  }, [version.canRedo]);

  const savePage = async (updates, pageId = null) => {
    if (!hasPageLease) {
      return;
    }
    const id = pageId || page.ID;
    try {
      setSaveStatus('saving');
      version.setCurrentIdx(null);
      const { Order, ...pageData } = page;
      const Data = [
        {
          ID: '',
          Content: pageData.Content,
          HTMLContent: pageData.HTMLContent,
          CourseID: courseId,
          Name: pageData.Name,
          PageID: pageData.ID,
        },
        ...(version?.query?.cache?.data?.Data?.slice(0, -1) || []),
      ];
      version.query.cache.setData({ ...version.query.cache.data, Data });

      const data = { ...pageData, ...updates };
      cache.setData(data);

      const url = `v2/course/${courseId}/pages/${id}`;
      const path = version.canRedo ? `${url}?invalidateVersionsAfterID=${version.versionId}` : url;
      await CustomAxios.put(path, data);
      version.query.refetch();
      setSaveStatus('saved');
    } catch (err) {
      setTimeout(() => setSaveStatus('error'), 2000);
      setErrorSaving(true);
      console.log(err);
    }
  };

  const handleChanges = useCallback(
    async (updates) => {
      posthog.capture('CourseSave');
      await savePage({
        Content: updates.design,
        HTMLContent: updates.html,
      });
      setSaveStatus('saved');
      if (updates.hardRefresh) {
        setRefreshTheme(new Date().toISOString());
      }
    },
    [savePage]
  );

  // THIS ONLY APPLIES TO UNLAYER TYPE PAGES *NOT* BRITE-EDITOR
  const handleAutoChanges = useDebounce(handleChanges, 1500);

  const wasRowWithHeadingDuplicated = (args, design) => {
    if (design.schemaVersion !== 12) {
      console.log("unlayer schema version is not 12, so we won't check for duplicate rows with headings");
      return false;
    }

    if (args.type !== 'row:added' || !args.item) {
      return false;
    }

    let newRow;

    // unlayer has a bug where the row:added event is fired but the args includes one of the items in the new row rather than the row itself,
    // so if we did not get a row we will manually find it instead
    if (!args.item.columns) {
      let itemInRow = args.item.id;
      for (let row of design.body.rows) {
        for (let col of row.columns) {
          for (let item of col.contents) {
            if (item.id === itemInRow) {
              newRow = row;
              break;
            }
          }

          if (newRow) {
            break;
          }
        }

        if (newRow) {
          break;
        }
      }
    } else {
      // we must already have the row
      newRow = args.item;
    }

    // if we didn't find a row, we can't check for a heading so we'll just assume false
    if (!newRow.columns) {
      return false;
    }

    // check all contents of the new row to see if there's a heading
    for (let col of newRow.columns) {
      if (!col.contents) {
        continue;
      }
      for (let item of col.contents) {
        if (item.type === 'heading') {
          return true;
        }
      }
    }

    return false;
  };

  const handleUnlayerUpdates = (args) => {
    posthog.capture('CourseEdit', {
      actionType: 'unlayer:' + args.type,
    });
    window.unlayer.exportHtml(({ design, html }) => {
      if (
        usesDesignStyles &&
        ((args.type === 'content:modified' && args.changes.name === 'headingType') ||
          (args.type === 'content:added' && args.item?.type === 'heading') ||
          wasRowWithHeadingDuplicated(args, design))
      ) {
        // destroy the unlayer editor so no more edits can be made until this save is finished and the editor is reloaded
        if (window.unlayer) {
          window.unlayer.destroy();
        }
        handleChanges({ design, html, hardRefresh: true });
        return;
      }
      if ((!args.type.startsWith('design') && !args.type.startsWith('action')) || args.type.startsWith('content')) {
        handleAutoChanges({ design, html });
      }
    });
  };

  useEffect(() => {
    if (page?.ID) {
      if (page.Type === 'decision' || page.Type === 'question') {
        setSaveStatus('saved');
      }
    }
    if (window.unlayer && page?.ID) {
      window.unlayer?.addEventListener('design:updated', handleUnlayerUpdates);
    }
  }, [page?.ID, handleUnlayerUpdates, handleChanges]);

  const friendlySaveStatus = useMemo(() => {
    if (saveStatus === 'saving') {
      return 'Saving';
    } else if (saveStatus === 'saved') {
      return 'Saved';
    } else if (saveStatus === 'initial') {
      return 'Ready';
    } else if (saveStatus === 'error') {
      return 'Error';
    } else if (saveStatus === '') {
      return 'Unsaved';
    }
    return '';
  }, [saveStatus]);

  return {
    friendlySaveStatus,
    savePage,
    saveStatus,
    setSaveStatus,
    refreshTheme,
  };
};
