import { getGenericQueryProps, getUrl } from '.';

const url = '/v1/bp/autofill_suggestion';

const getLocationOfSuggestion = (suggestion) => {
  if (suggestion?.file_type === 'csv') {
    return {
      ref_cell_col: suggestion?.suggested_column,
      ref_cell_row: suggestion?.suggested_row,
      ref_cell_col_end: null,
      ref_cell_row_end: null,
    };
  } else if (suggestion?.file_type === 'pdf') {
    return {
      ref_page_number: suggestion?.suggested_area_page_number,
      ref_position_top: suggestion?.suggested_area_percent_from_top,
      ref_position_left: suggestion?.suggested_area_percent_from_left,
      ref_position_height: suggestion?.suggested_area_percent_of_height,
      ref_position_width: suggestion?.suggested_area_percent_of_width,
    };
  }
};

export const fieldHistory = {
  url,
  key: 'field-history',
  getResourceOptions: (resourceProps) => {
    const resourceUrl = getUrl(url, resourceProps);
    return {
      url: resourceUrl,
      queryProps: {
        initialData: [],
        ...getGenericQueryProps(resourceUrl, resourceProps, {
          headers: {
            'Accept-Profile': 'brite',
          },
        }),
      },
    };
  },
  utils: {
    getPost: (body) => ({
      url: `/v1/bp/product_field_history`,
      body,
      config: {
        headers: {
          'Content-Profile': 'brite',
          Prefer: 'return=representation',
        },
      },
      optimistic: (old) => [body, ...old],
    }),
    getFieldHistoryFromSuggestion: (resource, suggestion) => {
      const location = getLocationOfSuggestion(suggestion);

      const source_metadata = {
        ref_file_id: suggestion?.original_file_id,
        ref_sheet: suggestion?.tab_name,
        ref_file_type: suggestion?.file_type,
        ...location,
      };
      const body = {
        value: suggestion?.suggested_value,
        source: 'autofill@v1',
        product_id: resource?.params?.productId,
        created_by_user_id: resource?.params?.userId,
        business_id: resource?.params?.businessId,
        property_chain: suggestion?.property_chain,
        source_metadata,
      };

      return body;
    },
  },
};
