import { createEditor, insertText, Node, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';
import { useCallback, useState } from 'react';
import { elementMap, getMentionName, insertMention, intialSlateValue, removeNode, withInlines } from './utils';
import { DefaultElement } from '../../../BriteEditor/editor-components/text-v2/slate-components';
import { getBlock } from '../../../BriteEditor/editor-components/text-v2/slate-utils';

export const useSlateComment = (props) => {
  const { initialValue = intialSlateValue, onSubmit = () => {} } = props;

  const [slateEditor] = useState(() => withInlines(withReact(withHistory(createEditor()))));

  const resetEditor = () => {
    Transforms.removeNodes(slateEditor, { at: [0] });
    initialValue.forEach((node) => {
      Transforms.insertNodes(slateEditor, node, { at: [0] });
    });
  };

  const onKeyDown = (event) => {
    if (event.key === 'ArrowLeft') {
      event.stopPropagation();
    } else if (event.key === 'ArrowRight') {
      event.stopPropagation();
    } else if (event.key === '@') {
      event.preventDefault();
      insertMention(slateEditor, '');
    } else if (event.key === 'Enter') {
      if (!event.shiftKey && !event.metaKey && !event.ctrlKey) {
        event.preventDefault();
        onSubmit(event, slateEditor.children);
        resetEditor();
      }
    }
  };

  const onChange = () => {
    const mention = getBlock(slateEditor, (node) => node?.type === 'mention');
    if (mention && !!mention?.[0]?.data?.user?.UserID) {
      const text = Node.string(mention?.[0]);
      if (text !== getMentionName(mention?.[0]?.data?.user)) {
        removeNode(slateEditor, (n) => n?.data?.mentionId === mention?.[0]?.data?.mentionId);
        const at = slateEditor?.selection;
        insertText(slateEditor, text, { at });
      }
    }
  };

  const renderElement = useCallback((slateProps) => {
    const Element = elementMap?.[slateProps.element.type] || DefaultElement;
    return <Element {...slateProps} />;
  }, []);

  const renderLeaf = useCallback((slateProps) => {
    return <span {...slateProps.attributes}>{slateProps.children}</span>;
  }, []);

  const editorProps = {
    onKeyDown,
    renderElement,
    renderLeaf,
  };

  const slateProps = {
    editor: slateEditor,
    initialValue,
    onChange,
  };

  return {
    editorProps,
    slateProps,
    onSubmit: (e) => {
      onSubmit(e, slateEditor.children);
      resetEditor();
    },
    resetEditor,
  };
};
