import { MenuItem } from '@material-ui/core';
import { css } from '@emotion/css';
import { LinkBreak, X } from 'phosphor-react';
import { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router';
import { useQueryAPI } from '../../../react-query';
import { Div, Modal, Text, Button, Select } from '../../../shared/components';
import { flex } from '../../../shared/shared-styles';
import { useForm } from '../../../shared/use-form';
import { removeLink } from '../../editor-components/text-v2/slate-utils';
import { SmartFieldInput } from '../../utility-components/smart-field-input';
import { formatUrl } from '../text-toolbar';

export const TextV2LinkModal = ({ slateEditor, linkProps, onUpdate, ...modalProps }) => {
  const isInitial = useRef(true);
  const { courseId } = useParams();
  const { getProperty, values, setValues, hasChanges } = useForm(
    {
      linkType: 'url',
      ...linkProps,
    },
    linkProps
  );

  const pages = useQueryAPI({
    url: `v2/course/${courseId}/pages`,
    cacheKey: ['editor'],
    retry: 1,
  });

  useEffect(() => {
    if (!isInitial.current) {
      setValues({ ...values, href: '' });
    }
    isInitial.current = false;
  }, [values?.linkType]);

  const validUrl = useMemo(() => {
    if (values?.linkType === 'url') {
      const [, isValid] = formatUrl(values?.linkText);
      return isValid;
    }
    return true;
  }, [values?.linkText]);

  const handleLinkText = (e) => {
    const value = e.target.value;
    if (values?.linkType === 'url') {
      const [href, isValid] = formatUrl(value);
      if (isValid) {
        setValues({ ...values, href, linkText: value });
      } else {
        setValues({
          ...values,
          href: '',
          linkText: value,
        });
      }
    }
  };

  const handleRemoveLink = () => {
    removeLink(slateEditor);
    modalProps.onClose();
  };

  const handleSmartField = ({ data }) => {
    setValues((form) => ({
      ...form,
      href: data?.value,
      linkText: data?.name,
    }));
  };

  const removeSmartField = () => {
    setValues((form) => ({
      ...form,
      href: '',
      linkText: '',
    }));
  };

  return (
    <Modal {...modalProps}>
      <Div
        isForm
        css={css`
          width: 400px;
        `}
      >
        <Div
          css={css`
            ${flex('space-between')}
            padding: 24px;
            padding-bottom: 0;
          `}
        >
          <Text h2>Link</Text>
          <Button styles="icon" onClick={modalProps.onClose}>
            <X />
          </Button>
        </Div>

        <Div
          css={css`
            margin-top: 0;
            margin: 24px;
          `}
        >
          <Text
            label
            css={`
              margin-top: 16px;
            `}
          >
            Link Type
          </Text>
          <Select {...getProperty('linkType')}>
            <MenuItem value="url">URL</MenuItem>
            <MenuItem value="page">Guide Page</MenuItem>
          </Select>

          {!values.linkType || values.linkType === 'url' ? (
            <>
              <Text label>URL</Text>
              <SmartFieldInput
                nameFilter="URL"
                value={values?.href}
                smartFieldDisplay={values?.linkText || ''}
                handleSmartField={handleSmartField}
                removeSmartField={removeSmartField}
                inputProps={{
                  ...getProperty('linkText'),
                  onChange: handleLinkText,
                }}
              />
            </>
          ) : (
            <>
              <Text
                label
                css={`
                  margin-top: 16px;
                `}
              >
                Link to Page
              </Text>
              <Select {...getProperty('linkText')}>
                {pages?.data?.map(({ Name, ID }) => (
                  <MenuItem value={ID}>{Name}</MenuItem>
                ))}
              </Select>
            </>
          )}
          <Text
            label
            css={`
              margin-top: 16px;
            `}
          >
            Open link in
          </Text>
          <Select {...getProperty('target')}>
            <MenuItem value="_blank">New Tab</MenuItem>
            <MenuItem value="_self">Same Tab</MenuItem>
          </Select>
        </Div>

        <Div
          css={css`
            ${flex('space-between')}
            margin-top: 0;
            margin: 24px;
          `}
        >
          <Button styles="icon" onClick={handleRemoveLink}>
            <LinkBreak />
          </Button>
          <Div
            css={css`
              ${flex('right')}
            `}
          >
            <Button
              css={`
                margin-right: 16px;
              `}
              secondary
              onClick={modalProps.onClose}
            >
              Cancel
            </Button>
            <Button disabled={!hasChanges || !validUrl} onClick={() => onUpdate(values)} type="submit">
              Update
            </Button>
          </Div>
        </Div>
      </Div>
    </Modal>
  );
};

// {
//   "type": "link",
//   "children": [
//       {
//           "text": "Go to google"
//       }
//   ],
//   "href": "https://www.google.com/",
//   "target": "_blank",
//   "linkText": "https://www.google.com/"
// }
