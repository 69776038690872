import { getGenericQueryProps, getUrl } from '.';
import { CustomAxios } from '../../redux/axios/axios';

export const url = '/v1/course/:courseId';

export const guide = {
  key: 'guide',
  url: '/v1/course/:courseId',
  getResourceOptions: (resourceProps) => {
    const resourceUrl = getUrl(url, resourceProps);
    return {
      url: resourceUrl,
      queryProps: {
        initialData: {},
        enabled: !!resourceProps?.params?.courseId,
        ...getGenericQueryProps(resourceUrl, resourceProps),
      },
    };
  },
  utils: {
    getPut: (id, body) => ({
      url: `/v2/course/${id}`,
      body,
    }),
  },
};

export const key = 'guide';

export const getResourceOptions = (resourceProps) => {
  const resourceUrl = getUrl(url, resourceProps);
  return {
    url: resourceUrl,
    queryProps: {
      initialData: {},
      enabled: !!resourceProps?.params?.courseId,
      ...getGenericQueryProps(resourceUrl, resourceProps),
    },
  };
};

export const methods = (resource) => ({
  put: async (id, body, options = {}) => {
    const opts = { optimistic: true, refetch: true, ...options };
    const old = { ...resource?.query?.data };
    const updates = {
      ...body,
      Name: body?.Name || 'Untitled Guide',
    };

    try {
      if (opts?.optimistic) {
        const data = { ...resource?.query?.data, ...updates };
        resource.setData(data);
      }
      await CustomAxios.put(`/v2/course/${id}`, updates);
    } catch (err) {
      resource?.setData(old);
      console.warn(err);
      throw err;
    } finally {
      if (opts?.refetch) {
        resource?.query?.refetch();
      }
    }
  },

  delete: async (id, options = {}) => {
    const opts = { optimistic: true, refetch: true, ...options };
    const old = [...(resource?.query?.data || [])];
    try {
      if (opts?.optimistic) {
        const next = resource?.query?.data?.filter((item) => item.ID !== id);
        resource?.setData([...next]);
      }
      await CustomAxios.delete(`${url}/${id}`);
    } catch (err) {
      resource?.setData([...old]);
      throw err;
    } finally {
      if (opts?.refetch) {
        resource?.query?.refetch();
      }
    }
  },
});
