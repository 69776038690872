import { getGenericQueryProps, getUrl } from '.';

const url = '/v1/course';

const transformGuides = (data) => {
  let courses = data;
  courses = courses.filter((course) => course.Type !== 'vendor' && course.Type !== 'survey');
  courses = courses.sort((a, b) => a.Name.localeCompare(b.Name));
  return courses;
};

export const guides = {
  url,
  key: 'guides',
  getResourceOptions: (resourceProps) => {
    const resourceUrl = getUrl(url, resourceProps);
    return {
      url: resourceUrl,
      queryProps: {
        initialData: [],
        select: transformGuides,
        ...getGenericQueryProps(resourceUrl, resourceProps),
      },
    };
  },
  utils: {
    getDuplicatePost: (idToDuplicate, toBusinessId, updates) => ({
      url: `${url}/${idToDuplicate}/copies/${toBusinessId}`,
      body: {
        ...updates,
        CourseFolderID: null,
      },
    }),
    getDelete: (id) => ({
      url: `${url}/${id}`,
      optimistic: (old) => old?.filter((item) => item.ID !== id),
    }),
    getPut: (id, body) => ({
      body,
      url: `${url}/${id}/properties`,
      optimstic: (old) => old?.map((item) => (item?.ID === id ? body : item)),
    }),
  },
};
