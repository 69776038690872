import { Bug } from 'phosphor-react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { history } from '../history';
import { CustomAxios } from '../redux/axios/axios';
import { Button, Box, Input, Modal, Text } from '../shared/components';
import { colors } from '../shared/styles';
import { useSearchParams } from '../shared/use-search-params';
import { useForm } from '../shared/use-form';

function getBrowser() {
  const userAgent = navigator.userAgent;

  if (userAgent.includes('Chrome') && !userAgent.includes('Edg') && !userAgent.includes('OPR')) {
    return 'Chrome';
  } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
    return 'Safari';
  } else if (userAgent.includes('Firefox')) {
    return 'Firefox';
  } else if (userAgent.includes('Edg')) {
    return 'Edge';
  } else if (userAgent.includes('OPR') || userAgent.includes('Opera')) {
    return 'Opera';
  } else if (userAgent.includes('MSIE') || userAgent.includes('Trident')) {
    return 'Internet Explorer';
  } else {
    return 'Unknown browser';
  }
}

const getLinearData = (data) => {
  const { loomLink, ...rest } = data;
  let logOutput = console?.allLogs?.toReversed().reduce((acc, item) => {
    if (!item || !item?.value || !item?.value?.[0]) {
      return acc;
    }

    let msg = item.value[0];

    // if item is an object, capture the value in a string
    if (typeof item.value[0] !== 'string') {
      if (item.value[0].message) {
        msg = item.value[0].message;
      }

      if (item.type === 'error' && item.value[0].stack) {
        msg += '\n' + item.value[0].stack.replace('\n', '\t\n');
      }
    }
    msg = msg.replace('\n', '\t\t\n');

    // eslint-disable-next-line
    return acc + `${item.datetime}  ${item.type}  ${msg}` + '\n';
  }, 'Logs (descending):\n\n');

  const requestErrors = [...console.requestErrors].map((item) => {
    const url = item?.config?.url;
    const { Authorization, ...headers } = item?.config?.headers || {};
    return {
      message: item?.message,
      method: item.config?.method,
      headers,
      url,
      response: item?.response?.data,
    };
  });

  const browser = getBrowser();

  const BrowserContext = {
    timestamp: new Date().toISOString(),
    pathname: `https://app.be-brite.com${history.location.pathname}`,
    loomLink,
    browser,
    requestErrors,
    logOutput,
  };

  return {
    ...rest,
    VideoURL: loomLink,
    Browser: browser,
    Pathname: `https://app.be-brite.com${history.location.pathname}`,
    BrowserContext,
  };
};

export const BugReport = ({ ...modalProps }) => {
  const { params: searchParams } = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm(
    {
      TicketType: 'bug',
      UserTitle: '',
      UserDescription: '',
      loomLink: '',
    },
    [modalProps?.display, searchParams, history.location.pathname]
  );

  const [step, setStep] = useState(0);

  const submitLinearTicket = async () => {
    try {
      setIsLoading(true);
      let data = getLinearData(form.values);
      await CustomAxios.post(`v1/app-feedback/tickets`, data);
      toast.success(`Successfully sent ticket!`);
      modalProps?.onClose();
    } catch (err) {
      toast.error('Error sending ticket...');
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    if (step === 0) {
      modalProps?.onClose();
    } else {
      setStep(0);
    }
  };

  const handleStep = () => {
    if (step === 0) {
      setStep(1);
    } else {
      submitLinearTicket();
    }
  };

  const canSubmit = form?.values?.UserTitle;

  return (
    <Modal {...modalProps}>
      <Modal.Paper>
        <Modal.Header
          title={
            <Box
              flex="left"
              css={`
                svg {
                  margin-right: 16px;
                }
              `}
            >
              <Bug />
              <Text h2>Bug Report</Text>
            </Box>
          }
          onClose={modalProps?.onClose}
        />
        <Modal.Body>
          {step === 0 ? (
            <>
              <Box
                css={`
                  margin-bottom: 16px;
                `}
              >
                <Text
                  label
                  bold
                  css={`
                    margin-bottom: 8px;
                  `}
                >
                  Description
                </Text>
                <Input {...form.getProperty('UserTitle')} rows={2} />
                <Text
                  css={`
                    margin-top: 8px;
                  `}
                >
                  What is the issue you're experiencing, and how should it ideally work?
                </Text>
              </Box>
            </>
          ) : step === 1 ? (
            <>
              <Box
                css={`
                  margin-bottom: 16px;
                `}
              >
                <Text
                  label
                  bold
                  css={`
                    margin-bottom: 8px;
                  `}
                >
                  Steps to reproduce
                </Text>
                <Input {...form.getProperty('UserDescription')} rows={3} />
                <Text
                  css={`
                    margin-top: 8px;
                  `}
                >
                  What were the actions taken that produced this issue?
                </Text>
              </Box>

              <Box
                css={`
                  margin-bottom: 16px;
                `}
              >
                <Text label bold>
                  Loom link
                </Text>
                <Input {...form.getProperty('loomLink')} />
              </Box>
            </>
          ) : null}
        </Modal.Body>
        <Modal.Actions>
          <Box
            css={`
              width: 100%;
            `}
            flex="space-between"
          >
            <Box
              flex="left"
              css={`
                width: 100%;
                margin: auto 0;
              `}
            >
              <Box
                onClick={() => setStep(0)}
                css={`
                  cursor: pointer;
                  width: 16px;
                  height: 16px;
                  margin-right: 16px;
                  border-radius: 50%;
                  border: 1px solid ${colors.gray[400]};
                  ${step === 0
                    ? `
                  background-color: ${colors.purple};
                `
                    : ''}
                `}
              />
              <Box
                onClick={() => setStep(1)}
                css={`
                  cursor: pointer;
                  width: 16px;
                  height: 16px;
                  margin-right: 16px;
                  border-radius: 50%;
                  border: 1px solid ${colors.gray[400]};
                  ${step === 1
                    ? `
                  background-color: ${colors.purple};
                `
                    : ''}
                `}
              />
            </Box>
            <Box flex="right">
              <Button secondary onClick={handleBack}>
                {step === 0 ? 'Cancel' : 'Back'}
              </Button>
              <Button onClick={handleStep} disabled={isLoading || !canSubmit}>
                {step === 0 ? 'Next' : 'Submit'}
              </Button>
            </Box>
          </Box>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
