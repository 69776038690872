import { DefaultElement, withReact } from 'slate-react';
import { useCallback } from 'react';
import { elementMap, listSchema } from '../text-v2/slate-config';
import { textEditorShortKeys, toggleBlock, toggleInline, updateContent } from '../text-v2/slate-utils';
import { Editor, Transforms, createEditor } from 'slate';
import { ListsEditor, withLists, onKeyDown as slateList_onKeyDown } from '@prezly/slate-lists';
import { HistoryEditor, withHistory } from 'slate-history';
import { useState } from 'react';
import { Leaf } from '../text-v2/slate-components';
import { useRef } from 'react';

const inlineElements = ['link', 'smart-field'];

function withInlines(editor) {
  const { isInline } = editor;
  editor.isInline = (element) => inlineElements.includes(element.type) || isInline(element);
  return editor;
}

export const useBriteSlate = ({ slate, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);

  const blockPathString = useRef('');
  const [slateEditor] = useState(() => withLists(listSchema)(withInlines(withReact(withHistory(createEditor())))));

  const renderElement = useCallback((slateProps) => {
    const Element = elementMap?.[slateProps.element.type] || DefaultElement;
    return <Element {...slateProps} />;
  }, []);

  const renderLeaf = useCallback((slateProps) => {
    return <Leaf {...slateProps} />;
  }, []);

  const syncState = () => updateContent(slateEditor, slate);

  const onKeyDown = (event) => {
    if (event.key === 'ArrowLeft') {
      event.stopPropagation();
    } else if (event.key === 'ArrowRight') {
      event.stopPropagation();
    } else if (event.ctrlKey || event.metaKey) {
      if (event.key === 'z') {
        event.stopPropagation();
        if (event.shiftKey) {
          HistoryEditor.redo(slateEditor);
        } else {
          HistoryEditor.undo(slateEditor);
        }
      } else if (event.key in textEditorShortKeys) {
        event.preventDefault();
        const { type, key } = textEditorShortKeys[event.key];
        if (type === 'inline') {
          toggleInline(slateEditor, key);
        }
      }
    } else if (event.key === 'Backspace' || event.key === 'Delete') {
      const [block] = Editor.nodes(slateEditor, {
        match: (n) => !Editor.isBlock(slateEditor, n) && n.type === 'smart-field',
      });
      const [, blockPath] = block || [];
      if (block?.[0]?.type === 'smart-field') {
        event.preventDefault();
        event.stopPropagation();
        Transforms.delete(slateEditor, {
          at: blockPath,
        });
      } else if (ListsEditor.isAtStartOfListItem(slateEditor)) {
        event.preventDefault();
        ListsEditor.decreaseDepth(slateEditor);
      }
    }
    slateList_onKeyDown(slateEditor, event);
  };

  const onChange = (slateContent) => {
    setIsLoading(true);
    const anchor = slateEditor?.selection?.anchor || null;
    if (anchor !== null) {
      const { text = '' } = slateContent[anchor?.path?.[0]]?.children?.[anchor?.path?.[1]] || { text: '' };
      if (anchor?.offset === 2 && anchor?.path[1] === 0 && text.startsWith('- ')) {
        const at = {
          ...slateEditor.selection,
          anchor: { ...anchor, offset: 0 },
        };
        Transforms.delete(slateEditor, { at });
        toggleBlock(slateEditor, 'unordered-list');
      } else if (anchor.offset === 3 && anchor.path[1] === 0 && text.startsWith('1. ')) {
        const at = {
          ...slateEditor.selection,
          anchor: { ...anchor, offset: 0 },
        };
        Transforms.delete(slateEditor, { at });
        toggleBlock(slateEditor, 'ordered-list');
      }
    }
    blockPathString.current = '';
    setIsLoading(false);
  };

  const onBlur = () => {
    setIsLoading(true);
    onUpdate();
    setIsLoading(false);
  };

  const editorProps = {
    onKeyDown,
    onBlur,
    renderElement,
    renderLeaf,
    scrollSelectionIntoView: () => {},
  };

  const slateProps = {
    editor: slateEditor,
    initialValue: slate || [],
    onChange,
  };

  return {
    slateProps,
    editorProps,
    data: { isLoading, syncState },
  };
};
