import { createContext } from 'react';
import { useBasicReducer } from '../../use-basic-reducer';

export const SlateTextboxContext = createContext();

export const SlateTextboxProvider = ({ children }) => {
  const [state, actions] = useBasicReducer({
    id: '',
    type: '',
    searchValue: '',
    isSelected: false,
  });

  return <SlateTextboxContext.Provider value={{ state, actions }}>{children}</SlateTextboxContext.Provider>;
};
