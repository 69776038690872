import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import { CustomAxios } from '../redux/axios/axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { cloneDeep } from 'lodash';
import { history } from '../history';
import axios from 'axios';

import savePageAsTemplateImage from './save-page-template-new.jpg';
import { useStore } from '../store-provider/use-store';
import { Box, Modal } from '../shared/components';

export const PageTemplates = ({ currentFolder, selectedGuideType }) => {
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [loadingImage, setLoadingImage] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);

  const {
    data: { selectedBusiness },
  } = useStore();
  const { ID: businessId = '' } = selectedBusiness;

  const getPageTemplates = async () => {
    try {
      setLoading(true);

      let response = await CustomAxios.get(`/v1/templates`);
      let pageTemplates = response.data || [];

      setTemplates(pageTemplates);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error('Could not get page templates');
    }
  };

  useEffect(() => {
    getPageTemplates();
  }, []);

  const isChecked = (template) => {
    for (let temp of selectedTemplates) {
      if (temp.ID === template.ID) {
        return true;
      }
    }
    return false;
  };

  const previewTemplate = async (template) => {
    setLoadingImage(true);
    setPreviewModal(true);
    try {
      const response = await axios.post(
        'https://api.unlayer.com/v2/export/image',
        { displayMode: 'web', design: template.Content },
        {
          headers: {
            Authorization:
              'Basic THZyN3VQNGpmTzhxMWhZS1dRN2oyVnVrc3pZckl5NmFNYWJ3UTEyZ1JIVEk0MmJNNndOQndLZWZCb1A0eWlEbzo=',
          },
        }
      );

      setPreviewImage(response.data.data.url);

      setLoadingImage(false);
    } catch (e) {
      setLoadingImage(false);
      setPreviewImage('');
      setPreviewModal(false);
    }
  };

  const closePreviewImage = () => {
    setLoadingImage(false);
    setPreviewImage('');
    setPreviewModal(false);
  };

  const routeToBuilder = (id) => {
    history.push(`/${businessId}/courses/${id}`);
  };

  const importSelectedTemplates = async () => {
    setLoading(true);

    try {
      let course;
      let pages = [];
      let order = 1;
      for (let temp of selectedTemplates) {
        pages.push({
          Type: temp.Type || 'regular',
          Content: temp.Content,
          Name: temp.Name,
          Order: order,
          FrontendID: '_' + Math.random().toString(36).substr(2, 9),
          InsurancePlans: [],
          Question: null,
          IsLocked: false,
          TemplateID: '00000000-0000-0000-0000-000000000000',
        });

        order = order + 1;
      }
      const newCourseDefault = {
        Name: 'Untitled Guide',
        Type: selectedGuideType,
        Views: 0,
        IsPublished: false,
        Pages: pages,
        ViewTotalsMonthly: true,
        CourseFolderID: currentFolder ? currentFolder.ID : null,
      };

      const response = await CustomAxios.post(`/v1/course`, newCourseDefault);
      course = response.data;

      setLoading(false);
      routeToBuilder(course.ID);
      window?.ChurnZero?.push(['trackEvent', 'Guide Created', `Name: ${newCourseDefault?.Name}`, 1]);
      toast.success('Imported successfully!');
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error('Failed to import.');
    }
  };

  if (loading) {
    return (
      <div
        className={css`
          display: flex;
          justify-content: center;
          margin: 48px;
        `}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {templates.length > 0 && (
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <div
            className={css`
              font-weight: 400;
              color: #797979;
              margin-right: 10px;
              margin-left: 12px;
            `}
          >
            Select the templates you'd like to use.
          </div>
          <div>
            <Button
              disabled={selectedTemplates.length === 0}
              color="primary"
              variant="contained"
              onClick={() => {
                importSelectedTemplates();
              }}
            >
              Create guide
            </Button>
          </div>
        </div>
      )}

      {templates.length === 0 && (
        <div
          className={css`
            text-align: center;
            font-size: 16px;
            margin: 24px;
          `}
        >
          <div
            className={css`
              font-weight: bold;
              font-size: 18px;
              margin-bottom: 24px;
            `}
          >
            You have no page templates.
          </div>
          <div>
            <div
              className={css`
                margin-bottom: 48px;
              `}
            >
              You can save a page as a template in the guide builder. Example below.
            </div>
            <img src={savePageAsTemplateImage} width="800px" height="264px" alt="Save Template" />
          </div>
        </div>
      )}

      {templates.length > 0 && (
        <div>
          {templates.map((template) => {
            return (
              <div
                className={css`
                  display: flex;
                  align-items: center;
                  padding: 12px;
                `}
              >
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked(template)}
                        onChange={(e) => {
                          let temp = cloneDeep(selectedTemplates);
                          if (e.target.checked) {
                            temp.push(template);
                          } else {
                            temp = temp.filter(function (obj) {
                              return obj.ID !== template.ID;
                            });
                          }

                          setSelectedTemplates(temp);
                        }}
                        name={template.ID}
                        color="primary"
                      />
                    }
                    label={template.Name}
                  />
                </FormGroup>

                <div
                  className={css`
                    margin-right: 20px;
                  `}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      previewTemplate(template);
                    }}
                  >
                    Preview
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {previewModal && (
        <Modal display onClose={() => closePreviewImage()}>
          <Box
            css={`
              gap: 16px;
              margin: 24px;
              overflow: auto;
            `}
          >
            {loadingImage && (
              <div
                className={css`
                  display: flex;
                  justify-content: center;
                  margin-top: 80px;
                `}
              >
                <CircularProgress />
              </div>
            )}
            {previewImage && (
              <div
                className={css`
                  margin: 10px auto;
                `}
              >
                <img src={previewImage} alt="preview" />
              </div>
            )}
          </Box>

          <div
            className={css`
              display: flex;
              justify-content: center;
              margin-top: 30px;
              margin-bottom: 30px;
            `}
          >
            <Button
              onClick={() => {
                closePreviewImage();
              }}
              color="primary"
              variant="contained"
            >
              Close
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};
