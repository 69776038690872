import { get, set, merge as _merge } from 'lodash';
import { useStateSync } from './use-state-sync';
import { useState } from 'react';

export const useForm = (value, deps = []) => {
  const [form, setForm, isSynced, valueUpdatedAt] = useStateSync(value, deps);
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e, options = {}) => {
    const { characterLimit = null, transformChange = null } = options;
    const { name, value } = e.target || {};
    let nextValue = characterLimit === null ? value : value.slice(0, characterLimit);
    if (transformChange !== null) {
      nextValue = transformChange(nextValue);
    }
    setForm((v) => {
      let next = { ...v };
      set(next, name, nextValue);
      return next;
    });
  };

  const getProperty = (property, options = {}) => {
    const { initialValue, transformValue = null, checkbox = false } = options;
    let value = get(form, property, initialValue);
    if (transformValue !== null) {
      value = transformValue(value);
    }
    const attributeName = checkbox ? 'checked' : 'value';
    return {
      [attributeName]: value,
      onChange: (e) => onChange(e, options),
      name: property,
    };
  };

  const merge = (updates) => {
    const data = _merge({}, { ...form }, { ...updates });
    setForm(data);
  };

  const resetForm = () => setForm(value);

  return {
    getProperty,
    values: form,
    setValues: setForm,
    merge,
    resetForm,
    hasChanges: !isSynced,
    isLoading,
    setIsLoading,
    updateAt: valueUpdatedAt,
  };
};
