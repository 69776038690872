import { MenuItem } from '@material-ui/core';
import { css } from '@emotion/css';
import { DotsThree } from 'phosphor-react';
import { history } from '../history';
import { Button, Div, DropMenu, Input, Loader, NewTag, Text, Icon, Box } from '../shared/components';
import { container, flex } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { format, isAfter } from 'date-fns';
import { ACLWrapper } from '../shared/components/acl-wrapper';
import { WRITE_BENEFITS_PACKAGE } from '../shared/acl-constants';
import { FlagsBadge } from '../products/flags/flags-badge';
import { ReactComponent as BenefitPackageIcon } from '../images/svg/benefit-package.svg';
import CalendarX from '../images/CalendarX.png';
import Dates from '../shared/components/Dates';

export const BenefitPackageBlocks = ({
  list,
  needsAttentionResource,
  editPackageName,
  pkgDescriptions,
  setPkgDescriptions,
  handleDuplicate,
  setSelectedPkg,
  setModal,
  saveDescriptions,
}) => {
  return list.map((pkg) => (
    <Div
      onClick={() => pkg?.ID && history.push(`packages/${pkg.ID}`)}
      css={css`
        ${container.box}
        ${container.hoverOutline}
              width: 440px;
        background-color: white;
        margin-right: 24px;
        margin-bottom: 24px;
        padding: 16px 24px;
      `}
    >
      <Div
        css={css`
          ${flex('left')}
        `}
      >
        <Div
          css={css`
            ${flex('left')}
            flex-grow: 1;
          `}
        >
          <Icon
            SVG={BenefitPackageIcon}
            size={32}
            weight="light"
            className={css`
              margin-right: 16px;
            `}
          />
          <NewTag createdAt={pkg?.CreatedAt} />
          {pkg?.PlanEndDate !== null && isAfter(new Date(), Dates.getDateFromISO(pkg?.PlanEndDate)) ? (
            <Box
              flex="left"
              css={`
                img {
                  margin-right: 8px;
                }
              `}
            >
              <img src={CalendarX} alt="Expired Plan Date" />
              <Text label>{format(Dates.getDateFromISO(pkg?.PlanEndDate), 'MMM d, yyyy')}</Text>
            </Box>
          ) : null}
        </Div>
        <FlagsBadge flagCountOverride={needsAttentionResource?.data?.[pkg?.ID]?.length} />
        {pkg.ID ? (
          <ACLWrapper acls={[WRITE_BENEFITS_PACKAGE]}>
            <DropMenu
              button={
                <Button styles="icon">
                  <DotsThree />
                </Button>
              }
            >
              <MenuItem onClick={() => editPackageName(pkg)}>Change Details</MenuItem>
              {pkg?.ID in pkgDescriptions ? null : (
                <MenuItem
                  onClick={() =>
                    setPkgDescriptions((des) => ({
                      ...des,
                      [pkg?.ID]: '',
                    }))
                  }
                >
                  Add Description
                </MenuItem>
              )}
              <MenuItem onClick={() => handleDuplicate(pkg)}>Duplicate</MenuItem>
              <MenuItem
                onClick={() => {
                  setSelectedPkg(pkg);
                  setModal('delete-package');
                }}
              >
                Delete
              </MenuItem>
            </DropMenu>
          </ACLWrapper>
        ) : (
          <Loader />
        )}
      </Div>
      <Text
        h3
        css={`
          margin: 8px 0;
          width: 100%:
        `}
        ellipsis
      >
        {pkg?.Name}
      </Text>
      <Div
        css={css`
          ${flex('left')}
        `}
      >
        <Text
          label
          css={`
            padding-right: 16px;
            ${pkg?.ID in pkgDescriptions ? `border-right: 1px solid ${colors.gray[300]}` : ''};
          `}
        >
          {pkg?.ProductCount}
          {pkg?.ProductCount === 1 ? ' Benefit' : ' Benefits'}
        </Text>
        <Input
          value={pkgDescriptions?.[pkg?.ID]}
          onChange={(e) => {
            const value = e.target.value;
            setPkgDescriptions((des) => ({
              ...des,
              [pkg?.ID]: value,
            }));
          }}
          onBlur={() => saveDescriptions(pkg)}
          onClick={(e) => e.stopPropagation()}
          placeholder="Add a description..."
          css={`
            input {
              color: ${colors.gray[500]};
            }
            flex-grow: 1;
            padding-top: 0;
            padding-bottom: 0;
            margin: 0;
            margin-left: 8px;
            height: 32px;
            border: none;
            background: transparent;
            ${pkg?.ID in pkgDescriptions ? '' : 'visibility: hidden;'}
            :hover {
              border: none;
            }
          `}
        />
      </Div>
    </Div>
  ));
};
