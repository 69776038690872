import { Children, cloneElement } from 'react';
import { useThemedProps } from '../useThemedProps';

// EXAMPLE:
//
// <Table>
//   <Column
//      width="40%"
//      columnHeader="Name"
//      headerStyles={{ textAlign: 'right' }}
//   >
//      {(data) => (<div>{data?.Name}</div>)}
//   </Column>
// </Table>
//
export const Column = ({ data, children }) => {
  return <td className="table-cell">{children(data)}</td>;
};

export const Table = ({ list, children, onHeaderClick, onRowClick, ...rest }) => {
  const props = useThemedProps(rest, {});
  const handleRowClick = (e, row) => {
    e.stopPropagation();
    onRowClick?.(row);
  };

  const handleHeaderClick = (e, data) => {
    e.stopPropagation();
    onHeaderClick?.(data);
  };

  return (
    <table {...props}>
      <colgroup>
        {Children.map(children, (child) => (
          <col style={{ width: child.width, maxWidth: child.width }} />
        ))}
      </colgroup>
      <thead>
        <tr className="table-header">
          {Children.map(children, (child, idx) => {
            return (
              <th
                onClick={(e) =>
                  handleHeaderClick(e, {
                    columnHeader: child.props.columnHeader,
                    columnKey: child.props.columnKey,
                    idx,
                  })
                }
                style={{ cursor: 'pointer', textAlign: 'left', ...child?.props?.headerStyles }}
              >
                {child.props.columnHeader}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {list.map((rowData) => (
          <tr className="table-row" onClick={(e) => handleRowClick(e, rowData)}>
            {Children.map(children, (child) => cloneElement(child, { data: rowData }))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
