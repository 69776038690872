import React, { useEffect, useMemo, useState } from 'react';
import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import { css } from '@emotion/css';
import { validateEmail } from '../Courses/ContactInfo';
import { toast } from 'react-toastify';
import { CustomAxios } from '../redux/axios/axios';
import { history } from '../history';
import { useStore } from '../store-provider/use-store';
import { Autocomplete } from '@material-ui/lab';
import { Modal } from '../shared/components';

const emptyState = { Name: 'Select Company', ID: '' };

export const AddMember = ({ display, onClose, defaultBusiness = emptyState, hideBusinessSelection = false }) => {
  const {
    data: { companies, acls },
  } = useStore();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [business, setBusiness] = useState(defaultBusiness);
  const [loading, setLoading] = useState('');

  useEffect(() => {
    if (defaultBusiness.ID) {
      setBusiness(defaultBusiness);
    }
  }, [defaultBusiness.ID]);

  const resourceIds = Object.keys(acls);

  const accessibleCompanies = useMemo(() => {
    const list = companies.filter(({ ID, ParentID }) => {
      const id = ID in resourceIds ? ID : ParentID;
      if (id in acls) {
        return acls[id].Acls.includes('write:business_access');
      }
      return false;
    });
    return list;
  }, [resourceIds.length]);

  useEffect(() => {
    setError('');
  }, [email]);

  if (!display) {
    return null;
  }

  const handleInviteSuccess = (userId) => {
    setEmail('');
    toast.success(`Successfully granted access.`);
    if (!hideBusinessSelection) {
      history.push(`/members/${userId}`);
    } else {
      onClose();
    }
  };

  const handleConflict = async (businessId, email) => {
    let userId = '';
    try {
      const { data: user } = await CustomAxios.get(`public/v1/users/${email}`, {
        headers: { BusinessID: businessId },
      });
      userId = user.ID;
      await CustomAxios.post(
        `v1/user/${user.ID}/access/${businessId}/role/viewer`,
        {},
        {
          headers: { BusinessID: businessId },
        }
      );
      handleInviteSuccess(userId);
    } catch (err) {
      console.warn(err);
      if (err.response.status === 500 && userId) {
        handleInviteSuccess(userId);
      }
      toast.error(`Failed to add user access.`);
    }
  };

  const handleInvite = async () => {
    const validEmail = validateEmail(email);
    if (!validEmail) {
      setError('Invalid email');
    } else if (!!business.ID) {
      try {
        setLoading(true);
        const { data } = await CustomAxios.post(
          `v1/users/${email}`,
          {
            Email: email,
            RoleID: 'viewer',
            Type: 'single',
            Business: { ID: business.ID },
          },
          {
            headers: { BusinessID: business.ID },
          }
        );
        window?.ChurnZero?.push(['trackEvent', 'Settings', `User created: ${email}`, 1]);
        handleInviteSuccess(data.ID);
      } catch (err) {
        console.warn(err);
        if (err.response.status === 409) {
          await handleConflict(business.ID, email);
        } else {
          toast.error(`Failed to add user access.`);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal onClose={onClose} display>
      <div
        className={css`
          margin: 32px;
        `}
      >
        <Typography style={{ fontWeight: 'bold', fontSize: '20px' }}>Add Member</Typography>
        {!hideBusinessSelection && (
          <div
            className={css`
              margin: 16px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 540px;
            `}
          >
            <Typography>Select Company</Typography>
            <Autocomplete
              style={{ width: '350px' }}
              disableClearable
              id="company_select"
              noOptionsText={'Not Found.'}
              options={accessibleCompanies}
              getOptionLabel={(option) => option.Name}
              onChange={(_, value) => setBusiness(value)}
              value={business}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
          </div>
        )}

        <div
          className={css`
            margin: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 540px;
          `}
        >
          <Typography>Email</Typography>
          <TextField
            error={!!error}
            helperText={error}
            style={{ width: '350px' }}
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div
          className={css`
            display: flex;
            justify-content: right;
            margin: 16px;
            margin-bottom: 0;
          `}
        >
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleInvite}
            style={{ marginLeft: '16px' }}
            disabled={!!(!email || loading || !business.ID)}
          >
            {!loading ? 'Add' : <CircularProgress />}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
