import { CircularProgress } from '@material-ui/core';
import { css } from '@emotion/css';
import { X } from 'phosphor-react';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { fileTypesMap } from '../../Documents/FullscreenUploader';
import { CustomAxios } from '../../redux/axios/axios';
import { Button, Div, Modal, Text } from '../../shared/components';
import { flex, px } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import React from 'react';

const container = css`
  width: 500px;
  padding: 32px;
`;

export const ImageBlock = (BodyImageURL) => {
  const [displayImageUpload, setDisplayImageUpload] = React.useState(false);
  const [imageURL, setImageURL] = React.useState(BodyImageURL);

  const [loadingDocuments, setLoadingDocuments] = useState(false);

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setLoadingDocuments(true);
      const url = `/v2/media/${acceptedFiles[0].name ? encodeURIComponent(acceptedFiles[0].name) : 'no-name'}/${
        acceptedFiles[0].type ? encodeURIComponent(acceptedFiles[0].type.replace('/', '.')) : 'image.png'
      }`;
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      try {
        const { data } = await CustomAxios.post(url, formData, config);
        setImageURL(data.PublicURL);
        setDisplayImageUpload(false);
      } catch (err) {
        toast.error('Error uploading image. Try again.');
      } finally {
        setLoadingDocuments(false);
      }
    } else {
      toast.error('Invalid file or too many files');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: fileTypesMap.images,
    noClick: false,
    noDragEventsBubbling: true,
  });

  return (
    <div
      className={css`
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        :hover,
        :focus {
          border-radius: 16px;
          outline: 1px solid var(--gray-700, #25282d);
        }
        padding: 8px;
        padding-right: 8px;
      `}
      onClick={() => {
        if (!displayImageUpload) {
          setDisplayImageUpload(true);
        }
      }}
    >
      <div>
        {imageURL && <img src={imageURL} alt="" width="100%" height="auto" />}

        <Modal display={displayImageUpload}>
          <Div className={container}>
            <Div
              css={css`
                ${flex('jcsb aic')}
              `}
            >
              <Text styles="h2">Upload Image</Text>
              <Button
                styles="icon"
                onClick={() => {
                  setDisplayImageUpload(false);
                }}
              >
                <X />
              </Button>
            </Div>
            <Div
              {...getRootProps({
                className: css`
                  ${flex('jcc aic')}
                  margin: ${px.md} 0;
                  border: 1px dashed ${colors.gray[400]};
                  border-radius: 8px;
                  height: 200px;
                  cursor: pointer;
                  ${isDragActive &&
                  `
                                border: 1px solid ${colors.black};
                                background-color: ${colors.gray[300]};
                            `}
                `,
              })}
            >
              {loadingDocuments ? (
                <CircularProgress />
              ) : (
                <Text styles="label center">Drop a new image here, or click to select a file</Text>
              )}
              <input {...getInputProps()} />
            </Div>
          </Div>
        </Modal>
      </div>
    </div>
  );
};
