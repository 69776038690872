import { css } from '@emotion/css';
import { CaretDown, CaretUp, X } from 'phosphor-react';
import { useMemo, useState } from 'react';
import { Button, Div, Input, Modal, Text, Toggle } from '../../../shared/components';
import { flex, shadows } from '../../../shared/shared-styles';
import { colors } from '../../../shared/styles';
import { useOutsideClick } from '../../../shared/use-outside-click';

export const PageSettings = ({ onSave, pageOptions, existingRoles, ...modalProps }) => {
  const [showRoles, setShowRoles] = useState(false);
  const ref = useOutsideClick(() => setShowRoles(false));
  const [newPageRole, setNewPageRole] = useState(pageOptions.Role);
  const [forceActive, setForceActive] = useState(pageOptions.ForceActive);
  const [strictProductMatching, setStrictProductMatching] = useState(pageOptions.StrictProductMatching);

  const filteredRoles = useMemo(() => {
    if (!newPageRole) {
      return existingRoles;
    }
    const lowerCasePageRole = newPageRole.toLowerCase();
    return existingRoles.filter((item) => {
      return item.toLowerCase().includes(lowerCasePageRole);
    });
  }, [existingRoles?.toString(), newPageRole]);

  const handleSave = () => {
    onSave({
      Role: newPageRole,
      ForceActive: forceActive,
      StrictProductMatching: strictProductMatching,
    });
    modalProps.onClose();
  };

  const Dropdown = () => {
    const updatePageRole = (newPageRole) => {
      setNewPageRole(newPageRole);
      setShowRoles(false);
    };

    return (
      <>
        <Button styles="icon sm" onClick={() => setShowRoles(true)}>
          {showRoles ? <CaretUp /> : <CaretDown />}
        </Button>
        {filteredRoles?.length && showRoles ? (
          <Div
            ref={ref}
            css={css`
              position: absolute;
              top: calc(100% + 4px);
              right: 0;
              left: 0;
              border-radius: 8px;
              background-color: white;
              ${shadows.md}
            `}
            onMouseDown={(e) => e.stopPropagation()}
          >
            {!!newPageRole ? (
              <Div onClick={() => updatePageRole('')}>
                <Text
                  label
                  css={`
                    padding: 8px;
                    border-radius: 8px;
                    :hover {
                      background-color: ${colors.gray[100]};
                      cursor: pointer;
                    }
                  `}
                >
                  None
                </Text>
              </Div>
            ) : null}
            {filteredRoles?.map((item) =>
              newPageRole !== item ? (
                <Div onClick={() => updatePageRole(item)}>
                  <Text
                    label
                    css={`
                      padding: 8px;
                      border-radius: 8px;
                      :hover {
                        background-color: ${colors.gray[100]};
                        cursor: pointer;
                      }
                    `}
                  >
                    {item}
                  </Text>
                </Div>
              ) : null
            )}
          </Div>
        ) : null}
      </>
    );
  };

  return (
    <Modal {...modalProps}>
      <Div
        css={css`
          width: 500px;
        `}
      >
        <Div
          css={css`
            ${flex('space-between')}
            padding: 32px;
          `}
        >
          <Text h2>Page Settings</Text>
          <Button styles="icon" onClick={modalProps.onClose}>
            <X />
          </Button>
        </Div>

        <Div
          css={css`
            padding: 0 32px;
          `}
        >
          <Text label>Page Role</Text>
          <Input
            placeholder="None"
            value={newPageRole}
            onClick={() => setShowRoles(true)}
            onChange={(e) => setNewPageRole(e.target.value)}
            css={css`
              position: relative;
              width: 100%;
            `}
            endIcon={<Dropdown />}
          />
        </Div>

        <Div
          css={css`
            padding: 32px 32px;
          `}
        >
          <Text label>Force Active</Text>
          <Toggle checked={forceActive} onChange={(e) => setForceActive(e.target.checked)} />
        </Div>

        <Div
          css={css`
            padding: 0px 32px;
          `}
        >
          <Text label>Strict Product Matching</Text>
          <Toggle checked={strictProductMatching} onChange={(e) => setStrictProductMatching(e.target.checked)} />
        </Div>

        <Div
          css={css`
            ${flex('right')}
            padding: 32px;
          `}
        >
          <Button
            css={css`
              margin-right: 8px;
            `}
            secondary
            onClick={modalProps.onClose}
          >
            Close
          </Button>
          <Button onClick={handleSave}>Update</Button>
        </Div>
      </Div>
    </Modal>
  );
};
