import { useEffect, useRef, useState } from 'react';

export const useDebounceValue = (value, delay, deps = [value]) => {
  const ref = useRef(null);
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (ref?.current) {
      clearTimeout(ref?.current);
    }
    ref.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(ref?.current);
    };
  }, [delay, ...deps]);
  return [debouncedValue, setDebouncedValue];
};
