import posthog from 'posthog-js';
import { useEffect } from 'react';

export const usePosthogRegister = (register, deps = []) => {
  useEffect(() => {
    posthog.register(register);
    return () => {
      Object.keys(register).forEach((key) => {
        posthog.unregister(key);
      });
    };
  }, deps);
};
