import { css } from '@emotion/css';
import { colors } from '../../shared/styles';
import { AutoFill } from './auto-fill';

export const SidePanel = ({ state, autoFillProps, onClose }) => {
  return (
    <div
      className={css`
        position: relative;
        max-width: 600px;
        min-width: 500px;
        overflow: auto;
        padding: 32px;
        background-color: white;
        border-left: 1px solid ${colors.gray[300]};
        height: calc(100vh - 100px);
      `}
    >
      <AutoFill state={state} autoFillProps={autoFillProps} onClose={onClose} />
    </div>
  );
};
