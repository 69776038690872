import { css } from '@emotion/css';
import { Info } from 'phosphor-react';
import { Button, Div, Text } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';

export const ReviewAcaPlans = ({ selectedPlans, removePlanFromSelected }) => {
  return (
    <Div
      css={css`
        width: 840px;
        margin: 32px 0;
      `}
    >
      <Text h2>Review Your Selected Plans</Text>
      <Div
        css={css`
          margin-top: 24px;
          border-top: 1px solid ${colors.gray[300]};
        `}
      >
        {selectedPlans?.map((plan) => (
          <Div
            key={plan?.ID}
            css={css`
              ${flex('left start')}
              border-bottom: 1px solid ${colors.gray[300]};
              padding: 24px 0;
            `}
          >
            <Div
              css={css`
                flex-grow: 1;
              `}
            >
              <Text label>{plan?.PlanName}</Text>
              <Text
                css={`
                  font-size: 0.9em;
                `}
              >
                {plan?.CarrierName}
              </Text>
            </Div>
            <Button
              text
              css={`
                color: ${colors.purple};
                font-weight: normal;
              `}
              onClick={() => removePlanFromSelected(plan)}
            >
              Remove
            </Button>
          </Div>
        ))}
        <Div
          css={css`
            border-radius: 8px;
            background-color: ${colors.gray[100]};
            padding: 24px;
            margin-top: 16px;
            ${flex('left')}
            p {
              margin-left: 16px;
            }
          `}
        >
          <Info weight="fill" color={colors.purple} />
          <Text label>Any ACA plans that are imported will be added in your benefits package.</Text>
        </Div>
      </Div>
    </Div>
  );
};
