import { isToday } from 'date-fns';
import { cloneDeep, isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryAPI } from '../react-query';
import { CustomAxios } from '../redux/axios/axios';
import { defaultDesignStyles } from './utils';

const addLinkToList = (list, url) => {
  if (url && !list.includes(url)) {
    list.push(url);
  }
};

export const useDesignStyles = (setThemeId = null, deps = []) => {
  const [current, setCurrent] = useState({});
  const [original, setOriginal] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [selected, setSelected] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const designStylesQuery = useQueryAPI({
    url: `/v1/themes`,
    defaultValue: [],
    select: (data) => {
      const { old, recent } = data.reduce(
        (prev, item) => {
          if (!item.CreatedAt || isToday(new Date(item.CreatedAt))) {
            return { ...prev, recent: [...prev.recent, item] };
          } else {
            return { ...prev, old: [...prev.old, item] };
          }
        },
        { old: [], recent: [] }
      );
      return [
        ...recent.sort((a, b) => b?.CreatedAt?.localeCompare?.(a?.CreatedAt)),
        ...old.sort((a, b) => a.Name.localeCompare(b.Name)),
      ];
    },
  });

  const getDesignStyle = (id) => {
    return designStylesQuery?.cache?.data?.find(({ ID }) => ID === id) || {};
  };

  const handleCurrent = (updates) => {
    setCurrent(updates);
    if (updates?.ID === original?.ID) {
      const hasChanges = !isEqual(updates, original);
      setHasChanges(hasChanges);
    } else {
      setHasChanges(false);
    }
  };

  const resetCurrent = () => {
    const theme = getDesignStyle(current.ID);
    setCurrent(cloneDeep(theme));
    setHasChanges(false);
  };

  useEffect(() => {
    const original = getDesignStyle(current?.ID);
    setOriginal(cloneDeep(original));
    if (!current?.ID) {
      setSelected('');
    }
  }, [current?.ID]);

  useEffect(() => {
    setCurrent({});
  }, deps);

  const fontURLs = useMemo(() => {
    if (!current?.ID) {
      return [];
    }

    let urls = [];
    const design = current?.Theme;
    addLinkToList(urls, design?.Body?.Font?.URL);
    addLinkToList(urls, design?.Heading?.FontOverride?.URL);
    addLinkToList(urls, design?.Heading2?.FontOverride?.URL);
    addLinkToList(urls, design?.Heading3?.FontOverride?.URL);
    addLinkToList(urls, design?.Heading4?.FontOverride?.URL);
    return urls;
  }, [
    current?.ID,
    current?.Theme?.Body?.Font?.URL,
    current?.Theme?.Heading?.FontOverride?.URL,
    current?.Theme?.Heading1?.FontOverride?.URL,
    current?.Theme?.Heading2?.FontOverride?.URL,
    current?.Theme?.Heading3?.FontOverride?.URL,
    current?.Theme?.Heading4?.FontOverride?.URL,
  ]);

  const saveCurrent = async () => {
    try {
      setIsSaving(true);
      const newDesignStyles = designStylesQuery.data?.map((item) => {
        if (item.ID === current.ID) {
          return current;
        }
        return item;
      });
      designStylesQuery.cache.setData(newDesignStyles);
      await CustomAxios.put(`/v1/themes/${current.ID}`, current);
      const original = getDesignStyle(current.ID);
      setOriginal(cloneDeep(original));
      setHasChanges(false);
      toast.success('Successfully saved!');
    } catch (err) {
      toast.error('Error saving design style...');
      throw err;
    } finally {
      setIsSaving(false);
    }
  };

  const createNewDesignStyle = async (Name) => {
    const Theme = cloneDeep(defaultDesignStyles);
    const data = { Name, Theme };
    const lastData = [...designStylesQuery.cache.data];
    try {
      designStylesQuery.cache.setData([data, ...designStylesQuery.cache.data]);
      const { data: response } = await CustomAxios.post(`/v1/themes`, data);
      designStylesQuery.cache.setData([response, ...lastData]);
      setCurrent(response);
      if (setThemeId !== null) {
        setThemeId(response?.ID);
      }
      designStylesQuery.refetch();
      toast.success('Successfully created new Design Style!');
    } catch (err) {
      designStylesQuery.cache.setData(lastData);
      throw err;
    }
  };

  return {
    isSaving,
    current,
    setCurrent: handleCurrent,
    hasChanges,
    resetCurrent,
    saveCurrent,
    fontURLs,
    designStylesQuery,
    createNewDesignStyle,
    selected,
    setSelected,
  };
};
