import { css } from '@emotion/css';
import { noop } from 'lodash';
import { X } from 'phosphor-react';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { EditorProvider } from '../BriteEditor/editor-provider';
import { EditorDetailsProvider } from '../BriteEditor/provider/editor-detail-provider';
import { Editor } from '../Content/Builder-v2/pages/editor';
import { history } from '../history';
import { useQueryAPI } from '../react-query/query-api';
import { CustomAxios } from '../redux/axios/axios';
import { Button, Div, Loader, Modal, Text } from '../shared/components';
import { flex } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { useSearchParams } from '../shared/use-search-params';

export const EditTemplate = () => {
  const ref = useRef();
  const { businessId, templateId } = useParams();
  const { updateParams } = useSearchParams();

  const templateQuery = useQueryAPI({ url: `/v1/templates/${templateId}` });
  const { data: template, cache } = templateQuery;

  const [modal, setModal] = useState('');
  const [hasChanges, setHasChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const updateTemplateContent = ({ Content }) => {
    setHasChanges(true);
    cache.setData({ ...template, Content });
  };

  const exitTemplate = () => {
    if (!!modal || !hasChanges) {
      history.replace(`/${businessId}/templates`);
    } else {
      setModal('confirm-cancel');
    }
  };

  const saveTemplate = async () => {
    try {
      setIsSaving(true);
      await CustomAxios.put(`v2/templates/${template?.ID}`, {
        ...template,
        Content: cache?.data?.Content,
      });
      toast.success(`Saved template!`);
      setHasChanges(false);
    } catch (err) {
      console.warn(err);
      toast.error(`Error saving template!`);
    } finally {
      setIsSaving(false);
      if (modal === 'confirm-cancel') {
        exitTemplate();
      }
    }
  };

  const handleUnlayerUpdates = (args) => {
    if ((!args.type.startsWith('design') && !args.type.startsWith('action')) || args.type.startsWith('content')) {
      window.unlayer.exportHtml(({ design }) => {
        updateTemplateContent({ Content: design });
      });
    }
  };

  useEffect(() => {
    updateParams({ pageId: template?.ID });
    if (template?.ID && template?.Type === 'regular') {
      window?.unlayer?.addEventListener('design:updated', handleUnlayerUpdates);
      return () => {
        window?.unlayer?.removeEventListener('design:updated', handleUnlayerUpdates);
      };
    }
  }, [window?.unlayer, template?.ID]);

  return (
    <Modal full display={true}>
      <Div
        css={css`
          width: 100%;
          height: 100px;
          padding: 32px;
          border-bottom: 1px solid ${colors.gray[300]};
          ${flex('space-between')}
        `}
      >
        <Text h1>{template?.Name}</Text>
        <Div
          css={css`
            ${flex('right')}
          `}
        >
          <Button
            secondary
            css={`
              margin-right: 16px;
            `}
            onClick={exitTemplate}
          >
            Close
          </Button>
          <Button
            disabled={!hasChanges || isSaving}
            css={`
              position: relative;
            `}
            onClick={saveTemplate}
          >
            <Loader
              type="three-dots"
              isLoading={isSaving}
              className={css`
                ${flex('center start')} background-color: ${colors.gray[300]};
              `}
            />
            Save
          </Button>
        </Div>
      </Div>
      <Div
        css={css`
          ${flex('center')} width: 100%;
          height: 48px;
          background-color: ${colors.black};
        `}
      >
        <Text color="white">You are editing a template</Text>
      </Div>
      <Div
        css={css`${flex('center start')} width: 100%; height: calc(100vh - 148px); background-color ${
          colors.gray[100]
        }; overflow-y: hidden;`}
      >
        <EditorDetailsProvider>
          {templateQuery?.data?.Type === 'regular' ? (
            <Editor ref={ref} page={templateQuery?.data} setSaveStatus={noop} refreshTheme={noop} />
          ) : templateQuery?.data?.Type === 'brite-editor' ? (
            <EditorProvider
              editorType="template"
              query={templateQuery}
              savePage={updateTemplateContent}
              isEditable={true}
            />
          ) : null}
        </EditorDetailsProvider>
      </Div>

      <Modal display={modal === 'confirm-cancel'} onClose={() => setModal('')}>
        <Div
          css={css`
            width: 500px;
          `}
        >
          <Div
            css={css`
              ${flex('space-between')} padding: 32px;
            `}
          >
            <Text h2>Are you sure?</Text>
            <Button styles="icon" onClick={() => setModal('')}>
              <X />
            </Button>
          </Div>
          <Div
            css={css`
              padding: 0 32px;
            `}
          >
            <Text>You have unsaved changes! Would you like to save before leaving?</Text>
          </Div>
          <Div
            css={css`
              ${flex('right')} padding: 32px;
            `}
          >
            <Button
              secondary
              css={`
                margin-right: 16px;
              `}
              onClick={exitTemplate}
            >
              Discard
            </Button>
            <Button
              disabled={isSaving}
              onClick={saveTemplate}
              css={`
                position: relative;
              `}
            >
              <Loader
                type="three-dots"
                isLoading={isSaving}
                className={css`
                  ${flex('center start')} background-color: ${colors.gray[300]};
                `}
              />
              Save Changes
            </Button>
          </Div>
        </Div>
      </Modal>
    </Modal>
  );
};
