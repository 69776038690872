import { get, set } from 'lodash';
import { extendedStates } from '../../constants';
import { setPropertyChain } from '../../products/configs/product-utils';
import { planTypeOptions } from '../../products/field-components/field-types';
import { saveFormatMap } from '../../products/field-components/field-utils';

export const compareTwoStrings = (first = '', second = '') => {
  first = first.replace(/\s+/g, '');
  second = second.replace(/\s+/g, '');

  if (first === second) return 1; // identical or empty
  if (first.length < 2 || second.length < 2) return 0; // if either is a 0-letter or 1-letter string

  let firstBigrams = new Map();
  for (let i = 0; i < first.length - 1; i++) {
    const bigram = first.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;

    firstBigrams.set(bigram, count);
  }

  let intersectionSize = 0;
  for (let i = 0; i < second.length - 1; i++) {
    const bigram = second.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;

    if (count > 0) {
      firstBigrams.set(bigram, count - 1);
      intersectionSize++;
    }
  }

  return (2.0 * intersectionSize) / (first.length + second.length - 2);
};

export const findBestMatch = (mainString, targetStrings) => {
  const ratings = [];
  let bestMatchIndex = 0;

  for (let i = 0; i < targetStrings.length; i++) {
    const currentTargetString = targetStrings[i];
    const currentRating = compareTwoStrings(mainString, currentTargetString);
    ratings.push({ target: currentTargetString, rating: currentRating });
    if (currentRating > ratings[bestMatchIndex].rating) {
      bestMatchIndex = i;
    }
  }

  const bestMatch = ratings[bestMatchIndex];

  return {
    ratings: ratings,
    bestMatch: bestMatch,
    bestMatchIndex: bestMatchIndex,
  };
};

export const extractNumbers = (string, fallback = 0) => {
  try {
    return Number(string.replace(/[^0-9.]/g, ''));
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

const getPercentOrCurrency = (value) => {
  const dollarIndex = value?.indexOf('$');
  const percentIndex = value?.indexOf('%');
  if (percentIndex > -1 || dollarIndex > -1) {
    if (percentIndex > dollarIndex) {
      return '%';
    } else {
      return '$';
    }
  }
  return '';
};

// All these values should be lower-case
const afterDeductibleParseStrings = ['after deductible', 'ad', 'deductible', 'ded. then'];

const coveredParseStrings = ['covered'];

const advancedInputField = (state, propertyChain, data) => {
  try {
    const splits = data.split(' ');
    const filtered = splits.filter((item) => /\d/.test(item));
    const values = filtered;
    const stringCheck = values.join('');
    let updates = get(state.product, propertyChain);

    if (stringCheck.includes('/') || values.length > 1) {
      updates.ADOrCopay = 'COPAY + %';
      const dollarValue = values.find((item) => item.includes('$')) || values[0];
      const specialCopay = extractNumbers(dollarValue);

      updates.SpecialCopay = saveFormatMap.dollar(specialCopay, {
        isNumber: true,
      });

      const percentValue = values.find((item) => item.includes('%')) || values[1];
      const value = extractNumbers(percentValue);

      updates.Value = saveFormatMap.percent(value, { isNumber: true });
    } else if (afterDeductibleParseStrings.some((item) => data?.toLowerCase()?.includes(item))) {
      updates.ADOrCopay = 'AD';
    } else if (coveredParseStrings.some((item) => data?.toLowerCase()?.includes(item))) {
      if (data?.toLowerCase()?.includes('not')) {
        updates.ADOrCopay = 'Not Covered';
      } else {
        updates.ADOrCopay = '100% Covered';
      }
    } else {
      updates.ADOrCopay = 'COPAY';
    }

    if (updates.ADOrCopay === 'COPAY + %') {
      updates.PercentOrDollar = '%';
    } else {
      const percentOrDollar = getPercentOrCurrency(values.at(-1));
      if (percentOrDollar) {
        updates.PercentOrDollar = percentOrDollar;
      }
    }

    if (values.length === 1) {
      const type = updates.PercentOrDollar === '%' ? 'percent' : 'dollar';
      const next = saveFormatMap[type](values[0], { isNumber: true });
      updates.Value = next;
    }
    setPropertyChain(state, propertyChain, updates);
  } catch (err) {
    console.warn(err);
  }
};

const prescriptionDrugTier = (state, propertyChain, value) => {
  const append = 'Tier Structure';
  const tierNumber = extractNumbers(value, 3);
  let next;
  if (tierNumber > 2 && tierNumber < 7) {
    next = `${tierNumber} ${append}`;
  } else {
    next = '3 Tier Structure';
  }
  setPropertyChain(state, propertyChain, next);
};

const prescriptionDrugDeductible = (state, propertyChain, value) => {
  if (value.toLowerCase() === 'none') {
    const next = {
      Value: -2,
      MaxValue: null,
      PercentOrDollar: '',
      ADOrCopay: '',
      SpecialCopay: 0,
    };
    setPropertyChain(state, propertyChain, next);
  } else {
    const data = get(state.product, propertyChain);
    let next = {
      ...data,
      Value: value,
    };

    const percentOrDollar = getPercentOrCurrency(value);
    if (percentOrDollar) {
      next.PercentOrDollar = percentOrDollar;
    }

    next.Value = extractNumbers(value, 0);
    setPropertyChain(state, propertyChain, next);
  }
};

export const percent = (state, propertyChain, data) => {
  try {
    let nextPercent = saveFormatMap.percent(data, { isNumber: true });
    nextPercent = Math.min(100, nextPercent);
    if (nextPercent > 1) {
      nextPercent = nextPercent / 100;
    }

    setPropertyChain(state, propertyChain, nextPercent);
  } catch (err) {
    console.log(err);
  }
};

export const date = (state, propertyChain, data) => {
  try {
    const nextDate = new Date(data).toISOString();
    setPropertyChain(state, propertyChain, nextDate);
  } catch (err) {
    console.log(err);
  }
};

export const medicalPlanTypes = (state, propertyChain, value) => {
  const { bestMatch } = findBestMatch(value, planTypeOptions);
  setPropertyChain(state, propertyChain, bestMatch.target.toLowerCase());
};

export const states = (state, propertyChain, value) => {
  const shortenedStates = extendedStates.map(({ id }) => id);
  const shortMatch = findBestMatch(value, shortenedStates);
  if (shortMatch.bestMatch.target === 'ALL') {
    setPropertyChain(state, propertyChain, shortenedStates);
  } else {
    const list = shortMatch.ratings.filter(({ rating }) => rating > 0).map(({ target }) => target);
    setPropertyChain(state, propertyChain, list);
  }
};

const carrier = (state, propertyChain, value, options = {}) => {
  options?.setSearchTerm({
    propertyChain,
    value,
  });
};

const surestInputField = (state, propertyChain, data) => {
  try {
    const splits = data.split(' ');
    const filtered = splits.filter((item) => /\d/.test(item));

    let lower,
      upper = '';
    if (filtered?.length > 1) {
      lower = saveFormatMap.dollar(filtered[0], { isNumber: true });
      upper = saveFormatMap.dollar(filtered[1], { isNumber: true });
    } else {
      lower = saveFormatMap.dollar(filtered[0], { isNumber: true });
    }

    const [property1, property2, property3] = propertyChain?.split('.');
    setPropertyChain(state, `${property1}.${property2}`, {
      [`${property3}Lower`]: lower,
      [`${property3}Upper`]: upper,
    });
  } catch (err) {
    console.warn(err);
  }
};

const toggle = (state, propertyChain, data, options = {}) => {
  const trueValues = ['yes', 'true', 'y', 't'];
  if (trueValues.includes(data.toLowerCase())) {
    setPropertyChain(state, propertyChain, true);
  } else {
    setPropertyChain(state, propertyChain, false);
  }
};

// *******************************************************************
// ** AUTO-PASTE-TYPES -> based on the product-layout field types:
// ** These are used to format the values copied from the spreadsheet
// ** so we can successfully apply them to the various fields.
// *******************************************************************
export const autoPasteTypes = {
  date,
  percent,
  states,
  medicalPlanTypes,
  advancedInputField,
  surestInputField,
  prescriptionDrugTier,
  prescriptionDrugDeductible,
  carrier,
  toggle,
};

const monthlyContributions = (state, propertyChain, data) => {
  const percentOrDollar = getPercentOrCurrency(data);
  let Cost = { ...state?.product?.Cost };
  const key = propertyChain?.replace('Cost.', '');
  const value = extractNumbers(data);
  Cost = set(Cost, key, value);
  if (percentOrDollar) {
    Cost.Contributions.ContributionType = percentOrDollar;
  }
  setPropertyChain(state, 'Cost', Cost);
};

const monthlyPremiums = (state, propertyChain, data) => {
  let Cost = { ...state?.product?.Cost };
  const key = propertyChain?.replace('Cost.', '');
  const value = extractNumbers(data);
  Cost = set(Cost, key, value);
  setPropertyChain(state, 'Cost', Cost);
};

export const autoPastePropertyChains = {
  'Cost.Contributions.MonthlyContributions.EmployeeOnly': monthlyContributions,
  'Cost.Contributions.MonthlyContributions.EmployeeChild': monthlyContributions,
  'Cost.Contributions.MonthlyContributions.EmployeeChildren': monthlyContributions,
  'Cost.Contributions.MonthlyContributions.EmployeeSpouse': monthlyContributions,
  'Cost.Contributions.MonthlyContributions.Family': monthlyContributions,
  'Cost.TotalMonthlyPremiums.EmployeeOnly': monthlyPremiums,
  'Cost.TotalMonthlyPremiums.EmployeeChild': monthlyPremiums,
  'Cost.TotalMonthlyPremiums.EmployeeChildren': monthlyPremiums,
  'Cost.TotalMonthlyPremiums.EmployeeSpouse': monthlyPremiums,
  'Cost.TotalMonthlyPremiums.Family': monthlyPremiums,
};

export const disabledTypes = {
  // states: "Select applicable states from the drop down.",
};
