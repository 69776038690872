import React, { useEffect, useState, useMemo } from 'react';
import { css } from '@emotion/css';

import {
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  Checkbox,
  ListItemIcon,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import { statesMap as statesConst } from '../../constants';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { orderBy } from 'lodash';
import { toast } from 'react-toastify';
import { CustomAxios } from '../../redux/axios/axios';
import { Input, Text } from '../../shared/components';

const useStyles = makeStyles(() => ({
  paper: {
    padding: 16,
    minHeight: 500,
    maxWidth: 1000,
    margin: '16px auto',
  },
  listPaper: {
    padding: 16,
    marginTop: 4,
  },
  listPaperBottom: {
    padding: 16,
    marginTop: 24,
    maxHeight: 400,
  },
  paginationPaper: {
    padding: 16,
    marginTop: 4,
    maxHeight: 400,
    overflow: 'auto',
    background: '#f9f9f9',
  },
}));

const MAX_PAGE_SIZE = 10;
const ITEM_HEIGHT = 74;

const getPageSize = () => {
  const height = window.innerHeight;
  const relativeDiff = 420;
  const units = (height - relativeDiff) / ITEM_HEIGHT;
  return Math.min(MAX_PAGE_SIZE, Math.floor(units));
};

const pageSize = getPageSize();

export const ImportIchraPlan = ({ close, packageId }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [importing, setImporting] = useState(false);

  const [stateSelection, setStateSelection] = useState({});
  const [monthSelection, setMonthSelection] = useState({});
  const [year, setYear] = useState('');
  const [plans, setPlans] = useState([]);
  const [noPlans, setNoPlans] = useState(false);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [EmployeeChildren, setEmployeeChildren] = useState(0);
  const [EmployeeOnly, setEmployeeOnly] = useState(0);
  const [EmployeeSpouse, setEmployeeSpouse] = useState(0);
  const [Family, setFamily] = useState(0);
  const [selectAll, setSelectAll] = useState(true);
  const [filter, setFilter] = useState('');
  const [page, setPage] = React.useState(1);

  useEffect(() => {
    setSelectAll(selectedPlans.length === plans.length);
  }, [selectedPlans.length, plans.length]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const queryStates = (inputValue, callback) => {
    CustomAxios.get('/v1/aca/states?planType=ichra')
      .then((response) => {
        let states = response.data || [];
        let stateOptions = states.map((stateAbbreviation) => {
          return {
            value: stateAbbreviation,
            label: statesConst[stateAbbreviation],
          };
        });
        if (inputValue) {
          stateOptions = stateOptions.filter((i) => {
            return i.label.toLowerCase().includes(inputValue.toLowerCase());
          });
        }

        callback(stateOptions);
      })
      .catch(() => {
        callback([]);
      });
  };

  const importPlans = async () => {
    setImporting(true);
    const extractSelectedPlans = plans.filter(({ ID }) => selectedPlans.includes(ID));
    if (extractSelectedPlans && extractSelectedPlans.length > 0) {
      let Iplans = [];

      for (let plan of extractSelectedPlans) {
        plan.PageID = '00000000-0000-0000-0000-000000000000';
        delete plan.ID;
        plan.HSAContributionType = 'HRA';
        plan.HSACompatible = true;
        plan.HSAContributions.EmployeeChildren = Number(EmployeeChildren);
        plan.HSAContributions.EmployeeOnly = Number(EmployeeOnly);
        plan.HSAContributions.EmployeeSpouse = Number(EmployeeSpouse);
        plan.HSAContributions.Family = Number(Family);
        plan.EmployeeContributionType = '%';
        plan.buyUpNetwork = { show: false };
        plan.narrowNetwork = { show: false };
        Iplans.push(plan);
      }

      //save
      for (let values of Iplans) {
        if (values.PrescriptionDrugs.Tier4.ADOrCopay === 'N/A') {
          values.PrescriptionDrugs.Tier4.ADOrCopay = 'AD';
          values.PrescriptionDrugs.Tier4.PercentOrDollar = '%';
          values.PrescriptionDrugs.Tier4.Value = 101;
        }

        if (values.PrescriptionDrugs.MedicalDeductibleApplies.ADOrCopay === 'Medical Deductible Applies') {
          values.PrescriptionDrugs.MedicalDeductibleApplies.ADOrCopay = 'AD';
          values.PrescriptionDrugs.MedicalDeductibleApplies.PercentOrDollar = '$';
          values.PrescriptionDrugs.MedicalDeductibleApplies.Value = -1;
        }

        if (values.PrescriptionDrugs.MedicalDeductibleApplies.ADOrCopay === 'None') {
          values.PrescriptionDrugs.MedicalDeductibleApplies.ADOrCopay = 'AD';
          values.PrescriptionDrugs.MedicalDeductibleApplies.PercentOrDollar = '$';
          values.PrescriptionDrugs.MedicalDeductibleApplies.Value = -2;
        }

        if (values.PrescriptionDrugs.MedicalDeductibleApplies.ADOrCopay === 'Flat Rate') {
          values.PrescriptionDrugs.MedicalDeductibleApplies.ADOrCopay = 'AD';
          values.PrescriptionDrugs.MedicalDeductibleApplies.PercentOrDollar = '$';
        }

        values.MultiNetworkCategory = 'default';
        values.MultiNetworkID = '';

        if (values.InNetworkPlanDesign.EmbeddedDeductibleOOPM === 'No') {
          values.InNetworkPlanDesign.EmbeddedDeductibleOOPM = false;
        } else {
          values.InNetworkPlanDesign.EmbeddedDeductibleOOPM = true;
        }

        if (values.InNetworkPlanDesign.Coinsurance && values.InNetworkPlanDesign.Coinsurance !== 0) {
          values.InNetworkPlanDesign.Coinsurance = values.InNetworkPlanDesign.Coinsurance / 100.0;
        }

        values.PageID = '00000000-0000-0000-0000-000000000000';

        try {
          let resp = await CustomAxios.post(`/v1/plans`, values);

          await CustomAxios.put(`/v1/benefitspackage/${packageId}/product/${resp.data.ID}`);
        } catch (e) {
          console.log('did not create plan');
        }
      }
    }
    setImporting(false);
    close();
  };

  const handleToggle = (value) => () => {
    const currentIndex = selectedPlans.indexOf(value.ID);
    const newChecked = [...selectedPlans];

    if (currentIndex === -1) {
      newChecked.push(value.ID);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedPlans(newChecked);
  };

  const toggleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedPlans(plans.map(({ ID }) => ID));
    } else {
      setSelectedPlans([]);
    }
  };

  const queryPlans = () => {
    setNoPlans(false);
    setLoading(true);

    let url = `/v2/aca/plans?planType=ichra`;
    if (stateSelection && stateSelection.value) {
      url += `&state=${stateSelection.value}`;
    }
    if (year && monthSelection && monthSelection.value) {
      url += `&effectiveDate=${year}-${monthSelection.value}-01`;
    }

    CustomAxios.get(url)
      .then((response) => {
        let plans = response.data || [];
        if (plans.length === 0) {
          setNoPlans(true);
        }
        plans = orderBy(plans, ['Name'], ['asc']);
        setSelectedPlans(plans.map(({ ID }) => ID));
        setPlans(plans);
        setLoading(false);
      })
      .catch(() => {
        setNoPlans(true);
        setPlans([]);
        setLoading(false);
        setSelectedPlans([]);
      });
  };

  const filteredPlans = useMemo(() => {
    const upperCaseFilter = filter.toUpperCase();
    if (!filter) {
      return plans;
    }
    return plans.filter(
      ({ Name, HiosID }) => HiosID.includes(upperCaseFilter) || Name.toUpperCase().includes(upperCaseFilter)
    );
  }, [filter, plans.length]);

  if (importing) {
    return (
      <div
        className={css`
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 24px;
        `}
      >
        {' '}
        <CircularProgress />
        <div
          className={css`
            margin: 20px;
          `}
        >
          Importing plans. This may take a few minutes.
        </div>
      </div>
    );
  }

  return (
    <div
      className={css`
        padding-bottom: 100px;
      `}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Typography color="primary" style={{ fontWeight: 'bold', fontSize: '24px', margin: '40px 0' }}>
          Import ICHRA
        </Typography>
        <Button color="primary" variant="contained" disabled={selectedPlans.length === 0} onClick={importPlans}>
          Import
        </Button>
      </div>
      <div>
        <Paper elevation={0}>
          <div
            className={css`
              text-align: left;
              font-weight: bold;
            `}
          >
            Search for ICHRA Plans
          </div>
          <div
            className={css`
              display: flex;
              align-items: center;
            `}
          >
            <div
              className={css`
                width: 250px;
              `}
            >
              <AsyncSelect
                placeholder="State"
                cacheOptions
                defaultOptions
                loadOptions={queryStates}
                onChange={(selectedState) => {
                  setStateSelection(selectedState);
                }}
              />
            </div>
            <div
              className={css`
                margin: 16px;
              `}
            >
              <div
                className={css`
                  display: flex;
                `}
              >
                <div
                  className={css`
                    width: 200px;
                    margin-right: 24px;
                  `}
                >
                  <Select
                    placeholder="Month"
                    className={css`
                      line-height: 26px;
                    `}
                    isClearable
                    isSearchable
                    onChange={(month) => {
                      if (!month) {
                        month = {};
                      }
                      setMonthSelection(month);
                    }}
                    options={[
                      { value: '01', label: 'January' },
                      { value: '02', label: 'February' },
                      { value: '03', label: 'March' },
                      { value: '04', label: 'April' },
                      { value: '05', label: 'May' },
                      { value: '06', label: 'June' },
                      { value: '07', label: 'July' },
                      { value: '08', label: 'August' },
                      { value: '09', label: 'September' },
                      { value: '10', label: 'October' },
                      { value: '11', label: 'November' },
                      { value: '12', label: 'December' },
                    ]}
                  />
                </div>

                <div>
                  <Input
                    className={css`
                      background: white;
                      padding: 10px;
                      font-weight: 500;
                    `}
                    placeholder="Year"
                    value={year}
                    onChange={(event) => setYear(event.target.value)}
                  />
                </div>
              </div>
            </div>
            <div
              className={css`
                margin: 16px;
              `}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (stateSelection && stateSelection.value && year && monthSelection && monthSelection.value) {
                    queryPlans();
                  } else {
                    toast.warning('All fields are required to search ICHRA plans.');
                  }
                }}
              >
                Search
              </Button>
            </div>
          </div>

          {loading && (
            <div
              className={css`
                display: flex;
                justify-content: center;
                width: 100%;
                padding: 24px;
              `}
            >
              {' '}
              <CircularProgress />
            </div>
          )}

          {plans && plans.length > 0 && (
            <>
              <div
                className={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-top: 16px;
                `}
              >
                <Input
                  placeholder="Filter by Name or HIOS ID"
                  value={filter}
                  onChange={(event) => setFilter(event.target.value)}
                />
                <div
                  className={css`
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 50%;
                  `}
                >
                  <Typography>Select All</Typography>
                  <Checkbox
                    edge="end"
                    color="primary"
                    checked={selectAll}
                    tabIndex={-1}
                    disableRipple
                    onChange={toggleSelectAll}
                  />
                </div>
              </div>

              <Paper className={classes.listPaper}>
                <div
                  className={css`
                    font-weight: bold;
                  `}
                >
                  All plans are selected for import by default. Unselect plans that you don't want to be included in the
                  import.
                </div>
                {filteredPlans.length ? (
                  <List
                    className={css`
                      min-height: ${ITEM_HEIGHT * pageSize}px;
                    `}
                  >
                    {filteredPlans.slice((page - 1) * pageSize, page * pageSize).map((plan) => {
                      return (
                        <ListItem key={plan.ID} button onClick={handleToggle(plan)}>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              color="primary"
                              checked={selectedPlans.indexOf(plan.ID) !== -1}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText primary={plan.Name} secondary={`HIOS ID: ${plan.HiosID}`} />
                        </ListItem>
                      );
                    })}
                  </List>
                ) : (
                  <div
                    className={css`
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      text-align: center;
                      height: 50px;
                    `}
                  >
                    <Typography variant="body1" color="error">
                      Your filter doesn't match any results.
                    </Typography>
                  </div>
                )}
              </Paper>
              <Paper className={classes.paginationPaper}>
                <Pagination
                  count={
                    filteredPlans.length % pageSize === 0
                      ? filteredPlans.length / pageSize
                      : Math.floor(filteredPlans.length / pageSize) + 1
                  }
                  page={page}
                  onChange={handleChange}
                />
              </Paper>

              <Paper className={classes.listPaperBottom}>
                <React.Fragment>
                  <Text
                    weight="bold"
                    margin={{ bottom: '10px' }}
                    size="16px"
                    className={css`
                      margin-bottom: 15px;
                    `}
                  >
                    HRA Monthly Contributions{' '}
                  </Text>
                  <div
                    className={css`
                      display: flex;
                      align-items: center;
                      margin-bottom: 10px;
                      justify-content: space-between;
                    `}
                  >
                    <Text
                      weight="400"
                      size="14px"
                      className={css`
                        margin-right: 10px;
                      `}
                    >
                      Single{' '}
                    </Text>
                    <div
                      className={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 80px;
                      `}
                    >
                      <div
                        className={css`
                          margin-right: 5px;
                        `}
                      >
                        $
                      </div>
                      <Input
                        type="number"
                        step="any"
                        placeholder="Enter incentive"
                        value={EmployeeOnly}
                        onChange={(e) => setEmployeeOnly(e.target.value)}
                        className={css`
                          height: 40px;
                          font-weight: 400;
                        `}
                      />
                    </div>
                  </div>
                  <div
                    className={css`
                      display: flex;
                      align-items: center;
                      margin-bottom: 10px;
                      justify-content: space-between;
                    `}
                  >
                    <Text
                      weight="400"
                      size="14px"
                      className={css`
                        margin-right: 10px;
                      `}
                    >
                      Employee + Spouse{' '}
                    </Text>
                    <div
                      className={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 80px;
                      `}
                    >
                      <div
                        className={css`
                          margin-right: 5px;
                        `}
                      >
                        $
                      </div>
                      <Input
                        type="number"
                        step="any"
                        placeholder="Enter incentive"
                        value={EmployeeSpouse}
                        onChange={(e) => setEmployeeSpouse(e.target.value)}
                        className={css`
                          height: 40px;
                          font-weight: 400;
                        `}
                      />
                    </div>
                  </div>
                  <div
                    className={css`
                      display: flex;
                      align-items: center;
                      margin-bottom: 10px;
                      justify-content: space-between;
                    `}
                  >
                    <Text
                      weight="400"
                      size="14px"
                      className={css`
                        margin-right: 10px;
                      `}
                    >
                      Employee + Child(ren){' '}
                    </Text>
                    <div
                      className={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 80px;
                      `}
                    >
                      <div
                        className={css`
                          margin-right: 5px;
                        `}
                      >
                        $
                      </div>
                      <Input
                        type="number"
                        step="any"
                        placeholder="Enter incentive"
                        value={EmployeeChildren}
                        onChange={(e) => setEmployeeChildren(e.target.value)}
                        className={css`
                          height: 40px;
                          font-weight: 400;
                        `}
                      />
                    </div>
                  </div>
                  <div
                    className={css`
                      display: flex;
                      align-items: center;
                      margin-bottom: 10px;
                      justify-content: space-between;
                    `}
                  >
                    <Text
                      weight="400"
                      size="14px"
                      className={css`
                        margin-right: 10px;
                      `}
                    >
                      Family{' '}
                    </Text>
                    <div
                      className={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 80px;
                      `}
                    >
                      <div
                        className={css`
                          margin-right: 5px;
                        `}
                      >
                        $
                      </div>
                      <Input
                        type="number"
                        step="any"
                        placeholder="Enter incentive"
                        value={Family}
                        onChange={(e) => setFamily(e.target.value)}
                        className={css`
                          height: 40px;
                          font-weight: 400;
                        `}
                      />
                    </div>
                  </div>
                </React.Fragment>
              </Paper>
            </>
          )}

          {noPlans && <Paper className={classes.listPaper}>No plans available</Paper>}
        </Paper>
      </div>
    </div>
  );
};
