import { useContext } from 'react';
import { useSearchParams } from '../../shared/use-search-params';
import { EditorStoreContext } from './provider';

export const useEditor = () => {
  const value = useContext(EditorStoreContext);

  const { state, dispatch } = value;
  const { params } = useSearchParams();

  // Manually override disableTileDrag when in `collaborating` mode
  const isCommentMode = params?.mode === 'collaborating';
  return {
    state: {
      ...state,
      disableTileDrag: state?.disableTileDrag || isCommentMode,
    },
    dispatch,
  };
};
