import React from 'react';
import { css } from '@emotion/css';
import { ImageBlock } from './image-editor.js';

import { Editor, EditorState, ContentState } from 'draft-js';

const EditorBlock = (props) => {
  const { style, defaultText } = props;
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createWithContent(ContentState.createFromText(defaultText))
  );
  const editor = React.useRef(null);

  return (
    <div
      style={style}
      className={css`
        :hover,
        :focus {
          border-radius: 16px;
          outline: 1px solid #25282d;
        }
      `}
    >
      <Editor
        ref={editor}
        editorState={editorState}
        onChange={(editorState) => {
          setEditorState(editorState);
        }}
        spellCheck={true}
      />
    </div>
  );
};

export const EmailEditor = (props) => {
  const { business, guide, editorID } = props;
  const [ctaText, setCTAText] = React.useState('View Benefits');

  // CTATextEditEvent captures key inputs and checks if the button has too many characters to fit or not.
  const CTATextEditEvent = (e) => {
    if (e.target.value.length >= 18) {
      // Too many characters for button, don't add any more.
      return;
    }
    setCTAText(e.target.value);
  };

  // NOTE: Do not use "className" for styling, since classes won't be exported for the raw html
  // to be sent as an email (unless you specifically want styling that only applies to admin view).
  // Additionally, "flex" and other CSS3 properties are not supported by all email clients.
  // Also be aware that colors should be specified as hex codes (e.g. #FFF), not CSS variables, like var(--gray-700, #25282d) because Outlook will fail to render those.
  return (
    <span id={editorID}>
      <div
        style={{
          outline: '1px solid #C4C4C4',
          ':hover, :focus': { outline: '1px solid #25282D' },
          borderRadius: '16px',
          paddingTop: '32px',
          paddingRight: '32px',
          paddingLeft: '32px',
          paddingBottom: '16px',
          margin: '4px',
          maxWidth: '650px',
        }}
      >
        <div
          id="EmailEditor-upper-image-styling"
          style={{
            textAlign: 'center',
            paddingTop: '24px',
            paddingBottom: '24px',
          }}
        >
          <img src={business.LogoURL} alt="BriteCore Logo" width="25%" height="auto" />
        </div>
        <EditorBlock
          style={{
            color: '#25282D',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: 'Roboto',
            fontSize: '34px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '48px',
            letterSpacing: '0.25px',
            padding: '8px',
            paddingLeft: '16px',
            paddingRight: '16px',
          }}
          defaultText={`Welcome to Open Enrollment`}
        />
        <div
          id="EmailEditor-center-image-styling"
          style={{
            textAlign: 'center',
            paddingTop: '24px',
            paddingBottom: '24px',
            paddingLeft: '16px',
            paddingRight: '16px',
          }}
        >
          {ImageBlock('https://storage.googleapis.com/brite-business-media/default/emailBodyImage.png')}
        </div>
        <EditorBlock
          style={{
            fontSize: '16px',
            lineHeight: '24px',
            fontFamily: 'Roboto',
            letterSpacing: '0.15px',
            color: '#66737F',
            padding: '16px',
            paddingLeft: '16px',
            paddingRight: '16px',
          }}
          defaultText={`Welcome to your benefits experience.

It is Open Enrollment time. You have the opportunity to make changes and updates to your benefits for the upcoming year. Once you make these elections you cannot change them unless you have a qualifying life event. Click the button below to learn about each of these benefits and how they can benefit you and your loved ones.`}
        />
        <div
          style={{
            color: '#E8ECFF',
            paddingTop: '54px',
            textAlign: 'center',
          }}
        >
          <div
            className={css`
              padding: 12px 12px;
              :hover,
              :focus {
                border-radius: 16px;
                outline: 1px solid #25282d;
              }
            `}
          >
            {/* No href on this until the email content is sent to server, so that the button text is editable */}
            <a id="email-editor-cta" target="_blank">
              <input
                id="email-editor-cta-button"
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '24px',
                  letterSpacing: '0.46px',
                  color: '#FFF',
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  padding: '12px 24px',
                  alignItems: 'center',
                  borderRadius: '8px',
                  background: '#25282D',
                  textDecoration: 'none', // Gmail doesn't support text-decoration, but other email clients might
                }}
                value={ctaText}
                onChange={CTATextEditEvent}
                cursor="text"
              />
            </a>
          </div>
        </div>
        <p
          style={{
            paddingTop: '0px',
            textAlign: 'center',
            justifyContent: 'center',
            color: '#66737F',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.15px',
            cursor: 'default',
          }}
        >
          <b>Link</b>: {'https://britehr.app/' + guide.VanityURL}
        </p>
      </div>
    </span>
  );
};
