import React, { useState, useEffect, useContext } from 'react';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import { useLogin } from './use-login';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, auth0Props }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [tokenGlobal, setToken] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [showBusinessPopup, setShowBusinessPopup] = useState(true);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [showWalkthrough, setShowWalkthrough] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  const { handleLogin } = useLogin();

  useEffect(() => {
    if (isAuthenticated) {
      window?.ChurnZero?.push(['trackEvent', 'Login', '', 1]);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(auth0Props);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        if ('targetUrl' in appState) {
          // We need to replace any '//'s in the path, so it doesn't try to reroute to http://{path}...etc
          appState.targetUrl = appState.targetUrl && appState.targetUrl.replace(/\/\//g, '/');
        }
        onRedirectCallback(appState);
      }
      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);
      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
        try {
          const token = await auth0FromHook.getTokenSilently();
          setToken(token);
          await handleLogin({
            accessToken: token,
            email: user.email,
            setLoading,
            setShowBusinessPopup,
            setShowTermsPopup,
            auth0FromHook,
            setShowWalkthrough,
          });
          setLoading(false);
        } catch (err) {
          auth0FromHook.loginWithRedirect({
            redirect_uri: window.location.origin,
            appState: { targetUrl: `/dashboard` },
            authorizationParams: {
              prompt: 'login',
            },
          });
        }
      } else {
        setShowBusinessPopup(false);
        setLoading(false);
      }
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        tokenGlobal,
        loading,
        showBusinessPopup,
        setShowBusinessPopup,
        showTermsPopup,
        setShowTermsPopup,
        setShowWalkthrough,
        showWalkthrough,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: async (...p) => {
          window?.ChurnZero?.push(['trackEvent', 'Logout']);
          window?.ChurnZero?.push(['stop']);
          await auth0Client.logout(...p);
          window.location.reload();
        },
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
