import { useKnockClient, useNotificationStore, useNotifications } from '@knocklabs/react';
import { useEffect } from 'react';
import { useStateSync } from '../shared/use-state-sync';
import { CustomAxios } from '../redux/axios/axios';
import { toast } from 'react-toastify';
import { useBasicReducer } from '../shared/use-basic-reducer';

const KNOCK_FEED_ID = 'df9ea5bc-8179-4d73-b9f2-e92115297399';

export const useKnock = (filter = {}) => {
  const client = useKnockClient();
  const feed = useNotifications(client, KNOCK_FEED_ID);
  const store = useNotificationStore(feed);

  const reducer = useBasicReducer({ preferences: {} });
  const [state, actions] = reducer;

  async function fetchPreferences() {
    try {
      const preferences = await client.user.getPreferences();
      actions.set('preferences', preferences);
    } catch (err) {
      console.error('error getting user notification preferences', err);
    }
  }

  useEffect(() => {
    fetchPreferences();
  }, [client]);

  useEffect(() => {
    feed.fetch();
  }, [feed, window.location.pathname]);

  const [updatedAt] = useStateSync(new Date().toISOString(), [store?.items?.length, feed, client]);

  return {
    updatedAt,
    sdk: {
      client,
      feed,
      store,
    },
    reducer: {
      state,
      actions,
    },
  };
};

export const knockUtils = {
  markItemAsRead: (knock, item) => {
    knock.sdk.feed.markAsRead(item);
  },
  archiveItem: (knock, item) => {
    knock.sdk.feed.markAsArchived(item);
  },
  markAllAsRead: (knock) => {
    knock.sdk.feed.markAsRead(knock?.sdk?.store?.items);
  },
  archiveAll: (knock) => {
    knock.sdk.feed.markAsArchived(knock?.sdk?.store?.items);
  },
  toggleAllCategoriesByChannelType: async (knock, channelType, value) => {
    let preferenceUpdate = { ...knock?.reducer?.state?.preferences };
    const categoryKeys = Object.keys(preferenceUpdate?.categories || {});
    for (const key of categoryKeys) {
      preferenceUpdate.categories[key].channel_types[channelType] = value;
    }
    try {
      knock?.reducer?.actions.set('preferences', preferenceUpdate);
      const preferences = await knock?.sdk?.client?.user?.setPreferences(preferenceUpdate);
      knock?.reducer?.actions.set('preferences', preferences);
    } catch (err) {
      console.log(err);
    }
  },
  unsubscribeFromItem: (knock, item) => {
    try {
      const deleteUrl = `/v1/notifications/${item?.data?.resource_type}/${item?.data?.resource_id}/subscriptions/${knock?.sdk?.client?.userId}`;
      CustomAxios.delete(deleteUrl);
      toast.success('Successfully unsubscribed from notification');
    } catch (err) {
      toast.error('Failed to unsubscribe from notification');
      console.log(err);
      return;
    }
  },
};
