import { css, cx } from '@emotion/css';
import { useState } from 'react';
import { Div } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { useDebounce } from '../../shared/use-debounce';
import { useEvent } from '../../shared/use-event';
import { ComponentIconMap } from '../toolbar-menus';

const tileContainer = ({ x, y, drag }) => css`
  position: fixed;
  top: ${y}px;
  left: ${x - 24}px;
  width: 52px;
  height: 52px;
  z-index: 1000;
  border-radius: 50%;
  filter: drop-shadow(4px 16px 6px ${colors.purple}2);
  pointer-events: none;
  background-color: ${colors.gray[600]};
`;

export const Tile = ({ drag }) => {
  const [coordinates, setCoordinates] = useState([0, 0]);
  const [x, y] = coordinates;

  const delayed_setCoordinates = useDebounce(setCoordinates, 0);

  const handleMouseMovement = (event) => {
    if (drag?.isDragging) {
      delayed_setCoordinates([event.pageX, event.pageY]);
    }
  };

  const handleMouseDown = (event) => {
    setCoordinates([event.pageX, event.pageY]);
  };

  useEvent('mousemove', handleMouseMovement, { capture: true });
  useEvent('mousedown', handleMouseDown, { capture: true });

  return (
    <Div
      css={cx(
        css`
          ${flex('jcc aic')}
        `,
        tileContainer({ drag, x, y })
      )}
    >
      <ComponentIconMap type={drag?.type} size={32} color="white" />
    </Div>
  );
};
