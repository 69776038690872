import React from 'react';
import { css } from '@emotion/css';

export const Page = (props) => (
  <div
    className={css`
      overflow: auto;
      position: relative;
    `}
  >
    {props.children}
  </div>
);
