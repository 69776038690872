import { getGenericQueryProps, getUrl } from '.';

const url = 'v2/business/:businessId';

export const businessData = {
  url,
  key: 'business',
  getResourceOptions: (resourceProps) => {
    const resourceUrl = getUrl(url, resourceProps);
    return {
      url: resourceUrl,
      queryProps: {
        initialData: [],
        ...getGenericQueryProps(resourceUrl, resourceProps),
      },
    };
  },
};
