import { css } from '@emotion/css';
import { Div, Text, Slider } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { useDebounce } from '../../shared/use-debounce';
import { useStateSync } from '../../shared/use-state-sync';
import { modifyContent } from '../provider/utils';
import { useEditorResource } from '../use-editor-resource';
import { ColorPicker } from './menu-components';

export const SpaceToolbar = ({ editor, element, location }) => {
  const { data: styles } = useEditorResource('styles');

  const styleObject = { ...styles?.space, ...element?.attributes?.style };
  const rawHeight = element?.attributes?.style?.minHeight || '';

  const [height, setHeight] = useStateSync(Number(rawHeight.replace(/\D/g, '') || 0), [rawHeight]);

  const updateHeight = useDebounce((value) => {
    const minHeight = `${Math.max(8, Number(value))}px`;
    modifyContent.merge(editor, location, {
      attributes: {
        style: {
          height: '100%',
          minHeight,
        },
      },
    });
  }, 25);

  const changeSpaceHeight = (value) => {
    setHeight(value);
    updateHeight(value);
  };

  return (
    <Div
      css={css`
        ${flex('jcl aic')}
      `}
    >
      <Text styles="label">Height</Text>
      <Slider
        aria-label="Width"
        min={8}
        max={300}
        value={height || 0}
        style={{ width: '140px' }}
        valueLabelDisplay="auto"
        onChange={(e, value) => changeSpaceHeight(value)}
      />

      <ColorPicker
        item={element}
        title="Background Color"
        updateColor={(backgroundColor) =>
          modifyContent.merge(editor, location, {
            attributes: { style: { backgroundColor } },
          })
        }
        currentColor={styleObject?.backgroundColor}
      />
    </Div>
  );
};
