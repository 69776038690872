import { css } from '@emotion/css';
import { container, shadows } from '../../../../shared/shared-styles';
import { colors } from '../../../../shared/styles';

const linkStyle = css`
  display: inline;
  padding: 0 4px;
  border-radius: 8px;
  :hover {
    ${shadows.lg}
    text-decoration: underline;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
    z-index: -1;
    ${container.box}
    color: ${colors.purple};
    padding: 0 4px;
  }
`;

export const Link = ({ children }) => {
  return <a className={linkStyle}>{children}</a>;
};
