import { useResource, useRequests } from '../react-query';
import { guides } from '../react-query/resources/guides';
import { toast } from 'react-toastify';
import { CustomAxios } from '../redux/axios/axios';
import { ConfirmationModal } from '../shared/components';

export const RemoveFolderModal = ({ display, hideRemoveFolder, folder, fetchFolders }) => {
  const guidesResource = useResource(guides, { staleTime: 5 * 60 * 1000 });
  const guidesRequests = useRequests(guidesResource);

  const { data: courses } = guidesResource?.query;

  const removeFolder = async () => {
    try {
      const folderedCourses = courses.filter(({ CourseFolderID }) => CourseFolderID === folder?.ID);

      await Promise.allSettled(
        folderedCourses.map((guide) => {
          const guidesPut = guides.utils.getPut(guide?.ID, {
            ...guide,
            CourseFolderID: null,
          });
          return guidesRequests.put({
            ...guidesPut,
            optimistic: false,
            refetch: false,
          });
        })
      );
      guidesResource.query.refetch();
      await CustomAxios.delete(`/v1/course/folder/${folder.ID}`);
      fetchFolders();
      hideRemoveFolder();
    } catch (err) {
      hideRemoveFolder();
      toast.error(`Error removing guide.`);
    }
  };

  return (
    <ConfirmationModal
      display={display}
      title="Remove Folder"
      subtitle="Only the folder will be removed. Any guide inside will be moved to the all guides."
      onConfirm={() => removeFolder()}
      onClose={() => hideRemoveFolder()}
      isDeleteAlert
      confirmText="Delete"
    />
  );
};
