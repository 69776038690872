import { css } from '@emotion/css';
import { useEffect } from 'react';
import { Div } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { useDebounceValue } from '../../shared/use-debounce-value';
import { useStateSync } from '../../shared/use-state-sync';
import { modifyContent } from '../provider/utils';
import { SmartFieldInput } from '../utility-components/smart-field-input';

export const getVideoUrl = (value) => {
  try {
    const url = new URL(value);
    if (url.hostname === 'www.youtube.com') {
      if (value.includes('/embed/')) {
        return value;
      } else if (url.pathname === '/watch') {
        const v = url.searchParams.get('v');
        if (!v) {
          return '';
        }
        return `${url.origin}/embed/${v}`;
      } else if (url.pathname.includes('/embed')) {
        return value;
      } else {
        return `${url.origin}/embed${url.pathname}`;
      }
    } else if (url.hostname === 'youtu.be') {
      return `${url.protocol}//www.youtube.com/embed${url.pathname}`;
    } else if (url.hostname === 'vimeo.com') {
      if (!url.pathname.startsWith('/video')) {
        let { pathname } = url;
        const [, id1, id2] = pathname.split('/');
        if (id2) {
          pathname = `/${id1}?h=${id2}`;
        }
        return `${url.protocol}//player.vimeo.com/video${pathname}`;
      }
    } else if (url.hostname === 'www.loom.com') {
      const nextUrl = url.href.replace('share', 'embed');
      return nextUrl;
    } else if (url.hostname === 'drive.google.com') {
      // if they copy paste the wrong URL from google drive, let's try to help them out and get the right one
      return url.href.replace('/view', '/preview').replace('/edit', '/preview');
    }
    return value;
  } catch (err) {
    console.log(err);
    return '';
  }
};

export const VideoToolbar = ({ editor, element, location }) => {
  const onUpdate = (updates) => modifyContent.merge(editor, location, updates);
  const [videoUrl, setVideoUrl, isVideoUrlSynced] = useStateSync(element?.videoUrl, [element?.videoUrl]);
  const [debounce_value] = useDebounceValue(videoUrl, 500);

  const handleSmartField = ({ data }) => {
    onUpdate({
      attributes: { src: data?.value },
      videoUrl: data?.name,
    });
  };

  const removeSmartField = () => {
    onUpdate({
      attributes: { src: '' },
      videoUrl: '',
    });
  };

  const handleVideoUrl = (e) => {
    const src = getVideoUrl(videoUrl);
    onUpdate({ attributes: { src }, videoUrl });
  };

  useEffect(() => {
    if (!isVideoUrlSynced) {
      handleVideoUrl();
    }
  }, [debounce_value]);

  return (
    <Div
      css={css`
        ${flex('left')}
        p {
          min-width: max-content;
          margin-right: 16px;
        }
      `}
    >
      <SmartFieldInput
        css={`
          min-width: 350px;
          height: 40px;
        `}
        nameFilter="URL"
        value={element?.attributes?.src}
        smartFieldDisplay={videoUrl || ''}
        handleSmartField={handleSmartField}
        removeSmartField={removeSmartField}
        inputProps={{
          value: videoUrl,
          placeholder: 'Video URL',
          onChange: (e) => setVideoUrl(e.target.value),
        }}
      />
    </Div>
  );
};
