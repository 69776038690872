import Fuse from 'fuse.js';
import { useMemo } from 'react';

export const useFuse = (list, config, deps = []) => {
  if (!list) {
    list = [];
  }

  const fuse = useMemo(() => {
    return new Fuse(list, config);
  }, [...deps]);

  const search = (value) => {
    return fuse?.search(value) || [];
  };

  return { search };
};
