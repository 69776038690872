import { css } from '@emotion/css';
import { useState } from 'react';
import { PRODUCT_HEADERS } from '../benefit-package/constants';
import { Button, Div, Loader, Modal, Text } from '../shared/components';
import { flex } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { useSearchParams } from '../shared/use-search-params';
import { productUtils } from './configs/product-utils';
import { useProductData } from './configs/use-product-data';
import { ProductForm } from './product-form';

export const ConnectedProduct = ({ productId, details }) => {
  const { params, removeParam } = useSearchParams();
  const state = useProductData({ productId, ...details });

  const [isSaving, setIsSaving] = useState(false);

  const { product, hasChanges } = state;

  const handleSave = async () => {
    try {
      setIsSaving(true);
      await productUtils.saveProduct(state);
      removeParam('connectedProductId');
    } catch (err) {
      console.log(err);
    } finally {
      setIsSaving(false);
    }
  };

  const isAdding = params?.isAddingConnectedProduct;

  return (
    <Modal full display={true} onClose={() => removeParam('connectedProductId')}>
      <Div
        css={css`
          ${flex('space-between')}
          width: 100vw;
          height: 100px;
          padding: 32px;
        `}
      >
        <Text h1>{isAdding ? 'Add' : 'Edit'} Benefit</Text>
        <Div
          css={css`
            ${flex('right')}
          `}
        >
          <Button
            css={`
              margin-right: 16px;
            `}
            secondary
            onClick={() => removeParam('connectedProductId')}
          >
            Cancel
          </Button>
          <Button
            disabled={hasChanges || isSaving}
            onClick={handleSave}
            css={`
              position: relative;
            `}
          >
            <Loader
              type="three-dots"
              isLoading={isSaving}
              className={css`
                background-color: ${colors.gray[300]};
                ${flex('center start')}
              `}
            />
            {isAdding ? 'Add Benefit' : 'Save Benefit'}
          </Button>
        </Div>
      </Div>
      <Div
        css={css`
          width: 100vw;
          height: 48px;
          background-color: ${colors.black};
          ${flex('center')}
          p {
            color: white;
          }
        `}
      >
        <Text>You are adding a new {PRODUCT_HEADERS[product?.Type]}</Text>
      </Div>
      <Div
        css={css`
          ${flex('center start')}
          width: 100vw;
          height: calc(100vh - 148px);
          overflow-y: auto;
        `}
      >
        <ProductForm state={state} />
      </Div>
    </Modal>
  );
};
