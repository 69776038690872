import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import { format } from 'date-fns';
import { ConnectionsContainer } from './Connections';
import { toast } from 'react-toastify';
import { Importer, ImporterField } from 'react-csv-importer';
import 'draft-js/dist/Draft.css';
import { EmailEditor } from './Editor/text-editor';
import { Handout } from './Handout';

import { useLocation } from 'react-router-dom';
import SuccessText from '../Courses/success-text.svg';
import { FormatPhoneNumber } from '../lib/communications/formatting';

import {
  Button,
  IconButton,
  Tooltip,
  MenuItem,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  Select,
  CircularProgress,
  FormControl,
  Checkbox,
} from '@material-ui/core';
import {
  WarningCircle,
  Lightning,
  X,
  QrCode,
  LinkSimple,
  HandWaving,
  ArrowLeft,
  LinkBreak,
  Info,
  Share,
  UploadSimple,
} from 'phosphor-react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MessagingIcon from './messageIcon.png';
import EmailIcon from './emailIcon.png';
import MessagingIconDisabled from './messageIcon-modified.png';
import { TextingHistory } from '../Courses/TextingHistory';
import { fetchCourses } from '../Courses/course-requests';

import { useStore } from '../store-provider/use-store';
import Slack from './slack-icon-no-words.png';
import SlackDisabled from './slack-disabled.png';
import Teams from './microsoft-teams-logo.png';
import TeamsDisabled from './microsoft-teams-logo-modified.png';
import HandoutImage from './handout-icon.png';

import { useQueryAPI } from '../react-query';
import QRCode from 'react-qr-code';
import { Copy, DropMenu, Modal, Text } from '../shared/components';
import { CustomAxios } from '../redux/axios/axios';
import { BriteSearchableMultiSelect } from '../Courses/Courses';
import PurpleCheck from './purple-checkr.png';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { Box } from '../shared/components/box';
import { useModal } from '../common/hooks/useModal';
import { EnableTextingModal } from './EnableTextingModal';
import { colors } from '../shared/styles';
import { scrollbar } from '../shared/shared-styles';

const SLACK_INDIVIDUALS_TAB = 'Individuals';
const SLACK_CHANNELS_TAB = 'Channels';
const SLACK_SEND_METHOD_DM = 'Direct Message';
const SLACK_SEND_METHOD_POST = 'Post to channels';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function isValidEmail(email) {
  return /^\S+@\S+$/.test(email);
}

const phoneNumberErrorCountryCode =
  "Local number appears invalid. International numbers require '+' before the country code.";
const phoneNumberErrorGeneric =
  'Number appears invalid. May not be textable due to incorrect digits or because it is a landline.';
const textMessageMaxLength = 450;

export const Messaging = () => {
  const enableTextingModal = useModal();

  const [showIntegrations, setShowIntegrations] = useState(false);
  const [hideBanner, setHideBanner] = useState(false);
  const [showSendGuide, setShowSendGuide] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const canEmailMessage = useFeatureFlagPayload('email-messaging');
  const canTextMessage = useFeatureFlagPayload('text-messaging-new');

  let query = useQuery();
  let queryCourseId = query.get('courseId');

  const [showCourseSendSuccess, setShowCourseSendSuccess] = useState(false);
  const [showTeamsModal, setShowTeamsModal] = React.useState(false);
  const [showMessageTemplates, setShowMessageTemplates] = useState(false);
  const [showCopyGuideLink, setShowCopyGuideLink] = useState(false);
  const [showSlackMessage, setShowSlackMessage] = useState(false);
  const [showTextMessage, setShowTextMessage] = useState(false);
  const [showEmailMessage, setShowEmailMessage] = useState(false);
  const [showTeamsMessage, setShowTeamsMessage] = useState(false);
  const [showSelectGuide, setShowSelectGuide] = useState(false);
  const [guideSearchText, setGuideSearchText] = useState('');
  const [showSetVanityURL, setShowSetVanityURL] = useState(false);
  const [vanityUrlLoading, setVanityUrlLoading] = useState(false);
  const [vanityUrlTaken, setVanityUrlTaken] = useState(false);

  const [textingErrors, setTextingErrors] = useState([]);

  const [textAgree, setTextAgree] = useState(false);
  const [emailAgree, setEmailAgree] = useState(false);
  const [textAgreeAgain, setTextAgreeAgain] = useState(false);
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('');
  const [currentEmailAddress, setCurrentEmailAddress] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [emailSenderName, setEmailSenderName] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState(new Set());
  const [badPhoneNumbersFromUpload, setBadPhoneNumbersFromUpload] = useState([]);
  const [badEmailsFromUpload, setBadEmailsFromUpload] = useState([]);
  const [importedNumRows, setImportedNumRows] = useState(0);
  const [numImportedEmailRows, setNumImportedEmailRows] = useState(0);
  const [sentToPhoneNumbers, setSentToPhoneNumbers] = useState([]);
  const [emailAddresses, setEmailAddresses] = useState(new Set());
  const [showBriteGuideHandout, setShowBriteGuideHandout] = useState(false);

  const { data: courses, refetch } = useQueryAPI({
    urlKey: 'v1/courses',
    queryFn: fetchCourses,
    defaultValue: [],
  });

  useEffect(() => {
    if (queryCourseId) {
      for (let course of courses) {
        if (course.ID === queryCourseId) {
          setSelectedCourse(course);
          if (course.VanityURL) {
            setShowSendGuide(true);
          } else {
            setShowSetVanityURL(true);
          }
        }
      }
    }
  }, [queryCourseId, courses]);

  const {
    data: { kolla, selectedBusiness },
  } = useStore();

  const { Name: employeeName } = selectedBusiness;

  const [messageBody, setMessageBody] = useState(
    `${employeeName} employees! Check out the best benefit options for this upcoming year, click the link we’ve provided for you below!`
  );

  const [showImportPhoneNumbers, setShowImportPhoneNumbers] = useState(false);
  const [showImportEmailAddresses, setShowImportEmailAddresses] = useState(false);
  const [newVanityURL, setNewVanityURL] = useState('');
  const [vanityURLErrorMsg, setVanityURLErrorMsg] = useState('');

  // Slack stuff
  const [slackTab, setSlackTab] = useState(SLACK_INDIVIDUALS_TAB);
  const [slackSendMethod, setSlackSendMethod] = useState(SLACK_SEND_METHOD_POST);
  const [allSlackUsersSelected, setAllSlackUsersSelected] = useState(false);
  const [slackContactsToMessage, setSlackContactsToMessage] = useState(new Set());
  const [slackChannelMembers, setSlackChannelMembers] = useState(new Set());
  const [slackUsers, setSlackUsers] = useState([]);
  const [slackUserMap, setSlackUserMap] = useState(new Map());
  const [slackChannels, setSlackChannels] = useState({});
  const [slackChannelsToMessage, setSlackChannelsToMessage] = useState(new Set());
  const [slackUsersPlusAll, setSlackUsersPlusAll] = useState([]);
  const [slackLoadingChannelMembers, setSlackLoadingChannelMembers] = useState([]);
  const [slackChannelMembersMap, setSlackChannelMembersMap] = useState(new Map());

  const slackEnabled = kolla?.slack;
  const [fetchSlackEndpoints, setFetchSlackEndpoints] = useState(false);

  // teams stuff

  const [teamsChannels, setTeamsChannels] = useState({});
  const [teamsUsers, setTeamsUsers] = useState([]);

  const [teamsUsersPlusAll, setTeamsUsersPlusAll] = useState([]);

  const [allTeamsUsersSelected, setAllTeamsUsersSelected] = useState(false);
  const [teamsContactsToMessage, setTeamsContactsToMessage] = useState(new Set());
  const [teamsChannelsToMessage, setTeamsChannelsToMessage] = useState(new Set());
  const [teamsTeamOptions, setTeamsTeamOptions] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState({});

  const teamsEnabled = kolla ? kolla['microsoft-teams-47756'] : false;

  const [fetchTeamsEndpoints, setFetchTeamsEndpoints] = useState(false);
  const [loadingTeams, setLoadingTeams] = useState(false);

  useEffect(() => {
    if (slackEnabled) {
      setFetchSlackEndpoints(true);
    }
  }, [slackEnabled]);

  useEffect(() => {
    if (teamsEnabled) {
      setFetchTeamsEndpoints(true);
    }
  }, [teamsEnabled]);

  useQueryAPI(
    {
      url: `v1/slack/user`,
      enabled: fetchSlackEndpoints,
      select: (data) => (data?.Users?.length ? data?.Users : []),
      defaultValue: [],
      onMount: async (users) => {
        users.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        setSlackUsers(users);
        setSlackUserMap(users.reduce((u, user) => u.set(user.id, user), new Map()));
        setSlackUsersPlusAll([{ id: 'all', name: 'Select All' }, ...users]);
        // Reset the selected users
        setSlackContactsToMessage(new Set());
        setAllSlackUsersSelected(false);
      },
    },
    [fetchSlackEndpoints]
  );

  useQueryAPI(
    {
      url: `v1/slack/channel`,
      enabled: fetchSlackEndpoints,
      select: (data) => (data?.Channels?.length ? data?.Channels : []),
      defaultValue: [],
      onMount: async (channels) => {
        // Order by channel name
        channels.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        setSlackChannels(channels);
        // Reset the selected channels
        setSlackChannelsToMessage(new Set());
      },
    },
    [fetchSlackEndpoints]
  );

  const setUpVanityUrl = async () => {
    try {
      setVanityUrlLoading(true);
      await CustomAxios.put(`/v1/course/${selectedCourse.ID}/vanityurl`, {
        URL: newVanityURL,
      });
      refetch();
      setVanityUrlLoading(false);

      setVanityUrlTaken(false);
      setShowSetVanityURL(false);
      setSelectedCourse((course) => {
        course.VanityURL = newVanityURL;
        return course;
      });
      setShowSendGuide(true);
    } catch (e) {
      setVanityUrlLoading(false);
      setVanityUrlTaken(true);
    }
  };

  const fetchTeamsData = async () => {
    try {
      setLoadingTeams(true);
      let channelResponse = await CustomAxios.get(`v1/teams/${selectedTeam.teamId}/channel`);
      let channels = channelResponse.data;

      channels.sort((a, b) => (a.displayName > b.displayName ? 1 : b.displayName > a.displayName ? -1 : 0));
      setTeamsChannels(channels);
      // Reset the selected channels
      setSlackChannelsToMessage(new Set());

      let usersResponse = await CustomAxios.get(`v1/teams/${selectedTeam.teamId}/user`);
      let users = usersResponse.data;

      users.sort((a, b) => (a.displayName > b.displayName ? 1 : b.displayName > a.displayName ? -1 : 0));
      setTeamsUsers(users);

      setTeamsUsersPlusAll([{ id: 'all', displayName: 'Select All' }, ...users]);
      // Reset the selected users
      setTeamsContactsToMessage(new Set());
      setAllTeamsUsersSelected(false);
      setLoadingTeams(false);
    } catch (e) {
      console.log(e);
      setLoadingTeams(false);
    }
  };

  useEffect(() => {
    if (selectedTeam && selectedTeam.displayName) {
      fetchTeamsData();
    }
  }, [selectedTeam]);

  useQueryAPI(
    {
      url: `v1/teams`,
      enabled: fetchTeamsEndpoints,
      select: (data) => (data?.length ? data : []),
      defaultValue: [],
      onMount: async (teamOptions) => {
        teamOptions.sort((a, b) =>
          a.displayName.toLowerCase() > b.displayName.toLowerCase()
            ? 1
            : b.displayName.toLowerCase() > a.displayName.toLowerCase()
            ? -1
            : 0
        );

        setTeamsTeamOptions(teamOptions);
        if (teamOptions.length > 0) {
          setSelectedTeam(teamOptions[0]);
        }
      },
    },
    [fetchTeamsEndpoints]
  );

  const cleanUpSlack = () => {
    setSlackContactsToMessage(new Set());
    setTeamsContactsToMessage(new Set());
    setSlackTab(SLACK_INDIVIDUALS_TAB);
    setSlackSendMethod(SLACK_SEND_METHOD_POST);
    setSlackChannelsToMessage(new Set());
    setTeamsChannelsToMessage(new Set());
    setAllSlackUsersSelected(false);
    setAllTeamsUsersSelected(false);
    setSlackLoadingChannelMembers(false);
  };

  const cleanUpState = () => {
    setLoading(false);
    setShowSendGuide(false);

    cleanUpSlack();
  };

  const sendTeamsMessage = async (channels, body, cId) => {
    if (messageBody.length > 4000) {
      toast.error(`Message body has too many characters.`);
      return;
    }

    const allIds = Array.from(channels).map((c) => c.userId);

    let ids = [...new Set(allIds)];

    setLoading(true);

    const data = {
      UserIDs: ids,
      Message: body,
      CourseID: cId,
    };
    try {
      setShowTeamsMessage(false);
      await CustomAxios.post(`v1/teams/message`, data);
      cleanUpState();
      setShowCourseSendSuccess(true);
      window?.ChurnZero?.push(['trackEvent', 'Guide Sent', '', 1]);
    } catch (err) {
      toast.error(`Failed to send teams message.`);
      console.log(err);
      cleanUpState();
    }
  };
  useEffect(() => {
    if (employeeName) {
      setMessageBody(
        `${employeeName} employees! Check out the best benefit options for this upcoming year, click the link we’ve provided for you below!`
      );
    }
  }, [employeeName]);

  const onImageDownload = () => {
    const svg = document.getElementById('QRCode');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'QRCode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const sendSlackMessage = async (channels, body, cId) => {
    if (messageBody.length > 4000) {
      toast.error(`Message body has too many characters.`);
      return;
    }

    const ids = Array.from(channels).map((c) => c.id);

    setLoading(true);

    const data = {
      ChannelIDs: ids,
      Message: body,
      CourseID: cId,
    };
    try {
      await CustomAxios.post(`v1/slack/message`, data);
      cleanUpState();
      setShowCourseSendSuccess(true);
      window?.ChurnZero?.push(['trackEvent', 'Guide Sent', '', 1]);
    } catch (err) {
      toast.error(`Failed to send slack message.`);
      console.log(err);
      cleanUpState();
    }
  };

  const sendTextMessage = async () => {
    if (messageBody.length > textMessageMaxLength) {
      toast.error(`Message body has too many characters.`);
      return;
    }
    setLoading(true);

    let nums = [...phoneNumbers].map((n) => ({ PhoneNumber: n }));
    if (currentPhoneNumber) {
      nums.push({ PhoneNumber: currentPhoneNumber });
    }

    try {
      if (nums.length > 0) {
        let body = {
          Body: messageBody,
          Recipients: nums,
          CourseID: selectedCourse.ID,
        };

        let response = await CustomAxios.post(`/v1/sms/send`, body);
        if (response.data.SubmissionErrors && response.data.SubmissionErrors.length > 0) {
          setTextingErrors(response.data.SubmissionErrors);
        } else {
          setTextingErrors([]);
        }
      }

      setLoading(false);
      setSentToPhoneNumbers([...phoneNumbers]);
      setPhoneNumbers(new Set());
      setCurrentPhoneNumber('');

      setShowSendGuide(false);

      // show success
      setShowCourseSendSuccess(true);
      window?.ChurnZero?.push(['trackEvent', 'Guide Sent', '', 1]);
    } catch (e) {
      setLoading(false);
      toast.error(`Failed to send text message`);
      console.log(e);
    }
  };

  const sendEmailMessage = async (bodyHTMLString) => {
    if (bodyHTMLString?.length > 150000) {
      toast.error(`Email is too large. Please reduce the contents.`);
      return;
    }
    setLoading(true);

    if (currentEmailAddress) {
      emailAddresses.add(currentEmailAddress);
    }
    let emails = [...emailAddresses];
    try {
      if (emails.length > 0) {
        let body = {
          emailBody: bodyHTMLString,
          emailAddresses: emails,
          guideID: selectedCourse.ID,
          emailSubject: emailSubject,
          fromName: emailSenderName,
        };
        await CustomAxios.post(`/v1/email/sendGuide`, body);
      }

      setLoading(false);

      setNumImportedEmailRows(0);
      setEmailAddresses(new Set());
      setBadEmailsFromUpload([]);
      setCurrentEmailAddress('');
      setEmailSenderName('');
      setEmailSubject('');
      setShowSendGuide(false);

      window?.ChurnZero?.push(['trackEvent', 'Guide Sent', '', 1]);

      //show success
      setShowCourseSendSuccess(true);
    } catch (e) {
      setLoading(false);
      toast.error(`Failed to send email`);
    }
  };

  return (
    <Box page>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Text h1>Messaging</Text>
        <div
          className={css`
            display: flex;
            align-items: center;
            gap: 16px;
          `}
        >
          <Tooltip title="Integrations">
            <IconButton
              onClick={() => {
                setShowIntegrations(true);
              }}
            >
              <Lightning color="#25282d" />
            </IconButton>
          </Tooltip>

          <Button color="primary" variant="contained" onClick={() => setShowSelectGuide(true)}>
            Send Guide
          </Button>
        </div>
      </div>

      {!canTextMessage?.value && (
        <>
          <div
            className={css`
              margin: 0 auto;
              max-width: 700px;
              margin-top: 40px;
            `}
          >
            <div
              className={css`
                background: #6d44fb33;

                border-radius: 16px;
                padding: 16px;
                text-align: center;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                /* identical to box height, or 133% */

                letter-spacing: 0.15px;

                color: #6d44fb;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 16px;
              `}
            >
              <WarningCircle color="#6d44fb" />
              Looks like you haven’t turned text messaging on yet
            </div>

            <div
              className={css`
                margin-top: 32px;

                border: 1px solid #d1dae3;
                border-radius: 8px;
                padding: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
              `}
            >
              <div
                className={css`
                  margin-bottom: 16px;
                `}
              >
                <img src={MessagingIcon} width="56px" height="56px" alt="Messaging" />
              </div>
              <div
                className={css`
                  font-family: 'Roboto';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 24px;
                  line-height: 32px;
                  /* or 133% */

                  text-align: center;

                  color: #25282d;
                `}
              >
                With Brite text messaging you can{' '}
                <span
                  className={css`
                    position: relative;
                    &::before {
                      content: '';
                      position: absolute;
                      top: 100%;
                      width: 100%;
                      left: 0;
                      height: 5px;
                      border-radius: 2px;
                      background: linear-gradient(270deg, #62a9e5 0%, #c449f5 50%, #c05194 100%);
                    }
                  `}
                >
                  double
                </span>{' '}
                your employees benefit engagement
              </div>
              <div
                className={css`
                  font-family: 'Roboto';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  /* identical to box height, or 150% */

                  text-align: center;
                  letter-spacing: 0.15px;

                  color: #66737f;
                  margin-top: 16px;
                  margin-bottom: 16px;
                `}
              >
                Brite messaging allows you to text your employee's direct links to your Guides.
              </div>

              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  enableTextingModal.toggle();
                }}
              >
                Turn On Texting
              </Button>
            </div>
          </div>
        </>
      )}

      <div
        className={css`
          margin: 0 auto;
          width: 1000px;
        `}
      >
        <ConnectionsContainer
          canTextMessage={canTextMessage?.value}
          showTeamsModal={showTeamsModal}
          setShowTeamsModal={setShowTeamsModal}
          showConnectionsModal={showIntegrations}
          closeShowConnectionsModal={() => setShowIntegrations(false)}
        />
      </div>

      {canTextMessage?.value && (
        <>
          {!hideBanner && !slackEnabled && !teamsEnabled && (
            <div
              className={css`
                margin-top: 24px;
                background: #6d44fb33;
                margin-left: 14px;
                margin-right: 14px;
                border-radius: 16px;
                padding: 24px;

                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <div
                className={css`
                  display: flex;
                  align-items: center;
                  gap: 16px;
                `}
              >
                <Lightning color="#6d44fb" />
                <div
                  className={css`
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    /* identical to box height, or 150% */

                    letter-spacing: 0.15px;

                    color: #25282d;
                  `}
                >
                  Engage with more employees by integrating Slack or Microsoft Teams.
                </div>
              </div>
              <div
                className={css`
                  display: flex;
                  align-items: center;
                  gap: 40px;
                `}
              >
                <Button
                  onClick={() => {
                    setShowIntegrations(true);
                  }}
                  variant="contained"
                  style={{
                    backgroundColor: '#6D44FB',
                    color: '#fff',
                  }}
                >
                  Connect Integrations
                </Button>
                <Button
                  onClick={() => setHideBanner(true)}
                  style={{
                    color: '#6D44FB',
                  }}
                >
                  Do it Later
                </Button>
              </div>
            </div>
          )}{' '}
          <TextingHistory courses={courses} />
        </>
      )}

      {showSetVanityURL && (
        <Modal
          onClose={() => setShowSetVanityURL(false)}
          display
          css={`
            border-radius: 16px;
            padding: 32px;
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 168px;
            `}
          >
            <div
              className={css`
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 133.4%;
                /* identical to box height, or 32px */

                color: #25282d;
              `}
            >
              Create a Custom URL for this Guide
            </div>
            <div>
              <IconButton onClick={() => setShowSetVanityURL(false)}>
                <X />
              </IconButton>
            </div>
          </div>

          <div
            className={css`
              margin-top: 24px;
              background: #6d44fb33;

              border-radius: 16px;
              padding: 24px;

              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 32px;
            `}
          >
            <div
              className={css`
                display: flex;
                align-items: center;
                gap: 16px;
              `}
            >
              <Info color="#6d44fb" />
              <div
                className={css`
                  font-family: 'Roboto';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  /* identical to box height, or 150% */

                  letter-spacing: 0.15px;

                  color: #25282d;
                `}
              >
                Before you can message a guide you will need to create a custom URL
              </div>
            </div>
          </div>

          <div
            className={css`
              display: flex;
              align-items: center;
              margin-bottom: 32px;
            `}
          >
            <div
              className={css`
                margin-right: 16px;
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
              `}
            >
              https://britehr.app/
            </div>

            <TextField
              placeholder="Enter Custom Extension"
              variant="outlined"
              value={newVanityURL}
              style={{ flexGrow: '1' }}
              error={!!vanityURLErrorMsg}
              helperText={vanityURLErrorMsg}
              onChange={(e) => {
                const val = e.target.value;
                if (!val.match(/^[a-zA-Z0-9-_]*$/)) {
                  setVanityURLErrorMsg('URL can only contain letters, numbers, dashes, and underscores.');
                } else {
                  setVanityURLErrorMsg('');
                }
                setNewVanityURL(val);
              }}
            />
          </div>

          {vanityUrlTaken && (
            <div
              className={css`
                margin: 10px;
                color: red;
                text-align: center;
              `}
            >
              Extension is taken. Try a different extension.
            </div>
          )}

          <div
            className={css`
              display: flex;
              justify-content: flex-end;
              align-items: center;
            `}
          >
            <div>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setShowSetVanityURL(false);
                }}
              >
                Cancel
              </Button>
            </div>
            <div
              className={css`
                margin-left: 16px;
              `}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setUpVanityUrl();
                }}
                disabled={!newVanityURL || vanityUrlLoading || !!vanityURLErrorMsg}
              >
                {vanityUrlLoading ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {enableTextingModal.isOpen && (
        <EnableTextingModal display={enableTextingModal.isOpen} onClose={enableTextingModal.hide} />
      )}
      {showSendGuide && (
        <Modal
          onClose={() => setShowSendGuide(false)}
          display
          css={`
            border-radius: 16px;
            padding: 32px;
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 168px;
            `}
          >
            <div
              className={css`
                display: flex;
                align-items: center;
                gap: 8px;
              `}
            >
              <IconButton
                onClick={() => {
                  setShowSendGuide(false);
                  setShowSelectGuide(true);
                }}
              >
                <ArrowLeft />
              </IconButton>
              <div
                className={css`
                  font-family: 'Roboto';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 24px;
                  line-height: 133.4%;
                  /* identical to box height, or 32px */

                  color: #25282d;
                `}
              >
                Select Message Type
              </div>
            </div>
            <div>
              <IconButton onClick={() => setShowSendGuide(false)}>
                <X />
              </IconButton>
            </div>
          </div>
          <div
            className={css`
              margin-top: 14px;
            `}
          >
            <div
              className={css`
                max-height: 45vh;
                overflow-y: auto;
                ${scrollbar.style}
                padding-right: 16px;
              `}
            >
              {canEmailMessage?.value && (
                <div
                  onClick={() => {
                    setShowEmailMessage(true);
                  }}
                  className={css`
                    border: 1px solid #d1dae3;
                    border-radius: 8px;
                    padding: 24px;
                    margin-top: 12px;
                    margin-bottom: 12px;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 32px;
                    flex: 1;
                    cursor: pointer;
                    &:hover {
                      background: #e8edf3;
                    }
                  `}
                >
                  <div
                    className={css`
                      display: flex;
                      align-items: center;
                      gap: 32px;
                    `}
                  >
                    <img src={EmailIcon} width="40px" height="40px" alt="Email" />
                    <div>
                      <div
                        className={css`
                          font-family: 'Roboto';
                          font-style: normal;
                          font-weight: 700;
                          font-size: 18px;
                          line-height: 24px;
                          /* identical to box height, or 133% */

                          letter-spacing: 0.15px;

                          color: #25282d;
                        `}
                      >
                        Email
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                onClick={() => {
                  if (canTextMessage?.value) {
                    setShowTextMessage(true);
                  } else {
                    enableTextingModal.toggle();
                  }
                }}
                className={css`
                  border: 1px solid #d1dae3;
                  border-radius: 8px;
                  padding: 24px;
                  margin-top: 12px;
                  margin-bottom: 12px;

                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 32px;
                  flex: 1;
                  cursor: pointer;
                  &:hover {
                    background: ${canTextMessage?.value ? '#e8edf3' : 'transparent'};
                  }
                `}
              >
                <div
                  className={css`
                    display: flex;
                    align-items: center;
                    gap: 32px;
                  `}
                >
                  <img
                    src={canTextMessage?.value ? MessagingIcon : MessagingIconDisabled}
                    width="40px"
                    height="40px"
                    alt="Message"
                  />
                  <div>
                    <div
                      className={css`
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 24px;
                        /* identical to box height, or 133% */

                        letter-spacing: 0.15px;

                        color: ${canTextMessage?.value ? '#25282d' : '#9AA7B5'};
                      `}
                    >
                      Text Message
                    </div>
                  </div>
                </div>
                {!canTextMessage?.value && (
                  <div>
                    <Button
                      variant={'outlined'}
                      onClick={(e) => {
                        e.stopPropagation();
                        enableTextingModal.toggle();
                      }}
                    >
                      Connect
                    </Button>
                  </div>
                )}
              </div>

              <div
                onClick={() => {
                  if (slackEnabled) {
                    setShowSlackMessage(true);
                  } else {
                    try {
                      window.kolla.openConnector('slack');
                      window?.ChurnZero?.push(['setAttribute', 'account', 'integration-slack', true]);
                    } catch (e) {
                      console.error('Kolla error opening slack');
                    }

                    // setShowIntegrations(true);
                  }
                }}
                className={css`
                  border: 1px solid #d1dae3;
                  border-radius: 8px;
                  padding: 24px;
                  margin-top: 12px;
                  margin-bottom: 12px;
                  gap: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  cursor: pointer;
                  flex: 1;
                  &:hover {
                    background: ${kolla?.slack ? '#e8edf3' : 'transparent'};
                  }
                `}
              >
                <div
                  className={css`
                    display: flex;
                    align-items: center;
                    gap: 32px;
                  `}
                >
                  <img
                    src={kolla?.slack ? Slack : SlackDisabled}
                    className={css`
                      width: 40px;
                      height: 40px;
                    `}
                    alt="Slack"
                  />
                  <div>
                    <div
                      className={css`
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 24px;
                        /* identical to box height, or 133% */

                        letter-spacing: 0.15px;

                        color: ${kolla?.slack ? '#25282d' : '#9AA7B5'};
                      `}
                    >
                      Slack
                    </div>
                  </div>
                </div>
                {!slackEnabled && (
                  <div
                    className={css`
                      margin-left: 80px;
                    `}
                  >
                    <Button
                      variant={'outlined'}
                      onClick={(e) => {
                        e.stopPropagation();
                        try {
                          window.kolla.openConnector('slack');
                          window?.ChurnZero?.push(['setAttribute', 'account', 'integration-slack', true]);
                        } catch (e) {
                          console.error('Kolla error opening slack connector');
                        }

                        // setShowIntegrations(true);
                      }}
                    >
                      Connect
                    </Button>
                  </div>
                )}
              </div>

              <div
                onClick={() => {
                  if (teamsEnabled) {
                    setShowTeamsMessage(true);
                  } else {
                    setShowTeamsModal(true);
                    //setShowIntegrations(true);
                  }
                }}
                className={css`
                  border: 1px solid #d1dae3;
                  border-radius: 8px;
                  padding: 24px;
                  margin-top: 12px;
                  margin-bottom: 12px;
                  gap: 22px;
                  display: flex;
                  align-items: center;

                  cursor: pointer;
                  justify-content: space-between;
                  flex: 1;
                  &:hover {
                    background: ${teamsEnabled ? '#e8edf3' : 'transparent'};
                  }
                `}
              >
                <div
                  className={css`
                    display: flex;
                    align-items: center;
                    gap: 22px;
                  `}
                >
                  <img
                    src={teamsEnabled ? Teams : TeamsDisabled}
                    className={css`
                      width: 50px;
                      height: 50px;
                    `}
                    alt="Teams"
                  />
                  <div>
                    <div
                      className={css`
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 24px;
                        /* identical to box height, or 133% */

                        letter-spacing: 0.15px;

                        color: ${teamsEnabled ? '#25282d' : '#9AA7B5'};
                      `}
                    >
                      Microsoft Teams
                    </div>
                  </div>
                </div>
                {!teamsEnabled && (
                  <div
                    className={css`
                      margin-left: 80px;
                    `}
                  >
                    <Button
                      variant={'outlined'}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowTeamsModal(true);
                        //setShowIntegrations(true);
                      }}
                    >
                      Connect
                    </Button>
                  </div>
                )}
              </div>

              <div
                onClick={() => {
                  setShowBriteGuideHandout(true);
                }}
                className={css`
                  border: 1px solid #d1dae3;
                  border-radius: 8px;
                  padding: 24px;
                  margin-top: 12px;
                  margin-bottom: 12px;
                  gap: 22px;
                  display: flex;
                  align-items: center;

                  cursor: pointer;
                  justify-content: space-between;
                  flex: 1;
                  &:hover {
                    background: #e8edf3;
                  }
                `}
              >
                <div
                  className={css`
                    display: flex;
                    align-items: center;
                    gap: 22px;
                  `}
                >
                  <img
                    src={HandoutImage}
                    className={css`
                      width: 50px;
                      height: 50px;
                    `}
                    alt="Handout"
                  />
                  <div>
                    <div
                      className={css`
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 24px;
                        /* identical to box height, or 133% */

                        letter-spacing: 0.15px;

                        color: #25282d;
                      `}
                    >
                      Brite Guide Handout
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={css`
                  background: #f5f7fa;
                  border: 2px solid #25282d;
                  padding: 16px;
                  border-radius: 8px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                `}
              >
                <div
                  className={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <IconButton
                    onClick={() => {
                      setShowSendGuide(false);
                      setSelectedCourse(null);
                      setShowSelectGuide(true);
                    }}
                  >
                    {' '}
                    <img src={PurpleCheck} width="24px" height="24px" alt="Check" />
                  </IconButton>

                  <div
                    className={css`
                      font-family: 'Roboto';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 22px;
                      line-height: 133.4%;
                      /* or 29px */

                      color: #25282d;
                    `}
                  >
                    {selectedCourse.Name}
                  </div>
                </div>
              </div>
            </div>

            <div
              className={css`
                padding-top: 24px;
                display: flex;
                align-items: center;
                gap: 10px;
              `}
            >
              <Copy value={`https://britehr.app/${selectedCourse.VanityURL}`}>
                <div
                  className={css`
                    display: flex;
                    align-items: center;
                    gap: 16px;

                    cursor: pointer;
                    border-radius: 8px;
                    padding: 4px;
                    &:hover {
                      background: #e8edf3;
                    }
                  `}
                >
                  <Share />
                  Copy Guide URL
                </div>
              </Copy>

              <div
                onClick={() => {
                  setShowQrCode(true);
                }}
                className={css`
                  display: flex;
                  align-items: center;
                  gap: 16px;
                  cursor: pointer;
                  padding: 4px;
                  border-radius: 8px;
                  &:hover {
                    background: #e8edf3;
                  }
                `}
              >
                <QrCode /> Download QR Code
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showQrCode && (
        <Modal onClose={() => setShowQrCode(false)} display full>
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 28px;
              border-bottom: 1px solid #d1dae3;
            `}
          >
            <div
              className={css`
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 34px;
                line-height: 123.5%;
                /* identical to box height, or 42px */

                letter-spacing: 0.25px;

                color: #25282d;
              `}
            >
              QR Code
            </div>
            <div
              className={css`
                display: flex;
                align-items: center;
                gap: 16px;
              `}
            >
              <div>
                <Button variant="outlined" onClick={() => setShowQrCode(false)}>
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  color="primary"
                  onClick={() => {
                    onImageDownload();
                  }}
                  variant={'contained'}
                >
                  Download QR Code
                </Button>
              </div>
            </div>
          </div>

          <div
            className={css`
              border-radius: 8px;
              padding: 32px;
              display: flex;
              justify-content: center;
              max-width: 700px;
              min-width: 600px;
              margin: 32px auto;
              overflow: auto;
            `}
          >
            <QRCode id="QRCode" value={`https://britehr.app/${selectedCourse.VanityURL}?lt=pdf-qr-code-manual`} />
          </div>
        </Modal>
      )}

      {showCopyGuideLink && (
        <Modal
          onClose={() => {
            setShowCopyGuideLink(false);
          }}
          display
          full
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 28px;
              border-bottom: 1px solid #d1dae3;
            `}
          >
            <div
              className={css`
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 34px;
                line-height: 123.5%;
                /* identical to box height, or 42px */

                letter-spacing: 0.25px;

                color: #25282d;
              `}
            >
              Copy Guide Link
            </div>
            <div
              className={css`
                display: flex;
                align-items: center;
                gap: 16px;
              `}
            >
              <DropMenu
                title="Message Templates Help"
                button={
                  <IconButton>
                    <HandWaving />
                  </IconButton>
                }
              >
                <MenuItem
                  onClick={() => {
                    setShowMessageTemplates(true);
                  }}
                >
                  Message Templates
                </MenuItem>
              </DropMenu>
              <div>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowCopyGuideLink(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  color="primary"
                  onClick={() => {
                    setShowCopyGuideLink(false);
                  }}
                  variant={'contained'}
                >
                  Done
                </Button>
              </div>
            </div>
          </div>

          <div
            className={css`
              background: #f5f7fa;
              border-radius: 8px;
              padding: 32px;

              cursor: pointer;

              max-width: 700px;
              min-width: 600px;
              margin: 32px auto;
              overflow: auto;
            `}
          >
            <Copy value={`https://britehr.app/${selectedCourse.VanityURL}`}>
              <div>
                <div
                  className={css`
                    display: flex;
                    align-items: center;

                    justify-content: center;
                    margin-top: 16px;
                  `}
                >
                  <div
                    className={css`
                      font-weight: 700;
                      font-size: 18px;
                      line-height: 182%;
                      text-align: center;

                      color: #25282d;
                    `}
                  >
                    https://britehr.app/{selectedCourse.VanityURL}
                  </div>
                </div>
                <div
                  className={css`
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    margin-top: 8px;
                    letter-spacing: 0.15px;
                    text-align: center;
                    color: #66737f;
                  `}
                >
                  Click to Copy
                </div>
              </div>
            </Copy>
          </div>
        </Modal>
      )}

      {showSlackMessage && (
        <Modal
          onClose={() => {
            setShowSlackMessage(false);
          }}
          display
          full
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 28px;
              border-bottom: 1px solid #d1dae3;
            `}
          >
            <div
              className={css`
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 34px;
                line-height: 123.5%;
                /* identical to box height, or 42px */

                letter-spacing: 0.25px;

                color: #25282d;
              `}
            >
              Slack Guide Link
            </div>
            <div
              className={css`
                display: flex;
                align-items: center;
                gap: 16px;
              `}
            >
              <DropMenu
                title="Message Templates Help"
                button={
                  <IconButton>
                    <HandWaving size={32} />
                  </IconButton>
                }
              >
                <MenuItem
                  onClick={() => {
                    setShowMessageTemplates(true);
                  }}
                >
                  Message Templates
                </MenuItem>
              </DropMenu>
              <div>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowSlackMessage(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  color="primary"
                  disabled={!messageBody || slackContactsToMessage.length === 0}
                  onClick={() => {
                    if (slackTab === SLACK_CHANNELS_TAB && slackSendMethod === SLACK_SEND_METHOD_POST) {
                      sendSlackMessage(slackChannelsToMessage, messageBody, selectedCourse.ID);
                    } else {
                      sendSlackMessage(slackContactsToMessage, messageBody, selectedCourse.ID);
                    }
                    setShowSlackMessage(false);
                  }}
                  variant={'contained'}
                >
                  Send Message
                </Button>
              </div>
            </div>
          </div>

          <div
            id="send-via-slack"
            className={css`
              padding: 48px;
              max-width: 700px;
              margin: 0 auto;
              overflow: auto;
            `}
          >
            <div
              className={css`
                display: flex;
                align-items: center;
                border-bottom: 1px solid #d1dae3;
                padding-bottom: 18px;
                width: 100%;
              `}
            >
              <div
                className={css`
                  cursor: pointer;
                  font-weight: 700;
                  font-size: 18px;
                  color: ${slackTab === SLACK_INDIVIDUALS_TAB ? '#25282D' : '#66737f'};
                  border-bottom: ${slackTab === SLACK_INDIVIDUALS_TAB ? '4px solid #25282D' : '4px solid transparent'};
                  padding-bottom: 18px;
                  margin-bottom: -19px;
                  justify-content: center;
                  width: 50%;
                  display: flex;
                `}
                onClick={() => {
                  setSlackTab(SLACK_INDIVIDUALS_TAB);
                  // Clear out selected recipients if the user switches tabs, to ensure no extra people/channels are messaged.
                  setSlackContactsToMessage(new Set());
                  setAllSlackUsersSelected(false);
                  setSlackChannelsToMessage(new Set());
                }}
              >
                {SLACK_INDIVIDUALS_TAB}
              </div>
              <div
                className={css`
                  cursor: pointer;
                  font-weight: 700;
                  font-size: 18px;
                  color: ${slackTab === SLACK_CHANNELS_TAB ? '#25282D' : '#66737f'};
                  border-bottom: ${slackTab === SLACK_CHANNELS_TAB ? '4px solid #25282D' : '4px solid transparent'};
                  padding-bottom: 18px;
                  margin-bottom: -19px;
                  justify-content: center;
                  width: 50%;
                  display: flex;
                `}
                onClick={() => {
                  setSlackTab(SLACK_CHANNELS_TAB);
                  // Clear out selected recipients if the user switches tabs, to ensure no extra people/channels are messaged.
                  setSlackContactsToMessage(new Set());
                  setAllSlackUsersSelected(false);
                  setSlackChannelsToMessage(new Set());
                }}
              >
                Channels
              </div>
            </div>
            <div
              className={css`
                min-width: 640px;
              `}
            >
              <div
                className={css`
                  margin-bottom: 12px;
                `}
              >
                {slackTab === SLACK_CHANNELS_TAB && (
                  <div id="slack-channels-tab">
                    <div
                      className={css`
                        padding-bottom: 8px;

                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-top: 16px;
                      `}
                    ></div>

                    <div
                      className={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      `}
                    >
                      <div
                        className={css`
                          flex-grow: 1;
                          width: 100%;
                        `}
                      >
                        {
                          <BriteSearchableMultiSelect
                            label="Add Channels"
                            labelKey="name"
                            value={slackChannelsToMessage}
                            options={slackChannels}
                            onChange={(e, selectedChannels) => {
                              setSlackChannelsToMessage(new Set(selectedChannels));

                              // Even though the slackSendMethod may not be SLACK_SEND_METHOD_DM, we should still populate all the users to DM from the selected channels, in case the user switches to DMs.
                              // Each time the list of channels to message is modified, remove any existing members from the list, and re-populate members from added/remaining channels.
                              setSlackLoadingChannelMembers(true);
                              setSlackContactsToMessage(new Set());
                              setSlackChannelMembers(new Set());

                              let contactsToMessage = new Set();
                              let channelMembersSet = new Set();
                              let channelMembersMap = slackChannelMembersMap;

                              let channelMembersPromises = [];

                              // Iterate over all the currently selected channels
                              for (let i = 0; i < selectedChannels.length; i++) {
                                const channelID = selectedChannels[i].id;

                                // If we've already fetched the members for this channel, use the cached version.
                                if (channelMembersMap[channelID] !== undefined) {
                                  channelMembersMap[channelID].forEach((memberID) => {
                                    const slackUser = slackUserMap.get(memberID);
                                    // It is possible that the user is a bot, or has been deactivated, so they won't be in the original users response from our backend.
                                    if (slackUser !== undefined) {
                                      contactsToMessage.add(slackUser);
                                      channelMembersSet.add(slackUser);
                                    }
                                  });
                                } else {
                                  // If don't have members for this channel, fetch them from the backend.

                                  const f = async () => {
                                    try {
                                      const response = await CustomAxios.get(`/v1/slack/channel/${channelID}/member`);
                                      const memberIDs = response.data.ChannelMemberIDs;

                                      memberIDs.forEach((memberID) => {
                                        const slackUser = slackUserMap.get(memberID);
                                        if (slackUser !== undefined) {
                                          contactsToMessage.add(slackUser);
                                          channelMembersSet.add(slackUser);
                                        }
                                      });
                                      channelMembersMap[channelID] = memberIDs;
                                    } catch (err) {
                                      console.warn(err);
                                    }
                                  };
                                  channelMembersPromises.push(f());
                                }
                              }

                              Promise.all(channelMembersPromises).then(() => {
                                setSlackContactsToMessage(contactsToMessage);
                                setSlackChannelMembers(channelMembersSet);
                                setSlackChannelMembersMap(channelMembersMap);
                                setSlackLoadingChannelMembers(false);
                              });
                            }}
                          />
                        }
                      </div>
                    </div>
                    <RadioGroup
                      aria-label="send method"
                      value={slackSendMethod}
                      onChange={(e) => {
                        setSlackSendMethod(e.target.value);
                      }}
                      row
                    >
                      <FormControlLabel
                        value={SLACK_SEND_METHOD_POST}
                        control={<Radio color="default" />}
                        label={<div>Post to channels</div>}
                      />
                      <FormControlLabel
                        value={SLACK_SEND_METHOD_DM}
                        control={<Radio color="default" />}
                        label={
                          <div>
                            Direct message members in channels
                            <Tooltip title="Prefer direct message for better insights and engagement when sending essential guides.">
                              <IconButton aria-label="help">
                                <HelpOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        }
                      />
                    </RadioGroup>
                  </div>
                )}
                {slackTab === SLACK_INDIVIDUALS_TAB && (
                  <div id="slack-dm-tab">
                    <div
                      className={css`
                        padding-bottom: 8px;

                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-top: 16px;
                      `}
                    ></div>

                    <div
                      className={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      `}
                    >
                      <div
                        className={css`
                          flex-grow: 1;
                          width: 100%;
                        `}
                      >
                        {
                          <BriteSearchableMultiSelect
                            label="Add Individuals"
                            labelKey="name"
                            closeOnSelect={false}
                            value={slackContactsToMessage}
                            options={slackUsersPlusAll}
                            allAreSelected={allSlackUsersSelected}
                            onChange={(e, newValue) => {
                              let useAll = false;
                              newValue.forEach((member) => {
                                if (member.id === 'all') {
                                  useAll = true;
                                }
                              });

                              if (useAll) {
                                if (allSlackUsersSelected) {
                                  // If all users were already selected, and the "all" option is selected again, then unselect all users.
                                  setSlackContactsToMessage(new Set());
                                  setAllSlackUsersSelected(false);
                                } else {
                                  setSlackContactsToMessage(new Set(slackUsers));
                                  setAllSlackUsersSelected(true);
                                }
                              } else {
                                setSlackContactsToMessage(new Set(newValue));
                                if (newValue.length === slackUsers.length) {
                                  // If all slack users have been selected (even though the "all" option was not used) need to set all to true.
                                  setAllSlackUsersSelected(true);
                                } else {
                                  // Keep AllSlackUsersSelected false if the "all" option was not used, and not all users have been manually added.
                                  setAllSlackUsersSelected(false);
                                }
                              }
                            }}
                          />
                        }
                      </div>
                    </div>
                  </div>
                )}
                {slackTab === SLACK_CHANNELS_TAB && slackSendMethod === SLACK_SEND_METHOD_DM && (
                  <div id="slack-dm-tab">
                    <div
                      className={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      `}
                    >
                      <div
                        className={css`
                          flex-grow: 1;
                          width: 100%;
                        `}
                      >
                        {!slackLoadingChannelMembers && (
                          <BriteSearchableMultiSelect
                            label="Filter Individuals (optional)"
                            labelKey="name"
                            closeOnSelect={false}
                            value={slackContactsToMessage}
                            options={slackChannelMembers}
                            defaultValues={slackContactsToMessage}
                            onChange={(e, newValue) => {
                              setSlackContactsToMessage(new Set(newValue));
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className={css`
                    padding-bottom: 8px;
                    padding-top: 16px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    /* identical to box height, or 150% */

                    letter-spacing: 0.15px;

                    color: #25282d;
                  `}
                >
                  <div>Message</div>
                  <div>
                    <span
                      className={css`
                        color: ${messageBody.length > 360 ? 'red' : 'inherit'};
                      `}
                    >
                      {messageBody.length}
                    </span>{' '}
                    / 360
                  </div>
                </div>
                <TextField
                  variant="outlined"
                  multiline
                  rows={4}
                  style={{ width: '100%' }}
                  value={messageBody}
                  onChange={(e) => {
                    setMessageBody(e.target.value);
                  }}
                />
                <div
                  className={css`
                    font-size: 12px;
                    margin-top: 4px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 24px;
                    margin-bottom: 16px;

                    letter-spacing: 0.15px;

                    color: #66737f;
                  `}
                >
                  The guide link will show up at the end of your message.
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showEmailMessage && (
        <Modal
          onClose={() => {
            setShowEmailMessage(false);
          }}
          display
          full
          css={`
            overflow-y: auto;
            padding-bottom: 100px;
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 28px;
              border-bottom: 1px solid #d1dae3;
            `}
          >
            <div
              className={css`
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 34px;
                line-height: 123.5%;
                /* identical to box height, or 42px */

                letter-spacing: 0.25px;

                color: #25282d;
              `}
            >
              Email Guide Link
            </div>
            <div
              className={css`
                display: flex;
                align-items: center;
                gap: 16px;
              `}
            >
              <DropMenu
                title="Message Templates Help"
                button={
                  <IconButton>
                    <HandWaving size={32} />
                  </IconButton>
                }
              >
                <MenuItem
                  onClick={() => {
                    setShowMessageTemplates(true);
                  }}
                >
                  Message Templates
                </MenuItem>
              </DropMenu>
              <div>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowEmailMessage(false);
                    setEmailAddresses(new Set());
                    setCurrentEmailAddress('');
                    setEmailSenderName('');
                    setEmailSubject('');
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  color="primary"
                  onClick={() => {
                    // Update the CTA link in the email body to actually go to the guide, and overwrite the input field with a button so it cannot be edited, and so that it can be clicked.
                    // This is done here for now so that the user can edit the CTA text rather than having it open a new tab.
                    let emailCTA = document.getElementById('email-editor-cta');
                    let emailCTAText = document.getElementById('email-editor-cta-button');

                    // Add the link so that this is now a clickable link in the email.
                    emailCTA.href = 'https://britehr.app/' + selectedCourse.VanityURL;

                    const CTAButton = document.createElement('button');
                    CTAButton.innerHTML = emailCTAText.value;
                    CTAButton.style =
                      emailCTAText.attributes.style.value +
                      ' cursor: pointer; padding-left:48px; padding-right:48px; padding-top:12px; padding-bottom:12px;';
                    emailCTA.replaceChildren(CTAButton);

                    sendEmailMessage(document.getElementById('emailBodySpan').outerHTML);

                    setShowEmailMessage(false);
                  }}
                  disabled={loading || !emailAgree || !emailSubject || !emailSenderName || emailAddresses.length < 1}
                  variant={'contained'}
                >
                  Send Email
                </Button>
              </div>
            </div>
          </div>

          <div
            className={css`
              padding-right: 16px;
              padding-left: 16px;
              padding-top: 48px;
              padding-bottom: 48px;
              max-width: 700px;
              margin: 0 auto;
              overflow: auto;
            `}
          >
            <div
              id="padding-for-upper-fields"
              className={css`
                padding-right: 35px;
                padding-left: 32px;
              `}
            >
              <div
                id="subject-sender"
                className={css`
                  flex-direction: row;
                  display: flex;
                `}
              >
                <div
                  className={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  `}
                ></div>
                <div
                  className={css`
                    flex-grow: 1;
                    width: 100%;
                    margin-right: 16px;
                    margin-bottom: 24px;
                  `}
                >
                  <div
                    className={css`
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                      /* identical to box height, or 150% */
                      margin-bottom: 4px;
                      letter-spacing: 0.15px;

                      color: #25282d;
                    `}
                  >
                    Email Subject
                  </div>
                  <TextField
                    style={{ width: '100%' }}
                    variant="outlined"
                    placeholder="Enter Email Subject"
                    value={emailSubject}
                    onChange={(e) => setEmailSubject(e.target.value)}
                  />
                </div>
                <div
                  className={css`
                    flex-grow: 1;
                    width: 100%;
                  `}
                >
                  <div
                    className={css`
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                      /* identical to box height, or 150% */

                      letter-spacing: 0.15px;
                      margin-bottom: 4px;
                      color: #25282d;
                    `}
                  >
                    Sender Name
                  </div>
                  <TextField
                    style={{ width: '100%' }}
                    variant="outlined"
                    placeholder="Enter Sender Name"
                    value={emailSenderName}
                    onChange={(e) => setEmailSenderName(e.target.value)}
                  />
                </div>
              </div>
              <div
                className={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                `}
              >
                <div
                  className={css`
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    /* identical to box height, or 150% */

                    letter-spacing: 0.15px;
                    margin-bottom: 4px;
                    color: #25282d;
                  `}
                >
                  Email Addresses
                </div>
              </div>
              <div
                className={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                `}
              >
                <div
                  className={css`
                    flex-grow: 1;
                    width: 100%;
                    margin-right: 16px;
                  `}
                >
                  <TextField
                    style={{ width: '100%' }}
                    variant="outlined"
                    placeholder="Enter Email Address"
                    value={currentEmailAddress}
                    onChange={(e) => setCurrentEmailAddress(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <Button
                          onClick={() => {
                            if (currentEmailAddress) {
                              emailAddresses.add(currentEmailAddress);
                            }

                            setCurrentEmailAddress('');
                          }}
                        >
                          Add
                        </Button>
                      ),
                    }}
                  />
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setShowImportEmailAddresses(true);
                      setBadEmailsFromUpload([]);
                      setNumImportedEmailRows(0);
                    }}
                    color="primary"
                    size="large"
                    variant="outlined"
                    startIcon={<UploadSimple size={24} style={{ height: '40px' }} />}
                  >
                    Import
                  </Button>
                </div>
              </div>
              <div
                className={css`
                  max-height: 250px;
                  overflow: auto;
                  margin-bottom: 32px;
                  margin-top: 8px;
                  background: ${emailAddresses.size > 0 ? '#f5f7fa' : 'fff'};
                  border-radius: 8px;
                  padding: ${emailAddresses.size > 0 ? '8px' : '0px'};
                `}
              >
                {emailAddresses.size > 0
                  ? [...emailAddresses].map((emailAddress) => {
                      return (
                        <div
                          key={emailAddress}
                          className={css`
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                          `}
                        >
                          <div>{emailAddress}</div>

                          <div>
                            <IconButton
                              onClick={() => {
                                let next = new Set(emailAddresses);
                                next.delete(emailAddress);
                                setEmailAddresses(next);
                              }}
                              color="primary"
                            >
                              <X />
                            </IconButton>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
            <EmailEditor business={selectedBusiness} guide={selectedCourse} editorID="emailBodySpan" />

            <FormControlLabel
              style={{ fontSize: '12px', marginBottom: '12px' }}
              control={
                <Checkbox
                  checked={emailAgree}
                  onChange={(e) => {
                    setEmailAgree(e.target.checked);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label={
                <div
                  className={css`
                    font-size: 12px;
                    padding-top: 8px;
                  `}
                >
                  By clicking here, I represent that I have the authorization of the Company to send the above email
                  which is not for marketing or solicitation and that Brite bears no responsibility for the content of
                  the email message, including the content of any external links.
                </div>
              }
            />
          </div>
        </Modal>
      )}

      {showBriteGuideHandout && (
        <Handout
          setShowBriteGuideHandout={setShowBriteGuideHandout}
          guideURL={`https://britehr.app/${selectedCourse.VanityURL}`}
          guideID={selectedCourse.ID}
          selectedCourse={selectedCourse}
        />
      )}

      {showTextMessage && (
        <Modal
          onClose={() => {
            setShowTextMessage(false);
          }}
          display
          full
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 28px;
              border-bottom: 1px solid #d1dae3;
            `}
          >
            <div
              className={css`
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 34px;
                line-height: 123.5%;
                /* identical to box height, or 42px */

                letter-spacing: 0.25px;

                color: #25282d;
              `}
            >
              Text Guide Link
            </div>
            <div
              className={css`
                display: flex;
                align-items: center;
                gap: 16px;
              `}
            >
              <DropMenu
                title="Message Templates Help"
                button={
                  <IconButton>
                    <HandWaving size={32} />
                  </IconButton>
                }
              >
                <MenuItem
                  onClick={() => {
                    setShowMessageTemplates(true);
                  }}
                >
                  Message Templates
                </MenuItem>
              </DropMenu>
              <div>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowTextMessage(false);
                    setSentToPhoneNumbers([...phoneNumbers]);
                    setPhoneNumbers(new Set());
                    setCurrentPhoneNumber('');
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  color="primary"
                  onClick={() => {
                    sendTextMessage();
                    setShowTextMessage(false);
                  }}
                  disabled={
                    !textAgree ||
                    !textAgreeAgain ||
                    loading ||
                    messageBody.length > textMessageMaxLength ||
                    phoneNumbers.size === 0
                  }
                  variant={'contained'}
                >
                  Send Message
                </Button>
              </div>
            </div>
          </div>

          <div
            className={css`
              padding: 48px;
              max-width: 700px;
              margin: 0 auto;
              overflow: auto;
            `}
          >
            <div
              className={css`
                margin-bottom: 12px;
              `}
            >
              <div
                className={css`
                  padding-bottom: 8px;

                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                `}
              >
                <div
                  className={css`
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    /* identical to box height, or 150% */

                    letter-spacing: 0.15px;

                    color: #25282d;
                  `}
                >
                  Phone Numbers {phoneNumbers.size > 0 && <span>({phoneNumbers.size})</span>}
                </div>
              </div>

              <div
                className={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                `}
              >
                <div
                  className={css`
                    flex-grow: 1;
                    width: 100%;
                    margin-right: 16px;
                  `}
                >
                  <TextField
                    style={{ width: '100%' }}
                    variant="outlined"
                    placeholder="Enter Phone Number"
                    value={currentPhoneNumber}
                    onChange={(e) => setCurrentPhoneNumber(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <Button
                          onClick={() => {
                            if (currentPhoneNumber) {
                              // Check that the number is a mobile number and is valid.
                              if (
                                isValidPhoneNumber(currentPhoneNumber) ||
                                isValidPhoneNumber('+1' + currentPhoneNumber)
                              ) {
                                // The backend will handle adding the US country code, if it is missing.
                                setPhoneNumbers((phoneNumbers) =>
                                  new Set(phoneNumbers).add(currentPhoneNumber.replace(/[^\d+]/g, ''))
                                );
                                setCurrentPhoneNumber('');
                              } else if (isValidPhoneNumber('+' + currentPhoneNumber)) {
                                toast.error(phoneNumberErrorCountryCode);
                              } else {
                                toast.error(phoneNumberErrorGeneric);
                              }
                            }
                          }}
                        >
                          Add
                        </Button>
                      ),
                    }}
                  />
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setBadPhoneNumbersFromUpload([]);
                      setImportedNumRows(0);
                      setShowImportPhoneNumbers(true);
                    }}
                    color="primary"
                    size="large"
                    variant="outlined"
                    startIcon={<UploadSimple size={24} />}
                  >
                    Import
                  </Button>
                </div>
              </div>
              <div
                className={css`
                  max-height: 250px;
                  overflow: auto;
                  margin-bottom: 32px;
                  margin-top: 8px;
                  background: ${phoneNumbers.length > 0 ? '#f5f7fa' : 'fff'};
                  border-radius: 8px;
                  padding: ${phoneNumbers.length > 0 ? '8px' : '0px'};
                `}
              >
                {phoneNumbers &&
                  [...phoneNumbers].map((phoneNumber) => {
                    return (
                      <div
                        key={phoneNumber}
                        className={css`
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                        `}
                      >
                        <div>{FormatPhoneNumber(phoneNumber)}</div>

                        <div>
                          <IconButton
                            onClick={() => {
                              let updatedPhoneNumbers = new Set(phoneNumbers);
                              updatedPhoneNumbers.delete(phoneNumber);
                              setPhoneNumbers(updatedPhoneNumbers);
                            }}
                            color="primary"
                          >
                            <X />
                          </IconButton>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div
                className={css`
                  padding-bottom: 8px;
                  padding-top: 16px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  /* identical to box height, or 150% */

                  letter-spacing: 0.15px;

                  color: #25282d;
                `}
              >
                <div>Message</div>
                <div>
                  <span
                    className={css`
                      color: ${messageBody.length > textMessageMaxLength ? 'red' : 'inherit'};
                    `}
                  >
                    {messageBody.length}
                  </span>{' '}
                  / {textMessageMaxLength}
                </div>
              </div>
              <TextField
                variant="outlined"
                multiline
                rows={4}
                style={{ width: '100%' }}
                value={messageBody}
                onChange={(e) => {
                  setMessageBody(e.target.value);
                }}
              />
              <div
                className={css`
                  font-size: 12px;
                  margin-top: 4px;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 24px;
                  margin-bottom: 16px;

                  letter-spacing: 0.15px;

                  color: #66737f;
                `}
              >
                Guide link will be added to the end of the message automatically.
              </div>

              <FormControlLabel
                style={{ fontSize: '12px', marginBottom: '12px' }}
                control={
                  <Checkbox
                    checked={textAgree}
                    onChange={(e) => {
                      setTextAgree(e.target.checked);
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <div
                    className={css`
                      font-size: 12px;
                    `}
                  >
                    By clicking here, I represent that I have the authorization of the Company to send the above text
                    message and use such images and information included, and that Brite bears no responsibility for the
                    content of the text message, including the content of any external links.
                  </div>
                }
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={textAgreeAgain}
                    onChange={(e) => {
                      setTextAgreeAgain(e.target.checked);
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <div
                    className={css`
                      font-size: 12px;
                    `}
                  >
                    I have read and understood the terms and conditions of the SAAS Agreement, including but not limited
                    to compliance provisions under the Telephone Consumer Protection Act.
                  </div>
                }
              />
            </div>
          </div>
        </Modal>
      )}

      {showCourseSendSuccess && (
        <Modal
          display
          onClose={() => {
            setShowCourseSendSuccess(false);
          }}
          css={`
            overflow: auto;
            min-width: 800px;
          `}
        >
          <>
            {' '}
            <div
              className={css`
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin: 32px;
              `}
            >
              <IconButton
                onClick={() => {
                  setShowCourseSendSuccess(false);
                }}
              >
                <X size={24} />
              </IconButton>
            </div>
            <div
              className={css`
                margin: 32px;
                margin-top: 0px;
              `}
            >
              <div
                className={css`
                  display: flex;
                  justify-content: center;
                `}
              >
                <img src={SuccessText} alt="Success" />
              </div>
              <div
                className={css`
                  font-weight: 700;
                  font-size: 24px;
                  line-height: 133.4%;
                  margin: 32px;
                  text-align: center;

                  color: #25282d;
                `}
              >
                {textingErrors.length > 0
                  ? 'Your message is sending to ' +
                    (sentToPhoneNumbers.length - textingErrors?.length) +
                    ' of ' +
                    sentToPhoneNumbers.length +
                    ' recipients'
                  : 'Your message is sending succesfully'}
              </div>
              {textingErrors.length > 0 && <h3>The following recipients had errors</h3>}
              <div
                className={css`
                  margin-bottom: 24px;
                  overflow-y: scroll;
                  max-height: 250px;
                `}
              >
                {textingErrors.map((error) => {
                  return (
                    <div
                      className={css`
                        margin: 8px;
                        color: red;
                      `}
                    >
                      {error.PhoneNumber}: {error.Error}
                    </div>
                  );
                })}
              </div>
              <div
                className={css`
                  display: flex;
                  justify-content: center;
                  margin-bottom: 32px;
                `}
              >
                <Button variant="contained" color="primary" onClick={() => setShowCourseSendSuccess(false)}>
                  Close
                </Button>
              </div>
            </div>
          </>
        </Modal>
      )}

      {showImportPhoneNumbers && (
        <Modal
          onClose={() => {
            setShowImportPhoneNumbers(false);
          }}
          display
          css={`
            overflow: auto;
            min-width: 800px;
          `}
        >
          <>
            {' '}
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 32px;
              `}
            >
              <div
                className={css`
                  font-weight: 700;
                  font-size: 24px;
                  line-height: 32px;
                  color: #25282d;
                `}
              >
                Import Phone Numbers
              </div>

              <IconButton
                onClick={() => {
                  setShowImportPhoneNumbers(false);
                }}
              >
                <X size={24} />
              </IconButton>
            </div>
            <div
              className={css`
                font-size: 16px;
                color: #25282d;
                margin-left: 32px;
                margin-right: 32px;
              `}
            >
              Import a CSV with mobile phone numbers in one column. Numbers can be in local or international format. The
              following examples are all valid: <br />
              <br />
              (415) 555-2671
              <br />
              4154442671
              <br />
              +14155552671
            </div>
            <div
              className={css`
                margin: 32px;
              `}
            >
              {
                <Importer
                  assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
                  restartable={false} // optional, lets user choose to upload another file when import is complete
                  delimiter={','} // this struggles to autodetect if the delimiters are not consistent, so we force it to be a comma and it will read better whether or not there are commas
                  processChunk={async (rows, { startIndex }) => {
                    try {
                      new Promise((resolve) => {
                        let nums = new Set(phoneNumbers);
                        let badNums = [];
                        rows.forEach((row, index) => {
                          // Check that the number is a valid mobile phone number.
                          if (isValidPhoneNumber(row.PhoneNumber) || isValidPhoneNumber('+1' + row.PhoneNumber)) {
                            // The backend will handle adding the US country code, if it is missing.
                            nums.add(row.PhoneNumber);
                          } else if (row.PhoneNumber === '') {
                            badNums.push({
                              PhoneNumber: row.PhoneNumber,
                              Error: 'Empty phone number.',
                              Row: index + 2 + startIndex,
                            });
                          } else if (isValidPhoneNumber('+' + row.PhoneNumber)) {
                            badNums.push({
                              PhoneNumber: row.PhoneNumber,
                              Error: phoneNumberErrorCountryCode,
                              Row: index + 2 + startIndex,
                            });
                          } else {
                            badNums.push({
                              PhoneNumber: row.PhoneNumber,
                              Error: phoneNumberErrorGeneric,
                              Row: index + 2 + startIndex,
                            });
                          }
                        });
                        setImportedNumRows((importedNumRows) => importedNumRows + rows.length);
                        setBadPhoneNumbersFromUpload((badPhoneNumbersFromUpload) => [
                          ...badPhoneNumbersFromUpload,
                          ...badNums,
                        ]);
                        setPhoneNumbers((phoneNumbers) => new Set([...phoneNumbers, ...nums]));
                        resolve();
                      });
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                  onComplete={({ file, preview, fields, columnFields }) => {}}
                  onClose={({ file, preview, fields, columnFields }) => {
                    setShowImportPhoneNumbers(false);
                  }}
                >
                  <ImporterField name="PhoneNumber" label="Phone Number" />
                </Importer>
              }
              {badPhoneNumbersFromUpload.length > 0 && (
                <div>
                  <div
                    className={css`
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 133.4%;
                      margin-bottom: 32px;
                      margin-top: 8px;
                      text-align: center;

                      color: #25282d;
                    `}
                  >
                    {badPhoneNumbersFromUpload.length} of {importedNumRows} rows were skipped with errors
                  </div>
                  <div
                    className={css`
                      margin-bottom: 24px;
                      overflow-y: scroll;
                      max-height: 250px;
                    `}
                  ></div>
                  <div
                    className={css`
                      display: flex;
                      justify-content: center;
                      margin-bottom: 32px;
                    `}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        let csvOfImportErrors =
                          'data:text/csv;charset=utf-8,Row,PhoneNumber,Error\r\n' +
                          badPhoneNumbersFromUpload
                            .map((e) => {
                              return [e.Row, e.PhoneNumber, e.Error];
                            })
                            .join('\n');
                        var encodedUri = encodeURI(csvOfImportErrors);
                        var link = document.createElement('a');
                        link.setAttribute('href', encodedUri);
                        link.setAttribute('download', 'brite_text_import_errors.csv');
                        document.body.appendChild(link);
                        link.click();
                      }}
                    >
                      Download Errors
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </>
        </Modal>
      )}

      {showImportEmailAddresses && (
        <Modal
          onClose={() => {
            setShowImportEmailAddresses(false);
          }}
          display
          css={`
            overflow: auto;
            min-width: 800px;
          `}
        >
          <>
            {' '}
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 32px;
              `}
            >
              <div
                className={css`
                  font-weight: 700;
                  font-size: 24px;
                  line-height: 32px;
                  color: #25282d;
                `}
              >
                Import Email Addresses
              </div>

              <IconButton
                onClick={() => {
                  setShowImportEmailAddresses(false);
                  setNumImportedEmailRows(0);
                }}
              >
                <X size={24} />
              </IconButton>
            </div>
            <div
              className={css`
                margin-bottom: 32px;
              `}
            >
              <div
                className={css`
                  display: flex;
                  justify-content: center;
                `}
              >
                <div>
                  <div
                    className={css`
                      margin-bottom: 18px;
                    `}
                  >
                    Import a CSV file with a column containing the email addresses you wish to use.
                  </div>
                  <Importer
                    assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
                    restartable={false} // optional, lets user choose to upload another file when import is complete
                    delimiter={','} // this struggles to autodetect if the delimiters are not consistent, so we force it to be a comma and it will read better whether or not there are commas
                    processChunk={async (rows, { startIndex }) => {
                      try {
                        new Promise((resolve) => {
                          let addresses = new Set(emailAddresses);
                          let badAddresses = [];
                          rows.forEach((row, index) => {
                            // Check that the number is a valid mobile phone number.
                            if (isValidEmail(row.EmailAddress)) {
                              // The backend will handle adding the US country code, if it is missing.
                              addresses.add(row.EmailAddress);
                            } else {
                              badAddresses.push({
                                EmailAddress: row.EmailAddress,
                                Error: 'Does not appear to be an email address.',
                                Row: index + 1 + startIndex,
                              });
                            }
                          });
                          setNumImportedEmailRows((numImportedEmailRows) => numImportedEmailRows + rows.length);
                          setBadEmailsFromUpload((badEmailsFromUpload) => [...badEmailsFromUpload, ...badAddresses]);
                          setEmailAddresses((emailAddresses) => new Set([...emailAddresses, ...addresses]));
                          resolve();
                        });
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                    onComplete={({ file, preview, fields, columnFields }) => {}}
                    onClose={({ file, preview, fields, columnFields }) => {
                      setShowImportEmailAddresses(false);
                      setNumImportedEmailRows(0);
                    }}
                  >
                    <ImporterField name="EmailAddress" label="Email Address" />
                  </Importer>
                  {badEmailsFromUpload.length > 0 && (
                    <div>
                      <div
                        className={css`
                          font-weight: 600;
                          font-size: 16px;
                          line-height: 133.4%;
                          margin-bottom: 32px;
                          margin-top: 8px;
                          text-align: center;

                          color: #25282d;
                        `}
                      >
                        {badEmailsFromUpload.length} of {numImportedEmailRows} rows were skipped with errors
                      </div>
                      <div
                        className={css`
                          margin-bottom: 24px;
                          overflow-y: scroll;
                          max-height: 250px;
                        `}
                      ></div>
                      <div
                        className={css`
                          display: flex;
                          justify-content: center;
                          margin-bottom: 32px;
                        `}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            let csvOfImportErrors =
                              'data:text/csv;charset=utf-8,Row,EmailAddress,Error\r\n' +
                              badEmailsFromUpload
                                .map((e) => {
                                  return [e.Row, e.EmailAddress, e.Error];
                                })
                                .join('\n');
                            var encodedUri = encodeURI(csvOfImportErrors);
                            var link = document.createElement('a');
                            link.setAttribute('href', encodedUri);
                            link.setAttribute('download', 'brite_email_import_errors.csv');
                            document.body.appendChild(link);
                            link.click();
                          }}
                        >
                          Download Errors
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        </Modal>
      )}

      {showTeamsMessage && (
        <Modal
          onClose={() => {
            setShowTeamsMessage(false);
          }}
          display
          full
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 28px;
              border-bottom: 1px solid #d1dae3;
            `}
          >
            <div
              className={css`
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 34px;
                line-height: 123.5%;
                /* identical to box height, or 42px */

                letter-spacing: 0.25px;

                color: #25282d;
              `}
            >
              Teams Guide Link
            </div>
            <div
              className={css`
                display: flex;
                align-items: center;
                gap: 16px;
              `}
            >
              <DropMenu
                title="Message Templates Help"
                button={
                  <IconButton>
                    <HandWaving size={32} />
                  </IconButton>
                }
              >
                <MenuItem
                  onClick={() => {
                    setShowMessageTemplates(true);
                  }}
                >
                  Message Templates
                </MenuItem>
              </DropMenu>
              <div>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowTeamsMessage(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  color="primary"
                  onClick={() => {
                    sendTeamsMessage(teamsContactsToMessage, messageBody, selectedCourse.ID);
                  }}
                  disabled={!messageBody || teamsContactsToMessage.length === 0}
                  variant={'contained'}
                >
                  Send Message
                </Button>
              </div>
            </div>
          </div>

          <div
            id="send-via-teams"
            className={css`
              padding: 48px;
              max-width: 700px;
              margin: 0 auto;
              overflow: auto;
            `}
          >
            <div
              className={css`
                display: flex;
                align-items: center;
                border-bottom: 1px solid #d1dae3;
                padding-bottom: 18px;
                width: 100%;
              `}
            >
              <div
                className={css`
                  cursor: pointer;
                  font-weight: 700;
                  font-size: 18px;
                  color: ${slackTab === SLACK_INDIVIDUALS_TAB ? '#25282D' : '#66737f'};
                  border-bottom: ${slackTab === SLACK_INDIVIDUALS_TAB ? '4px solid #25282D' : '4px solid transparent'};
                  padding-bottom: 18px;
                  margin-bottom: -19px;
                  justify-content: center;
                  width: 50%;
                  display: flex;
                `}
                onClick={() => {
                  setSlackTab(SLACK_INDIVIDUALS_TAB);
                  // Clear out selected recipients if the user switches tabs, to ensure no extra people/channels are messaged.
                  setTeamsContactsToMessage(new Set());
                  setAllTeamsUsersSelected(false);
                  setTeamsChannelsToMessage(new Set());
                }}
              >
                {SLACK_INDIVIDUALS_TAB}
              </div>
              <div
                className={css`
                  cursor: pointer;
                  font-weight: 700;
                  font-size: 18px;
                  color: ${slackTab === SLACK_CHANNELS_TAB ? '#25282D' : '#66737f'};
                  border-bottom: ${slackTab === SLACK_CHANNELS_TAB ? '4px solid #25282D' : '4px solid transparent'};
                  padding-bottom: 18px;
                  margin-bottom: -19px;
                  justify-content: center;
                  width: 50%;
                  display: flex;
                `}
                onClick={() => {
                  setSlackTab(SLACK_CHANNELS_TAB);
                  // Clear out selected recipients if the user switches tabs, to ensure no extra people/channels are messaged.
                  setTeamsContactsToMessage(new Set());
                  setAllTeamsUsersSelected(false);
                  setTeamsChannelsToMessage(new Set());
                }}
              >
                Channels
              </div>
            </div>
            <div
              className={css`
                min-width: 640px;
              `}
            >
              <div
                className={css`
                  margin-bottom: 12px;
                `}
              >
                {slackTab === SLACK_CHANNELS_TAB && (
                  <div id="slack-channels-tab">
                    <div
                      className={css`
                        padding-bottom: 8px;

                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-top: 16px;
                      `}
                    ></div>
                    {teamsTeamOptions && teamsTeamOptions.length > 1 && (
                      <div id="teams-select-team-tab">
                        <div
                          className={css`
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding-bottom: 16px;
                          `}
                        >
                          <div
                            className={css`
                              flex-grow: 1;
                              width: 100%;
                            `}
                          >
                            <FormControl variant="outlined" fullWidth style={{ width: '100%' }}>
                              <InputLabel id="demo-simple-select-outlined-label">Select Team</InputLabel>
                              <Select
                                value={selectedTeam}
                                onChange={(e) => {
                                  setSelectedTeam(e.target.value);
                                }}
                                variant={'outlined'}
                                label="Select Team"
                                fullWidth
                                style={{ width: '100%' }}
                              >
                                {teamsTeamOptions.map((opt) => {
                                  return (
                                    <MenuItem value={opt} key={opt.teamId}>
                                      {opt.displayName}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    )}{' '}
                    <div
                      className={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      `}
                    >
                      <div
                        className={css`
                          flex-grow: 1;
                          width: 100%;
                        `}
                      >
                        {loadingTeams ? (
                          <div>
                            <CircularProgress />
                          </div>
                        ) : (
                          <BriteSearchableMultiSelect
                            label="Add Channels"
                            disabled={!selectedTeam.displayName}
                            labelKey="displayName"
                            value={teamsChannelsToMessage}
                            options={teamsChannels}
                            onChange={(e, selectedChannels) => {
                              setTeamsChannelsToMessage(new Set(selectedChannels));

                              setSlackLoadingChannelMembers(true);
                              setTeamsContactsToMessage(new Set());

                              let contactsToMessage = new Set();

                              let channelMembersPromises = [];

                              // Iterate over all the currently selected channels
                              for (let i = 0; i < selectedChannels.length; i++) {
                                const channelID = selectedChannels[i].id;

                                const f = async () => {
                                  try {
                                    const response = await CustomAxios.get(
                                      `/v1/teams/${selectedTeam?.teamId || ''}/channel/${channelID}/member`
                                    );
                                    const members = response.data;

                                    members.forEach((member) => {
                                      contactsToMessage.add(member);
                                    });
                                  } catch (err) {
                                    console.warn(err);
                                  }
                                };
                                channelMembersPromises.push(f());
                              }

                              Promise.all(channelMembersPromises).then(() => {
                                setTeamsContactsToMessage(contactsToMessage);

                                setSlackLoadingChannelMembers(false);
                              });
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div>All members of the channel(s) will receive an individual chat message.</div>
                  </div>
                )}
                {slackTab === SLACK_INDIVIDUALS_TAB && (
                  <div id="slack-dm-tab">
                    <div
                      className={css`
                        padding-bottom: 8px;

                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-top: 16px;
                      `}
                    ></div>

                    {teamsTeamOptions && teamsTeamOptions.length > 1 && (
                      <div id="teams-select-team-tab">
                        <div
                          className={css`
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding-bottom: 8px;
                          `}
                        >
                          <div
                            className={css`
                              flex-grow: 1;
                              width: 100%;
                            `}
                          >
                            <FormControl variant="outlined" fullWidth style={{ width: '100%' }}>
                              <InputLabel id="demo-simple-select-outlined-label">Select Team</InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                value={selectedTeam}
                                onChange={(e) => {
                                  setSelectedTeam(e.target.value);
                                }}
                                variant={'outlined'}
                                label="Select Team"
                                fullWidth
                                style={{ width: '100%' }}
                              >
                                {teamsTeamOptions.map((opt) => {
                                  return (
                                    <MenuItem value={opt} key={opt.teamId}>
                                      {opt.displayName}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      `}
                    >
                      <div
                        className={css`
                          flex-grow: 1;
                          width: 100%;
                        `}
                      >
                        {loadingTeams ? (
                          <div>
                            <CircularProgress />
                          </div>
                        ) : (
                          <BriteSearchableMultiSelect
                            label="Add Individuals"
                            disabled={!selectedTeam.displayName}
                            labelKey="displayName"
                            closeOnSelect={false}
                            value={teamsContactsToMessage}
                            options={teamsUsersPlusAll}
                            allAreSelected={allTeamsUsersSelected}
                            onChange={(e, newValue) => {
                              let useAll = false;
                              newValue.forEach((member) => {
                                if (member.id === 'all') {
                                  useAll = true;
                                }
                              });

                              if (useAll) {
                                if (allTeamsUsersSelected) {
                                  // If all users were already selected, and the "all" option is selected again, then unselect all users.
                                  setTeamsContactsToMessage(new Set());
                                  setAllTeamsUsersSelected(false);
                                } else {
                                  setTeamsContactsToMessage(new Set(teamsUsers));
                                  setAllTeamsUsersSelected(true);
                                }
                              } else {
                                setTeamsContactsToMessage(new Set(newValue));
                                if (newValue.length === teamsUsers.length) {
                                  // If all teams users have been selected (even though the "all" option was not used) need to set all to true.
                                  setAllTeamsUsersSelected(true);
                                } else {
                                  // Keep AllTeamsUsersSelected false if the "all" option was not used, and not all users have been manually added.
                                  setAllTeamsUsersSelected(false);
                                }
                              }
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className={css`
                    padding-bottom: 8px;
                    padding-top: 16px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    /* identical to box height, or 150% */

                    letter-spacing: 0.15px;

                    color: #25282d;
                  `}
                >
                  <div>Message</div>
                  <div>
                    <span
                      className={css`
                        color: ${messageBody.length > 360 ? 'red' : 'inherit'};
                      `}
                    >
                      {messageBody.length}
                    </span>{' '}
                    / 360
                  </div>
                </div>
                <TextField
                  variant="outlined"
                  multiline
                  rows={4}
                  style={{ width: '100%' }}
                  value={messageBody}
                  onChange={(e) => {
                    setMessageBody(e.target.value);
                  }}
                />
                <div
                  className={css`
                    font-size: 12px;
                    margin-top: 4px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 24px;
                    margin-bottom: 16px;

                    letter-spacing: 0.15px;

                    color: #66737f;
                  `}
                >
                  The guide link will show up at the end of your message.
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showMessageTemplates && (
        <Modal
          onClose={() => {
            setShowMessageTemplates(false);
          }}
          display
          css={`
            overflow: auto;
            min-width: 800px;
          `}
        >
          <>
            {' '}
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 32px;
              `}
            >
              <div
                className={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <IconButton
                  onClick={() => {
                    setShowMessageTemplates(false);
                  }}
                >
                  <ArrowLeft size={32} weight="bold" color="#25282d" />
                </IconButton>

                <div
                  className={css`
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 32px;
                    color: #25282d;
                  `}
                >
                  Brite Message Templates
                </div>
              </div>

              <IconButton
                onClick={() => {
                  setShowMessageTemplates(false);
                }}
              >
                <X size={24} />
              </IconButton>
            </div>
            <div
              className={css`
                margin: 32px;
                margin-top: 0px;
              `}
            >
              <div
                className={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                `}
              >
                <div
                  className={css`
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 182.4%;

                    color: #25282d;
                  `}
                >
                  Template 1
                </div>
              </div>

              <div
                className={css`
                  background: #f5f7fa;
                  border-radius: 8px;
                  padding-top: 16px;
                  padding-left: 24px;
                  padding-right: 24px;
                  padding-bottom: 32px;
                  margin-top: 16px;
                  margin-bottom: 32px;
                `}
              >
                All {employeeName} Employees,
                <br />
                <br /> Open Enrollment time is here! This is your time to make your benefits elections for the upcoming
                year. Open enrollment opens [insert start date] and closes at midnight [insert end date]. <br />
                <br />
                Click the link below to get started:
              </div>

              <div
                className={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                `}
              >
                <div
                  className={css`
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 182.4%;

                    color: #25282d;
                  `}
                >
                  Template 2
                </div>
              </div>

              <div
                className={css`
                  background: #f5f7fa;
                  border-radius: 8px;
                  padding-top: 16px;
                  padding-left: 24px;
                  padding-right: 24px;
                  padding-bottom: 32px;
                  margin-top: 16px;
                  margin-bottom: 32px;
                `}
              >
                <p>Hey {employeeName} Employees,&nbsp;</p>
                <p>
                  Open Enrollment is here! Open Enrollment is your time to learn about the benefits offered to you and
                  to make your elections for the year. Open enrollment will be held from [insert start date - insert end
                  date] and closing at midnight.&nbsp;
                </p>
                <p>There are two parts to our Open Enrollment this year:&nbsp;</p>
                <ul>
                  <li>Get recommendations &amp; learn about the benefits (link below)</li>
                  <li>Enroll in your benefits (through [insert enrollment link])</li>
                </ul>
                <p>Click the link below to get started:</p>
                <p>https://britehr.app/{selectedCourse.VanityURL}</p>
              </div>

              <div
                className={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                `}
              >
                <div
                  className={css`
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 182.4%;

                    color: #25282d;
                  `}
                >
                  Template 3
                </div>
              </div>

              <div
                className={css`
                  background: #f5f7fa;
                  border-radius: 8px;
                  padding-top: 16px;
                  padding-left: 24px;
                  padding-right: 24px;
                  padding-bottom: 32px;
                  margin-top: 16px;
                  margin-bottom: 32px;
                `}
              >
                <p>
                  Open Enrollment for {employeeName} is here! Open Enrollment is your time to learn about the benefits
                  we offer and make your elections for the year. *All of these benefit elections will go into effect
                  [X/1/20XX] and cannot be updated until next year (*unless you have a qualified life event).
                </p>
                <p>Open Enrollment will be held from [X/X/20XX - X/X/20XX] closing at midnight.</p>
                <p>There are two parts to our Open Enrollment this year:</p>
                <ul>
                  <li>
                    Learn about the benefits <span style={{ color: '#4a86e8' }}>(NEW)</span>
                  </li>
                  <li>Elect benefits</li>
                </ul>
                <p>
                  <strong>Learning about the benefits - </strong>
                  <strong>https://britehr.app/{selectedCourse.VanityURL}</strong>
                </p>
                <p>
                  {employeeName} offers incredible employee benefits to our employees. We have incorporated a new way of
                  learning how each of these benefits work, including where to download their apps, quick links to find
                  providers/facilities, and even some recommendation tools to help you pick the right plan for you. Feel
                  free to share this link with those that you plan to enroll on your plans as well!
                </p>
                <p>*please know that you cannot make your benefits elections in this learning tool.</p>
                <p>
                  <strong>Elect Your Benefits - </strong>
                  <strong>[Hyperlink HRIS Enrollment Link]</strong>
                </p>
                <p>
                  Open Enrollment will be held [xx/x/20xx - xx/x/20xx] closing at midnight. During this period, log into
                  [insert HRIS name] [insert URL link] to either select or waive benefits. On [xx/x/20xx] after the
                  window closes, all selections will be locked-in and sent to the carriers. Please note that you must
                  select <strong>"Agree"</strong> in the Enrollment Summary Screen to complete your open enrollment.
                </p>
                <p>Sincerely,</p>
                <p>[Insert Company Contact]</p>
              </div>
            </div>
          </>
        </Modal>
      )}

      {showSelectGuide && (
        <Modal
          onClose={() => {
            setShowSelectGuide(false);
          }}
          display
          css={`
            border-radius: 16px;
            padding-bottom: 32px;
            min-width: 500px;
          `}
        >
          <>
            <div
              className={css`
                padding-left: 32px;
                padding-right: 32px;
                padding-top: 32px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 168px;
              `}
            >
              <div
                className={css`
                  font-family: 'Roboto';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 24px;
                  line-height: 133.4%;
                  /* identical to box height, or 32px */

                  color: #25282d;
                `}
              >
                Select a Guide to Send
              </div>
              <div>
                <IconButton onClick={() => setShowSelectGuide(false)}>
                  <X />
                </IconButton>
              </div>
            </div>

            <div
              className={css`
                border-bottom: 1px solid #d1dae3;
                padding-top: 16px;
                padding-bottom: 16px;
              `}
            >
              <div
                className={css`
                  padding-left: 32px;
                  padding-right: 32px;
                  font-family: 'Roboto';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 24px;

                  /* identical to box height, or 171% */

                  letter-spacing: 0.15px;

                  color: #25282d;
                `}
              >
                Search
              </div>
              <TextField
                placeholder="Search by guide name"
                variant="outlined"
                style={{
                  width: '100%',
                  paddingLeft: '32px',
                  paddingRight: '32px',
                }}
                value={guideSearchText}
                onChange={(e) => {
                  setGuideSearchText(e.target.value);
                }}
              />
            </div>

            <div
              className={css`
                padding: 32px;
                max-height: 35vh;
                overflow: auto;
                border-bottom: 1px solid ${colors.gray[300]};
              `}
            >
              {courses.filter((course) => {
                if (guideSearchText === '') return courses;
                return course.Name.toLowerCase().includes(guideSearchText.toLowerCase());
              }).length === 0 && (
                <div>
                  No Brite Guides available
                  {guideSearchText === '' ? '.' : ' that match your search.'}
                </div>
              )}
              {courses
                .filter((course) => {
                  if (guideSearchText === '') return courses;
                  return course.Name.toLowerCase().includes(guideSearchText.toLowerCase());
                })
                .map((course) => {
                  if (course.ArchivedAt) {
                    return null;
                  }
                  return (
                    <div
                      onClick={() => {
                        if (course.VanityURL) {
                          setShowSendGuide(true);
                        } else {
                          setNewVanityURL('');
                          setVanityURLErrorMsg('');
                          setShowSetVanityURL(true);
                        }

                        setShowSelectGuide(false);
                        setSelectedCourse(course);
                      }}
                      key={course.ID}
                      className={css`
                        border: 1px solid #9aa7b5;
                        padding: 16px;
                        border-radius: 8px;
                        cursor: pointer;
                        margin-bottom: 16px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        :hover {
                          outline: 2px solid ${colors.black};
                          border: 1px solid transparent;
                        }
                      `}
                    >
                      <div>
                        <div
                          className={css`
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 133.4%;
                            /* or 29px */

                            color: #25282d;
                          `}
                        >
                          {course.Name}
                        </div>
                        <div
                          className={css`
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 150%;
                            /* identical to box height, or 24px */

                            letter-spacing: 0.15px;

                            color: #66737f;
                          `}
                        >
                          {format(new Date(course.CreatedAt), 'MMM dd yyyy ')}
                        </div>
                      </div>
                      <div>{course.VanityURL ? <LinkSimple color="#25282D" /> : <LinkBreak color="#EB4E3D" />}</div>
                    </div>
                  );
                })}
            </div>
          </>
        </Modal>
      )}
    </Box>
  );
};
