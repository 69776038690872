import { useDropzone } from 'react-dropzone';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { media } from '../react-query/resources/media';
import { useRequests } from '../react-query/use-resource';

export const useUploadMedia = ({ setLoading, accept = [], onSuccess, searchParams = {}, maxFiles = null }) => {
  const requests = useRequests();

  const uploadMedia = useCallback(async (files) => {
    try {
      setLoading(true);
      //Upload Media
      const list = await Promise.allSettled(
        files.map((file) => {
          const postMeta = media?.utils?.getPost(file, searchParams);
          return requests.post(postMeta);
        })
      );
      onSuccess(list.filter((item) => item?.status === 'fulfilled')?.map((item) => item?.value?.data));
    } catch (err) {
      console.warn(err);
      toast.error('Failed to upload media.');
    } finally {
      setLoading(false);
    }
  }, []);

  const handleMedia = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0 && (maxFiles === null || acceptedFiles.length <= maxFiles)) {
        const files = acceptedFiles?.map((item) => media.utils.extractFileObject(item));
        uploadMedia(files);
      } else {
        toast.error('Invalid file or too many files');
      }
    },
    [maxFiles, uploadMedia]
  );

  const dropzone = useDropzone({
    onDrop: handleMedia,
    accept,
    noDragEventsBubbling: true,
  });

  return {
    ...dropzone,
  };
};
