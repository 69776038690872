import { css } from '@emotion/css';
import { flex } from '../shared-styles';
import { colors } from '../styles';
import { Div } from './div';

export const Icon = ({
  SVG,
  size = 32,
  weight = 'normal',
  color = colors.black,
  containerStyle = {},
  css: cssString = '',
  ...rest
}) => {
  const weightNumber = weight === 'light' ? '1.25' : weight === 'normal' ? '1.5' : weight === 'bold' ? '2' : weight;

  if (SVG === null) {
    return null;
  }

  return (
    <Div
      css={css`
        ${flex('center')}
        ${cssString}
      `}
      style={containerStyle}
    >
      <SVG fill="none" stroke={color} width={size} height={size} weight={weight} strokeWidth={weightNumber} {...rest} />
    </Div>
  );
};
