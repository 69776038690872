// LAYOUT
// selection.location === [2, 0, 1]
// selection.location is an array of coordinates that correspond
// to a components location. For example, to select *LIST.ITEM1
// the location would be:

import { cloneDeep, get, isEqual, set } from 'lodash';
import { defaultComponents } from '../constants';
import { draftToSlate } from '../editor-components/text-v2/slate-utils';
import { defaultLayout } from './provider';
import * as uuid from 'uuid';

export const getLocationPath = (location = []) => {
  return location.reduce((prev, index, count) => {
    if (count === 0) {
      return prev + `rows[${index}]`;
    } else if (count === 1) {
      return prev + `.columns[${index}]`;
    } else if (count === 2) {
      return prev + `.list[${index}]`;
    } else {
      return prev + `[${index}]`;
    }
  }, '');
};

export const updateLayout = (layout, location, data) => {
  let next = cloneDeep(layout);
  const locationPath = getLocationPath(location);
  next = set(next, locationPath, data);
  return next;
};

export const getRowId = () => uuid.v4();

export const getNewDefaultRow = (item, overrides = {}) => {
  const rowId = getRowId();
  return {
    type: 'default',
    columns: [item],
    rowId,
    stackColumns: true,
    ...overrides,
  };
};

// Returns the item at the 'location' + 'path'
export const getLayoutSelection = (layout, location, path = '') => {
  let locationPath = getLocationPath(location);
  if (path) {
    locationPath += '.' + path;
  }
  return get(layout, locationPath);
};

export const iterateColumn = (column, fn) => {
  if (column?.type === 'list') {
    for (let listIdx = 0; listIdx < column?.list?.length; listIdx++) {
      fn([listIdx], column?.list?.[listIdx]);
    }
  } else {
    fn([], column);
  }
};

export const iterateColumns = (columns, fn) => {
  for (let colIdx = 0; colIdx < columns?.length; colIdx++) {
    const column = columns[colIdx];
    iterateColumn(column, (indices, item) => {
      const location = [colIdx, ...indices];
      fn(location, item);
    });
  }
};

export const iterateLayout = (layout, fn) => {
  for (let rowIdx = 0; rowIdx < layout?.rows?.length; rowIdx++) {
    const columns = layout?.rows?.[rowIdx]?.columns;
    iterateColumns(columns, (location, item) => {
      fn([rowIdx, ...location], item);
    });
  }
};

export const getLastIndices = (editor) => {
  const { layout } = editor?.state;
  const lastRow = layout?.rows?.length - 1;
  const lastCol = layout?.rows?.at(-1)?.columns?.length - 1;
  return layout?.rows?.at(-1)?.columns?.at(-1)?.type === 'list'
    ? [lastRow, lastCol, layout?.rows?.at(-1)?.columns?.at(-1)?.list?.length - 1]
    : [lastRow, lastCol];
};
export const getNextLocation = (editor, direction, location) => {
  const get = (location) => getSelection(editor, location);
  let current = location;
  let isLooping = true;
  let count = 0;
  const directionValue = direction === 'forward' ? 1 : direction === 'backward' ? -1 : 1;
  while (true) {
    const [row, column, listIdx] = current;
    const parent = get(current.slice(0, -1));
    const element = get(current);
    if (parent?.type === 'list') {
      if (!!element) {
        if (direction === 'backward' && listIdx + directionValue < 0) {
          current = [row, column + directionValue];
        } else {
          current = [row, column, listIdx + directionValue];
        }
      } else if (parent?.type === 'list' && !element) {
        const nextColumn = column + directionValue;
        current = [row, nextColumn];
      }
    } else if (parent?.type === 'default') {
      if (element?.type === 'list') {
        const nextListIdx = direction === 'forward' ? 0 : direction === 'backward' ? element?.list?.length - 1 : 0;
        current = [row, column, nextListIdx];
      } else if (!!element) {
        if (
          (direction === 'forward' && column + directionValue === parent?.columns?.length) ||
          (direction === 'backward' && column + directionValue < 0)
        ) {
          current = [row + directionValue, 0];
        } else {
          current = [row, column + directionValue];
        }
      }
    }

    const check = get(current);
    isLooping = check?.type === 'list' && !!check;

    count++;
    if (!isLooping || count > 10000) {
      if (!check) {
        // If the "current" indices doesn't return anything
        // from the layout then set the "current" indices
        // to an un-selected state. (null)
        current = null;
      }
      break;
    }
  }
  return current;
};

// Removes the item at 'location' and returns the new rows
export const removeItem = (layout, location) => {
  const [rowIdx, colIdx, subRowIdx = null] = location;
  if (location.length === 2 && layout?.rows?.[rowIdx]?.columns?.length === 1) {
    let rows = [...layout?.rows];
    const row = rows?.[rowIdx];
    return rows?.filter(({ rowId }) => rowId !== row.rowId);
  } else {
    return [...layout?.rows]?.map((row, idx1) => {
      if (rowIdx === idx1) {
        const columns = row.columns.reduce((prev, value, idx2) => {
          if (subRowIdx === null) {
            if (idx2 === colIdx) {
              return [...prev];
            } else {
              // eslint-disable-next-line
              const { width = null, ...rest } = value;
              const nextColumnItem = [...prev, rest];
              return nextColumnItem;
            }
          } else if (value?.type === 'list' && subRowIdx !== null && idx2 === colIdx) {
            const { width = null, list } = value;
            const columnRows = list.filter((_, idx3) => idx3 !== subRowIdx);
            if (!columnRows?.length) {
              return prev;
            } else if (columnRows?.length === 1) {
              const widthValue = width === null ? {} : { width };
              return [...prev, { ...columnRows[0], ...widthValue }];
            } else {
              return [...prev, { ...value, list: columnRows }];
            }
          } else {
            // eslint-disable-next-line
            const { width = null, ...rest } = value;
            return [...prev, rest];
          }
        }, []);

        return { ...row, columns };
      }

      return row;
    });
  }
};

export const copyLayoutListItem = (layout, indices) => {
  const [rowIndex, columnIndex, listIndex] = indices;
  let rows = [...layout.rows];
  const row = layout.rows[rowIndex];
  let columns = [...row.columns];
  const column = columns[columnIndex];
  let list = [...column.list];
  const nextListItem = cloneDeep(list[listIndex]);
  const newListItem = { ...nextListItem, id: uuid.v4() };
  list.splice(listIndex + 1, 0, newListItem);
  columns.splice(columnIndex, 1, { ...column, list });
  rows.splice(rowIndex, 1, { ...row, columns });
  return rows;
};

export const copyLayoutColumn = (layout, indices) => {
  const [rowIndex, columnIndex] = indices;
  let rows = [...layout.rows];
  const row = layout.rows[rowIndex];
  let columns = [...row.columns];
  const nextColumn = cloneDeep(row.columns[columnIndex]);
  const newColumn = { ...nextColumn, id: uuid.v4() };
  columns.splice(columnIndex + 1, 0, newColumn);
  columns = columns.map(({ width, ...rest }) => rest);
  rows.splice(rowIndex, 1, { ...row, columns });
  return rows;
};

export const copyLayoutRow = (layout, index) => {
  const rowId = getRowId();
  let newRow = cloneDeep(layout.rows[index]);
  if (newRow?.columns?.length) {
    iterateColumns(layout.rows[index]?.columns, (location, item) => {
      const [colIdx = null, listIdx = null] = location || [];
      if (listIdx !== null && colIdx !== null) {
        newRow.columns[colIdx].list[listIdx] = { ...item, id: uuid.v4() };
      } else if (colIdx !== null) {
        newRow.columns[colIdx] = { ...item, id: uuid.v4() };
      }
    });
  }
  const copy = { ...newRow, rowId };
  let rows = [...layout?.rows];
  rows.splice(index + 1, 0, copy);
  return rows;
};

export const copyMultipleLayoutRows = (layout, copyRows, index) => {
  const copiedRows = copyRows?.map((item) => {
    const rowId = getRowId();
    let newRow = cloneDeep(item);
    iterateColumns(newRow?.columns, (location, item) => {
      const [colIdx = null, listIdx = null] = location || [];
      if (listIdx !== null && colIdx !== null) {
        newRow.columns[colIdx].list[listIdx] = { ...item, id: uuid.v4() };
      } else if (colIdx !== null) {
        newRow.columns[colIdx] = { ...item, id: uuid.v4() };
      }
    });
    return { ...newRow, rowId };
  });
  let rows = [...layout?.rows];
  rows.splice(index + 1, 0, ...copiedRows);
  return rows;
};

export const cleanUpRows = (rows) => {
  const next = rows.reduce((prev, item) => {
    if (item?.columns?.length === 1) {
      if (item?.columns?.[0]?.type === 'list') {
        const list = item?.columns?.[0]?.list?.map((item) => getNewDefaultRow(item));
        return [...prev, ...list];
      }
    }
    return [...prev, item];
  }, []);
  return next;
};

// ROW0: COLUMN0

// ROW1: COLUMN0      COLUMN1

// ROW2: COLUMN0      COLUMN1
//       LIST.ITEM0
//       *LIST.ITEM1

export const handleConvert = (layout) => {
  let next = cloneDeep(layout);
  iterateLayout(layout, (location) => {
    const item = getLayoutSelection(layout, location);
    const { rawState, ...rest } = item;
    const path = getLocationPath(location);
    try {
      if (item.component === 'text') {
        const slateContent = draftToSlate(item?.content);
        const nextItem = {
          ...rest,
          component: 'textV2',
          slateContent,
        };
        next = set(next, path, nextItem);
      }
    } catch (err) {
      const nextItem = {
        ...rest,
        component: 'textV2',
        slateContent: defaultComponents?.textV2?.slateContent,
      };
      next = set(next, path, nextItem);
    }
  });
  return next;
};

const initializeContent = (data, textConversion = false) => {
  if (!data) {
    return cloneDeep(defaultLayout);
  } else {
    if (textConversion) {
      const next = handleConvert(data);
      return next;
    }
    return cloneDeep(data);
  }
};

export const editorUtils = {
  getHistoryAtSelection: (editor, data = {}) => {
    try {
      const { history, pageId } = data;
      const location = editor?.state?.selection;
      const [r, c, cl = null] = location;
      const rowId = editor?.state?.layout?.rows?.[r]?.rowId || '';
      let list = [];
      let last = null;
      for (const version of history) {
        const layout = version?.Content;
        let item = null;
        if (pageId === version?.PageID && !!version?.ID) {
          const row = layout?.rows?.find((row) => row?.rowId === rowId);
          item = row?.columns?.[c];
          if (item?.type === 'list' && cl !== null) {
            item = item?.list?.[cl];
          }
        }
        const hasChanges = !isEqual(item, last);
        if (hasChanges && !!item) {
          last = item;
          list.push({ change: item, version });
        }
      }
      return list;
    } catch (err) {
      console.log(err);
      return [];
    }
  },
  isLocationAtSelection: (editor, location) => {
    return location?.toString() === editor?.state?.selection?.toString();
  },
  getSelection: (editor, location = null) => {
    const { state } = editor;
    const locationIndices = location === null ? state?.selection : location;
    return locationIndices?.reduce((prev, locationIdx, idx) => {
      if (idx === 0) {
        return prev?.[locationIdx];
      } else if (idx === 1) {
        return prev?.columns?.[locationIdx];
      } else if (idx === 2) {
        return prev?.list?.[locationIdx];
      }
      return prev;
    }, state?.layout?.rows);
  },
  initializeContent: (editor, nextLayout, options = {}) => {
    const { dispatch, state } = editor;
    const layout = initializeContent(nextLayout, state?.flags?.textConversion);
    dispatch({
      type: 'initialize-content',
      layout,
      options: {
        disableSave: true,
        syncRenderState: true,
        ...options,
      },
    });
  },
  setProperty: (editor, key, value, options = {}) => {
    const { dispatch } = editor;
    dispatch({
      type: 'set-property',
      key,
      value,
      options,
    });
  },
  completeSave: (editor, options = {}) => {
    const { dispatch } = editor;
    dispatch({ type: 'complete-save-changes', options });
  },
};

export const modifyContent = {
  set: (editor, location, updates, options = {}) => {
    const { dispatch } = editor;
    // If `location` is [], `updates` will replace state.layout.rows
    dispatch({
      type: 'set-layout-content',
      location,
      updates,
      options,
    });
  },
  merge: (editor, location, updates, options = {}) => {
    const { dispatch } = editor;
    dispatch({
      type: 'merge-layout-content',
      location,
      updates,
      options,
    });
  },
  remove: (editor, location, options = {}) => {
    const { dispatch } = editor;
    dispatch({
      type: 'remove-layout-content',
      location,
      options,
    });
  },
};
