import { css, cx } from '@emotion/css';

const flexOptions = {
  'space-between': `justify-content: space-between; align-items: center;`,
  'space-around': `justify-content: space-around; align-items: center;`,
  'space-evenly': `justify-content: space-evenly; align-items: center;`,
  center: `justify-content: center; align-items: center;`,
  left: `justify-content: left; align-items: center;`,
  right: `justify-content: right; align-items: center;`,
  middle: `align-items: center;`,
  start: `align-items: start;`,
  end: `align-items: end;`,
  stretch: `align-items: stretch;`,

  column: `flex-direction: column;`,
  row: `flex-direction: row;`,
  'column-reverse': `flex-direction: column-reverse;`,
  'row-reverse': `flex-direction: row-reverse;`,
  grow: `flex-grow: 1;`,
  wrap: `flex-wrap: wrap;`,
  'wrap-reverse': 'flex-wrap: wrap-reverse;',
};

const handleFlex = (options) => {
  const values = options?.split(' ') || [];
  const style = values.reduce((prev, item) => {
    return prev + ' ' + flexOptions?.[item] || '';
  }, `display: flex;`);
  return style;
};

export const useThemedProps = (props, theme = {}) => {
  const { css: cssString, flex = '', className = '', ...rest } = props;

  const { __styles, ...properties } = Object.entries(rest)?.reduce(
    (prev, [key, value]) => {
      if (value === true && key in theme) {
        return { ...prev, __styles: prev.__styles + theme[key] };
      }
      return {
        ...prev,
        [key]: value,
      };
    },
    { __styles: theme?.default || '' }
  );

  const flexStyles = flex ? handleFlex(flex) : '';

  return {
    ...properties,
    className: cx(
      css`
        ${__styles}
        ${flexStyles}
      ${cssString}
      `,
      className
    ),
  };
};
