import React from 'react';
import { CustomAxios } from '../redux/axios/axios';
import { css } from '@emotion/css';
import { cloneDeep } from 'lodash';
import { Button, Div, Modal, Text } from '../shared/components';
import { container, flex } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { toast } from 'react-toastify';

class SimpleReactFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      fileDisplay: null,
      loading: false,
      uploadFailed: false,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.resize = this.resizeAndUpload.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);
  }

  onDeleteImage() {
    CustomAxios.get(`/v1/course/${this.props.headerImageCourse.ID}`)
      .then((courseResponse) => {
        let course = courseResponse.data;
        let clonedCourse = cloneDeep(course);
        clonedCourse.LogoURL = '';

        CustomAxios.put(`/v1/course/${this.props.headerImageCourse.ID}`, clonedCourse)
          .then(() => {
            // get full course and update it with LogoURL updated
            this.setState({ loading: false });
            this.props.closeModal();
            this.props.refresh();
          })
          .catch(() => {
            toast.error(
              `Unable to remove image, another user may be editing this guide or you may not be able to save changes.`
            );
            this.setState({ loading: false });
            this.props.closeModal();
          });
      })
      .catch(() => {
        toast.error(
          `Unable to remove image, another user may be editing this guide or you may not be able to save changes.`
        );
        this.setState({ loading: false });
        this.props.closeModal();
      });
  }

  onFormSubmit(e) {
    e.preventDefault(); // Stop form submit
    this.setState({ loading: true });
    this.fileUpload(this.state.file)
      .then((response) => {
        CustomAxios.get(`/v1/course/${this.props.headerImageCourse.ID}`)
          .then((courseResponse) => {
            let course = courseResponse.data;
            let clonedCourse = cloneDeep(course);
            clonedCourse.LogoURL = response.data || '';

            CustomAxios.put(`/v1/course/${this.props.headerImageCourse.ID}`, clonedCourse)
              .then(() => {
                // get full course and update it with LogoURL updated
                this.setState({ loading: false });
                this.props.closeModal();
                this.props.refresh();
              })
              .catch(() => {
                this.setState({ loading: false });
                this.props.closeModal();
              });
          })
          .catch(() => {
            this.setState({ loading: false });
            this.props.closeModal();
          });
      })
      .catch((er) => {
        this.setState({ loading: false });
        this.setState({ uploadFailed: true });
        toast.error(
          `Unable to add image, another user may be editing this guide or you may not be able to save changes.`
        );
      });
  }
  onChange(e) {
    this.setState({
      file: e.target.files[0],
      fileDisplay: URL.createObjectURL(e.target.files[0]),
    });
  }
  fileUpload(file) {
    const url = '/v1/media';
    const formData = new FormData();
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    if (file.size > 1000000) {
      return this.resizeAndUpload(file, 1000, 1000, file.type, url, config, formData);
    } else {
      formData.append('file', file);
      return CustomAxios.post(url, formData, config);
    }
  }

  // resizeAndUpload will attempt (for up to 5 seconds) to load the image into a hidden canvas and then shrink it without changing aspect ratio.
  resizeAndUpload(file, max_width, max_height, imageEncoding, url, config, formData) {
    var fileLoader = new FileReader(),
      imageObj = new Image();

    fileLoader.onload = function () {
      var data = this.result;
      imageObj.src = data;
    };

    fileLoader.readAsDataURL(file);

    // resizeResult is a promise because we need to wait for the data URI to read into the buffer which is async.
    const resizeResult = new Promise((resolve, reject) => {
      setTimeout(() => {
        // Give up after 5 seconds if we can't load the image.
        reject('Image took too long to load');
      }, 5000);

      imageObj.onload = function () {
        if (!file.type.match('image.*')) {
          reject('Not an image');
        }
        // Check for empty images
        if (this.width === 0 || this.height === 0) {
          reject('Image is empty');
        } else {
          var hRatio = max_width / this.width;
          var vRatio = max_height / this.height;
          var ratio = Math.min(hRatio, vRatio);

          //create a hidden canvas object we can use to create the new resized image data
          var canvas = document.createElement('canvas');
          canvas.id = 'hiddenCanvas';
          canvas.width = this.width * ratio;
          canvas.height = this.height * ratio;
          canvas.style.visibility = 'hidden';
          document.body.appendChild(canvas);

          let context = canvas.getContext('2d');
          context.drawImage(imageObj, 0, 0, this.width, this.height, 0, 0, this.width * ratio, this.height * ratio);

          var byteString = atob(canvas.toDataURL(imageEncoding).split(',')[1]);
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }

          const blob = new Blob([ab], { type: file.type });
          const result = new File([blob], file.name, {
            type: file.type,
            lastModified: file.lastModified,
          });
          formData.append('file', result);
          CustomAxios.post(url, formData, config)
            .then((response) => {
              resolve(response);
            })
            .catch((er) => {
              reject(er);
            });
        }
      };

      fileLoader.onabort = function () {
        reject('The upload was aborted.');
      };

      fileLoader.onerror = function () {
        reject('An error occured while reading the file.');
      };

      imageObj.onabort = function () {
        reject('Image load was aborted.');
      };

      imageObj.onerror = function () {
        reject('An error occured while loading image.');
      };
    });

    return resizeResult;
  }

  render() {
    return (
      <Modal display={true} onClose={this.props.closeModal}>
        <Modal.Paper width="500px">
          <Modal.Header
            title={`${this.props.headerImageCourse.LogoURL ? 'Update ' : 'Upload '} Header Image`}
            onClose={this.props.closeModal}
          />
          <form onSubmit={this.onFormSubmit}>
            <Modal.Body>
              <Text>An image header will appear in the top left corner of each page throughout your guide.</Text>
              {this.props.headerImageCourse.LogoURL && (
                <Div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 24px;
                  `}
                >
                  <img
                    src={this.props.headerImageCourse.LogoURL}
                    alt="guide header"
                    className={css`
                      max-height: 234px;
                      max-width: 436px;
                    `}
                  />
                </Div>
              )}
              {!this.props.headerImageCourse.LogoURL && !this.state.fileDisplay && (
                <Div
                  css={css`
                    position: relative;
                    ${flex('center')}
                    border: 1px dashed ${colors.gray[500]};
                    margin: 32px 0;
                    padding: 8px;
                    padding-top: 24px;
                    border-radius: 8px;
                    ${container.hover}
                    padding-bottom: 24px;
                    height: 160px;
                    input {
                      cursor: pointer;
                      position: absolute;
                      opacity: 0;
                      top: 0;
                      bottom: 0;
                      left: 0;
                      right: 0;
                    }
                  `}
                >
                  <Text label>Click to upload image</Text>
                  <input type="file" onChange={this.onChange} />
                </Div>
              )}
              {this.state.fileDisplay && (
                <Div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 24px;
                  `}
                >
                  <img
                    src={this.state.fileDisplay}
                    alt="guide header"
                    className={css`
                      max-height: 234px;
                      max-width: 436px;
                    `}
                  />
                </Div>
              )}
            </Modal.Body>
            <Modal.Actions>
              {this.props.headerImageCourse.LogoURL ? (
                <>
                  <Button
                    secondary
                    onClick={() => {
                      this.props.closeModal();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      this.onDeleteImage();
                    }}
                    className={css`
                      margin-left: 16px;
                    `}
                  >
                    Delete Image
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    secondary
                    onClick={() => {
                      this.props.closeModal();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={!this.state.fileDisplay || this.state.loading}
                    className={css`
                      color: white;
                      margin-left: 16px;
                    `}
                  >
                    {this.state.loading ? 'Uploading...' : 'Save Image'}
                  </Button>
                </>
              )}
            </Modal.Actions>
          </form>
        </Modal.Paper>
      </Modal>
    );
  }
}

export default SimpleReactFileUpload;
