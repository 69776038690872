import { Element } from 'slate';
import { Link, SmartField } from './slate-components';
import { ListType } from '@prezly/slate-lists';
import { css } from '@emotion/css';

// SlateToHTML Serializer config
// *****************************************************************************
export const elementMap = {
  left: (props) => (
    <p {...props.attributes} style={{ textAlign: 'left' }}>
      {props.children}
    </p>
  ),
  center: (props) => (
    <p {...props.attributes} style={{ textAlign: 'center' }}>
      {props.children}
    </p>
  ),
  right: (props) => (
    <p {...props.attributes} style={{ textAlign: 'right' }}>
      {props.children}
    </p>
  ),
  link: Link,
  'smart-field': SmartField,
  'list-item': (props) => {
    // list-items are not selectable for some reason so we're gonna
    // grab the color of the first character in the list.
    const firstChildColor = props?.element?.children?.[0]?.children?.[0]?.color;
    const className = firstChildColor
      ? css`
          color: ${firstChildColor};
        `
      : '';

    return (
      <li {...props.attributes} className={className}>
        {props.children}
      </li>
    );
  },
  'list-item-text': (props) => <div {...props.attributes}>{props.children}</div>,
  'unordered-list': (props) => {
    return (
      <ul {...props.attributes} style={{ margin: 0 }}>
        {props.children}
      </ul>
    );
  },
  'ordered-list': (props) => {
    return (
      <ol {...props.attributes} style={{ margin: 0 }}>
        {props.children}
      </ol>
    );
  },
};

// Slate map: from "mark types" to "inline styles"
// *****************************************************************************
export const markStyleMap = {
  bold: { style: () => ({ fontWeight: 'bold' }), isTransformer: false },
  italic: { style: () => ({ fontStyle: 'italic' }), isTransformer: false },
  underline: {
    style: () => ({ textDecoration: 'underline' }),
    isTransformer: false,
  },
  strikethrough: {
    style: () => ({ textDecoration: 'line-through' }),
    isTransformer: true,
  },
  highlight: {
    style: () => ({ backgroundColor: 'rgba(0, 0, 0, .1)' }),
    isTransformer: true,
  },
  superscript: {
    style: () => ({
      fontSize: '.83em',
      verticalAlign: 'super',
    }),
    isTransformer: true,
  },
  subscript: {
    style: () => ({
      fontSize: '.83em',
      verticalAlign: 'sub',
    }),
    isTransformer: true,
  },
  color: {
    style: ({ color }) => ({ color }),
    isTransformer: true,
  },
};

export const listSchema = {
  isConvertibleToListTextNode: (node) => {
    return (
      Element.isElementType(node, 'p') ||
      Element.isElementType(node, 'center') ||
      Element.isElementType(node, 'left') ||
      Element.isElementType(node, 'right')
    );
  },
  isDefaultTextNode: (node) => Element.isElementType(node, 'p'),
  isListNode: (node, type) => {
    if (type === ListType.ORDERED) {
      return Element.isElementType(node, 'ordered-list');
    }

    if (type === ListType.UNORDERED) {
      return Element.isElementType(node, 'unordered-list');
    }

    return Element.isElementType(node, 'ordered-list') || Element.isElementType(node, 'unordered-list');
  },
  isListItemNode: (node) => Element.isElementType(node, 'list-item'),
  isListItemTextNode: (node) => Element.isElementType(node, 'list-item-text'),
  createDefaultTextNode: (props = {}) => {
    return { children: [{ text: '' }], ...props, type: 'p' };
  },
  createListNode: (type, props = {}) => {
    const nodeType = type === ListType.ORDERED || type === 'ordered-list' ? 'ordered-list' : 'unordered-list';
    return { children: [{ text: '' }], ...props, type: nodeType };
  },
  createListItemNode: (props = {}) => {
    return { children: [{ text: '' }], ...props, type: 'list-item' };
  },
  createListItemTextNode: (props = {}) => {
    return { children: [{ text: '' }], ...props, type: 'list-item-text' };
  },
};
