import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import { Menu, MenuItem, CircularProgress, IconButton, Typography } from '@material-ui/core';
import { useQueryAPI } from '../react-query';
import { DotsThree, X } from 'phosphor-react';
import { toast } from 'react-toastify';
import { CustomAxios } from '../redux/axios/axios';
import { useStore } from '../store-provider/use-store';
import { FormatPhoneNumber } from '../lib/communications/formatting';
import { Text, Button, Modal, Input, Box } from '../shared/components';
import { BriteLoader } from '../shared/components/brite-loader';
import { flex } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { Tooltip } from '../common/components/Tooltip';

const container = css`
  width: 100%;
  max-width: 700px;
  margin-top: 32px;
  margin-bottom: 24px;
  padding-bottom: 48px;
  border-bottom: 1px solid ${colors.gray[300]};
`;

const itemContainer = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  max-width: 700px;
  padding: 16px;
  padding-bottom: 0;
  p {
    width: 30%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  button {
    margin-left: auto;
  }
`;

export const validateEmail = (email) => {
  if (!email) {
    return false;
  }
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validatePhone = (phone) => {
  const re = /^[0-9]+$/g;
  const test = re.test(phone);
  const length = phone.length === 10 || (phone.length === 11 && phone.substring(0, 1) === '1');
  return test && length;
};

export const ContactInfo = ({ course, updateCourse }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editing, setEditing] = useState(false);
  const [assignedId, setAssignedId] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [validPhone, setValidPhone] = useState(true);
  const [contact, setContact] = useState({
    Email: '',
    PhoneNumber: '',
    Description: '',
  });

  const {
    data: { selectedBusiness },
  } = useStore();
  const { ID: businessId = '' } = selectedBusiness;

  const { data, cache, refetch } = useQueryAPI({
    url: `/v1/contactinfo/list/${businessId}`,
    defaultValue: [],
    enabled: !!businessId,
  });

  const updateContact = (key, value) => {
    if (key === 'PhoneNumber') {
      value = value.replace(/[^0-9]/g, '');
    }
    setContact((contact) => ({ ...contact, [key]: value }));
  };

  const handleSave = async (method, contact) => {
    const url = method === 'post' ? `/v1/contactinfo` : `/v1/contactinfo/${contact.ID}`;
    let old = [...(data || [])];
    try {
      if (method === 'post') {
        cache.setData([...old, contact]);
      }
      await CustomAxios[method](url, contact);
      refetch();
    } catch (err) {
      console.warn(err);
      if (method === 'post') {
        cache.setData(() => old);
      }
      toast.error(`We couldn't save your changes. Please try again.`);
    }
  };

  const saveContactInfo = () => {
    if (validEmail && validPhone) {
      if (contact.ID) {
        const { ID, BusinessID, Email, Description, PhoneNumber } = contact;
        handleSave('put', { ID, BusinessID, Email, Description, PhoneNumber });
      } else {
        handleSave('post', { BusinessID: businessId, ...contact });
      }
      setEditing(false);
    } else {
      toast.error(`Fix the invalid fields before saving!`);
    }
  };

  const deleteContact = async () => {
    setAnchorEl(null);
    let old = data;
    try {
      cache.setData(data.filter(({ ID }) => ID !== contact.ID));
      await CustomAxios.delete(`/v1/contactinfo/${contact.ID}`);
    } catch (err) {
      console.warn(err);
      toast.error(`Couldn't delete the contact. Please try again`);
      cache.setData(() => old);
    }
  };

  const handleEdit = () => {
    setAnchorEl(null);
    setEditing((edit) => !edit);
  };

  const handleNew = (e) => {
    handleEdit(e, {
      PhoneNumber: '',
      Email: '',
      Description: '',
    });
  };

  const openMoreMenu = (event, contact) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setContact(contact);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleAssign = async () => {
    setAnchorEl(null);

    setAssignedId(contact.ID);

    updateCourse({ ...course, ContactInfoID: contact.ID });
  };

  useEffect(() => {
    if (course && course.ContactInfoID) {
      setAssignedId(course.ContactInfoID);
    }
  }, [course && course.ContactInfoID]);

  return (
    <div className={container}>
      <Text h4>Guide Contacts</Text>

      <Text
        css={`
          margin-top: 8px;
          margin-bottom: 16px;
        `}
      >
        Add a contact so employees can easily get in touch with the right people on your team.
      </Text>

      {!data || (data && !data.length && <BriteLoader overlay={false} />)}

      {data &&
        data.map((contact) => (
          <Box
            css={`
              margin: 16px 0;
            `}
            key={contact.ID}
            onClick={() => {
              setContact(contact);
              handleEdit();
            }}
          >
            <div>
              <div className={itemContainer}>
                <Typography>{contact.Email}</Typography>
                <Typography>{FormatPhoneNumber(contact.PhoneNumber)}</Typography>
                <Typography>{contact.Description}</Typography>
                {!contact.ID || (contact.ID === assignedId && assignedId !== course.ContactInfoID) ? (
                  <CircularProgress style={{ margin: '8px', marginLeft: 'auto' }} />
                ) : (
                  <div
                    className={css`
                      margin-left: auto;
                      display: flex;
                      * > {
                        margin: 0 4px;
                      }
                    `}
                  >
                    <Tooltip label="More">
                      <IconButton onClick={(e) => openMoreMenu(e, contact)}>
                        <DotsThree />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
              {assignedId === contact.ID && assignedId === course.ContactInfoID && (
                <Typography
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginBottom: '8px',
                  }}
                >
                  Assigned to {course.Name}
                </Typography>
              )}
            </div>
          </Box>
        ))}

      <Button onClick={handleNew}>Add Contact</Button>

      <Modal display={editing} onClose={() => setEditing(false)}>
        <div
          className={css`
            width: 500px;
            margin: 32px;
          `}
        >
          <div
            className={css`
              ${flex('space-between')}
            `}
          >
            <Text h2>Contact</Text>
            <Button styles="icon" onClick={() => setEditing(false)}>
              <X />
            </Button>
          </div>

          <div
            className={css`
              margin: 24px 0;
            `}
          >
            <div
              className={css`
                ${flex('space-between')}
                margin: 8px 0;
              `}
            >
              <Text label>Email</Text>
              <Input
                css={`
                  width: 50%;
                `}
                value={contact.Email}
                onChange={(e) => updateContact('Email', e.target.value)}
                placeholder="Email"
                onBlur={() => setValidEmail(validateEmail(contact.Email))}
                error={!validEmail}
              />
            </div>

            <div
              className={css`
                ${flex('space-between')}
                margin: 8px 0;
              `}
            >
              <Text label>Phone</Text>
              <Input
                css={`
                  width: 50%;
                `}
                placeholder="Phone"
                value={FormatPhoneNumber(contact.PhoneNumber)}
                onBlur={() => setValidPhone(validatePhone(contact.PhoneNumber))}
                error={!validPhone}
                onChange={(e) => updateContact('PhoneNumber', e.target.value)}
              />
            </div>

            <div
              className={css`
                ${flex('space-between')}
                margin: 8px 0;
              `}
            >
              <Text label>Description</Text>
              <Input
                css={`
                  width: 50%;
                `}
                placeholder="Description"
                value={contact.Description}
                onChange={(e) => updateContact('Description', e.target.value)}
              />
            </div>
          </div>
          <div
            className={css`
              display: flex;
              justify-content: right;
              margin-top: 16px;
            `}
          >
            <Button
              secondary
              onClick={handleEdit}
              css={`
                margin-right: 16px;
              `}
            >
              Cancel
            </Button>
            <Button onClick={saveContactInfo}>Save</Button>
          </div>
        </div>
      </Modal>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={deleteContact}>Delete</MenuItem>
        {assignedId !== contact.ID && <MenuItem onClick={handleAssign}>Assign</MenuItem>}
      </Menu>
    </div>
  );
};
