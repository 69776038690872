import React, { useState } from 'react';
import { css } from '@emotion/css';
import { useAuth0 } from '../Auth/react-auth0-wrapper';
import logo from '../images/brite-logo-b.png';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { CustomAxios } from '../redux/axios/axios';
import { toast } from 'react-toastify';
import { Modal, Button, Text, Box } from '../shared/components';
import { BriteLoader } from '../shared/components/brite-loader';
import { shadows } from '../shared/shared-styles';

export const AcceptTerms = (props) => {
  const { setShowTermsPopup, logout } = useAuth0();
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const submit = async () => {
    if (agree) {
      setLoading(true);
      try {
        await CustomAxios.post(`/v1/terms`, {
          BusinessID: props.businessId,
          UserID: props.userId,
        });
        setLoading(false);
        setShowTermsPopup(false);
        setError(false);
      } catch (e) {
        setLoading(false);
        toast.error('Error saving. Please try submitting again.');
      }
    } else {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <Modal display={true} full>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          margin: 16px;
        `}
      >
        <img
          src={logo}
          alt="Logo"
          className={css`
            width: 83px;
            height: 35px;
          `}
        />

        <Button
          secondary
          onClick={() => {
            logout({ returnTo: window.location.origin });
          }}
        >
          Logout
        </Button>
      </div>

      <BriteLoader isLoading={loading} />
      <Box
        css={`
          height: 70vh;
        `}
        flex="center"
      >
        {!loading && (
          <Box
            css={`
              width: 480px;
              margin: auto;
              padding: 64px;
              background-color: white;
              border-radius: 8px;
              ${shadows.md}
            `}
          >
            <Text
              h1
              css={`
                margin-bottom: 32px;
              `}
            >
              Finish setting up your account
            </Text>
            <Text h4>Review the terms to continue</Text>

            <div className={css``}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agree}
                    color="default"
                    onChange={(e) => {
                      setAgree(e.target.checked);
                    }}
                    name="agree"
                  />
                }
                label={
                  <Text>
                    <span>
                      I agree to the{' '}
                      <a
                        className={css`
                          color: blue;
                          text-decoration: none;
                          &:hover {
                            color: black;
                            text-decoration: none;
                          }
                          &:visited {
                            color: blue;
                            text-decoration: none;
                          }
                        `}
                        href="https://share.hsforms.com/18ZIPbaK4QQGU3xaJ_0N2MA3ihp9"
                        target="_blank"
                      >
                        Terms of Use{' '}
                      </a>
                    </span>
                    <span>
                      I understand the{' '}
                      <a
                        className={css`
                          color: blue;
                          text-decoration: none;
                          &:hover {
                            color: black;
                            text-decoration: none;
                          }
                          &:visited {
                            color: blue;
                            text-decoration: none;
                          }
                        `}
                        href="https://share.hsforms.com/1UQbu6cvVR5-AJ09KpbxSjA3ihp9"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                    </span>
                    .
                  </Text>
                }
              />
            </div>
            <div
              className={css`
                margin: 14px auto;
              `}
            >
              <Button
                css={`
                  width: 200px;
                  margin-left: auto;
                `}
                onClick={() => {
                  submit();
                }}
                disabled={!agree}
              >
                Submit
              </Button>

              {error && (
                <div
                  className={css`
                    margin-top: 14px;
                    color: red;
                  `}
                >
                  Must agree to continue.
                </div>
              )}
            </div>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
