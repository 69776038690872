import { useMemo } from 'react';

export const FontLinks = ({ fontURLs }) => {
  const urls = useMemo(() => {
    let list = [];
    for (const fontUrl of fontURLs || []) {
      try {
        const url = new URL(fontUrl);
        list.push({ fontUrl, itemProp: url.search });
      } catch {}
    }
    return list;
  }, [fontURLs?.toString()]);

  return urls.map(({ fontUrl, itemProp }) => (
    <link key={fontUrl} itemProp={itemProp} rel="stylesheet" href={fontUrl} />
  ));
};
