import { css } from '@emotion/css';
import BriteLogo from '../../images/brite-logo.png';
import { colors } from '../../shared/styles';

export const PlaceholderElement = () => {
  return (
    <div
      className={css`
        width: 100%;
        height: 100px;
        background-color: linear-gradient(45deg, ${(colors.black, colors.gray[500], colors.black)});
      `}
    >
      <img src={BriteLogo} alt="Brite Logo" />
    </div>
  );
};
