import { format, isAfter } from 'date-fns';
import { Box, Button, Input, NewTag } from '../shared/components';
import Dates from '../shared/components/Dates';
import { Text, textTheme } from '../shared/components/text';
import { colors } from '../shared/styles';
import { DotsThree } from 'phosphor-react';
import { container } from '../shared/shared-styles';
import Calendar from '../images/Calendar.png';
import CalendarX from '../images/CalendarX.png';
import { history } from '../history';
import { useMemo } from 'react';
import { Dropdown } from '../shared/components/dropdown';
import { Column, Table } from '../shared/components/Table';

const tableStyle = `
  width: 100%;
  ${textTheme.label}
  border-spacing: 0;
  tr {
    text-align: left;
  }
  .table-row {
    :hover {
      ${container.hover}
    }
    td {
      padding: 16px;
      border-bottom: 1px solid ${colors.gray[300]};
    }
  }
  .table-header {
    th {
      padding: 16px;
      border-bottom: 1px solid ${colors.gray[300]};
    }
  }
`;

export const BenefitsPackageTable = ({
  list: pkgList,
  needsAttentionResource,
  editPackageName,
  pkgDescriptions,
  setPkgDescriptions,
  handleDuplicate,
  setSelectedPkg,
  setModal,
  saveDescriptions,
}) => {
  const handleRow = (pkg) => {
    if (pkg?.ID) {
      history.push(`packages/${pkg.ID}`);
    }
  };

  const listDep = pkgList?.map(({ ID }) => ID).join(',');
  const list = useMemo(() => {
    return pkgList.map((pkg) => {
      const description = pkg?.ID in pkgDescriptions ? { description: pkgDescriptions?.[pkg?.ID] } : {};
      return {
        ...pkg,
        ...description,
        comments: needsAttentionResource?.data?.[pkg?.ID]?.length,
      };
    });
  }, [needsAttentionResource?.query?.dataUpdatedAt, listDep, pkgDescriptions]);

  const dispatch = (action) => {
    if (action.type === 'delete') {
      setSelectedPkg(action.data);
      setModal('delete-package');
    } else if (action.type === 'row-click') {
      handleRow(action.data);
    } else if (action.type === 'edit-description') {
      setPkgDescriptions((des) => ({
        ...des,
        [action?.data?.ID]: action?.data?.value,
      }));
    } else if (action.type === 'save-descriptions') {
      saveDescriptions(action.data);
    } else if (action?.type === 'add-description') {
      setPkgDescriptions((des) => ({
        ...des,
        [action?.data]: '',
      }));
    } else if (action.type === 'edit-name') {
      editPackageName(action.data);
    } else if (action.type === 'duplicate') {
      handleDuplicate(action.data);
    } else if (action.type === 'delete') {
      setSelectedPkg(action.data);
      setModal('delete-package');
    }
  };

  return (
    <Table list={list} onRowClick={(data) => dispatch({ type: 'row-click', data })} css={tableStyle}>
      <Column width="40%" columnHeader="Name" columnKey="name">
        {(data) => (
          <Box
            css={`
              position: relative;
              width: 100%;
            `}
            flex="left"
          >
            <NewTag
              createdAt={data?.CreatedAt}
              css={`
                position: absolute;
                top: calc(50% - 16px);
                right: calc(100% + 16px);
              `}
            />
            <Box
              css={`
                width: 100%;
              `}
            >
              <Text h4>{data?.Name}</Text>
              <Input
                value={data?.description}
                onChange={(e) => {
                  dispatch({
                    type: 'edit-description',
                    data: {
                      ID: data?.ID,
                      value: e.target.value,
                    },
                  });
                }}
                onBlur={() => dispatch({ type: 'save-descriptions', data })}
                onClick={(e) => e.stopPropagation()}
                placeholder="Add a description..."
                css={`
                  input {
                    color: ${colors.gray[500]};
                  }
                  :focus-within {
                    background-color: white;
                  }
                  flex-grow: 1;
                  margin: 0;
                  margin-left: -8px;
                  padding: 0;
                  height: 32px;
                  border: none;
                  background: transparent;
                  ${'description' in data ? '' : 'display: none;'}
                  :hover {
                    border: none;
                  }
                `}
              />
            </Box>
          </Box>
        )}
      </Column>
      <Column width="15%" columnHeader="Plan Dates" columnKey="planDates">
        {(data) => {
          const start = Dates.getDateFromISO(data?.PlanStartDate, null);
          const end = Dates.getDateFromISO(data?.PlanEndDate, null);
          return end && start ? (
            <Box
              css={`
                width: 100%;
                img {
                  margin-right: 8px;
                }
              `}
              flex="left"
            >
              <img src={isAfter(new Date(), end) ? CalendarX : Calendar} alt="Plan Calendar" />
              <Text label>{format(isAfter(new Date(), end) ? end : start, 'M/d/yyyy')}</Text>
            </Box>
          ) : (
            '-'
          );
        }}
      </Column>
      <Column width="15%" columnHeader="Benefits" columnKey="benefitCount">
        {(data) => data?.ProductCount}
      </Column>
      <Column width="15%" columnHeader="Comments">
        {(data) =>
          data?.comments > 0 ? (
            <Text
              label
              css={`
                border-radius: 16px;
                background-color: ${colors.purple};
                padding: 4px 8px;
                width: max-content;
                min-width: 24px;
                color: white;
                font-weight: bold;
              `}
            >
              {data?.comments}
            </Text>
          ) : (
            '-'
          )
        }
      </Column>
      <Column
        headerStyles={{ textAlign: 'right', paddingRight: '24px' }}
        width="5%"
        columnHeader="More"
        columnKey="moreOptions"
      >
        {(data) => (
          <Box
            css={`
              width: 100%;
            `}
            flex="right"
          >
            <Dropdown
              stopPropagation
              css={`
                background: none;
              `}
              button={() => (
                <Button icon sm>
                  <DotsThree />
                </Button>
              )}
            >
              <Box
                css={`
                  width: 150px;
                  border-radius: 8px;
                `}
              >
                <Box option onClick={() => dispatch({ type: 'delete', data })}>
                  <Text label>Delete</Text>
                </Box>
                {!('description' in data) ? (
                  <Box
                    option
                    onClick={() =>
                      dispatch({
                        type: 'add-description',
                        data: data?.ID,
                      })
                    }
                  >
                    <Text label>Add Description</Text>
                  </Box>
                ) : null}
                <Box option onClick={() => dispatch({ type: 'edit-name', data })}>
                  <Text label>Change Details</Text>
                </Box>
                <Box option onClick={() => dispatch({ type: 'duplicate', data })}>
                  <Text label>Duplicate</Text>
                </Box>
                <Box option onClick={() => dispatch({ type: 'delete', data })}>
                  <Text label>Delete</Text>
                </Box>
              </Box>
            </Dropdown>
          </Box>
        )}
      </Column>
    </Table>
  );
};
