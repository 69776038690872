import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CustomAxios } from '../redux/axios/axios';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const CarrierSelect = (props) => {
  const [loading, setLoading] = useState(false);

  const [carriers, setCarriers] = useState([
    {
      ID: '00000000-0000-0000-0000-000000000000',
      Name: 'Use Custom Carrier',
    },
  ]);

  const [value, setValue] = useState(carriers[0]);

  useEffect(() => {
    setValue(carriers.find((option) => option.ID === props.carrierId));
  }, [JSON.stringify(carriers), props.carrierId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let result = await CustomAxios.get(`/v1/carriers`);

        let c = [];

        if (!props.hideCustom) {
          c.push({
            ID: '00000000-0000-0000-0000-000000000000',
            Name: 'Use Custom Carrier',
            Group: 'Custom',
          });
        } else {
          c.push({
            ID: '00000000-0000-0000-0000-000000000000',
            Name: `${props.customLabel ? 'All Vendors' : 'No carrier information'}`,
            Group: 'None',
          });
        }

        setCarriers(c.concat(result.data));

        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div
        className={css`
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 24px;
        `}
      >
        {' '}
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      className={
        props.removeStyle
          ? ''
          : css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex: 1;
            `
      }
    >
      {!props.hideCarrierText && <div> Carrier:</div>}
      <div>
        <Autocomplete
          groupBy={(option) => option.Group || 'Select'}
          id="carrier_select"
          options={carriers}
          onChange={(event, newValue) => {
            if (newValue && newValue.ID) {
              if (props.handleLogoSelect) {
                props.handleSelect(newValue.ID, newValue.LogoURL);
              } else {
                props.handleSelect(newValue.ID);
              }
            }
          }}
          value={value}
          noOptionsText={
            props.hideCustom ? 'Not found' : 'Not found. Search for `Use Custom Carrier` to enter your own.'
          }
          disableClearable
          getOptionLabel={(option) => option.Name}
          style={{ width: 250 }}
          renderInput={(params) => (
            <TextField {...params} label={props.customLabel ? props.customLabel : 'Carrier'} variant="outlined" />
          )}
        />
      </div>
    </div>
  );
};
