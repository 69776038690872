import { Select as MUISelect } from '@material-ui/core';
import { css, cx } from '@emotion/css';
import { container, flex } from '../shared-styles';
import { colors } from '../styles';
import { Div } from './div';
import { Text, textTheme } from './text';

export const Select = ({ className = '', css: cssStyles = '', children, ...props }) => {
  return (
    <Div
      className={cx(
        css`
          position: relative;
          .MuiSelect-root {
            :focus {
              background: none;
            }
            width: 100%;
            outline: none;
            border: none;
            max-height: 100px;
          }
          .MuiOutlinedInput-notchedOutline {
            border-color: ${colors.gray[300]};
          }
          .brite-select {
            border-radius: 8px;
            outline: none;
            border: none;
          }
          background-color: white;
          border-radius: 8px;
          li,
          option {
            ${textTheme.body}
          }
        `,
        css`
          ${cssStyles}
        `,
        className
      )}
    >
      {!props?.value && props?.placeholder && (
        <Text
          css={`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin-left: 8px;
            ${flex('left')}
          `}
        >
          {props.placeholder}
        </Text>
      )}
      <MUISelect
        className="brite-select"
        variant="outlined"
        style={{
          width: '100%',
          height: '44px',
          ...(props?.style || {}),
        }}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          style: { maxHeight: '500px' },
          getContentAnchorEl: null,
        }}
        {...props}
      >
        {children}
      </MUISelect>
    </Div>
  );
};

export const Option = ({ children, ...rest }) => {
  return (
    <option
      className={css`
        ${textTheme?.label}
        ${container.hover}
        border-radius: 8px;
        padding: 8px;
        margin: 0 8px;
      `}
      {...rest}
    >
      {children}
    </option>
  );
};
