export const NON_PRE_TAX = [
  'accident_plan',
  'cancer_buyup',
  'critical_illness',
  'dental_buyup',
  'dental_ortho_buyup',
  'hospital_indemnity',
  'healthcaregov',
  'medicare_recommendation',
  'reference_based_pricing',
  'long_term_disability',
  'short_term_disability',
  'supplimental_life',
  'vision_buyup',
  'custom',
  'core',
  'default',
  'insurance_plan',
];

export const PLAN_NAME_PRODUCTS = [
  'accident_plan',
  'cancer_buyup',
  'critical_illness',
  'dental_buyup',
  'dental_ortho_buyup',
  'hospital_indemnity',
  'long_term_disability',
  'short_term_disability',
  'supplimental_life',
];

export const BUY_UP_PRODUCTS = [
  'dental_buyup',
  'dental_ortho_buyup',
  'vision_buyup',
  'long_term_disability',
  'short_term_disability',
  'critical_illness',
  'accident_plan',
  'hospital_indemnity',
];

export const PLANS_WITH_PREVIEWS = [
  'custom',
  'cancer_buyup',
  'vision_buyup',
  'critical_illness',
  'dental_buyup',
  'long_term_disability',
  'hospital_indemnity',
  'short_term_disability',
  'accident_plan',
  'supplimental_life',
  'dental_ortho_buyup',
  'accident_plan',
  'default',
  'core',
  'insurance_plan',
];

export const MEDICAL_TYPES = [
  'default',
  'core',
  'insurance_plan',
  'medicare_recommendation',
  'reference_based_pricing',
  'medicare_recommendation',
  'healthcaregov',
];

export const CORE_TYPES = [
  'dental_buyup',
  'vision_buyup',
  'dental_ortho_buyup',
  'supplimental_life',
  'short_term_disability',
  'long_term_disability',
  'basic_life_v2',
  'supplemental_life_v2',
];

export const ADDITIONAL_TYPES = ['critical_illness', 'accident_plan', 'hospital_indemnity', 'cancer_buyup'];

export const PRE_TAX_TYPES = ['hsa_buyup', 'fsa_buyup', 'dependant_care_fsa_buyup', 'limited_fsa_buyup'];

export const ALL_TYPES = [
  ...new Set([...MEDICAL_TYPES, ...CORE_TYPES, ...ADDITIONAL_TYPES, ...PRE_TAX_TYPES, ...NON_PRE_TAX]),
];

export const PRODUCT_QUESTIONS = {
  vision_buyup: 'Do I (or my dependents) currently wear glasses or contacts?',
  dental_buyup: 'Does having healthy teeth impact my overall health?',
  dental_ortho_buyup: 'Are you (or your dependants under age 19) considering orthodontics?',
  supplimental_life:
    'If I were to pass away, how would my loved ones pay living expenses, meet housing payments, or pay off debts?',
  short_term_disability: 'What would you do if you were unable to work?',
  long_term_disability: 'What would you do if you were unable to work?',
  cancer_buyup: 'Do I have a family history of cancer?',
  critical_illness: 'Do I have a family history of any critical illnesses?',
  hospital_indemnity: 'Am I, or are my dependents, either prone to hospital stays or expecting to give birth?',
  accident_plan: 'Am I, or are my dependents, prone to accidents?',
};

export const APL_PRODUCT_QUESTIONS = {
  ...PRODUCT_QUESTIONS,
  hospital_indemnity: 'If I were to have an overnight stay at the hospital, would I receive benefit funds?',
};

export const PRODUCT_HEADERS = {
  insurance_plan: 'Medical',
  dental_buyup: 'Dental Insurance',
  vision_buyup: 'Vision',
  dental_ortho_buyup: 'Dental + Orthodontics',
  long_term_disability: 'Long Term Disability',
  short_term_disability: 'Short Term Disability',
  supplimental_life: 'Supplemental Life',
  accident_plan: 'Accident Insurance',
  hospital_indemnity: 'Hospital Indemnity',
  cancer_buyup: 'Cancer Insurance',
  critical_illness: 'Critical Illness Insurance',
  hsa_buyup: 'Health Savings Account',
  fsa_buyup: 'Flexible Spending Account',
  supplemental_life_v2: 'Supplemental Life',
  basic_life_v2: 'Basic Life',
  limited_fsa_buyup: 'Limited FSA',
  dependant_care_fsa_buyup: 'Dependent Care FSA',
};

export const APL_PRODUCT_HEADERS = {
  ...PRODUCT_HEADERS,
  supplimental_life: 'Portable Term Life Insurance',
};

export const PROGRAMS = {
  telemedicine: 'Telemedicine',
  wellness_differential: 'Wellness Differential',
  disease_management_program: 'Disease Management',
  specialty_pharmacy_program: 'Specialty Pharmacy',
  health_reimbursement_arrangement: 'Health Reimbursement Arrangement',
  custom: 'Custom',
};
