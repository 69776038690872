import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';

export const useStateSync = (valueFn, deps) => {
  const [valueUpdatedAt, setValueUpdatedAt] = useState('');

  const [value, _setValue] = useState(typeof valueFn === 'function' ? valueFn() : valueFn);

  const getValue = () => {
    if (typeof valueFn === 'function') {
      const newValue = valueFn(value);
      return newValue;
    } else {
      return valueFn;
    }
  };

  const setValue = (...args) => {
    _setValue(...args);
    setValueUpdatedAt(new Date().toISOString());
  };

  const updateValue = () => {
    const newValue = getValue();
    setValue(newValue);
  };

  const useCompEffect = deps?.length ? useDeepCompareEffect : useEffect;

  useCompEffect(() => {
    updateValue();
  }, deps);

  const isSynced = isEqual(value, getValue());

  return [value, setValue, isSynced, valueUpdatedAt];
};
