import { css } from '@emotion/css';
import { get } from 'lodash';
import {
  AlignBottomSimple,
  AlignCenterHorizontalSimple,
  AlignCenterVerticalSimple,
  AlignLeftSimple,
  AlignRightSimple,
  AlignTopSimple,
} from 'phosphor-react';
import { Button, Div } from '../../../shared/components';
import { flex, px } from '../../../shared/shared-styles';

export const Alignment = ({
  item,
  onUpdate,
  hideHorizontal = false,
  hideVertical = false,
  hAlignKey = 'justifyContent',
  vAlignKey = 'alignItems',
}) => {
  const getAlignIcon = (type, value) => {
    if (type === hAlignKey) {
      const textAlign = get(item, `container.attributes.style.${hAlignKey}`, 'left');
      const isAligned = textAlign === value;
      return isAligned ? 'icon-active sm' : 'icon sm';
    } else if (type === vAlignKey) {
      const verticalAlign = get(item, `container.attributes.style.${vAlignKey}`, 'start');
      const isAligned = verticalAlign === value;
      return isAligned ? 'icon-active sm' : 'icon sm';
    }
  };

  const changeHAlignment = (value) => {
    onUpdate({ container: { attributes: { style: { [hAlignKey]: value } } } });
  };

  const changeVAlignment = (value) => {
    onUpdate({
      container: {
        attributes: {
          style: {
            display: 'flex',
            [vAlignKey]: value,
          },
        },
      },
    });
  };

  return (
    <Div
      css={css`
        ${flex('aic')}
      `}
    >
      {!hideHorizontal && (
        <Div
          css={css`
            ${flex('aic')} padding: 0 ${px.sm};
          `}
        >
          <Button
            hoverLabel="Left Align"
            styles={getAlignIcon(hAlignKey, 'left')}
            onClick={() => changeHAlignment('left')}
          >
            <AlignLeftSimple size={24} />
          </Button>
          <Button
            hoverLabel="Center Align"
            styles={getAlignIcon(hAlignKey, 'center')}
            onClick={() => changeHAlignment('center')}
          >
            <AlignCenterHorizontalSimple size={24} />
          </Button>
          <Button
            hoverLabel="Right Align"
            styles={getAlignIcon(hAlignKey, 'right')}
            onClick={() => changeHAlignment('right')}
          >
            <AlignRightSimple size={24} />
          </Button>
        </Div>
      )}
      {!hideVertical && (
        <Div
          css={css`
            ${flex('aic')} padding: 0 ${px.sm};
          `}
        >
          <Button
            hoverLabel="Top Align"
            styles={getAlignIcon(vAlignKey, 'start')}
            onClick={() => changeVAlignment('start')}
          >
            <AlignTopSimple size={24} />
          </Button>
          <Button
            hoverLabel="Center Align"
            styles={getAlignIcon(vAlignKey, 'center')}
            onClick={() => changeVAlignment('center')}
          >
            <AlignCenterVerticalSimple size={24} />
          </Button>
          <Button
            hoverLabel="Bottom Align"
            styles={getAlignIcon(vAlignKey, 'end')}
            onClick={() => changeVAlignment('end')}
          >
            <AlignBottomSimple size={24} />
          </Button>
        </Div>
      )}
    </Div>
  );
};
