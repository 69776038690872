import { css } from '@emotion/css';
import { merge } from 'lodash';
import { Copy, DotsSixVertical, GearSix, Trash, X } from 'phosphor-react';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { useState } from 'react';
import { ReactComponent as ListPlus } from '../../images/svg/list-plus.svg';
import { Button, Div, Modal, Text, Toggle, Icon } from '../../shared/components';
import { container, flex, px } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { useSearchParams } from '../../shared/use-search-params';
import { useStateSync } from '../../shared/use-state-sync';
import { copyLayoutRow, copyMultipleLayoutRows, editorUtils, modifyContent } from '../provider/utils';
import { ColorPicker } from './menu-components';
import { Tooltip } from '../../common/components/Tooltip';

const rowToolbar = css`
  position: absolute;
  top: 8px;
  right: 100%;
  padding-right: 24px;
  bottom: 0;
  min-height: 36px;
  min-width: 72px;
  ${flex('center')}
  .tools {
    ${container.box}
    ${flex('column')}
    position: absolute;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 8px;
    opacity: 0;
    pointer-events: auto;
    padding: 8px;
    left: 8px;
    top: calc(50% - 64px);
    width: 48px;
  }
  .multiSelect {
    pointer-events: auto;
  }
  :hover {
    z-index: 100;
    box-shadow: 10px 0 8px -4px rgba(0, 0, 0, 0.05);
    .tools {
      opacity: 1;
    }
  }
`;

const dragTool = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 48px;
  ${flex('center')}
  .tools {
    cursor: grab;
    opacity: 0;
    :hover {
      background: none;
    }
  }
  :hover {
    z-index: 100;
    box-shadow: -10px 0 8px -4px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    .tools {
      opacity: 1;
    }
  }
`;

export const RowToolbar = ({ data, index, editor }) => {
  const showPDFDownloadFF = useFeatureFlagPayload('show-pdf-download')?.value;

  const { params, updateParams } = useSearchParams();

  const {
    state: { multiSelect, layout },
  } = editor;

  const [row, setRow] = useStateSync({ ...data }, [data]);

  const [modal, setModal] = useState(false);

  const resetRow = () => {
    setRow(data);
    setModal('');
  };

  const deleteRow = () => {
    const rows = [...layout?.rows].filter(({ rowId }) => rowId !== data.rowId);
    modifyContent?.set(editor, [], rows, { resetSelection: true });
  };

  const copyRow = () => {
    const rows = copyLayoutRow(layout, index);
    modifyContent?.set(editor, [], rows, { resetSelection: true });
  };

  const copyMultipleRows = () => {
    const rows = copyMultipleLayoutRows(layout, multiSelect.rows, multiSelect.end);
    modifyContent?.set(editor, [], rows, { resetSelection: true });
  };

  const deleteMultipleRows = () => {
    const rows = [...layout?.rows].filter((_, idx) => {
      return !(idx >= multiSelect?.start && idx <= multiSelect?.end);
    });
    modifyContent?.set(editor, [], rows, { resetSelection: true });
  };

  const updateViewMode = () => {
    const selectedRowIdx = [index];
    modifyContent?.set(editor, [selectedRowIdx], row, { resetSelection: true });
    setModal('');
  };

  const dragRow = (event) => {
    event.preventDefault();
    let start, end;
    if (multiSelect?.isSelecting) {
      start = multiSelect?.start;
      end = multiSelect?.end;
    } else {
      start = index;
      end = index;
    }
    editorUtils?.setProperty(editor, 'drag', {
      type: multiSelect?.isSelecting ? 'group' : 'row',
      action: 'multi-move',
      source: { start, end },
      isDragging: true,
    });
  };

  const openBlockModal = (event) => {
    event.stopPropagation();
    updateParams({ courseModal: 'save-content-block' });
  };

  const updateStyle = (updates) => {
    const style = merge({}, row.style || {}, updates);
    setRow({ ...row, style });
  };

  const getNumber = (value) => {
    const style = row?.style;
    return style?.[value]?.replace(/\D/g, '') || 0;
  };

  const updateRadius = (key) => {
    const radius = getNumber(key);
    if (radius !== 0 && radius !== '0') {
      updateStyle({ [key]: '0' });
    } else {
      updateStyle({ [key]: '8px' });
    }
  };

  return (
    <>
      {params?.mode === 'collaborating' ? null : multiSelect?.isSelecting &&
        'end' in multiSelect &&
        index === multiSelect?.start ? (
        <>
          <Tooltip label={'Move Group'}>
            <Div css={dragTool}>
              <Button draggable styles="icon sm" onDragStart={dragRow} className="tools">
                <DotsSixVertical size={24} color={colors.gray[500]} />
              </Button>
            </Div>
          </Tooltip>
          <Div className={rowToolbar}>
            <Div className="tools">
              <Button hoverLabel="Save Block" hoverPlacement="left" styles="icon sm" onClick={openBlockModal}>
                <Icon SVG={ListPlus} size={24} color={colors.gray[500]} />
              </Button>
              <Button hoverLabel="Copy Group" hoverPlacement="left" styles="icon sm" onClick={copyMultipleRows}>
                <Copy size={24} color={colors.gray[500]} />
              </Button>
              <Button hoverLabel="Delete Group" hoverPlacement="left" styles="icon sm" onClick={deleteMultipleRows}>
                <Trash size={24} color={colors.gray[500]} />
              </Button>
            </Div>
          </Div>
        </>
      ) : (
        !multiSelect?.isSelecting && (
          <>
            <Tooltip label={'Move Row'}>
              <Div css={dragTool}>
                <Button draggable styles="icon sm" onDragStart={dragRow} className="tools">
                  <DotsSixVertical size={24} color={colors.gray[500]} />
                </Button>
              </Div>
            </Tooltip>
            <Div className={rowToolbar}>
              <Div className="tools">
                <Button
                  hoverLabel="Row Settings"
                  hoverPlacement="left"
                  styles="icon sm"
                  onClick={() => setModal('row-settings')}
                >
                  <GearSix size={24} color={colors.gray[500]} />
                </Button>
                <Button hoverLabel="Copy Row" hoverPlacement="left" styles="icon sm" onClick={copyRow}>
                  <Copy size={24} color={colors.gray[500]} />
                </Button>
                <Button hoverLabel="Delete Row" hoverPlacement="left" styles="icon sm" onClick={deleteRow}>
                  <Trash size={24} color={colors.gray[500]} />
                </Button>
              </Div>
            </Div>
          </>
        )
      )}
      <Modal display={modal === 'row-settings'} onClose={() => setModal('')}>
        <Div
          css={css`
            padding: ${px.xl};
            width: 500px;
          `}
        >
          <Div
            css={css`
              ${flex('jcsb aic')} margin-bottom: ${px.md};
            `}
          >
            <Text styles="h2">Row Settings</Text>
            <Button styles="icon" onClick={() => setModal('')}>
              <X />
            </Button>
          </Div>

          <Div
            css={css`
              ${flex('jcsb aic')} margin-top: ${px.xs};
            `}
          >
            <Text styles={`mr label ${row?.hideOnDesktop ? 'bold' : ''}`}>Hide On Desktop</Text>
            <Toggle
              onClick={() => setRow({ ...row, hideOnDesktop: !row?.hideOnDesktop })}
              checked={row?.hideOnDesktop}
            />
          </Div>
          <Div
            css={css`
              ${flex('jcsb aic')} margin-top: ${px.xs};
            `}
          >
            <Text styles={`mr label ${row?.hideOnMobile ? 'bold' : ''}`}>Hide On Mobile</Text>
            <Toggle onClick={() => setRow({ ...row, hideOnMobile: !row?.hideOnMobile })} checked={row?.hideOnMobile} />
          </Div>
          {showPDFDownloadFF && (
            <Div
              css={css`
                ${flex('jcsb aic')} margin-top: ${px.xs};
              `}
            >
              <Text styles={`mr label ${row?.hideOnExport ? 'bold' : ''}`}>Hide On Export</Text>
              <Toggle
                onClick={() => setRow({ ...row, hideOnExport: !row?.hideOnExport })}
                checked={row?.hideOnExport}
              />
            </Div>
          )}
          <Div
            css={css`
              ${flex('jcsb aic')} margin-top: ${px.xs};
            `}
          >
            <Text styles={`mr label ${row?.stackColumns ? 'bold' : ''}`}>Stack Columns On Mobile</Text>
            <Toggle onClick={() => setRow({ ...row, stackColumns: !row?.stackColumns })} checked={row?.stackColumns} />
          </Div>

          <Text styles="h4 mv mt-xl bold">Row Styles</Text>

          <Div
            css={css`
              ${flex('jcsb aic')} margin: ${px.md} 0;
              margin-right: ${px.md};
            `}
          >
            <Text styles="mr label">Background Color</Text>
            <ColorPicker
              title="Row Background Color"
              updateColor={(backgroundColor) => updateStyle({ backgroundColor })}
              currentColor={row?.style?.backgroundColor || 'rgba(255, 255, 255, 0)'}
            />
          </Div>
          <Div
            css={css`
              ${flex('jcsb aic')} margin-top: ${px.xs};
            `}
          >
            <Text styles={`mr label ${row?.fullScreenWidth ? 'bold' : ''}`}>Full Screen Width</Text>
            <Toggle
              onClick={() => setRow({ ...row, fullScreenWidth: !row?.fullScreenWidth })}
              checked={row?.fullScreenWidth}
            />
          </Div>
          <Div
            css={css`
              ${flex('jcsb ais')} margin: ${px.xs} 0;
            `}
          >
            <Text styles="mr label">Corners</Text>
            <Div>
              <Div
                css={css`
                  ${flex('aic')}
                `}
              >
                <Button styles="icon sm" onClick={() => updateRadius('borderTopLeftRadius')}>
                  <div
                    className={css`
                      width: 24px;
                      height: 24px;
                      padding: 8px;
                      border-top-left-radius: ${2 * getNumber('borderTopLeftRadius')}px;
                      border-top: 4px solid ${colors.gray[400]};
                      border-left: 4px solid ${colors.gray[400]};
                    `}
                  />
                </Button>
                <Button styles="icon sm" onClick={() => updateRadius('borderTopRightRadius')}>
                  <div
                    className={css`
                      width: 24px;
                      height: 24px;
                      padding: 8px;
                      border-top-right-radius: ${2 * getNumber('borderTopRightRadius')}px;
                      border-top: 4px solid ${colors.gray[400]};
                      border-right: 4px solid ${colors.gray[400]};
                    `}
                  />
                </Button>
              </Div>
              <Div
                css={css`
                  ${flex('aic')}
                `}
              >
                <Button styles="icon sm" onClick={() => updateRadius('borderBottomLeftRadius')}>
                  <div
                    className={css`
                      width: 24px;
                      height: 24px;
                      padding: 8px;
                      border-bottom-left-radius: ${2 * getNumber('borderBottomLeftRadius')}px;
                      border-bottom: 4px solid ${colors.gray[400]};
                      border-left: 4px solid ${colors.gray[400]};
                    `}
                  />
                </Button>
                <Button styles="icon sm" onClick={() => updateRadius('borderBottomRightRadius')}>
                  <div
                    className={css`
                      width: 24px;
                      height: 24px;
                      padding: 8px;
                      border-bottom-right-radius: ${2 * getNumber('borderBottomRightRadius')}px;
                      border-bottom: 4px solid ${colors.gray[400]};
                      border-right: 4px solid ${colors.gray[400]};
                    `}
                  />
                </Button>
              </Div>
            </Div>
          </Div>
          <Div
            css={css`
              ${flex('jcr aic')} margin-top: ${px.xl};
            `}
          >
            <Button styles="secondary mr" onClick={resetRow}>
              Cancel
            </Button>
            <Button onClick={updateViewMode}>OK</Button>
          </Div>
        </Div>
      </Modal>
    </>
  );
};
